import dayjs from "dayjs";
import { create } from "zustand";
import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import { client } from "../../urqlClient";
import { Missions_AnalyzeMissionStatusQuery } from "../../gql/graphql";
import _ from "lodash";
import produce from "immer";

const Missions_AnalyzeMissionStatus = graphql(/* GraphQL */ `
  query Missions_AnalyzeMissionStatus($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      missionPublicationId

      # missionAnalytic {
      #   totalCashValue
      #   totalCashValue_NOSAFE
      #   pauschaleHausType
      #   pauschaleSaveType
      #   duration_min
      #   expectedDuration
      #   expectedDuration_NOSAFE
      #   cashPerMinute
      #   cashPerMinute_NOSAFE
      #   durationDeviation_min
      #   durationCorrection_min
      # }

      memberId

      patient {
        patientId
        lastName
        firstName
        latitude
        longitude
      }
      time
      startTS
      endTS
      duration_min
      day
      tourId
      isActive
      isChecked
    }
  }
`);

type LocalMissions_AnalyzeMissionStatus = ArrElement<
  Missions_AnalyzeMissionStatusQuery["missionsTimeRange"]
>;

type GroupedMissions = {
  patientId: string;
  patient: LocalMissions_AnalyzeMissionStatus["patient"];
  missionsAll: LocalMissions_AnalyzeMissionStatus[];
  missionsChecked: LocalMissions_AnalyzeMissionStatus[];
  missionsNotChecked: LocalMissions_AnalyzeMissionStatus[];
};

type AnalyzeMissionStatusState = {
  dateRange: { startDate: Date; endDate: Date };
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  missions: LocalMissions_AnalyzeMissionStatus[];
  groupedMissions: GroupedMissions[];
  allPatients: LocalMissions_AnalyzeMissionStatus["patient"][];
  lastUpdated: string;

  loadData: () => void;
  updateData(): void;
};

export const useAnalyzeMissionStatus = create<AnalyzeMissionStatusState>(
  (set, get) => ({
    dateRange: {
      startDate: dayjs().startOf("month").subtract(1, "month").toDate(),
      endDate: dayjs().endOf("month").subtract(1, "month").toDate(),
    },
    lastUpdated: (dayjs("2021-01-01").unix() * 1000).toString(),
    missions: [],
    groupedMissions: [],
    allPatients: [],
    setDateRange: (range) => {
      set({
        missions: [],
        groupedMissions: [],
        lastUpdated: (dayjs("2021-01-01").unix() * 1000).toString(),
      });
      set({ dateRange: range });
      get().loadData();
    },
    loadData: async () => {
      console.log("loadData", dayjs(Number(get().lastUpdated)).toISOString());

      set({ groupedMissions: [] });
      const res = await client
        .query(
          Missions_AnalyzeMissionStatus,
          {
            filter: {
              minDate: dayjs(get().dateRange.startDate).format("YYYY-MM-DD"),
              maxDate: dayjs(get().dateRange.endDate).format("YYYY-MM-DD"),
              updatedLater: get().lastUpdated,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();

      console.log(res);
      set({
        missions: res?.data?.missionsTimeRange || [],
        lastUpdated: (dayjs().unix() * 1000).toString(),
      });

      const _allMissions = res?.data?.missionsTimeRange || [];

      if (_allMissions.length === 0) return;

      // Get all patients
      const _allPatients = _.chain(_allMissions)
        .map((m) => m.patient)
        .uniqBy((p) => p?.patientId)
        .value();

      set({ allPatients: _allPatients });

      // Group Missions by patentId

      const _groupped = _.chain(_allMissions)
        .groupBy((m) => m.patient?.patientId)
        .toPairs()
        .toArray()
        .map((m) => {
          return {
            missionsAll: m[1],
            patientId: m[0],
            patient: m[1][0].patient,
            missionsChecked: m[1].filter((m) => m.isChecked),
            missionsNotChecked: m[1].filter((m) => !m.isChecked),
          };
        })
        .value();

      console.log(_groupped);

      set({
        groupedMissions: _groupped,
        //  lastUpdated: (dayjs().unix() * 1000).toString(),
      });
    },
    updateData: async () => {
      // get updated missions
      const ret = await client
        .query(
          Missions_AnalyzeMissionStatus,
          {
            filter: {
              minDate: dayjs(get().dateRange.startDate).format("YYYY-MM-DD"),
              maxDate: dayjs(get().dateRange.endDate).format("YYYY-MM-DD"),
              updatedLater: get().lastUpdated,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();

      console.log(ret);

      const _updatedMissions = ret?.data?.missionsTimeRange;

      if (!_updatedMissions || _updatedMissions.length === 0) return;

      // Get all patients with updated missions
      const _distinctPatients = _.chain(_updatedMissions)
        .map((m) => m.patient)
        .uniqBy((p) => p?.patientId)
        .value();

      console.log(_distinctPatients);

      set({ lastUpdated: (dayjs().unix() * 1000).toString() });

      //update missions in groupedMissions for each patient

      for (let e of _distinctPatients) {
        const _newGroupedMissions = produce(get().groupedMissions, (draft) => {
          const index = draft.findIndex((m) => m.patientId === e?.patientId);
          if (index !== -1) {
            const _allMissions = [...draft[index].missionsAll]; //make a copy of the array

            for (let m of _updatedMissions) {
              const _missionIndex = _allMissions.findIndex(
                (m2) => m2.id === m.id
              );
              if (_missionIndex !== -1) {
                _allMissions[_missionIndex] = m;
              }
            }

            draft[index].missionsAll = _allMissions;
            draft[index].missionsChecked = _allMissions.filter(
              (m) => m.isChecked
            );
            draft[index].missionsNotChecked = _allMissions.filter(
              (m) => !m.isChecked
            );
          }
          return draft;
        });
        console.log("New Grouped Missons", _newGroupedMissions);
        set({ groupedMissions: _newGroupedMissions });
      }
    },
  })
);
