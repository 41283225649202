import React, { useState, useEffect } from "react";

import { usePlanSchedule } from "./usePlanSchedule";
import {
  InputUpdateScheduleDay,
  InputUpdateScheduleEvery,
  Shift,
} from "@/src/gql/graphql";
import { cn } from "../../../helpers/utils";
import { Checkbox } from "../../UiComponents/Checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Label } from "@radix-ui/react-dropdown-menu";
import { Button } from "../../UiComponents/Button";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { BiCalendarExclamation } from "react-icons/bi";

type TimeOption = string;

type Props = {
  minStartTime: TimeOption;
  maxStartTime: TimeOption;
  acceptedMinStartTime: TimeOption;
  acceptedMaxStartTime: TimeOption;
  scheduleId: string;
  shift: Shift;
  updateSchedule: ({
    id,
    input,
  }: {
    id: string;
    input: InputUpdateScheduleDay | InputUpdateScheduleEvery;
  }) => void;
};

const TimeSelectorPlanSchedule = (props: Props) => {
  const { createScheduleDay, patientId } = usePlanSchedule();
  const { updateSchedule } = props;

  const {
    minStartTime,
    maxStartTime,
    acceptedMaxStartTime,
    acceptedMinStartTime,
    scheduleId,
    shift,
  } = props;

  const [myShift, setMyShift] = useState<"früh" | "spät">("früh");
  const [timeEnfored, setTimeEnforced] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<TimeOption>(minStartTime);
  const [endTime, setEndTime] = useState<TimeOption>(maxStartTime);
  const [endTimeOptions, setEndTimeOptions] = useState<TimeOption[]>([]);
  const [_acceptedMinStartTime, setAcceptedMinStartTime] =
    useState<TimeOption>(acceptedMinStartTime);
  const [_acceptedMaxStartTime, setAcceptedMaxStartTime] =
    useState<TimeOption>(acceptedMaxStartTime);

  useEffect(() => {
    const initialEndTimeOptions = generateTimeOptionsBasedOnStart(startTime);
    setEndTimeOptions(initialEndTimeOptions);
  }, []);

  const generateTimeOptions = ({
    startHour,
    endHour,
    step,
  }: {
    startHour: number;
    endHour: number;
    step: number;
  }): TimeOption[] => {
    let times: TimeOption[] = [];
    for (let i = startHour; i < endHour; i++) {
      for (let j = 0; j < 60; j += step) {
        const hour = String(i).padStart(2, "0");
        const minute = String(j).padStart(2, "0");
        times.push(`${hour}:${minute}`);
      }
    }
    return times;
  };

  const generateTimeOptionsBasedOnStart = (
    selectedStartTime: TimeOption
  ): TimeOption[] => {
    const timeOptions = generateTimeOptions({
      startHour: 6,
      endHour: 24,
      step: 15,
    });

    const selectedIndex = timeOptions.indexOf(selectedStartTime);
    return timeOptions.slice(selectedIndex + 4);
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as TimeOption;
    const timeOptions = generateTimeOptions({
      startHour: 6,
      endHour: 24,
      step: 15,
    });

    const selectedIndex = timeOptions.indexOf(value);
    setStartTime(value);
    const newEndTimeOptions = generateTimeOptionsBasedOnStart(value);
    if (!newEndTimeOptions.includes(endTime)) {
      setEndTime(newEndTimeOptions[0]);
    }

    // Ensure acceptedMinStartTime is always before startTime
    if (selectedIndex === 0) {
      setAcceptedMinStartTime("05:45"); // You can default to this or another value of your choice
    } else {
      setAcceptedMinStartTime(timeOptions[selectedIndex - 1]);
    }

    setEndTimeOptions(newEndTimeOptions);
    updateSchedule({
      id: scheduleId,
      input: {
        acceptedMinStartTime: value,
        preferedMinStartTime: value,
        acceptedMaxStartTime: _acceptedMaxStartTime,
        preferedMaxStartTime: _acceptedMinStartTime,
      },
    });
  };

  return (
    <div className="flex flex-col bg-white ">
      <div className="flex flex-row">
        {/* <div className="flex flex-row items-center">
          <select
            value={_acceptedMinStartTime}
            onChange={(e) => {
              setAcceptedMinStartTime(e.target.value as TimeOption);
              updateSchedule({
                id: scheduleId,
                input: {
                  acceptedMinStartTime: e.target.value,
                },
              });
            }}
            className="block w-full bg-white text-xs"
          >
            {generateTimeOptions({
              startHour: 6,
              endHour: 24,
              step: 15,
            }).map(
              (time) =>
                time >= shift.shiftStartTime &&
                time <= shift.shiftEndTime && (
                  <option key={time} value={time}>
                    {time}
                  </option>
                )
            )}
          </select>
        </div> */}
        <div className="flex flex-row items-center">
          <select
            value={startTime}
            onChange={handleStartTimeChange}
            className="block w-full bg-white "
          >
            {generateTimeOptions({
              startHour: 6,
              endHour: 24,
              step: 15,
            }).map(
              (time, index, arr) =>
                time >= shift.shiftStartTime &&
                time <= shift.shiftEndTime && (
                  <option key={time} value={time}>
                    {time}
                  </option>
                )
            )}
          </select>
        </div>
        <div className="flex items-center">
          <select
            value={endTime}
            onChange={(e) => {
              setEndTime(e.target.value as TimeOption);
              // const endSelectedIndex = newEndTimeOptions.indexOf(endTime);
              // // Ensure acceptedMaxEndTime is always after endTime
              // if (endSelectedIndex === newEndTimeOptions.length - 1) {
              //   setAcceptedMaxEndTime("23:45"); // You can default to this or another value of your choice
              // } else {
              //   setAcceptedMaxEndTime(newEndTimeOptions[endSelectedIndex + 1]);
              // }
              updateSchedule({
                id: scheduleId,
                input: {
                  acceptedMaxStartTime: _acceptedMaxStartTime,
                  preferedMaxStartTime: e.target.value,
                },
              });
            }}
            className="block w-full bg-white  "
          >
            {endTimeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Button
            variant={"neutral-ghost"}
            onClick={() => {
              setTimeEnforced(!timeEnfored);
            }}
          >
            <BiCalendarExclamation
              className={cn("h-5 w-5", {
                "text-danger": timeEnfored,
                "text-gray-300": !timeEnfored,
              })}
            />
          </Button>
        </div>

        {/* <div className="flex items-center">
          <select
            value={_acceptedMaxStartTime}
            onChange={(e) => {
              setAcceptedMaxStartTime(e.target.value as TimeOption);
              updateSchedule({
                id: scheduleId,
                input: {
                  acceptedMaxStartTime: e.target.value,
                },
              });
            }}
            className="block w-full bg-white text-xs "
          >
            {generateTimeOptions({
              startHour: 6,
              endHour: 24,
              step: 15,
            }).map(
              (time) =>
                time >= shift.shiftStartTime &&
                time <= shift.shiftEndTime && (
                  <option key={time} value={time}>
                    {time}
                  </option>
                )
            )}
          </select>
        </div> */}
      </div>
      <div className="flex flex-row gap-2 justify-center text-xs">
        <button
          className={cn("", { "text-primary font-semibold": shift.id === "f" })}
          onClick={() => {
            updateSchedule({
              id: scheduleId,
              input: {
                shiftId: "f",
              },
            });
          }}
        >
          Früh
        </button>
        <button
          className={cn("", { "text-primary font-semibold": shift.id === "m" })}
          onClick={() => {
            updateSchedule({
              id: scheduleId,
              input: {
                shiftId: "m",
              },
            });
          }}
        >
          Mittag
        </button>
        <button
          className={cn("", { "text-primary font-semibold": shift.id === "s" })}
          onClick={() => {
            updateSchedule({
              id: scheduleId,
              input: {
                shiftId: "s",
              },
            });
          }}
        >
          Spät
        </button>
        <div className="flex flex-row gap-2 justify-center text-xs">
          <Button
            variant={"neutral-ghost"}
            onClick={() => {
              createScheduleDay({
                acceptedMaxStartTime: props.acceptedMaxStartTime,
                acceptedMinStartTime: props.acceptedMinStartTime,
                preferedMaxStartTime: minStartTime,
                preferedMinStartTime: maxStartTime,
                patientId: patientId,
                count: 1,
                shiftId: props.shift.id,
                weekTurnus: 1,
                allCodedActions: "",
                allDayIds: [],
                preferedDayIds: [],
              });
            }}
          >
            +
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TimeSelectorPlanSchedule;
