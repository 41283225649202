import React from "react";

import { usePatientInfoMessage } from "./usePatientInfoMessage";
import { useUserInfo } from "../../UserInfo/useUserInfo";
import { Button } from "../../UiComponents/Button";
import { Textarea } from "../../UiComponents/Textarea";
import { set } from "lodash";
import { DisplayPatientInfo } from "../DisplayPatientInfo";

type Props = {
  messageId: string;
  setOpen: (open: boolean) => void;
};

const PatientInfoMessageAction = (props: Props) => {
  const { messageId, setOpen } = props;
  const [messageText, setMessageText] = React.useState("");
  const {
    createPatientInfoMessageResponse,
    updatePatientInfoMessage,
    loadData,
    patientId,
    patientMessages,
  } = usePatientInfoMessage();
  const { user } = useUserInfo();

  const message = patientMessages.find((m) => m.id === messageId);

  return (
    <div className="flex flex-col gap-2">
      <div>
        <DisplayPatientInfo patientId={patientId || ""} />
      </div>
      <div>{message?.subject}</div>
      <div>
        <Textarea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
      </div>

      <div>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => {
              createPatientInfoMessageResponse({
                infoMessageId: messageId,
                response: messageText,
              });

              setOpen(false);
            }}
          >
            Kommentar
          </Button>
          <Button
            onClick={() => {
              createPatientInfoMessageResponse({
                infoMessageId: messageId,
                response: "Ist erledigt: " + user?.name + "\n" + messageText,
              });
              updatePatientInfoMessage(messageId, {
                status: "done",
              });
              loadData({ patientId: undefined });
              setOpen(false);
            }}
          >
            Erledigt
          </Button>
          <Button
            onClick={() => {
              createPatientInfoMessageResponse({
                infoMessageId: messageId,
                response: "Bin Dran: " + user?.name + "\n" + messageText,
              });
              updatePatientInfoMessage(messageId, {
                status: "pending",
                ownedBy: user?.id,
              });
              loadData({ patientId: undefined });
              setOpen(false);
            }}
          >
            Bin Dran
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoMessageAction;
