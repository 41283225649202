import React from "react";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import { Calendar } from "../UiComponents/Calendar";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import { set } from "lodash";

type DateInterval = {
  startDay: string;
  endDay: string;
};

type Props = {
  setWeek: (newWeek: DateInterval) => void;
  currentWeek: DateInterval;
  updateData?: () => void;
};

const WeekSelectStartSa = (props: Props) => {
  const { setWeek, currentWeek } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="flex flex-row gap-2">
      <Button
        onClick={() => {
          const newStartDay = new Date(currentWeek.startDay);
          newStartDay.setDate(newStartDay.getDate() - 7);
          const newEndDay = new Date(currentWeek.endDay);
          newEndDay.setDate(newEndDay.getDate() - 7);
          setWeek({
            startDay: newStartDay.toISOString().split("T")[0],
            endDay: newEndDay.toISOString().split("T")[0],
          });
        }}
      >
        -
      </Button>

      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button variant={"neutral-ghost"} onClick={() => setIsOpen(!isOpen)}>
            {dayjs(currentWeek.startDay).format("DD.MMM")} -{" "}
            {dayjs(currentWeek.endDay).format("DD.MMM")}
            {dayjs(currentWeek.startDay).format("YYYY") !==
            dayjs().format("YYYY")
              ? ` ${dayjs(currentWeek.startDay).format("YYYY")}`
              : null}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <Calendar
            mode="single"
            weekStartsOn={6}
            selected={dayjs().toDate()}
            onSelect={(selectedDate) => {
              if (selectedDate) {
                const newStartDate = getStartDateOfWeek(
                  dayjs(selectedDate).format("YYYY-MM-DD")
                );
                const newEndDate = getEndDateOfWeek(
                  dayjs(selectedDate).format("YYYY-MM-DD")
                );

                setWeek({
                  startDay: dayjs(newStartDate).format("YYYY-MM-DD"),
                  endDay: dayjs(newEndDate).format("YYYY-MM-DD"),
                });
                if (props.updateData) {
                  props.updateData();
                }
                setIsOpen(false);
              }
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>

      {/* <Button variant={"neutral-ghost"}>
        {dayjs(currentWeek.startDay).format("DD.MMM")} -{" "}
        {dayjs(currentWeek.endDay).format("DD.MMM")}
        {dayjs(currentWeek.startDay).format("YYYY") !==
        dayjs(currentWeek.endDay).format("YYYY")
          ? ` ${dayjs(currentWeek.startDay).format("YYYY")}`
          : null}
      </Button> */}
      <Button
        onClick={() => {
          const newStartDay = new Date(currentWeek.startDay);
          newStartDay.setDate(newStartDay.getDate() + 7);
          const newEndDay = new Date(currentWeek.endDay);
          newEndDay.setDate(newEndDay.getDate() + 7);
          setWeek({
            startDay: newStartDay.toISOString().split("T")[0],
            endDay: newEndDay.toISOString().split("T")[0],
          });
        }}
      >
        +
      </Button>
    </div>
  );
};

export default WeekSelectStartSa;
