import React, { useEffect, useState } from "react";

import { LocalTourDatas_TourPublish, useTourPublish } from "./useTourPublish";
import dayjs from "dayjs";
import _ from "lodash";
import { DisplayMemberSmall } from "../Member/DisplayMemberSmall";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import { Button } from "../UiComponents/Button";
import TourPublishDetail from "./TourPublishDetail";

import { client } from "../../../src/urqlClient";

import {
  GetLatesTourInfoMessage_TourMessagesDocument,
  GetLatesTourInfoMessage_TourMessagesQuery,
} from "../../../src/gql/graphql";
import { InfoIcon } from "lucide-react";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import {
  HiOutlineChatBubbleBottomCenter,
  HiOutlineChatBubbleLeft,
} from "react-icons/hi2";
import { useTourInfoMessage } from "../TourInfoMessage/useTourInfoMessage";
import LoginButton from "../Auth0/LoginButton";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import { MyDimmer } from "../UiComponents/MyDimmer";
import { Helmet } from "react-helmet-async";

const TourPublishElement = (props: {
  tourData: LocalTourDatas_TourPublish;
}) => {
  const { tourData } = props;
  const { day, tourId } = tourData;

  const { setSelectedTourId } = useTourPublish();

  const [hasMessage, setHasMessage] = useState(false);

  const { allMessages } = useTourInfoMessage();

  useEffect(() => {
    const variables = {
      day,
      tourId,
    };

    client
      .query<GetLatesTourInfoMessage_TourMessagesQuery>(
        GetLatesTourInfoMessage_TourMessagesDocument,
        variables
      )
      .toPromise()
      .then((result) => {
        if (result?.data?.tourInfoMessage) {
          setHasMessage(true);
        } else {
          setHasMessage(false);
        }
      });
  }, [day, tourId, allMessages]);

  return (
    <div
      key={tourData.tourId}
      className="px-4 grid grid-cols-[8fr,3fr,2fr,1fr,1fr,1fr] gap-2 items-center border-b-2 border-gray-200 "
    >
      <div className="">{tourData.tourName}</div>
      <div>
        <DisplayMemberShortName
          hasExamination={tourData.member?.hasExamination}
          shortName={tourData.member?.shortName}
          hasQualificationLevel={tourData.member?.hasQualificationLevel}
        />
      </div>
      <div className="text-xs">
        <div>{dayjs(tourData.latesUpdateSupabase).format("ddd DD. MMM")}</div>
        <div>{dayjs(tourData.latesUpdateSupabase).format("HH:mm:ss")}</div>
      </div>
      <div>{tourData.missionChanges?.length}</div>
      <div>
        {hasMessage ? (
          <div>
            <HiOutlineChatBubbleLeft className="w-5 h-5 text-blue-500" />
          </div>
        ) : null}
      </div>
      <div>
        {true ? (
          <Button
            size={"compact-sm"}
            variant={"neutral-ghost"}
            disabled={!tourData.canSentToMobile}
            onClick={() => {
              console.log("Handy", tourData.tourId);
              setSelectedTourId(tourData.tourId);
            }}
          >
            Details
          </Button>
        ) : null}
      </div>
    </div>
  );
};

type Props = {};

export const TourPublish = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return <TourPublish1 {...props} />;
};

const TourPublish1 = (props: Props) => {
  const {
    tourDatas,
    setSelectedDay,
    selectedDay,
    updateMissionSupabase,
    prevDay,
    nextDay,
    selectedTourId,
    setSelectedTourId,
    isLoading,
  } = useTourPublish();
  const [earlyTourDatas, setEarlyTourDatas] = useState<
    LocalTourDatas_TourPublish[]
  >([]);
  const [lateTourDatas, setLateTourDatas] = useState<
    LocalTourDatas_TourPublish[]
  >([]);

  useEffect(() => {
    setSelectedDay(dayjs().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    setEarlyTourDatas(
      _.orderBy(
        tourDatas.filter((tourData) => tourData.shift === "F"),
        ["tourName"],
        ["asc"]
      )
    );
    setLateTourDatas(
      _.orderBy(
        tourDatas.filter((tourData) => tourData.shift === "S"),
        ["tourName"],
        ["asc"]
      )
    );
    setSelectedTourId("");
  }, [tourDatas]);

  return (
    <>
      <Helmet>
        <title>Touren Mobile {selectedDay}</title>
      </Helmet>
      <div className=" overflow-y-hidden h-full">
        <div className="flex flex-row gap-4 p-2 overflow-y-hidden h-full">
          <div className="flex flex-col gap-2 w-[700px]  p-2 overflow-y-hidden">
            <div className="bg-white flex flex-row justify-center items-center gap-2">
              <Button
                onClick={() => {
                  prevDay();
                }}
              >
                -
              </Button>
              <div>{dayjs(selectedDay).format("dddd DD.MM.YYYY")}</div>
              <Button
                onClick={() => {
                  nextDay();
                }}
              >
                +
              </Button>
            </div>
            <div className="overflow-y-scroll ">
              <div className="bg-white ">
                <div className="flex flex-row gap-2 items-center justify-center">
                  <div className="text-xl font-semibold text-center">Früh</div>
                  <div>
                    <Button
                      disabled={earlyTourDatas.every(
                        (tourData) => !tourData.canSentToMobile
                      )}
                      onClick={() => {
                        const _tourIds = earlyTourDatas.map(
                          (tourData) => tourData.tourId
                        );
                        updateMissionSupabase(_tourIds);
                      }}
                    >
                      Alle Auf das Handy
                    </Button>
                  </div>
                </div>
                <div className="">
                  {earlyTourDatas.map((tourData) => (
                    <TourPublishElement tourData={tourData} />
                  ))}
                </div>
              </div>
              <div className="bg-white">
                <div className="flex flex-row gap-2 justify-center">
                  <div className="text-xl font-semibold text-center">Spät</div>
                  <div>
                    <Button
                      disabled={lateTourDatas.every(
                        (tourData) => !tourData.canSentToMobile
                      )}
                      onClick={() => {
                        const _tourIds = lateTourDatas.map(
                          (tourData) => tourData.tourId
                        );
                        updateMissionSupabase(_tourIds);
                      }}
                    >
                      Alle Auf das Handy
                    </Button>
                  </div>
                </div>
                <div>
                  {lateTourDatas.map((tourData) => (
                    <TourPublishElement tourData={tourData} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className=" bg-white w-96 h-full overflow-y-visible">
            <TourPublishDetail tourId={selectedTourId} />
          </div>
        </div>
        <MyDimmer open={isLoading} />
      </div>
    </>
  );
};

export default TourPublish;
