import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "../../helpers/utils";

export const buttonVariants = cva(
  // "font-semibold uppercase no-underline disabled:opacity-50 disabled:cursor-not-allowed",
  "disabled:opacity-50 disabled:cursor-not-allowed font-mono",
  {
    variants: {
      variant: {
        danger: "text-white bg-red-500 hover:bg-red-500 focus:red-500 ",
        success: "text-white bg-green-500 hover:bg-green-500 focus:green-500 ",
        primary:
          "text-white bg-primary hover:bg-primary_active focus:ring-primary_active ",
        secondary:
          "text-white bg-secondary hover:bg-secondary_active focus:ring-secondary_active",
        accent:
          "text-black bg-accent hover:bg-accent_active focus:ring-accent_actice",
        neutral:
          "text-white bg-neutral hover:bg-neutral_active focus:ring-neutral_actice ",

        "primary-outline":
          "text-primary bg-transparent hover:bg-primary_active hover:text-white focus:ring-primary_active border-[1px] border-primary",
        "secondary-outline":
          "text-secondary bg-transparent hover:bg-secondary_active focus:ring-secondary_active border-1 border-secondary",
        "accent-outline":
          "text-accent bg-transparent hover:bg-accent_active hover:text-black focus:ring-accent_actice border-[1px] border-accent",
        "neutral-outline":
          "text-netral bg-transparent hover:bg-neutral_active hover:text-white focus:ring-neutral_actice border-[1px] border-neutral",
        "neutral-ghost":
          "text-netral bg-transparent hover:bg-neutral_active hover:text-white",
        "primary-ghost":
          "text-primary bg-transparent hover:bg-primary_active hover:text-white",
        "success-ghost":
          "text-success bg-transparent hover:bg-neutral_active hover:text-white",
        "danger-ghost":
          "text-danger bg-transparent hover:bg-neutral_active hover:text-white",
        "danger-outline":
          "text-danger bg-transparent hover:bg-neutral_active hover:text-danger border-[1px] border-danger",
      },
      size: {
        sm: "text-sm px-3 py-1",
        md: "text-base px-4 py-2",
        lg: "text-lg px-6 py-3",
        "compact-sm": "text-sm px-1 py-1",
      },
      // disabled: {
      //   true: "disabled: opacity-50 cursor-not-allowed",
      // },
    },
    defaultVariants: {
      variant: "neutral-outline",
      size: "sm",
      //   disabled: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

// export const Button1: React.FC<ButtonProps> = ({
//   className,
//   variant,
//   size,
//   ...props
// }) => <button className={button({ variant, size, className })} {...props} />;

type FancyButtonProps = {
  children?: React.ReactNode;
} & ButtonProps;

export const Button = React.forwardRef<HTMLButtonElement, FancyButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        {...props}
      >
        {props.children}
      </button>
    );
  }
);
