import { create } from "zustand";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import dayjs from "dayjs";

type DateInterval = {
  min: Date;
  max: Date;
};

type GlobalServiceState = {
  currentMinDay: string;
  currentMaxDay: string;
  currentMinDate: Date;
  currentMaxDate: Date;
  currentSelectedDate: Date;
  currentSelectedDay: string;
  setCurrentDateInterval: (input: DateInterval) => void;
  setCurrentSelectedDate: (date: Date) => void;
  nextDay(): void;
  prevDay(): void;
  prevWeek(): void;
  nextWeek(): void;
};

export const useGlobalService = create<GlobalServiceState>((set, get) => ({
  currentMinDate: getStartDateOfWeek(),
  currentMaxDate: getEndDateOfWeek(),
  currentSelectedDate: new Date(),
  currentSelectedDay: dayjs(new Date()).format("YYYY-MM-DD"),
  currentMinDay: dayjs(getStartDateOfWeek()).format("YYYY-MM-DD"),
  currentMaxDay: dayjs(getEndDateOfWeek()).format("YYYY-MM-DD"),
  setCurrentDateInterval: ({ min, max }) => {
    set({
      currentMinDate: min,
      currentMaxDate: max,
      currentMinDay: dayjs(min).format("YYYY-MM-DD"),
      currentMaxDay: dayjs(max).format("YYYY-MM-DD"),
    });
  },

  nextWeek: () => {
    const min = dayjs(get().currentMinDate).add(7, "days").toDate();
    const max = dayjs(get().currentMaxDate).add(7, "days").toDate();
    set({
      currentMinDate: min,
      currentMaxDate: max,
      currentMinDay: dayjs(min).format("YYYY-MM-DD"),
      currentMaxDay: dayjs(max).format("YYYY-MM-DD"),
    });
  },
  prevWeek: () => {
    const min = dayjs(get().currentMinDate).subtract(7, "days").toDate();
    const max = dayjs(get().currentMaxDate).subtract(7, "days").toDate();
    set({
      currentMinDate: min,
      currentMaxDate: max,
      currentMinDay: dayjs(min).format("YYYY-MM-DD"),
      currentMaxDay: dayjs(max).format("YYYY-MM-DD"),
    });
  },
  nextDay: () => {
    get().setCurrentSelectedDate(
      dayjs(get().currentSelectedDate).add(1, "days").toDate()
    );
  },
  prevDay: () => {
    get().setCurrentSelectedDate(
      dayjs(get().currentSelectedDate).subtract(1, "days").toDate()
    );
  },
  setCurrentSelectedDate: (date: Date) => {
    set({
      currentSelectedDate: date,
      currentSelectedDay: dayjs(date).format("YYYY-MM-DD"),
    });
  },
}));
