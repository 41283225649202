import dayjs from "dayjs";
import produce from "immer";
import _ from "lodash";
import create from "zustand";
import { graphql } from "../../gql";
import {
  AllGendersQuery,
  PatientDenyGendersQuery,
  PatientDenyGendersQueryVariables,
  SetPatientDenyGenderActionsMutation,
  SetPatientDenyGenderActionsMutationVariables,
} from "../../gql/graphql";
import { ArrElement } from "../../helpers/typeHelpers";

import { client } from "../../urqlClient";

// type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
//   ? ElementType
//   : never;

// export type LocalPatientMission = ArrElement<
//   GetPatientMissionListQuery["missionsTimeRange"]
// >;

const AllGenders = graphql(/* GraphQL */ `
  query AllGenders {
    allGenders {
      genderId
      description
      displayName
    }
  }
`);

const PatientDenyGenders = graphql(/* GraphQL */ `
  query PatientDenyGenders($patientId: String!) {
    patientDenyGenders(patientId: $patientId) {
      genderId
      gender {
        displayName
      }
      patientId
      actionLnr
      action {
        lnr
        leistungsart
        leistungskomplex
        examinationRequired
      }
    }
  }
`);

const SetPatientDenyGenderActions = graphql(/* GraphQL */ `
  mutation SetPatientDenyGenderActions(
    $patientId: String!
    $genderId: String!
    $actionLnrs: [String!]!
  ) {
    setPatientDenyGenderActions(
      actionLnrs: $actionLnrs
      patientId: $patientId
      genderId: $genderId
    ) {
      patientId
    }
  }
`);

export type LocalGender = ArrElement<AllGendersQuery["allGenders"]>;
export type LocalPatientDenyGender = ArrElement<
  PatientDenyGendersQuery["patientDenyGenders"]
>;

interface IPatientDenyGender {
  allGenders: LocalGender[] | undefined;
  patientDenyGenders: LocalPatientDenyGender[] | undefined;
  patientId: string;
  distinctPatientDenyGenders: LocalPatientDenyGender[];
  loadAllGenders: () => void;
  setPatientId: (patientId: string) => void;
  setActions(actionLnrs: string[], genderId: string): void;
}

export const usePatientDenyGender = create<IPatientDenyGender>((set, get) => ({
  allGenders: [],
  distinctPatientDenyGenders: [],
  patientDenyGenders: [],
  patientId: "",
  setPatientId: async (patientId: string) => {
    get().loadAllGenders();
    const result = await client
      .query<PatientDenyGendersQuery, PatientDenyGendersQueryVariables>(
        PatientDenyGenders,
        { patientId },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();
    console.log("result", result);

    const distinctPatientDenyGenders = _.uniqBy(
      result?.data?.patientDenyGenders,
      "genderId"
    );

    set({
      patientId,
      patientDenyGenders: result?.data?.patientDenyGenders,
      distinctPatientDenyGenders,
    });
  },
  loadAllGenders: async () => {
    const allGenders = await client
      .query<AllGendersQuery>(AllGenders, {})
      .toPromise();
    console.log("allGenders", allGenders);
    set({ allGenders: allGenders?.data?.allGenders });
  },
  setActions: async (actionLnrs: string[], genderId: string) => {
    console.log("actionLnrs", actionLnrs);
    console.log("genderId", genderId);

    const ret = await client
      .mutation<
        SetPatientDenyGenderActionsMutation,
        SetPatientDenyGenderActionsMutationVariables
      >(SetPatientDenyGenderActions, {
        patientId: get().patientId,
        actionLnrs: actionLnrs,
        genderId: genderId,
      })
      .toPromise()
      .then((res) => {
        console.log("res", res);
        get().setPatientId(get().patientId);
        return res;
      });
  },
}));
