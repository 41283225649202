import _ from "lodash";

export const actionLnrsCoded2Object = (actionLnrCoded: string) => {
  //console.log(actionLnrCoded);
  const _actionsWithQantitiy = actionLnrCoded.split("_");

  //console.log(_actionsWithQantitiy);

  if ((actionLnrCoded = "")) return [];

  const _actions = [];

  for (let e of _actionsWithQantitiy) {
    const _quantity = e.split("x")[0];
    const _actionLnr = e.split("x")[1];

    if (_quantity === undefined || _actionLnr === undefined) continue;

    _actions.push({ quantity: Number(_quantity), lnr: _actionLnr });
  }
  //console.log(_actions);

  return _actions;
};

type ActionLnrsObject = {
  quantity: number;
  lnr: string;
};
export const actions2Coded = (actions: ActionLnrsObject[]) => {
  let _actionLnrsCoded = "";

  const _actions = _.orderBy(actions, ["lnr"], ["asc"]);

  for (let e of _actions) {
    _actionLnrsCoded += `${e.quantity}x${e.lnr}_`;
  }
  return _actionLnrsCoded.slice(0, -1);
};
