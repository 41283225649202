import React, { useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { LocalTourOptimize, useTourOptimize } from "./useTourOptimize";
import DisplayAction from "../Action/DisplayAction";
import { Button } from "../UiComponents/Button";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import {
  PopoverRadix,
  PopoverRadixContent,
  PopoverRadixTrigger,
} from "../UiComponents/PopoverRadix";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";
import classNames from "classnames";

type Props = {
  tourId: string;
  day: string;
};

const TourOptimize = (props: Props) => {
  const { tourData, loadData, aggregation } = useTourOptimize();

  const columnHelper = createColumnHelper<LocalTourOptimize>();

  const columns = [
    columnHelper.accessor("from.mission.patient", {
      cell: (info) => {
        const value = info.getValue();

        return (
          <div>
            <div
              className={classNames("text-xs", {
                "bg-purple-400": info.row.original.from?.type === "pause",
              })}
            >
              {value?.lastName}, {value?.firstName}( {value?.patientId})
            </div>
            <div className="text-xs text-gray-400">{value?.address}</div>
          </div>
        );
      },
      header: (info) => <div> von Patient</div>,
    }),

    // columnHelper.accessor("from.patientId", {
    //   cell: (info) => <div className="text-center">{info.getValue()}</div>,
    //   header: (info) => <div> von Patient</div>,
    // }),
    columnHelper.accessor("toPatient", {
      cell: (info) => {
        const _value = info.getValue();

        return (
          <div className="text-xs">
            {_value ? (
              <>
                <div>{_value.lastName}</div>
                <div className="text-gray-400">{_value.address}</div>
              </>
            ) : null}
          </div>
        );
      },
      header: (info) => <div> nach Patient</div>,
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => {
        return (
          <div>
            <PopoverRadix>
              <PopoverRadixTrigger>
                <div>
                  <InfoCircledIcon className="h-5 w-5" />
                </div>
              </PopoverRadixTrigger>
              <PopoverRadixContent>
                <div className="w-96 m-2 p-2">
                  <DisplayMissionAnalytic
                    missionId={info.row.original.from?.missionId || ""}
                  />
                </div>
              </PopoverRadixContent>
            </PopoverRadix>
          </div>
        );
      },
      header: (info) => <div> </div>,
    }),
    columnHelper.accessor("plannedTravelTime_sec", {
      cell: (info) => (
        <div>
          <div className="text-center text-xs">
            E:{Math.round(info.getValue() / 60)} m
          </div>
          <div className="text-center text-xs">
            G:{Math.round(info.row.original.calculatedTravelTime_sec / 60)} m
          </div>
        </div>
      ),
      header: (info) => <div>Fahrt</div>,
    }),
    // columnHelper.accessor("calculatedTravelTime_sec", {
    //   cell: (info) => (
    //     <div className="text-center">{Math.round(info.getValue() / 60)}</div>
    //   ),
    //   header: (info) => <div> Google Zeit</div>,
    // }),
    columnHelper.accessor("deviationTravelTime_sec", {
      cell: (info) => (
        <div>
          <div className="text-center text-xs">
            Abw.: {Math.round(info.getValue() / 60)}
          </div>
          <div className="text-center text-xs">
            Dist:{info.row.original.calculatedTravelDistance_m / 1000}
          </div>
        </div>
      ),
      header: (info) => <div> Fahrt</div>,
    }),
    // columnHelper.accessor("calculatedTravelDistance_m", {
    //   cell: (info) => (
    //     <div className="text-center">{info.getValue() / 1000}</div>
    //   ),
    //   header: (info) => <div> Goo Strecke</div>,
    // }),
    columnHelper.accessor("from.plannedDuration_sec", {
      cell: (info) => (
        <div>
          <div className="text-center text-xs">Plan:{info.getValue() / 60}</div>
          <div className="text-center text-xs">
            Soll:{(info.row.original.from?.calculatedDuration_sec || 0) / 60}
          </div>
        </div>
      ),
      header: (info) => <div>Zeit</div>,
    }),
    columnHelper.accessor("from.calculatedDuration_sec", {
      cell: (info) => (
        <div className="text-center">
          {(info.row.original.from?.plannedDuration_sec || 0) / 60 -
            info.getValue() / 60}
        </div>
      ),
      header: (info) => <div> Abwei.</div>,
    }),
    columnHelper.accessor("from.mission.missionAnalytic", {
      cell: (info) => {
        const value = info.getValue();
        return (
          <div className="flex flex-wrap text-xs flex-col">
            <div>{value?.totalCashValue_NOSAFE}</div>
            <div>{value?.cashPerMinute_NOSAFE}</div>
          </div>
        );
      },
      header: (info) => <div> EUR</div>,
    }),

    columnHelper.accessor("from.mission.actionsWithQuantity", {
      cell: (info) => {
        const value = info.getValue();

        return (
          <div className="flex flex-wrap text-xs">
            {value?.map((action) => {
              return (
                <div>
                  <DisplayAction action={action as any} />
                </div>
              );
            })}
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    columns,
    data: tourData,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const _input = {
      tourId: props.tourId,
      day: props.day,
    };

    loadData(_input);
  }, [props.tourId, props.day]);

  return (
    <div className="overflow-y-auto bg-white">
      <div>{JSON.stringify(aggregation, null, 2)}</div>
      <table className="bg-white">
        <thead className="sticky top-0 bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="p-2" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="odd:bg-gray-50 even:bg-gray-100">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-1">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default TourOptimize;
