import dayjs from "dayjs";
import produce from "immer";
import _ from "lodash";
import { create } from "zustand";
import {
  ActionIdWithQuantity,
  AddMissionToDayDocument,
  AddMissionToDayMutation,
  AddMissionToDayMutationVariables,
} from "../../generated/graphql";
import { client } from "../../urqlClient";

export type LocalMissionTemplate = {
  scheduleIdentifier: string;
  patientId: string;
  actionIdsWithQuantity: ActionIdWithQuantity[];
  startTS: Date;
  endTS: Date;
};

type IManageSchedules = {
  lastUpdated: string;
  newMissions: LocalMissionTemplate[];
  addMission: (mission: LocalMissionTemplate) => void;
  removeMission: (mission: LocalMissionTemplate) => void;
  hasMission: (scheduleIdentifier: string) => boolean;
  toogleMission: (mission: LocalMissionTemplate) => void;
  createMissions: () => void;
  clearMissions: () => void;
};

export const useManageSchedules = create<IManageSchedules>((set, get) => ({
  newMissions: [],
  lastUpdated: dayjs().unix().toString(),
  addMission: (mission: LocalMissionTemplate) => {
    console.log("addMission", mission);
    set((state) => ({
      newMissions: [...state.newMissions, mission],
    }));
  },
  removeMission: (mission: LocalMissionTemplate) => {
    console.log("removeMission", mission);

    const _newMissions = produce(get().newMissions, (draft) => {
      const index = draft.findIndex(
        (m) => m.scheduleIdentifier === mission.scheduleIdentifier
      );
      if (index > -1) {
        draft.splice(index, 1);
      }
    });
    set({ newMissions: _newMissions });
  },
  hasMission: (scheduleIdentifier: string) => {
    const index = _.findIndex(
      get().newMissions,
      (m) => m.scheduleIdentifier === scheduleIdentifier
    );
    return index !== -1;
  },
  toogleMission: (mission: LocalMissionTemplate) => {
    if (get().hasMission(mission.scheduleIdentifier)) {
      get().removeMission(mission);
    } else {
      get().addMission(mission);
    }
  },
  createMissions: () => {
    console.log("createMissions", get().newMissions);
    const _neweMisions = get().newMissions;

    _neweMisions.forEach((mission) => {
      const _tourId =
        dayjs(mission.startTS).hour() < 14 ? "F_B_NeueTour" : "S_B_NeueTour";

      const obj = {
        input: {
          day: dayjs(mission.startTS).format("YYYY-MM-DD"),
          mission: {
            patientId: mission.patientId,
            actionsWithQantity: mission.actionIdsWithQuantity,
            startTS: mission.startTS.toISOString(),
            endTS: mission.endTS.toISOString(),
            tourId: _tourId,
            memberId: "1000",
          },
        },
      } as AddMissionToDayMutationVariables;

      client
        .mutation<AddMissionToDayMutation, AddMissionToDayMutationVariables>(
          AddMissionToDayDocument,
          obj
        )
        .toPromise()
        .then((res) => {
          console.log("res", res);
          set({ newMissions: [], lastUpdated: dayjs().unix().toString() });
        });
    });
  },
  clearMissions: () => {
    set({ newMissions: [] });
  },
}));
