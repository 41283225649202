import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import { useGetPatientIdsNoActionQuery } from "../../generated/graphql";
import { Button } from "../UiComponents/Button";
import { usePatientMissionList } from "./usePatientMissionList";
import { useGlobalService } from "../GlobalService/useGlobalService";

type Props = {
  handleSave: () => void;
  setCurrentPatientId: (id: string) => void;
};

const PatientNoActionList = (props: Props) => {
  const { currentMinDay, currentMaxDay, prevWeek, nextWeek } =
    useGlobalService();
  // const _minDate = dayjs().subtract(14, "days").format("YYYY-MM-DD");
  // const _maxDate = dayjs().add(7, "days").format("YYYY-MM-DD");

  const _minDay = currentMinDay;
  const _maxDay = currentMaxDay;

  const { patientsNoGeoData, loadPatientsNoGeoData } = usePatientMissionList();

  const [result, reececuteQuery] = useGetPatientIdsNoActionQuery({
    variables: {
      filter: {
        minDate: currentMinDay,
        maxDate: currentMaxDay,
        hasAction: false,
      },
    },
    requestPolicy: "network-only",
  });
  const { data, fetching, error } = result;

  const [patients, setPatients] = useState<any[]>();

  useEffect(() => {
    loadPatientsNoGeoData();
  }, []);

  useEffect(() => {
    if (!data) return;

    const xxx = _.filter(data?.missionsTimeRange, (x) => {
      return x.patientId === null;
    });

    // console.log("xxxx", xxx);

    const _patients = _.chain(data?.missionsTimeRange)
      .uniqBy("patientId")
      .map((o) => {
        //     console.log(o);
        return o?.patient;
      })
      .orderBy((o) => Number(o?.patientId))
      .value();
    if (_patients !== null && _patients !== undefined) {
      setPatients(_patients);
    }
    console.log("_patientIds", _patients);
  }, [data]);

  useEffect(() => {
    reececuteQuery();
  }, [reececuteQuery, currentMinDay, currentMaxDay]);

  if (error) {
    console.log("error", error);
    return <div>error</div>;
  }

  if (fetching) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div>Keine Leistungen</div>
        <div className="flex flex-row flex-wrap">
          {patients?.map((p) => {
            if (p === null) return null;
            return (
              <div key={p.patientId}>
                <Button
                  className="m-1"
                  size={"compact-sm"}
                  variant={"neutral-outline"}
                  onClick={() => {
                    props.setCurrentPatientId(p.patientId);
                    props.handleSave();
                  }}
                >
                  {p.patientId}
                  {/* <div className="flex justify-between">
                    <div className="h-1 w-2 bg-yellow-500"></div>
                    {!p?.hasSchedule ? (
                      <div className="h-1 w-2 bg-red-500"></div>
                    ) : null}
                  </div> */}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div>Keine Geo Daten</div>
        {patientsNoGeoData?.map((p) => {
          if (p === null) return <></>;
          return (
            <Button
              className="m-1"
              key={p.patientId}
              size={"compact-sm"}
              variant={"neutral-outline"}
              onClick={() => {
                props.setCurrentPatientId(p.patientId);
                props.handleSave();
              }}
            >
              {p.patientId}
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default PatientNoActionList;
