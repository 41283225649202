import React, { useEffect } from "react";

import { useAnalyzeMissionStatus } from "./useAnalyzeMissionStatus";
import dayjs from "dayjs";
import { set } from "lodash";
import { Button } from "../UiComponents/Button";

type Props = {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  setPatientId: (patientId: string) => void;
  closeDialog: (status: boolean) => void;
};

const AnalyzeMissionStatus = (props: Props) => {
  const {
    dateRange,
    setDateRange,
    missions,
    groupedMissions,
    loadData,
    lastUpdated,
    updateData,
  } = useAnalyzeMissionStatus();

  useEffect(() => {
    console.log("AnalyzeMissionStatus");
    setDateRange(props.dateRange);
  }, [dateRange]);

  return (
    <>
      <div className="flex flex-col h-full overflow-y-hidden">
        <div>{dayjs(Number(lastUpdated)).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div className="flex flex-row">
          <Button
            onClick={() => {
              updateData();
            }}
          >
            Update
          </Button>
        </div>
        <div className="overflow-y-auto">
          <div className="flex flex-row overflow-y-auto">
            <div className="w-full bg-red-200 overflow-y-scroll">
              <span>Offen</span>
              {groupedMissions.map((group) => {
                return group.missionsNotChecked.length > 0 ? (
                  <div>
                    <Button
                      onClick={() => {
                        props.setPatientId(group.patientId);
                        props.closeDialog(false);
                      }}
                    >
                      {group.patientId}
                    </Button>
                    {group.patient?.lastName}, {group.patient?.firstName}{" "}
                    {group.missionsNotChecked.length}{" "}
                    {group.missionsChecked.length}
                  </div>
                ) : null;
              })}
            </div>
            <div className="w-full h-full bg-green-200 overflow-y-scroll">
              <span>Fertig</span>
              {groupedMissions.map((group) => {
                return group.missionsNotChecked.length === 0 ? (
                  <div>
                    <Button
                      onClick={() => {
                        props.setPatientId(group.patientId);
                        props.closeDialog(false);
                      }}
                    >
                      {group.patientId}
                    </Button>
                    {group.patient?.lastName}, {group.patient?.firstName}{" "}
                    {group.missionsNotChecked.length}{" "}
                    {group.missionsChecked.length}
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyzeMissionStatus;
