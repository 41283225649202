import { create } from "zustand";
import { graphql } from "../../gql";

import {
  MissionAnalyticInfoQuery,
  MissionAnalyticInfoQueryVariables,
  MissionAnalyticInfoDocument,
} from "../../gql/graphql";
import { client } from "../../urqlClient";

const MissionAnalyticInfo = graphql(/* GraphQL */ `
  query MissionAnalyticInfo($id: String!) {
    mission(id: $id) {
      id
      tourId
      duration_min
      actionsWithQuantity {
        quantity
        actionLnr
        action {
          lnr
          leistungsart
          examinationRequired
          leistungsinhalte
          leistungskomplex
          executable
          mobileDisplayGroup
        }
      }
      missionAnalytic {
        totalCashValue
        totalCashValue_NOSAFE
        pauschaleHausType
        pauschaleSaveType
        duration_min
        expectedDuration
        expectedDuration_NOSAFE
        cashPerMinute
        cashPerMinute_NOSAFE
        durationDeviation_min
        totalPauschaleElements
        totalPauschaleCashValue
        timedElements
        complexedGrundElements
        behandlungMaxElements
        behandlungAdditionalElements
        allActions
        grundCashValue
        behandlungMaxCashValue
        timedCashValue
        behandlungAdditionalCashValue
        totalPauschaleElements
      }
    }
  }
`);

type LocalMissionAnalyticInfo = MissionAnalyticInfoQuery["mission"];

type DisplayMissionAnalyticState = {
  mission: LocalMissionAnalyticInfo | undefined | null;
  loadMissionAnalyticInfo: (id: string) => void;
};

export const useDisplayMissionAnalytic = create<DisplayMissionAnalyticState>(
  (set, get) => ({
    mission: undefined,
    loadMissionAnalyticInfo: async (id: string) => {
      console.log("loadMissionAnalyticInfo");
      const result = await client
        .query<MissionAnalyticInfoQuery, MissionAnalyticInfoQueryVariables>(
          MissionAnalyticInfo,
          { id: id },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();
      if (result.error) console.log(result.error);
      if (result.data) console.log(result.data);

      set({ mission: result?.data?.mission });
    },
  })
);
