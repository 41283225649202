import React, { useEffect, useMemo } from "react";

import { LocalPatientsIssue, usePatientIssue } from "./usePatientIssue";
import { Button } from "../UiComponents/Button";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { get } from "lodash";
import { getStartDateOfWeek } from "../../helpers/datetime";
import { getEndDateOfWeek } from "../../helpers/datetime";
import dayjs from "dayjs";
import { useGlobalService } from "../GlobalService/useGlobalService";
import GlobalWeekSelect from "../GlobalService/GlobalWeekSelect";
import { Link } from "react-router-dom";
import { MdEuro } from "react-icons/md";

const columnHelper = createColumnHelper<LocalPatientsIssue>();

type Props = {
  // handleSave: (value: boolean) => void;
  // setCurrentPatientId: (id: string) => void;
};

const PatientIssue = (props: Props) => {
  const {
    loadPatientIssues,
    patientIssues,
    isLoading,
    analyzeTravelTime,
    analyzeResults,
  } = usePatientIssue();
  const { currentMinDay, currentMaxDay } = useGlobalService();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor("patientId", {
        header: () => <div className="text-xs">PatientId</div>,
        cell: (info) => {
          return (
            <Link
              target="_blank"
              className="cursor-pointer"
              to={`/manageschedules/${info.getValue()}`}
            >
              <Button className="text-xs">{info.getValue()}</Button>
            </Link>
          );
        },
      }),
      columnHelper.accessor("patient", {
        header: () => <div className="text-xs">Patient</div>,
        cell: (info) => {
          const _patient = info.getValue();

          if (_patient === null || _patient === undefined) return null;

          return (
            <div className="text-xs font-semibold flex flex-col items-center">
              <div>{info.getValue()?.lastName}</div>
              <div>{info.getValue()?.firstName}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor("countAllMissions", {
        cell: (info) => (
          <div className="text-xs text-center">{info.getValue()}</div>
        ),
        header: () => <div className="text-xs">Anzahl</div>,
      }),
      columnHelper.accessor("sumTotalCashValue", {
        cell: (info) => (
          <div className="text-xs text-center">{info.getValue()}</div>
        ),
        header: () => (
          <div className="text-xs flex flex-col items-center">
            Total <MdEuro />
          </div>
        ),
      }),
      columnHelper.accessor("sumCashValue_NOSAVE", {
        cell: (info) => (
          <div className="text-xs text-center">{info.getValue()}</div>
        ),
        header: () => (
          <div className="text-xs flex flex-col items-center">
            nosave
            <MdEuro />
          </div>
        ),
      }),
      columnHelper.accessor("avgCashPerMinute_NOSAVE", {
        cell: (info) => (
          <div className="text-xs text-center">{info.getValue()}</div>
        ),
        header: () => (
          <div className="flex flex-col text-xs items-center">
            <div>nosave</div>
            <div className="text-xs flex flex-row items-center">
              <MdEuro />
              /min
            </div>
          </div>
        ),
      }),

      columnHelper.accessor("totalUnderPerf_min", {
        cell: (info) => (
          <>
            <div className="text-xs text-center bg-red-100">
              {info.row.original.totalUnderPerf_min} Min
            </div>
            <div className="text-xs text-center bg-red-100">
              {info.row.original.countUnderPerf} Stk
            </div>
          </>
        ),
        header: () => <div className="text-xs">zu lange</div>,
      }),

      columnHelper.accessor("totalOverPerf_min", {
        cell: (info) => (
          <>
            <div className="text-xs text-center bg-yellow-100">
              {info.row.original.totalOverPerf_min} Min
              <div>{info.row.original.countOverPerf} Stk</div>
            </div>
          </>
        ),
        header: () => <div className="text-xs">zu kurz</div>,
      }),

      columnHelper.accessor("totalCompensationUnderPerf_min", {
        cell: (info) => (
          <>
            <div className="text-xs text-center bg-red-100">
              {info.row.original.totalCompensationUnderPerf_min} min
            </div>
            <div className="text-xs text-center bg-yellow-100">
              {info.row.original.totalCompensationOverPerf_min} min{" "}
            </div>
          </>
        ),
        header: () => <div className="text-xs">Akzeptiert</div>,
      }),

      columnHelper.display({
        id: "actions",
        header: () => <div className="text-xs">Aktionen</div>,
        cell: (info) => {
          const _patientId = info.row.original.patientId;

          return (
            <Button
              className="text-xs"
              onClick={() => {
                analyzeTravelTime(_patientId);
              }}
            >
              Analyse
            </Button>
          );
        },
      }),
    ];
    return columns;
  }, [patientIssues]);

  const table = useReactTable({
    data: patientIssues,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    const _weekStart = currentMinDay;
    const _weekEnd = currentMaxDay;

    loadPatientIssues(currentMinDay, currentMaxDay);
  }, [currentMinDay, currentMaxDay]);

  const _weekStart = getStartDateOfWeek();
  const _weekEnd = getEndDateOfWeek();

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="flex w-full h-full overflow-y-hidden ">
      <div className="m-2 overflow-y-scroll bg-white">
        <div>
          <div>
            <GlobalWeekSelect />
          </div>
          <Button
            variant={"primary"}
            onClick={() => {
              const _weekStart = getStartDateOfWeek();
              const _weekEnd = getEndDateOfWeek();
              loadPatientIssues(
                dayjs(_weekStart).format("YYYY-MM-DD"),
                dayjs(_weekEnd).format("YYYY-MM-DD")
              );
            }}
          >
            Load
          </Button>
        </div>
        <div className="p-2 overflow-y-scroll">
          <table className="">
            <thead className="gap-2">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {table.getRowModel().rows.map((row) => (
                <tr className="p-2 m-2" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="p-4" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col bg-white gap-2 items-center overflow-y-scroll  p-2">
        {analyzeResults.map((result) => {
          return (
            <div>
              <div className="bg-slate-500 text-white">
                {result.day} {result.tourId}
              </div>
              <div className="flex flex-row gap-2 items-center justify-center">
                <div className="bg-gray-100">
                  {result.bevorPatientId} {result.beforePatientName}
                </div>
                <div className="flex-flex-col text-xs">
                  <div>{result.travelTimeFromPatient_min} min</div>
                  <div>{result.distanceFromPatient_km} km</div>
                </div>
                <div className="bg-green-100">
                  {result.currentPatientId} {result.curentPatientName}
                </div>
                <div className="flex flex-col text-xs">
                  <div>{result.travelTimeToPatient_min} min</div>
                  <div>{result.distanceToPatient_km} km</div>
                </div>
                <div className="bg-gray-100">
                  {result.afterPatientId} {result.afterPatientName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatientIssue;
