import { create } from "zustand";

import { graphql } from "../../../gql";
import { ArrElement } from "../../../helpers/typeHelpers";
import {
  GetPatientInfoMessagesQuery,
  GetPatientInfoMessagesQueryVariables,
  PatientInfoMessageUpdateInput,
} from "../../../gql/graphql";
import { client } from "../../../urqlClient";
import _ from "lodash";

const CreatePatientInfoMessageResponse = graphql(/* GraphQL */ `
  mutation CreatePatientInfoMessageResponse(
    $input: PatientInfoMessageResponseInput!
  ) {
    createPatientInfoMessageResponse(input: $input) {
      id
    }
  }
`);

const UpdatePatientInfoMessage = graphql(/* GraphQL */ `
  mutation UpdatePatientInfoMessage(
    $id: String!
    $input: PatientInfoMessageUpdateInput!
  ) {
    updatePatientInfoMessage(id: $id, input: $input) {
      id
    }
  }
`);

const ClosePatientInfoMessage = graphql(/* GraphQL */ `
  mutation ClosePatientInfoMessage($id: String!) {
    closePatientInfoMessage(id: $id) {
      id
    }
  }
`);

const PatientInfoMessages = graphql(/* GraphQL */ `
  query GetPatientInfoMessages(
    $patientId: String
    $onlyActive: Boolean
    $ownedBy: String
  ) {
    patientInfoMessages(
      patientId: $patientId
      onlyActive: $onlyActive
      ownedBy: $ownedBy
    ) {
      id
      patientId
      message
      messageType
      ownedBy
      subject
      patientInfoStatus {
        state
        description
      }
      status
      createdAt
      startDay
      endDay
      hasMatchingAbsence
      patient {
        patientId
        firstName
        lastName
      }
      responses {
        id
        createdAt
        createdBy
        response
      }
    }
  }
`);

const CreatePatientInfoMessage = graphql(/* GraphQL */ `
  mutation CreatePatientInfoMessage($input: PatientInfoMessageInput!) {
    createPatientInfoMessage(input: $input) {
      id
    }
  }
`);

export type LocalPatientInfoMessage = ArrElement<
  GetPatientInfoMessagesQuery["patientInfoMessages"]
>;

type PatientInfoMessageState = {
  commentInfoMessageId: string | undefined;
  showCreateAbsence: boolean;
  patientMessages: LocalPatientInfoMessage[];
  patientId: string | undefined;
  allPatientMessages: LocalPatientInfoMessage[];
  loadAllPatientMessages: () => void;
  loadData: ({ patientId }: { patientId?: string }) => void;
  setPatientId: (patientId: string) => void;
  createPatientInfoMessage: (message: {
    message: string;
    messageType: string;
    subject: string;
    startDay?: string;
    endDay?: string;
  }) => void;
  closePatientInfoMessage: (id: string) => void;
  updatePatientInfoMessage: (
    id: string,
    input: PatientInfoMessageUpdateInput
  ) => void;

  createPatientInfoMessageResponse: (response: {
    infoMessageId: string;
    response: string;
  }) => void;
  setShowCreateComment: (messageId: string | undefined) => void;
  setShowCreateAbsence: (show: boolean) => void;
};

export const usePatientInfoMessage = create<PatientInfoMessageState>(
  (set, get) => ({
    commentInfoMessageId: undefined,
    allPatientMessages: [],
    showCreateAbsence: false,
    patientId: undefined,
    patientMessages: [],
    setPatientId: (patientId: string) => {
      set({
        patientId,
      });
      get().loadData({});
    },
    loadData: async ({ patientId }) => {
      if (patientId) {
        set({
          patientId,
        });
      }

      const _patientId = get().patientId;
      console.log("loadData", _patientId);
      if (_patientId === "") {
        set({ patientMessages: [] });
        return;
      }
      client
        .query<
          GetPatientInfoMessagesQuery,
          GetPatientInfoMessagesQueryVariables
        >(
          PatientInfoMessages,
          {
            patientId: _patientId,
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          set({
            patientMessages: result?.data?.patientInfoMessages || [],
          });
        });
      get().loadAllPatientMessages();
    },
    createPatientInfoMessage: async (message) => {
      const _patientId = get().patientId;
      if (_patientId === undefined) {
        set({
          allPatientMessages: [],
        });

        return;
      }

      client
        .mutation(CreatePatientInfoMessage, {
          input: {
            patientId: _patientId,

            message: message.message,
            messageType: message.messageType,
            subject: message.subject,
            startDay: message.startDay,
            endDay: message.endDay,
          },
        })
        .toPromise()
        .then((result) => {
          get().loadData({ patientId: undefined });
        });
    },
    closePatientInfoMessage: async (id) => {
      client
        .mutation(ClosePatientInfoMessage, {
          id,
        })
        .toPromise()
        .then((result) => {
          get().loadData({ patientId: undefined });
        });
    },
    createPatientInfoMessageResponse: async (response) => {
      client
        .mutation(CreatePatientInfoMessageResponse, {
          input: {
            infoMessageId: response.infoMessageId,
            response: response.response,
          },
        })
        .toPromise()
        .then((result) => {
          get().loadData({ patientId: undefined });
        });
    },
    setShowCreateComment: (messageId) => {
      set({
        showCreateAbsence: false,
        commentInfoMessageId: messageId,
      });
    },
    setShowCreateAbsence: (show) => {
      set({
        showCreateAbsence: show,
      });
    },
    updatePatientInfoMessage: async (id, input) => {
      client
        .mutation(UpdatePatientInfoMessage, {
          id,
          input,
        })
        .toPromise()
        .then((result) => {
          console.log("updatePatientInfoMessage", result);
          get().loadData({ patientId: undefined });
        });
    },
    loadAllPatientMessages: async () => {
      client
        .query<
          GetPatientInfoMessagesQuery,
          GetPatientInfoMessagesQueryVariables
        >(
          PatientInfoMessages,
          {
            onlyActive: true,
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          console.log("allPatientMessages", result?.data?.patientInfoMessages);
          set({
            allPatientMessages: _.orderBy(
              result?.data?.patientInfoMessages || [],
              ["startDay"],
              ["asc"]
            ),
          });
        });
    },
  })
);
