import { create } from "zustand";

import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import {
  PatientSearchBoxQuery,
  PatientSearchBoxQueryVariables,
} from "../../gql/graphql";
import { client } from "../../urqlClient";
import _ from "lodash";

const PatientSearchBox = graphql(/* GraphQL */ `
  query PatientSearchBox($filter: PatientsFilter) {
    patients(filter: $filter) {
      patientId
      lastName
      firstName
      zipCode
      city
      address
      isActive
      isDa
      shortName
      isPrivate
    }
  }
`);

export type LocalPatientSearchBox = ArrElement<
  PatientSearchBoxQuery["patients"]
>;

interface PatientSearchboxState {
  patients: LocalPatientSearchBox[];
  filteredPatients: LocalPatientSearchBox[];
  filter: string;
  loadData: () => void;
  setFilter: (filter: string) => void;
  applyFilter: () => void;
}

export const usePatientSearchbox = create<PatientSearchboxState>(
  (set, get) => ({
    patients: [],
    filteredPatients: [],
    filter: "",

    loadData: async () => {
      client
        .query<PatientSearchBoxQuery, PatientSearchBoxQueryVariables>(
          PatientSearchBox,
          {
            filter: {
              daysOfLastMission: 60,
              isActive: true,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          set({
            patients: _.orderBy(result?.data?.patients, "lastName") || [],
            filteredPatients:
              _.orderBy(result?.data?.patients, "lastName") || [],
          });
        });
    },

    setFilter: (filter: string) => {
      set({ filter });
    },
    applyFilter: () => {
      const filteredPatients = get().patients.filter((patient) => {
        if (!patient) return false;
        if (!patient.firstName || !patient.lastName || !patient.patientId)
          return false;
        return (
          patient.firstName
            .toLowerCase()
            .includes(get().filter.toLowerCase()) ||
          patient.lastName.toLowerCase().includes(get().filter.toLowerCase()) ||
          patient.patientId.toLowerCase().includes(get().filter.toLowerCase())
        );
      });
      set({ filteredPatients });
    },
  })
);
