import React, { useEffect } from "react";
import { usePatientAbsence } from "./usePatientAbsence";

type Props = {
  patientId: string;
  numberLastEntries?: number;
};

const DisplayActivePatientAbsence = (props: Props) => {
  const { numberLastEntries } = props;
  const {
    allPatientAbsences,
    patientAbsences,
    activePatientAbsence,
    setPatientId,
    loadPatientAbsences,
  } = usePatientAbsence();
  const { patientId } = props;
  useEffect(() => {
    setPatientId(patientId);
    loadPatientAbsences();

    return () => {
      setPatientId("");
    };
  }, [patientId]);

  if (numberLastEntries !== undefined) {
    return (
      <div>
        {patientAbsences.slice(0, numberLastEntries).map((absence, index) => (
          <div key={index} className="flex flex-row gap-2 items-center">
            <div className="font-semibold text-xs w-32">{absence.startDay}</div>
            <div className="font-semibold text-xs w-32">{absence.endDay}</div>
            <div>{absence.remark}</div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="flex flex-row gap-2 items-center">
        <div className="font-semibold text-xs w-32">
          {activePatientAbsence?.startDay}
        </div>
        <div className="font-semibold text-xs w-32">
          {activePatientAbsence?.endDay}
        </div>
        <div>{activePatientAbsence?.remark}</div>
      </div>
    );
  }
};

export default DisplayActivePatientAbsence;
