import { cn } from "../../helpers/utils";

import React from "react";

type Props = {
  shortName?: string | undefined;
  hasExamination?: boolean | undefined;
  hasQualificationLevel?: string | undefined;
};

const DisplayMemberShortName = (props: Props) => {
  const { shortName, hasQualificationLevel } = props;

  if (shortName === undefined) return null;
  if (hasQualificationLevel === undefined) return null;

  //console.log("DisplayMemberShortName", props);
  return (
    <>
      {shortName !== undefined ? (
        <div
          className={cn("flex flex-row font-semibold", {
            "text-blue-500": hasQualificationLevel === "0",
            "text-gray-600": hasQualificationLevel === "8",
            "text-yellow-600": hasQualificationLevel === "2",
            "text-yellow-800": hasQualificationLevel === "3",
            "text-red-600": !["0", "2", "8", "3"].includes(
              hasQualificationLevel
            ),
          })}
        >
          <div>
            {shortName}
            <sub>{hasQualificationLevel}</sub>
          </div>
        </div>
      ) : null}
    </>
  );

  //   <span
  //     className={classNames("font-semibold", {
  //       "text-blue-500": !props.hasExamination,
  //       "text-gray-600": props.hasExamination,
  //     })}
  //   >
  //     [{props.shortName}]
  //   </span>
  // );
};

export default DisplayMemberShortName;
