import React from "react";

import { PatientSearchBox } from "../ManageSchedules/PatientSearchBox";
import { DisplayFullPatientInfo } from "../Patient/DisplayFullPatientInfo";
import DisplayScheduleByDay_old from "../Schedule/DisplayScheduleByDay_old";

import OptionsInput from "./OptionsInput";
import PatientWeekyView, { MoveableActionClipboard } from "./PatientWeekyView";
import { useAnalyzeMission } from "./useAnalyzeMission";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { PopoverRadixTrigger } from "../UiComponents/PopoverRadix";
import { PopoverRadix } from "../UiComponents/PopoverRadix";
import { PopoverRadixContent } from "../UiComponents/PopoverRadix";
import { Button } from "../UiComponents/Button";
import PatientSearchbox from "../PatientSearchbox/PatientSearchbox";
import AnalyzeMissionStatus from "../AnalyzeMissionStatus/AnalyzeMissionStatus";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import { Sheet, SheetContent, SheetTrigger } from "../UiComponents/Sheet";
import { cn } from "../../helpers/utils";
import MonthyOverview from "./MontlhyOverview";
import PatientCalendarView from "./PatientCalendarView";
import { MyDimmer } from "../UiComponents/MyDimmer";

type Props = {};

export const AnalyzeMission = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return <AnalyzeMission1 />;
};

const AnalyzeMission1 = (props: Props) => {
  const {
    loadMissions,
    setPatientId,
    patientId,
    patient,
    dateRange,
    isLoading,
  } = useAnalyzeMission();

  const [showSchedule, setShowSchedule] = React.useState(false);

  const [showCheckStatus, setShowSetStatus] = React.useState(false);

  //setPatientIds(["2710", "37"]);

  return (
    <>
      {/* <Dialog open={showCheckStatus} onOpenChange={setShowSetStatus}>
        <DialogContent
          title={"Freigabe Status"}
          className=" flex flex-col w-[60%] h-[70%]"
        >
          <AnalyzeMissionStatus
            dateRange={dateRange}
            setPatientId={setPatientId}
            closeDialog={setShowSetStatus}
          />
        </DialogContent>
      </Dialog> */}
      <div className="flex flex-col h-full w-full ">
        <div className="flex flex-col w-full ">
          <div className="flex flex-row bg-white w-full  ">
            {/* Start */}
            <div className="flex flex-row items-center ">
              <PopoverRadix>
                <PopoverRadixTrigger>
                  <Button>Leistungen</Button>
                </PopoverRadixTrigger>
                <PopoverRadixContent>
                  <div className="w-[50rem] m-4">
                    <DisplayScheduleByDay_old patientId={patientId} />
                  </div>
                </PopoverRadixContent>
              </PopoverRadix>

              <div className="p-2 bg-gray-100">
                {patient?.lastName} {patient?.firstName}, {patient?.patientId},{" "}
                {patient?.kkType}
              </div>
            </div>
            {/* Navbar Center */}
            <div className=" flex flex-row items-center ">
              <div className="w-max text-xl flex flex-row items-center">
                <div className="flex flex-row items-center">
                  <OptionsInput />
                </div>

                <div className="flex flex-row">
                  <Button onClick={loadMissions}>Laden</Button>
                </div>
                <div>
                  <PatientSearchbox onSelect={setPatientId} />
                </div>

                <div>
                  <Button
                    onClick={() => {
                      setShowSetStatus(!showCheckStatus);
                    }}
                  >
                    Status
                  </Button>
                </div>
                <PopoverRadix>
                  <PopoverRadixTrigger>
                    <Button>Übersicht</Button>
                  </PopoverRadixTrigger>
                  <PopoverRadixContent>
                    <div className="w-[30rem] m-4">
                      <MonthyOverview />
                    </div>
                  </PopoverRadixContent>
                </PopoverRadix>
              </div>
            </div>
            {/* NavBar end */}
            <div className="navbar-end"></div>
          </div>
        </div>
        <div className="flex flex-row w-full h-full ">
          {/* <AnalyzeMissionTable /> */}
          <div className="">
            {/* <PatientWeekyView /> */}
            <div className="">
              <PatientCalendarView />
              <MoveableActionClipboard />
              <div className="p-2 ">
                <div className="h-4" />
                <MyDimmer open={isLoading} />
              </div>
            </div>
          </div>
          {true && (
            <div
              className={cn(
                "absolute right-0 top-0 z-10 w-[600px] h-full bg-white p-2 ",
                {
                  hidden: !showCheckStatus,
                }
              )}
            >
              <Button
                onClick={() => {
                  setShowSetStatus(false);
                }}
              >
                Close
              </Button>
              <AnalyzeMissionStatus
                dateRange={dateRange}
                setPatientId={setPatientId}
                closeDialog={setShowSetStatus}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AnalyzeMission;
