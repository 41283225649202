import { graphql } from "../../gql";
import { GetLatesTourInfoMessage_TourMessagesQuery } from "@/src/gql/graphql";
import { client } from "../../urqlClient";
import { toast } from "react-hot-toast";

import { create } from "zustand";
import { t } from "xstate";

const GetTourInfoMessages_TourMessages = graphql(/* GraphQL */ `
  query GetTourInfoMessages_TourMessages($day: String!, $tourId: String!) {
    tourInfoMessages(day: $day, tourId: $tourId) {
      day
      tourId
      id
      message
      version
    }
  }
`);

const GetLatesTourInfoMessage_TourMessages = graphql(/* GraphQL */ `
  query GetLatesTourInfoMessage_TourMessages($day: String!, $tourId: String!) {
    tourInfoMessage(day: $day, tourId: $tourId) {
      day
      tourId
      id
      message
      version
    }
  }
`);

const CreateTourInfoMessage_TourMessages = graphql(/* GraphQL */ `
  mutation CreateTourInfoMessage_TourMessages(
    $day: String!
    $tourId: String!
    $message: String!
  ) {
    createTourInfoMessage(day: $day, tourId: $tourId, message: $message) {
      day
      tourId
      id
      message
      version
    }
  }
`);

export type LocalTourInfoMessage_TourInfoMessage =
  GetLatesTourInfoMessage_TourMessagesQuery["tourInfoMessage"];

type TourInfoMessageState = {
  allMessages: LocalTourInfoMessage_TourInfoMessage[];
  latestMessage: LocalTourInfoMessage_TourInfoMessage | null;
  tourId: string;
  day: string;
  loadData: (day: string, tourId: string) => void;
  isLoading: boolean;
  isError: boolean;
  error: string;
  createTourInfoMessage: (message: string) => void;
};

export const useTourInfoMessage = create<TourInfoMessageState>((set, get) => ({
  allMessages: [],
  latestMessage: null,
  tourId: "",
  day: "",
  isLoading: false,
  isError: false,
  error: "",
  loadData: async (day, tourId) => {
    const { data } = await client
      .query(
        GetTourInfoMessages_TourMessages,
        { day, tourId },
        { requestPolicy: "network-only" }
      )
      .toPromise();
    set({ allMessages: data?.tourInfoMessages });
    const { data: latestData } = await client
      .query(
        GetLatesTourInfoMessage_TourMessages,
        { day, tourId },
        { requestPolicy: "network-only" }
      )
      .toPromise();
    set({ latestMessage: latestData?.tourInfoMessage });
    set({ tourId, day });
  },
  createTourInfoMessage: async (message) => {
    const { tourId, day } = get();
    if (tourId == "" || !day) {
      set({ isError: true, error: "TourId or day is missing" });
      return;
    }
    set({ isLoading: true });
    const { data, error } = await client
      .mutation(CreateTourInfoMessage_TourMessages, { tourId, day, message })
      .toPromise();
    if (error) {
      console.log("error", error);
      toast.error("Feler beim Erstellen der Nchricht");
      set({ isError: true, error: error.message, isLoading: false });
      get().loadData(day, tourId);
    } else {
      console.log("data", data);
      toast.success("Message created");
      set({ latestMessage: data?.createTourInfoMessage, isLoading: false });
      get().loadData(day, tourId);
    }
  },
}));
