import React, { useEffect, useRef, useState } from "react";

import {
  LocalPatientInfoMessage,
  usePatientInfoMessage,
} from "./usePatientInfoMessage";
import { cn } from "../../../helpers/utils";
import { Button } from "../../UiComponents/Button";
import { CheckIcon } from "@radix-ui/react-icons";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useUserInfo } from "../../UserInfo/useUserInfo";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../UiComponents/Accordeon";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../../UiComponents/Dialog";
import PatientInfoMessageAction from "./PatientInfoMessageAction";
import { Switch } from "../../UiComponents/Switch";

const DisplayDate = (props: {
  from: string | undefined | null;
  to: string | undefined | null;
}) => {
  const { from, to } = props;

  console.log(from, to);

  if (!from && !to) {
    return <div className="flex flex-row gap-2 bg-gray-100">Kein Datum</div>;
  }

  return (
    <div>
      {from && "von " + dayjs(from).format("DD. MMM YYYY") + " "}
      {to && "bis " + dayjs(to).format("DD. MMM YYYY")}
    </div>
  );
};

type Props = {
  patientId?: string;
};

const PatientInfoMessageDisplayAll = (props: Props) => {
  const {
    patientMessages,
    setShowCreateComment,
    updatePatientInfoMessage,
    createPatientInfoMessageResponse,
    loadData,
    setPatientId,
  } = usePatientInfoMessage();

  const { user } = useUserInfo();
  const [openActionDialog, setOpenActionDialog] = React.useState(false);
  const [currentMessageId, setCurrentMessageId] = React.useState<string>("");
  const [filterOnlyOpen, setFilterOnlyOpen] = React.useState(true);
  const [filteredMessages, setFilteredMessages] = React.useState<
    LocalPatientInfoMessage[]
  >([]);

  useEffect(() => {
    setPatientId(props.patientId || "");
  }, []);

  useEffect(() => {
    if (filterOnlyOpen) {
      setFilteredMessages(
        patientMessages.filter((message) => message.status !== "done")
      );
    } else {
      setFilteredMessages(patientMessages);
    }
  }, [patientMessages, filterOnlyOpen]);

  return (
    <div className="bg-white flex flex-col gap-2 p-2   overflow-y-hidden w-[650px]">
      <div className="flex flex-row items-center gap-2">
        Nur Offene
        <Switch
          checked={filterOnlyOpen}
          onClick={() => setFilterOnlyOpen(!filterOnlyOpen)}
        />
      </div>
      <div className="h-full overflow-y-hidden">
        <Accordion type="multiple" asChild>
          <div className="h-full overflow-y-scroll">
            {filteredMessages.map((message) => {
              return (
                <div>
                  <AccordionItem value={message.id} asChild>
                    <div
                      key={message.id}
                      className="bg-gray-50 p-2 border-[1.5px] "
                    >
                      <div className="flex flex-row items-center gap-2">
                        <div className=" text-xs font-semibold">
                          {dayjs(message.createdAt).format("DD. MMMM YYYY")}
                        </div>{" "}
                        <div className="text-xs">
                          {dayjs(message.createdAt).format("HH:mm:ss")}
                        </div>
                        <div className="text-xs"> {message.ownedBy}</div>
                      </div>
                      <div className="flex flex-row items-center gap-2 p-2 ">
                        <AccordionTrigger className="py-1" />
                        <div
                          className={cn("text-lg flex flex-row gap-2", {
                            "text-red-500":
                              message.messageType === "cancelContract",
                            "text-blue-500": message.messageType === "absence",
                            "text-teal-500": message.messageType === "info",
                            "text-yellow-500":
                              message.messageType === "activity",
                          })}
                        >
                          {message.subject}
                          <div className="text-lg">
                            <DisplayDate
                              from={message.startDay}
                              to={message.endDay}
                            />
                            {/* {dayjs(message.startDay).format("DD. MMM YYYY")} bis{" "}
                            {dayjs(message.endDay).format("DD. MMM YYYY")} */}
                          </div>
                        </div>
                        <div>
                          {message.hasMatchingAbsence === true ? (
                            <div>
                              <CheckIcon className="h-6 w-6 text-green-500" />
                            </div>
                          ) : (
                            <div>
                              <ExclamationCircleIcon className="h-6 w-6 text-red-500" />
                            </div>
                          )}
                        </div>
                        <div>{message.patientInfoStatus.description}</div>
                      </div>
                      <div className="flex flex-row gap-2 justify-end">
                        {message.responses?.length}

                        <Button
                          onClick={() => {
                            setCurrentMessageId(message.id);
                            setOpenActionDialog(true);
                          }}
                        >
                          Aktion
                        </Button>

                        {/* <Button
                      className="text-xs"
                      size={"compact-sm"}
                      onClick={() => {
                        setShowCreateComment(message.id);
                      }}
                    >
                      Kommentar
                    </Button> */}
                        {message.status !== "done" ? (
                          <Button
                            className="text-xs"
                            size={"compact-sm"}
                            onClick={() => {
                              createPatientInfoMessageResponse({
                                infoMessageId: message.id,
                                response: "Ist erledigt: " + user?.name,
                              });
                              updatePatientInfoMessage(message.id, {
                                status: "done",
                              });
                              loadData({ patientId: undefined });
                            }}
                          >
                            Erledigt
                          </Button>
                        ) : (
                          <Button
                            className="text-xs"
                            size={"compact-sm"}
                            onClick={() => {
                              createPatientInfoMessageResponse({
                                infoMessageId: message.id,
                                response: "Wieder eröffnet: " + user?.name,
                              });
                              updatePatientInfoMessage(message.id, {
                                status: "open",
                              });
                              loadData({ patientId: undefined });
                            }}
                          >
                            Re-Öffnen
                          </Button>
                        )}
                        <Button
                          className="text-xs"
                          size={"compact-sm"}
                          onClick={() => {
                            createPatientInfoMessageResponse({
                              infoMessageId: message.id,
                              response: "bin dran: " + user?.name,
                            });
                            updatePatientInfoMessage(message.id, {
                              status: "pending",
                              ownedBy: user?.id,
                            });
                            loadData({ patientId: undefined });
                          }}
                        >
                          Bin Dran
                        </Button>
                      </div>
                      {message.message.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                      <AccordionContent>
                        {message?.responses?.map((s) => (
                          <div
                            key={s.id}
                            className="flex flex-col bg-gray-100 p-2 border-b-2 border-b-black"
                          >
                            <div className="flex flex-row gap-2">
                              <div className="text-xs">{s.createdAt}</div>
                              <div className="text-xs">{s.createdBy}</div>
                            </div>
                            {s.response.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </div>
                        ))}
                      </AccordionContent>
                    </div>
                  </AccordionItem>
                </div>
              );
            })}
          </div>
        </Accordion>
      </div>
      <Dialog open={openActionDialog} onOpenChange={setOpenActionDialog}>
        <DialogContent className="w-96">
          <div className="p-2">
            <>
              <PatientInfoMessageAction
                messageId={currentMessageId}
                setOpen={setOpenActionDialog}
              />
            </>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PatientInfoMessageDisplayAll;
