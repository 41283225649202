import _ from "lodash";
import { NewSchedule, Schedule } from "../../generated/graphql";
import { ScheduleCompact } from "../../type-definitions";

export const groupSchedules = (schedules: NewSchedule[] | null) => {
  if (schedules == null) return [];
  if (schedules.length === 0) return [];
  const newSchedules = [] as ScheduleCompact[];
  const groupedSchedules = _.chain(schedules)
    .groupBy((s) => {
      return s.minStartTime + "_" + s.maxStartTime;
    })
    .toArray()
    .orderBy(["0.minStartTime"], ["asc"])
    .value();

  //console.log("groupedSchedules ", groupedSchedules);

  // console.log("Patient Id ",schedules[0].patientId)
  // console.log("groupedSchedules ",schedules[0].patientId,groupedSchedules);

  // let newSchedule: Schedule = {
  //   id: "none",
  //   isActive: true,
  //   maxStartTime: "xxx",
  //   minStartTime: "xxx",
  //   patientId: schedules[0].patientId,
  //   plannedDuration: schedules[0].plannedDuration,
  //   updatedAt: "xxxx",
  //   actionsWithQuantity: [],
  //   days: [],
  // };

  groupedSchedules.forEach((group: NewSchedule[]) => {
    console.log("group", group);
    let newSchedule: ScheduleCompact = {
      id: "none",
      isActive: true,
      maxStartTime: "xxx",
      minStartTime: "xxx",
      patientId: schedules[0].patientId,
      daysWithQuantityList: [],
    };

    group.forEach((s) => {
      //  console.log(s);
      newSchedule.minStartTime = s.minStartTime;
      newSchedule.maxStartTime = s.maxStartTime;

      const days = s.days;

      days?.forEach((day) => {
        newSchedule.daysWithQuantityList.push({
          day: day,
          actionsWithQuantity: s.actionsWithQuantity as any,
          plannedDuration_min: Number(s.cash?.calculatedDuration_min),
        });
      });
    });
    newSchedules.push(newSchedule);
  });
  //console.log("new Schedules",schedules[0].patientId, newSchedules)
  return newSchedules;
};
