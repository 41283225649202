import React, { useEffect } from "react";

import { useMissionPublication } from "./useMissionPublication";
import { Button } from "../UiComponents/Button";
import { useGlobalService } from "../GlobalService/useGlobalService";

type Props = {};

const MissionPublicationMonthPicker = (props: Props) => {
  const { currentMinDay, currentMaxDay, prevWeek, nextWeek } =
    useGlobalService();

  const { setCurrentPeriod, missionPublications } = useMissionPublication();

  useEffect(() => {
    setCurrentPeriod({ minDay: currentMinDay, maxDay: currentMaxDay });
  }, [currentMinDay, currentMaxDay]);

  return (
    <div className="flex flex-row justify-between items-center gap-2">
      <div>
        <Button
          onClick={() => {
            prevWeek();
          }}
        >
          -
        </Button>
      </div>
      <div>
        {currentMinDay}- {currentMaxDay}
      </div>
      <div>
        <Button
          onClick={() => {
            nextWeek();
          }}
        >
          +
        </Button>
      </div>
    </div>
  );
};

export default MissionPublicationMonthPicker;
