import { Tour } from "../type-definitions";

const tourList = [
  {
    tourName: "Holt",
    sortIdentifierName: "01",
  },

  {
    tourName: "Raadt",
    sortIdentifierName: "02",
  },
  {
    tourName: "Stadt",
    sortIdentifierName: "03",
  },

  {
    tourName: "Heis",
    sortIdentifierName: "04",
  },
  {
    tourName: "Heiß",
    sortIdentifierName: "04",
  },
  {
    tourName: "Heim",
    sortIdentifierName: "05",
  },

  {
    tourName: "Haarz",
    sortIdentifierName: "06",
  },

  {
    tourName: "Dümpt",
    sortIdentifierName: "07",
  },

  {
    tourName: "Styr",
    sortIdentifierName: "08",
  },

  {
    tourName: "Selb",
    sortIdentifierName: "09",
  },
  {
    tourName: "Broich",
    sortIdentifierName: "10",
  },
  {
    tourName: "Saarn",
    sortIdentifierName: "11",
  },
  {
    tourName: "Speld",
    sortIdentifierName: "12",
  },
  {
    tourName: "Neue",
    sortIdentifierName: "13",
  },
];

export const getTourObject = (tourId: string | undefined | null): Tour => {
  const _tourId = tourId || "";
  const splitTourId = _tourId.split("_");
  const tourNameFirst = splitTourId[2] !== undefined ? splitTourId[2] : "X";

  const tourFound = tourList.find((o) => {
    const regex = new RegExp(o.tourName, "g");
    return regex.test(tourNameFirst);
  });
  const sortIdentifierNane = tourFound?.sortIdentifierName || "99";

  return {
    tourId: _tourId,
    sortIdentifier:
      (splitTourId[1] !== undefined ? splitTourId[1] : "X") +
      sortIdentifierNane,
    shift: splitTourId[0] !== undefined ? splitTourId[0] : "X",
    type: splitTourId[1] !== undefined ? splitTourId[1] : "X",
    tourName:
      (splitTourId[2] !== undefined ? splitTourId[2] : "X") +
      (splitTourId[3] !== undefined ? "_" + splitTourId[3] : "") +
      (splitTourId[4] !== undefined ? "_" + splitTourId[4] : "") +
      (splitTourId[5] !== undefined ? "_" + splitTourId[5] : ""),
  };
};
