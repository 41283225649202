import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button } from "../UiComponents/Button";
import axios from "axios";

import { useUserInfo } from "./useUserInfo";
import { set } from "lodash";

type Props = {};

const UserInfo = (props: Props) => {
  const {
    user,
    logout,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const { setUser } = useUserInfo();

  useEffect(() => {
    if (!user) return;
    getIdTokenClaims().then(async (claims) => {
      console.log("Claims", claims);
      console.log(user["/roles"]);
      setUser({
        id: user.sub || "",
        name: user.name || "nicht da",
        email: user.email || "nicht da",
        roles: user["/roles"],
      });
    });
  }, [user]);

  if (isLoading) return <div>Loading ...</div>;

  return (
    <div className="[z-999]">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button variant="neutral-outline">{user?.name}</Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="min-w-[220px] bg-white p-2 m-2 rounded-md [z-999]">
            <DropdownMenu.Item
              asChild
              className="p-2 leading-none"
              onClick={() => {
                console.log("Logout");
                logout();
              }}
            >
              <Button variant="neutral-ghost">Abmelden</Button>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="p-2 leading-none">
              Item 2
            </DropdownMenu.Item>
            <DropdownMenu.Item className="p-2 leading-none">
              Item 3
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default UserInfo;
