import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import {
  LocalGetAnalyseMission,
  useAnalyzeMission,
  WeekRow,
} from "./useAnalyzeMission";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";

import {
  PencilSquareIcon,
  DocumentDuplicateIcon,
  ArrowUpOnSquareStackIcon,
  CheckIcon,
  MinusSmallIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { FaFlagCheckered } from "react-icons/fa";

import weekOfYear from "dayjs/plugin/weekOfYear";
import _ from "lodash";
import classNames from "classnames";
import DisplayMarkedActions from "./DisplayMarkedActions";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import {
  Max_AssigendActionMissionExecutedFragment,
  Max_AssigendActionMissionFragment,
} from "../../generated/graphql";
import ActionClipboard from "../ActionClipboard/ActionClipboard";

import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import {
  ColorizedBackground,
  qualificationWarning,
} from "../PatientMissionList/PatientMissionList";

import MyErrorDialog from "../UiComponents/MyErrorDialog";

import { MyDimmer } from "../UiComponents/MyDimmer";
import { getTourObject } from "../../helpers/tourid";
import {
  PopoverRadix,
  PopoverRadixContent,
  PopoverRadixTrigger,
} from "../UiComponents/PopoverRadix";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";
import { Button } from "../UiComponents/Button";

import { SiAdguard } from "react-icons/si";
import PatientCalendarView from "./PatientCalendarView";
import { cn } from "../../helpers/utils";
import { BsHouse, BsHouseAdd, BsFillHouseFill } from "react-icons/bs";
import { GiSafetyPin } from "react-icons/gi";

import { FaBitcoin } from "react-icons/fa";
dayjs.extend(weekOfYear);

export const MoveableActionClipboard = () => {
  // Set the drag hook and define component movement based on gesture data
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0, delay: 0 }));
  const bind = useDrag(({ offset: [x, y] }) => api.set({ x, y }));
  const [isOpen, setIsOpen] = useState(true);
  const { actionsClipboardContent } = useActionClipboard();

  return (
    <animated.div
      {...bind()}
      style={{ x, y, touchAction: "none" }}
      className="absolute  border-2 rounded-xl  border-gray-400 m-5 p-2 top-32 
      backdrop-blur-sm bg-gray-50/80 z-[999]
      "
    >
      <div>
        <div className="p-2">
          <Button
            className="btn btn-circle btn-primary btn-sm"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {actionsClipboardContent.length}
          </Button>
        </div>
        <div className="w-96" hidden={isOpen}>
          <ActionClipboard />
        </div>
      </div>
    </animated.div>
  );
};
/**
 *
 * @param props
 * @returns
 * Displays info, if 31 is used to ensure that the mission is not shorter than expected
 */
const ShowWarningDuration = (props: { mission: LocalGetAnalyseMission }) => {
  const { mission } = props;
  if (!mission.missionAnalytic?.expectedDuration_NOSAFE) return null;

  const diff =
    mission.duration_min - mission.missionAnalytic.expectedDuration_NOSAFE;
  if (diff > 0) {
    return <div className="bg-red-500 rounded-md text-white px-1">{diff}</div>;
  }

  if (diff < 0) {
    return (
      <div className="bg-yellow-500 rounded-md text-black px-1">{diff}</div>
    );
  }

  return null;
};

export const DisplayPauschalen = (props: {
  hauspauschale: string | undefined;
  savepauschale: string | undefined;
}) => {
  return (
    <div className="flex flex-row items-center border-b-4 border-white">
      <BsFillHouseFill
        className={cn("h-3 w-3 text-gray-200", {
          "text-green-300": props.hauspauschale === "15a",
          "text-yellow-300": props.hauspauschale === "15",
        })}
      />

      <FaBitcoin
        className={cn("h-3 w-3 text-gray-200", {
          "text-green-300": props.savepauschale === "01",
        })}
      />
    </div>
  );
};

type DisplayCompactMisisonsProps = {
  missions: LocalGetAnalyseMission[];
};

export const DisplayCompactMissions = (props: DisplayCompactMisisonsProps) => {
  const { missions } = props;
  const {
    selectedMissionId,
    setSelectedMissionId,
    setMissionStatus,
    setActionsOnMission,
    updateMission,
    errorOnUPdate,
    setErrorOnUpdate,
  } = useAnalyzeMission();

  return (
    <>
      {missions?.map((mission) => {
        // console.log("Original", mission.actionsWithQuantity);
        const _ordredActions = _.orderBy(
          mission.actionsWithQuantity,
          ["action.examinationRequired", "action.leistungskomplex"],
          ["asc", "asc"]
        );

        //console.log("Ordered ", _ordredActions);

        return (
          <>
            <div className="text-xs flex flex-row gap-2 mb-1 items-center">
              <div className="border-b-4 border-b-white">
                {mission.isChecked ? (
                  <SiAdguard className="text-green-500" />
                ) : (
                  <SiAdguard className="text-gray-400" />
                )}
              </div>
              <div className="flex flex-row items-center">
                <div
                  className={cn("border-b-4  text-black", {
                    "border-gray-200":
                      mission.actionsWithQuantity?.length !== 0,
                    "border-red-400": mission.actionsWithQuantity?.length === 0,
                    "border-b-4 border-b-purple-500": qualificationWarning({
                      hasRequiredQualification:
                        mission?.member?.hasQualificationLevel!,
                      minRequiredQualification: mission?.minReqQualification,
                      isPrivate: mission?.patient?.isPrivate || false,
                    }),
                  })}
                >
                  {dayjs(Number(mission.startTS)).format("HH:mm")}
                </div>
                <div className="">
                  <DisplayPauschalen
                    hauspauschale={mission.missionAnalytic?.pauschaleHausType}
                    savepauschale={mission.missionAnalytic?.pauschaleSaveType}
                  />
                </div>
              </div>

              <div className=" flex flex-row gap-1 flex-wrap">
                {_ordredActions?.map((action) => {
                  return (
                    <div
                      className={classNames("flex flex-row flex-wrap", {
                        "border-b-4 border-yellow-200":
                          action?.action?.examinationRequired,
                        "border-b-4 border-blue-200":
                          !action?.action?.examinationRequired,
                      })}
                    >
                      {action?.action?.leistungskomplex}
                      {(action?.quantity || 0) > 1
                        ? `(${action?.quantity})`
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

type DisplayMisisonsProps = {
  missions: LocalGetAnalyseMission[];
};

export const DisplayMissions = (props: DisplayMisisonsProps) => {
  const { missions } = props;
  const {
    selectedMissionId,
    setSelectedMissionId,
    setMissionStatus,
    setActionsOnMission,
    updateMission,
    errorOnUPdate,
    setErrorOnUpdate,
  } = useAnalyzeMission();
  //console.log("Display Missions", missions);
  const setActionClipboardContent = useActionClipboard(
    (state) => state.setActionClipboardContent
  );

  const actionClipboardContent = useActionClipboard(
    (state) => state.actionsClipboardContent
  );

  const [errorOpen, setErrorOpen] = useState(false);

  return (
    <div className="">
      {missions?.map((mission) => {
        return (
          <>
            <div className="bg-gray-100 flex flex-row justify-between items-center">
              <div>
                {mission.isChecked ? (
                  <SiAdguard className="text-green-500" />
                ) : (
                  <SiAdguard className="text-gray-400" />
                )}
              </div>
              {/* <div>
                <input
                  onClick={() => {
                    if (selectedMissionId === mission.id) {
                      setSelectedMissionId("");
                    } else {
                      setSelectedMissionId(mission.id);
                    }
                  }}
                  onChange={() => {}}
                  className="form-check-input appearance-none h-4 w-4 border
                             border-gray-300 rounded-sm bg-white checked:bg-blue-500
                             checked:blue-green-500 focus:outline-none transition duration-200 
                              align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer mx-2"
                  type="checkbox"
                  checked={selectedMissionId === mission.id}
                  id="flexCheckDefault"
                />
              </div> */}
              <div className="text-xs flex flex-row gap-2">
                <span
                  className={classNames({
                    "bg-red-500 text-white px-1 rounded-sm":
                      mission.missionAnalytic?.totalCashValue_NOSAFE === 0,
                  })}
                >
                  {mission.missionAnalytic?.totalCashValue_NOSAFE} EUR
                </span>

                <ShowWarningDuration mission={mission} />
              </div>

              {/* <div>
                {mission.aggMobileExecutions?.allMobileExecutions?.length! >
                1 ? (
                  <div className="text-error">
                    {mission.aggMobileExecutions?.allMobileExecutions?.length}
                  </div>
                ) : null}
              </div> */}

              {qualificationWarning({
                hasRequiredQualification:
                  mission?.member?.hasQualificationLevel!,
                minRequiredQualification: mission?.minReqQualification,
                isPrivate: mission?.patient?.isPrivate || false,
              }) ? (
                <div>
                  <ExclamationTriangleIcon className="h-5 w-5 text-white bg-purple-500" />
                </div>
              ) : null}

              <PopoverRadix>
                <PopoverRadixTrigger>
                  <Button variant={"neutral-ghost"} size={"compact-sm"}>
                    <InfoCircledIcon className="h-5 w-5" />
                  </Button>
                </PopoverRadixTrigger>
                <PopoverRadixContent>
                  <div className="w-96 m-2 p-2">
                    <DisplayMissionAnalytic missionId={mission.id}>
                      <div>
                        {dayjs(Number(mission.startTS)).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                        {"  "}
                        {mission.patientId}
                      </div>
                    </DisplayMissionAnalytic>
                    <div className="">
                      <div className="flex flex-row items-center">
                        <DisplayMemberShortName
                          hasExamination={mission?.member?.hasExamination!}
                          shortName={mission?.member?.shortName!}
                          hasQualificationLevel={
                            mission?.member?.hasQualificationLevel!
                          }
                        />
                        <div>
                          {/* {!mission.hasMobileExecution ? (
                            <Button className="btn btn-xs btn-ghost btn-circle">
                              <PencilSquareIcon className="h-4 w-4 text-black disabled:text-gray-100" />
                            </Button>
                          ) : null} */}
                        </div>
                      </div>
                      <div className="text-xs text-gray-400">
                        {getTourObject(mission.tourId).tourName}(
                        {getTourObject(mission.tourId).shift}/
                        {getTourObject(mission.tourId).type})
                      </div>
                    </div>
                  </div>
                </PopoverRadixContent>
              </PopoverRadix>

              {/* <button className="btn btn-ghost btn-xs">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 disabled:text-gray-100" />
              </button> */}
            </div>
            <div className="flex flex-row mb-2">
              <div
                className={classNames(
                  "w-1 my-1 bg-red-400",
                  ColorizedBackground({
                    time: dayjs(Number(mission.startTS)).format("HH:mm"),
                  })
                )}
              ></div>
              <div className="">
                <div
                  key={mission.id}
                  className={classNames(
                    mission.isActive ? "bg-gray-50 mx-1 " : "bg-red-50 mx-1"
                  )}
                >
                  <div className="font-bold flex flex-row">
                    <div className="text-sm">
                      <div className="flex flex-row items-center justify-between">
                        <div>
                          {dayjs(Number(mission.startTS)).format("HH:mm")}-
                          {dayjs(Number(mission.endTS))
                            .add(1, "minute")
                            .format("HH:mm")}
                          ({mission.duration_min})
                        </div>
                        <div className="">
                          {mission.isActive ? (
                            <Button
                              disabled={!mission.editAllowed}
                              title="Deaktivieren"
                              variant="success-ghost"
                              size="compact-sm"
                              onClick={() => {
                                setMissionStatus(
                                  mission.id,
                                  !mission.isActive,
                                  mission.updatedAt
                                );
                              }}
                            >
                              <CheckIcon className="h-5 w-5" />
                            </Button>
                          ) : (
                            <Button
                              disabled={!mission.editAllowed}
                              title="Aktivieren"
                              variant="danger-ghost"
                              size="compact-sm"
                              onClick={() => {
                                setMissionStatus(
                                  mission.id,
                                  !mission.isActive,
                                  mission.updatedAt
                                );
                              }}
                            >
                              <MinusSmallIcon className="h-5 w-5" />
                            </Button>
                          )}
                        </div>
                      </div>
                      {/* {mission.aggMobileExecutions?.sigMobileExecution ? (
                        <div className="text-black">
                          (
                          {dayjs(
                            Number(
                              mission.aggMobileExecutions.sigMobileExecution
                                .startTS
                            )
                          ).format("HH:mm")}
                          -
                          {dayjs(
                            Number(
                              mission.aggMobileExecutions.sigMobileExecution
                                .endTS
                            )
                          ).format("HH:mm")}
                          )
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div
                  className={classNames("", {
                    "bg-red-50": mission.actionsWithQuantity?.length === 0,
                    // "bg-green-50": mission.actionsWithQuantity?.length !== 0,
                    //&&
                    // mission.aggMobileExecutions?.sigMobileExecution
                    //   ?.actionsWithQuantity?.length === 0,
                  })}
                >
                  <div className="">
                    <DisplayMarkedActions
                      mission={mission}
                      plannedActions={
                        mission.actionsWithQuantity as Max_AssigendActionMissionFragment[]
                      }
                      executedActions={[]}
                      // executedActions={
                      //   mission?.aggMobileExecutions?.sigMobileExecution
                      //     ?.actionsWithQuantity as Max_AssigendActionMissionExecutedFragment[]
                      // }
                    />
                  </div>
                  <div>
                    <div>
                      <div className="btn-group">
                        <Button
                          title="Leistungen kopieren"
                          variant="neutral-ghost"
                          size="compact-sm"
                          onClick={() => {
                            setActionClipboardContent(
                              mission.actionsWithQuantity as Max_AssigendActionMissionFragment[]
                            );
                          }}
                        >
                          <DocumentDuplicateIcon className="h-4 w-4" />
                        </Button>
                        <Button
                          disabled={!mission.editAllowed}
                          title="Leistungen zupordnen"
                          variant="neutral-ghost"
                          size="compact-sm"
                          onClick={() => {
                            setActionsOnMission(
                              mission,
                              actionClipboardContent
                            );
                          }}
                        >
                          <ArrowUpOnSquareStackIcon className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
      <MyErrorDialog
        open={errorOnUPdate}
        setOpen={setErrorOnUpdate}
        handleClose={updateMission}
      />
    </div>
  );
};

export type TableCustomCell<TData = unknown, TValue = unknown> = CellContext<
  TData,
  TValue
>;

type Props = {};

type CalenderCellProps = {
  info: any;
};

type HeaderDayWithButonProps = {
  day: string;
  isoDayNumber: number;
};

const HeaderDayWithButon = (props: HeaderDayWithButonProps) => {
  const { setCheckStatusByIsoDay } = useAnalyzeMission();
  return (
    <div className="flex flex-row justify-center items-center gap-2">
      {props.day}
      <Button
        variant={"neutral-ghost"}
        size={"compact-sm"}
        className="text-green-300"
        onClick={() => {
          setCheckStatusByIsoDay({
            isoDayNumber: props.isoDayNumber,
            checkStatus: true,
          });
        }}
      >
        <FaFlagCheckered className="" />
      </Button>
      <Button
        variant={"neutral-ghost"}
        size={"compact-sm"}
        className="text-red-300 "
        onClick={() => {
          setCheckStatusByIsoDay({
            isoDayNumber: props.isoDayNumber,
            checkStatus: false,
          });
        }}
      >
        <FaFlagCheckered />
      </Button>
    </div>
  );
};

const CellHeadline = (props: CalenderCellProps) => {
  const { info } = props;
  const {
    missions,
    addMissionToPublicationId,
    selectedMissionId,
    updateMission,
    setCheckStatusByDayRange,
  } = useAnalyzeMission();

  //const diff = info.column.id === "5" ? -5 : -1;

  const diff = info.column.id === "0" ? 6 : -1;

  const _day = dayjs(info.row.original.week)
    .add(Number(info.cell.column.id) + diff, "day")
    .format("YYYY-MM-DD");

  return (
    <div className=" flex flex-row justify-between items-center border-b-2 border-gray-500">
      <div className="flex flex-row gap-2">
        <Button
          variant={"neutral-ghost"}
          size={"compact-sm"}
          className="text-green-300"
          onClick={() => {
            setCheckStatusByDayRange({
              startDay: _day,
              endDay: _day,
              checkStatus: true,
            });
          }}
        >
          <FaFlagCheckered className="" />
        </Button>
        <Button
          variant={"neutral-ghost"}
          size={"compact-sm"}
          className="text-red-300 "
          onClick={() => {
            setCheckStatusByDayRange({
              startDay: _day,
              endDay: _day,
              checkStatus: false,
            });
          }}
        >
          <FaFlagCheckered />
        </Button>
        {/* <Button
          variant={"neutral-ghost"}
          size="compact-sm"
          onClick={() => {
            const myMission = missions.find((m) => m.id === selectedMissionId);
            console.log(props);

            addMissionToPublicationId(
              myMission,
              dayjs(info.row.original.week)
                .add(Number(info.cell.column.id) + diff, "day")
                .format("YYYY-MM-DD")
            );
          }}
        >
          <PlusCircleIcon className="h-4 w-4" />
        </Button> */}
      </div>
      <div className="flex text-gray-500 p-1 text-sm font-bold">
        {dayjs(info.row.original.week)
          .add(Number(info.cell.column.id) + diff, "day")
          .format("DD")}
      </div>
    </div>
  );
};

const PatientWeekyView = (props: Props) => {
  // const {
  //   missions,
  //   setMissonActive,
  //   setIsLoading,
  //   addMissionToPublicationId,
  //   selectedMissionId,
  //   isLoading,
  // } = useAnalyzeMission();

  // const [missionsByDay, setMissionsByDay] = useState<WeekRow[]>();

  // const columnHelper = createColumnHelper<WeekRow>();

  // const columns = [
  //   columnHelper.accessor("week", {
  //     header: () => {
  //       const { setCheckStatusAll } = useAnalyzeMission.getState();
  //       return (
  //         <div>
  //           Woche
  //           <div>
  //             <Button
  //               variant={"neutral-ghost"}
  //               size={"compact-sm"}
  //               className="text-green-300 "
  //               onClick={() => {
  //                 setCheckStatusAll({
  //                   checkStatus: true,
  //                 });
  //               }}
  //             >
  //               <FaFlagCheckered />
  //             </Button>
  //             <Button
  //               variant={"neutral-ghost"}
  //               size={"compact-sm"}
  //               className="text-red-300"
  //               onClick={() => {
  //                 setCheckStatusAll({
  //                   checkStatus: false,
  //                 });
  //               }}
  //             >
  //               <FaFlagCheckered />
  //             </Button>
  //           </div>
  //         </div>
  //       );
  //     },
  //     cell: (info) => {
  //       //console.log(info);

  //       const { setCheckStatusByDayRange } = useAnalyzeMission.getState();

  //       const _startDay = dayjs(info.getValue())
  //         .startOf("week")
  //         .format("YYYY-MM-DD");
  //       const _endDay = dayjs(info.getValue())
  //         .endOf("week")
  //         .format("YYYY-MM-DD");

  //       return (
  //         <div className="text-center flex flex-col justify-center items-center gap-2">
  //           {dayjs(info.getValue()).format("DD/MM")}
  //           <div className="flex flex-row gap-2">
  //             <Button
  //               variant={"neutral-ghost"}
  //               size={"compact-sm"}
  //               className="text-green-300 "
  //               onClick={() => {
  //                 setCheckStatusByDayRange({
  //                   startDay: _startDay,
  //                   endDay: _endDay,
  //                   checkStatus: true,
  //                 });
  //               }}
  //             >
  //               <FaFlagCheckered />
  //             </Button>
  //             <Button
  //               variant={"neutral-ghost"}
  //               size={"compact-sm"}
  //               className="text-red-300"
  //               onClick={() => {
  //                 setCheckStatusByDayRange({
  //                   startDay: _startDay,
  //                   endDay: _endDay,
  //                   checkStatus: false,
  //                 });
  //               }}
  //             >
  //               <FaFlagCheckered />
  //             </Button>
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),

  //   columnHelper.accessor("1", {
  //     header: () => <HeaderDayWithButon day={"Mo"} isoDayNumber={1} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //console.log(info);
  //       return (
  //         <div className="flex flex-col items-stretch ">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("2", {
  //     header: () => <HeaderDayWithButon day={"Di"} isoDayNumber={2} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //    console.log(_missions);
  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("3", {
  //     header: () => <HeaderDayWithButon day={"Mi"} isoDayNumber={3} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //console.log(_missions);
  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("4", {
  //     header: () => <HeaderDayWithButon day={"Do"} isoDayNumber={4} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       // console.log(_missions);
  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("5", {
  //     header: () => <HeaderDayWithButon day={"Fr"} isoDayNumber={5} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //console.log(_missions);
  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("6", {
  //     header: () => <HeaderDayWithButon day={"Sa"} isoDayNumber={6} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //    console.log(info);

  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("0", {
  //     header: () => <HeaderDayWithButon day={"So"} isoDayNumber={0} />,
  //     cell: (info) => {
  //       const _missions = info.getValue();
  //       //     console.log(_missions);
  //       return (
  //         <div className="flex flex-col items-stretch">
  //           <CellHeadline info={info} />
  //           <div className="">
  //             <DisplayMissions missions={_missions} />
  //           </div>
  //         </div>
  //       );
  //     },
  //   }),
  // ];

  // const table = useReactTable({
  //   data: missionsByDay as any[],
  //   columns: columns,
  //   getCoreRowModel: getCoreRowModel(),
  // });

  // // convert missions to weeky view
  // useEffect(() => {
  //   if (!missions) return;

  //   const _data = [] as {
  //     week: string;
  //     day: number;
  //     mission: LocalGetAnalyseMission;
  //   }[];

  //   //  console.log("missions", missions);

  //   //Create object with week and day
  //   missions.forEach((mission) => {
  //     const currentDay = dayjs(Number(mission.startTS));
  //     //console.log(currentDay);
  //     //currentDay.day(currentDay.day() >= 6 ? 6 : -1);
  //     //const week = currentDay.day(currentDay.day() >= 6 ? 6 : -1);

  //     const week = currentDay.startOf("week");

  //     // const day = dayjs(Number(mission.startTS)).day();

  //     // const isoDate = dayjs(Number(mission.startTS)).toISOString();
  //     const _mission = { ...mission };

  //     _data.push({
  //       week: week.format("YYYY-MM-DD"),
  //       day: currentDay.day(),
  //       mission: _mission,
  //     });
  //   });

  //   //console.log(_data);

  //   const groupedData = _.groupBy(_data, "week");

  //   // console.log("grouped Data", groupedData);

  //   const toast = _.chain(groupedData)
  //     .toPairs()
  //     .map((o) => {
  //       return {
  //         week: o[0],
  //         missions: o[1],
  //       };
  //     })
  //     .value();
  //   console.log("Toast", toast);

  //   let weekArray = [] as WeekRow[];

  //   toast.forEach((e) => {
  //     const _weekObj: WeekRow = {
  //       week: e.week,

  //       1: _.filter(e.missions, (o) => o.day === 1).map((o) => o.mission),
  //       2: _.filter(e.missions, (o) => o.day === 2).map((o) => o.mission),
  //       3: _.filter(e.missions, (o) => o.day === 3).map((o) => o.mission),
  //       4: _.filter(e.missions, (o) => o.day === 4).map((o) => o.mission),
  //       5: _.filter(e.missions, (o) => o.day === 5).map((o) => o.mission),
  //       6: _.filter(e.missions, (o) => o.day === 6).map((o) => o.mission),
  //       0: _.filter(e.missions, (o) => o.day === 0).map((o) => o.mission),
  //     };
  //     weekArray.push(_weekObj);
  //   });

  //   console.log("weekArray", weekArray);
  //   setMissionsByDay(weekArray);
  // }, [missions]);

  // if (!missionsByDay) return <div>Loading</div>;

  // return (
  //   <div className="">
  //     <PatientCalendarView />
  //     <MoveableActionClipboard />
  //     <div className="p-2 ">
  //       <div className="h-4" />
  //       <MyDimmer open={isLoading} />
  //     </div>
  //   </div>
  // );
  return <div>PatientWeeklyView</div>;
};

export default PatientWeekyView;
