import { create } from "zustand";

import { graphql } from "../../gql";
import { client } from "../../urqlClient";
import { ArrElement } from "../../helpers/typeHelpers";
import { ActiveMembersQuery, AllMembersQuery } from "../../gql/graphql";

export const ACTIVE_MEMBERS = graphql(`
  query ActiveMembers {
    activeMembers {
      memberId
      shortName
      lastName
      firstName
      hasExamination
      hasQualificationLevel
    }
  }
`);

export const ALL_MEMBERS = graphql(`
  query AllMembers {
    members {
      memberId
      shortName
      lastName
      firstName
      hasExamination
      hasQualificationLevel
    }
  }
`);

export type LocalMemberMemberSearchbox = ArrElement<
  ActiveMembersQuery["activeMembers"] | AllMembersQuery["members"]
>;

type MemberSearchboxState = {
  activeMembers: LocalMemberMemberSearchbox[];
  selectedMemberId: string;
  setSelectedMemberId: (memberId: string) => void;
  selectedMember: LocalMemberMemberSearchbox | undefined;
  loadActiveMembers: () => void;
  loadAllMembers: () => void;
};

export const useMemberSearchbox = create<MemberSearchboxState>((set, get) => ({
  selectedMemberId: "",
  selectedMember: undefined,
  setSelectedMemberId: (memberId: string) => {
    set({
      selectedMemberId: memberId,
      selectedMember: get().activeMembers.find(
        (member) => member?.memberId === memberId
      ),
    });
  },
  activeMembers: [],
  loadActiveMembers: async () => {
    const { data } = await client.query(ACTIVE_MEMBERS, {}).toPromise();
    if (data) {
      set({ activeMembers: data.activeMembers });
    }
  },
  loadAllMembers: async () => {
    const { data } = await client.query(ALL_MEMBERS, {}).toPromise();

    console.log("data", data);
    if (data?.members) {
      set({ activeMembers: data.members || [] });
    }
  },
}));
