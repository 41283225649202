import React from "react";
import PatientSearchbox from "../PatientSearchbox/PatientSearchbox";
import DisplayPatient from "../Patient/DisplayPatient";
import { DisplayPatientInfo } from "../Patient/DisplayPatientInfo";
import { DisplayMemberSmall } from "../Member/DisplayMemberSmall";
import {
  LocalMember_MissionEasyPlan,
  useMissionEasyPlan,
} from "./useMissionEasyPlan";
import DisplayScheduleByDay from "../Schedule/DisplayScheduleByDay";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import { Label } from "../UiComponents/Label";
import { Button } from "../UiComponents/Button";
import { Input } from "../UiComponents/Input";
import { DialogClose } from "../UiComponents/Dialog";
import { LocalPatientSearchBox } from "../PatientSearchbox/usePatientSearchbox";
import { to } from "@react-spring/web";
import { toast } from "react-toastify";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";

type Props = {
  tourId: string;
  member: LocalMember_MissionEasyPlan;
  day: string;
};

const selectableMinutes = ["05", "10", "15", "20", "25", "30"];

const CreateNewMission = (props: Props) => {
  const { tourId, member, day } = props;
  const [patientId, setPatientId] = React.useState<string | null>(null);
  const [duration, setDuration] = React.useState<string>("05");

  const [selectedPatient, setSelectedPatient] = React.useState<
    LocalPatientSearchBox | undefined
  >(undefined);

  const { appendMissionTour } = useMissionEasyPlan();
  const { actionsClipboardContent } = useActionClipboard();

  console.log("CreateNewMission", member);

  return (
    <div>
      <div>Neuer Einsatz</div>
      <PatientSearchbox
        onSelect={setPatientId}
        setPatient={(patient) => {
          setSelectedPatient(patient);
        }}
      />
      <div className="w-96 ">
        <DisplayPatientInfo patientId={patientId || ""} />
      </div>
      <div className="flex flex-row">
        <div className="text-xs">
          <DisplayScheduleByDay
            patientId={patientId || ""}
            minDay={day}
            maxDay={day}
          />
        </div>
      </div>
      <div className="w-full flex flex-row items-center">
        <ActionClipboard />
        <Label htmlFor="duration">
          Dauer (
          {selectableMinutes.map((m) => {
            return (
              <span>
                <Button
                  onClick={() => {
                    setDuration(m);
                  }}
                  size={"compact-sm"}
                  variant={"primary-ghost"}
                >
                  {m}
                </Button>
              </span>
            );
          })}
          )
        </Label>

        <Input
          type="number"
          id="duration"
          placeholder="Dauer"
          value={duration}
          onChange={(e) => {
            setDuration(e.target.value);
          }}
          step="5"
          min="05"
          max="120"
        />
        <DialogClose>
          <Button>Abbrechen</Button>
        </DialogClose>
        <Button
          onClick={() => {
            if (!selectedPatient) {
              toast.error("Bitte Patient auswählen");
              return;
            }

            appendMissionTour({
              tourId: tourId,
              patient: selectedPatient,
              duration: parseInt(duration),
              actionsWQ: actionsClipboardContent,
            });
          }}
        >
          Anlegen
        </Button>
      </div>
    </div>
  );
};

export default CreateNewMission;
