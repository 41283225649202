import React from "react";
import { Dialog, DialogContent } from "../UiComponents/Dialog";
import TourInfoMessage from "../TourInfoMessage/TourInfoMessage";

type Props = {
  day: string;
  tourId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const TourInfoMessageDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onOpenChange={props.setIsOpen}>
      <DialogContent className="w-[600px]">
        <TourInfoMessage
          day={props.day}
          tourId={props.tourId}
          setOpen={props.setIsOpen}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TourInfoMessageDialog;
