import { Schedule } from "@/src/generated/graphql";
import { graphql } from "../../../gql";
import { client } from "../../../urqlClient";
import {
  InputCreateScheduleDay,
  InputCreateScheduleEvery,
  InputUpdateScheduleDay,
  InputUpdateScheduleEvery,
  ScheduleDays_PlanScheduleQuery,
  ScheduleEverys_PlanScheduleQuery,
} from "../../../gql/graphql";
import { ArrElement } from "@/src/helpers/typeHelpers";
import { create } from "zustand";
import _ from "lodash";

const ScheduleEverys_PlanSchedule = graphql(/* GraphQL */ `
  query ScheduleEverys_PlanSchedule($patientId: String!) {
    scheduleEverys(patientId: $patientId) {
      id
      patientId
      every
      everyType
      actionsWithQuantity {
        actionLnr
        quantity
        lastDateOfMission
        action {
          lnr
          leistungsart
          leistungskomplex
          leistungsinhalte
          examinationRequired
        }
      }
      minStartTime
      maxStartTime
      acceptedMinStartTime
      acceptedMaxStartTime

      shiftId
      shift {
        id
        name
        shiftStartTime
        shiftEndTime
      }

      #allCodedActions
    }
  }
`);

const ScheduleDays_PlanSchedule = graphql(/* GraphQL */ `
  query ScheduleDays_PlanSchedule($patientId: String!) {
    scheduleDays(patientId: $patientId) {
      id
      patientId
      actionsWithQuantity {
        actionLnr
        quantity
        action {
          lnr
          leistungsart
          leistungskomplex
          leistungsinhalte
          examinationRequired
        }
      }
      timeGroup
      minStartTime
      maxStartTime
      acceptedMinStartTime
      acceptedMaxStartTime
      count
      shiftId
      shift {
        id
        name
        shiftStartTime
        shiftEndTime
      }
      allCodedActions
      allDays {
        id
        name
        shortName
      }

      preferedDays {
        name
        id
        shortName
      }
    }
  }
`);

export type LocalScheduleDay_PlanSchedule = ArrElement<
  ScheduleDays_PlanScheduleQuery["scheduleDays"]
>;

export type LocalScheduleEvery_PlanSchedule = ArrElement<
  ScheduleEverys_PlanScheduleQuery["scheduleEverys"]
>;

const UpdateScheduleDay_PlanSchedule = graphql(/* GraphQL */ `
  mutation UpdateScheduleDay_PlanSchedule(
    $id: String!
    $input: InputUpdateScheduleDay!
  ) {
    updateScheduleDay(id: $id, input: $input) {
      id
    }
  }
`);

const UpdateScheduleEvery_PlanSchedule = graphql(/* GraphQL */ `
  mutation UpdateScheduleEvery_PlanSchedule(
    $id: String!
    $input: InputUpdateScheduleEvery!
  ) {
    updateScheduleEvery(id: $id, input: $input) {
      id
    }
  }
`);

const CreateScheduleDay_PlanSchedule = graphql(/* GraphQL */ `
  mutation CreateSheduleDay_PlanSchedule($input: InputCreateScheduleDay!) {
    createScheduleDay(input: $input) {
      id
    }
  }
`);

const CreateScheduleEvery_PlanSchedule = graphql(/* GraphQL */ `
  mutation CreateSheduleEvery_PlanSchedule($input: InputCreateScheduleEvery!) {
    createScheduleEvery(input: $input) {
      id
    }
  }
`);

const DeleteScheduleDay_PlanSchedule = graphql(/* GraphQL */ `
  mutation DeleteScheduleDay_PlanSchedule($id: String!) {
    deleteScheduleDay(id: $id) {
      id
    }
  }
`);

const DeleteScheduleEvery_PlanSchedule = graphql(/* GraphQL */ `
  mutation DeleteScheduleEvery_PlanSchedule($id: String!) {
    deleteScheduleEvery(id: $id) {
      id
    }
  }
`);

type PlanScheduleState = {
  scheduleDays: LocalScheduleDay_PlanSchedule[];
  scheduleEverys: LocalScheduleEvery_PlanSchedule[];
  patientId: string;
  setPatientId: (patientId: string) => void;
  loadDaySchedules: () => void;
  loadEverySchedules: () => void;
  deleteScheduleDay: (id: string) => void;
  updateScheduleDay: ({
    id,
    input,
  }: {
    id: string;
    input: InputUpdateScheduleDay;
  }) => void;
  updateScheduleEvery: ({
    id,
    input,
  }: {
    id: string;
    input: InputUpdateScheduleEvery;
  }) => void;
  createScheduleDay: (input: InputCreateScheduleDay) => void;
  createScheduleEvery: (input: InputCreateScheduleEvery) => void;
  deleteScheduleEvery: (id: string) => void;
  timeGroups: string[];
};

export const usePlanSchedule = create<PlanScheduleState>((set, get) => ({
  scheduleDays: [],
  scheduleEverys: [],
  patientId: "",
  timeGroups: [],

  setPatientId: (patientId: string) => {
    set({ patientId });

    get().loadDaySchedules();
    get().loadEverySchedules();
  },
  loadDaySchedules: async () => {
    if (get().patientId === "") return;
    const ret = await client
      .query<ScheduleDays_PlanScheduleQuery>(
        ScheduleDays_PlanSchedule,
        {
          patientId: get().patientId,
        },
        { requestPolicy: "network-only" }
      )
      .toPromise();

    const _timeGroups = _.chain(ret.data?.scheduleDays)
      .uniqBy("timeGroup")
      .orderBy("timeGroup")
      .map("timeGroup")
      .value();
    set({ timeGroups: _timeGroups });

    console.log(ret.data);

    if (ret.data?.scheduleDays) {
      set({
        scheduleDays: _.orderBy(
          ret.data.scheduleDays,
          ["minStartTime"],
          ["asc"]
        ),
      });
    }
  },
  updateScheduleDay: async ({ id, input }) => {
    client
      .mutation(UpdateScheduleDay_PlanSchedule, {
        id,
        input,
      })
      .toPromise()
      .then((res) => {
        get().loadDaySchedules();
      });

    //console.log(res);
  },
  createScheduleDay: async (input) => {
    client
      .mutation(CreateScheduleDay_PlanSchedule, {
        input,
      })
      .toPromise()
      .then((res) => {
        console.log(res);
        get().loadDaySchedules();
      });
  },
  deleteScheduleDay: async (id) => {
    client
      .mutation(DeleteScheduleDay_PlanSchedule, {
        id,
      })
      .toPromise()
      .then((res) => {
        get().loadDaySchedules();
      });
  },
  loadEverySchedules: async () => {
    if (get().patientId === "") return;
    const ret = await client
      .query<ScheduleEverys_PlanScheduleQuery>(
        ScheduleEverys_PlanSchedule,
        {
          patientId: get().patientId,
        },
        { requestPolicy: "network-only" }
      )
      .toPromise();

    console.log(ret.data);

    if (ret.data?.scheduleEverys) {
      set({
        scheduleEverys: _.orderBy(
          ret.data.scheduleEverys,
          ["minStartTime", "id"],
          ["asc", "asc"]
        ),
      });
    }
  },
  updateScheduleEvery: async ({ id, input }) => {
    client
      .mutation(UpdateScheduleEvery_PlanSchedule, {
        id,
        input,
      })
      .toPromise()
      .then((res) => {
        get().loadEverySchedules();
      });

    //console.log(res);
  },
  createScheduleEvery: async (input) => {
    client
      .mutation(CreateScheduleEvery_PlanSchedule, {
        input,
      })
      .toPromise()
      .then((res) => {
        console.log(res);
        get().loadEverySchedules();
      });
  },
  deleteScheduleEvery: async (id) => {
    client
      .mutation(DeleteScheduleEvery_PlanSchedule, {
        id,
      })
      .toPromise()
      .then((res) => {
        get().loadEverySchedules();
      });
  },
}));
