import React, { useEffect, useState } from "react";
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from "../UiComponents/Command";
import { useQuery } from "urql";
import { graphql } from "../../gql";

import { useMemberSearchbox } from "./useMemberSearchbox";

import { Button } from "../UiComponents/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";

type Props = {
  setMemberId: (value: string) => void;
  currentMemberId?: string;
  allMember?: boolean;
};

const MemberSearchbox = (props: Props) => {
  const [inputValue, setInputValue] = useState("Mitarbeiter suchen");
  const [open, setOpen] = React.useState(false);

  const {
    setSelectedMemberId,
    selectedMember,
    loadActiveMembers,
    loadAllMembers,
    activeMembers,
  } = useMemberSearchbox();

  useEffect(() => {
    if (props.currentMemberId) {
      setSelectedMemberId(props.currentMemberId);
    }
  }, [props.currentMemberId]);

  useEffect(() => {
    if (props.allMember === true) {
      loadAllMembers();
    } else {
      loadActiveMembers();
    }
  }, []);

  useEffect(() => {
    if (selectedMember) {
      setInputValue(selectedMember.lastName + ", " + selectedMember.firstName);
    }
  }, [selectedMember]);

  return (
    <>
      <div className="">
        <Popover open={open}>
          <PopoverTrigger
            asChild
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Button
              role="combobox"
              placeholder="suchen"
              aria-expanded={open}
              variant={"neutral-ghost"}
            >
              {inputValue}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div>
              <Command>
                <CommandInput placeholder="zum suchen tipppen" />
                <CommandList>
                  {activeMembers?.map((item, index) => (
                    <CommandItem
                      key={item?.memberId}
                      onSelect={(currentValue) => {
                        props.setMemberId(item?.memberId || "");
                        setSelectedMemberId(item?.memberId || "");
                        setOpen(false);
                      }}
                    >
                      <div>
                        {item?.lastName}, {item?.firstName}, {item?.memberId},{" "}
                        {item?.shortName},{" "}
                        {JSON.stringify(item?.hasExamination)},{" "}
                        {item?.hasQualificationLevel}
                      </div>
                    </CommandItem>
                  ))}
                </CommandList>
              </Command>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};

export default MemberSearchbox;
