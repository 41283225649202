import React from "react";
import { LocalScheduleDay_PlanSchedule } from "./usePlanSchedule";
import { usePlanSchedule } from "./usePlanSchedule";
import produce from "immer";
import { cn } from "../../../helpers/utils";
import toast from "react-hot-toast";

import TimeSelectorPlanSchedule from "./TimeSelectorPlanSchedule";
import { Button } from "../../UiComponents/Button";
import { CheckIcon, TrashIcon } from "@radix-ui/react-icons";
import { useActionClipboard } from "../../ActionClipboard/useActionClipboard";
import DisplayActionCompact from "../../Action/DisplayActionCompact";

const dayReferences = [
  {
    id: "6",
    isSelected: false,
    shortName: "Sa",
    isoDayNumber: 6,
  },
  {
    id: "7",
    isSelected: false,
    shortName: "So",
    isoDayNumber: 7,
  },
  {
    id: "1",
    isSelected: false,
    shortName: "Mo",
    isoDayNumber: 1,
  },
  {
    id: "2",
    isSelected: false,
    shortName: "Di",
    isoDayNumber: 2,
  },
  {
    id: "3",
    isSelected: false,
    shortName: "Mi",
    isoDayNumber: 3,
  },
  {
    id: "4",
    isSelected: false,
    shortName: "Do",
    isoDayNumber: 4,
  },
  {
    id: "5",
    isSelected: false,
    shortName: "Fr",
    isoDayNumber: 5,
  },
];

type Props = {
  schedule: LocalScheduleDay_PlanSchedule;
};

const PlanScheduleDayRow = (props: Props) => {
  const { schedule } = props;
  const { updateScheduleDay, deleteScheduleDay } = usePlanSchedule();

  const { getActionClipbaordContentAsCoded } = useActionClipboard();

  return (
    <div className="flex flex-row gap-2 bg-gray-50 items-center ">
      <div className="bg-gray-100 p-1">
        <TimeSelectorPlanSchedule
          minStartTime={schedule.minStartTime}
          maxStartTime={schedule.maxStartTime}
          acceptedMaxStartTime={schedule.acceptedMaxStartTime}
          acceptedMinStartTime={schedule.acceptedMinStartTime}
          scheduleId={schedule.id}
          shift={schedule.shift}
          updateSchedule={updateScheduleDay}
        />
      </div>

      <div className="w-64 flex flex-row">
        {dayReferences.map((day) => {
          const isPossible =
            schedule.allDays?.findIndex((d) => d.id === day.id) !== -1;
          const isPrefered =
            schedule.preferedDays?.findIndex((d) => d.id === day.id) !== -1;
          return (
            <div className="flex flex-col items-center">
              <button
                key={day.id}
                className={cn(
                  "flex flex-row justify-center h-8 w-8 items-center font-mono",
                  {
                    "bg-primary text-white": isPrefered,
                    "bg-gray-200": !isPrefered,
                  }
                )}
                onClick={() => {
                  const index = schedule.preferedDays?.findIndex(
                    (d) => d.id === day.id
                  );
                  if (
                    index === undefined ||
                    schedule.preferedDays === null ||
                    schedule.preferedDays === undefined
                  ) {
                    toast.error(
                      "Error: index === undefined || schedule.days === null || schedule.days === undefined"
                    );
                    return;
                  }
                  if (index !== -1) {
                    // is in array, remove it
                    const _newPreferedDays = [...schedule.preferedDays];
                    _newPreferedDays.splice(index, 1);
                    // same for allDays
                    const _newAllDays = [...schedule.allDays];
                    _newAllDays.splice(index, 1);

                    const _newPreferedDayIds = _newPreferedDays?.map(
                      (d) => d.id
                    );
                    const _newAllDayIds = _newAllDays?.map((d) => d.id);
                    updateScheduleDay({
                      id: schedule.id,
                      input: {
                        preferedDayIds: _newPreferedDayIds,
                      },
                    });
                  } else {
                    //is not in Array, add it
                    const _newPreferedDays = [...schedule.preferedDays, day];
                    const _newPreferedDayIds = _newPreferedDays?.map(
                      (d) => d.id
                    );

                    const _newAllDays = [...schedule.allDays, day];
                    const _newAllDayIds = _newAllDays?.map((d) => d.id);

                    updateScheduleDay({
                      id: schedule.id,
                      input: {
                        preferedDayIds: _newPreferedDayIds,
                        allDayIds: _newAllDayIds,
                      },
                    });
                  }
                }}
              >
                {day.shortName}
              </button>

              <button
                onClick={() => {
                  const _indexPrefered = schedule.preferedDays?.findIndex(
                    (d) => d.id === day.id
                  );

                  if (_indexPrefered !== -1) {
                    toast.error("Erst den bevorzugten Tag entfernen", {
                      duration: 1000,
                    });
                    return;
                  }

                  const index = schedule.allDays?.findIndex(
                    (d) => d.id === day.id
                  );
                  if (
                    index === undefined ||
                    schedule.allDays === null ||
                    schedule.allDays === undefined
                  ) {
                    toast.error(
                      "Error: index === undefined || schedule.days === null || schedule.days === undefined"
                    );
                    return;
                  }
                  if (index !== -1) {
                    // is in array, remove it
                    const _newAllDays = [...schedule.allDays];
                    _newAllDays.splice(index, 1);
                    const _newAllDayIds = _newAllDays?.map((d) => d.id);

                    updateScheduleDay({
                      id: schedule.id,
                      input: {
                        allDayIds: _newAllDayIds,
                      },
                    });
                  } else {
                    //is not in Array, add it
                    const _newAllDays = [...schedule.allDays, day];
                    const _newAllDayIds = _newAllDays?.map((d) => d.id);

                    updateScheduleDay({
                      id: schedule.id,
                      input: {
                        allDayIds: _newAllDayIds,
                      },
                    });
                  }
                }}
              >
                <CheckIcon
                  className={cn("h-4 w-4", {
                    "text-primary": isPossible,
                    "text-gray-200": !isPossible,
                  })}
                />
              </button>
            </div>
          );
        })}
      </div>
      <div className="w-64 flex flex-wrap gap-2">
        {schedule.actionsWithQuantity?.map((awq) => {
          return <DisplayActionCompact action={awq} />;
        })}
      </div>
      <Button
        onClick={() => {
          updateScheduleDay({
            id: schedule.id,
            input: { allCodedActions: getActionClipbaordContentAsCoded() },
          });
        }}
      >
        Leistungen
      </Button>
      <Button
        variant={"danger-ghost"}
        onClick={() => {
          deleteScheduleDay(schedule.id);
        }}
      >
        <TrashIcon className="w-5 h-5" />
      </Button>
    </div>
  );
};

export default PlanScheduleDayRow;
