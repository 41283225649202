import classNames from "classnames";
import React, { ReactElement } from "react";
import { ActionWithQuantity } from "../../generated/graphql";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import { LocalActionWithQuantity } from "../../type-definitions";
import DisplayCashflow from "../Cashflow/DisplayCashflow";

interface Props {
  action: {
    quantity: number;
    action:
      | {
          leistungsart: string;
          examinationRequired: boolean;
        }
      | undefined
      | null;
  };
}

export default function DisplayActionCompact(props: Props): ReactElement {
  const toggleActionClipboardContent = useActionClipboard(
    (state) => state.toggleActionClipboardContent
  );
  const aq = props.action;

  return (
    <div
      className={classNames(
        "inline-block text-xs",
        {
          "border-b-4 border-blue-300": !aq?.action?.examinationRequired,
        },
        {
          "border-b-4 border-yellow-200 ": aq?.action?.examinationRequired,
        }
      )}
      onClick={() => {
        toggleActionClipboardContent(aq);
        console.log("ActionClicked", aq);
      }}
    >
      {aq?.action?.leistungsart}
      {aq.quantity === 1 ? "" : <span>({aq.quantity})</span>}
    </div>
  );
}
