import { create } from "zustand";
import { client } from "../../urqlClient";
import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import { TourStatExecutions_ReviewExecutionMobileQuery } from "../../gql/graphql";
import _ from "lodash";
import { UpsertSupabaseME_ReviewExecutionMobile } from "./useReviewExecutionMobile";
import toast from "react-hot-toast";

export const TourStatExecutions_ReviewExecutionMobile = graphql(/* GraphQL */ `
  query TourStatExecutions_ReviewExecutionMobile(
    $minDay: String!
    $maxDay: String!
  ) {
    tourStatExecutions(minDay: $minDay, maxDay: $maxDay) {
      countExecutions
      countMissions
      sumWorkTimePlanned
      sumWorkTimeExecuted
      diffWorkTime
      ratioCountExecuted
      ratioTimeExecuted
      day
      tourReviewStatus
      countOpenIssues
      dayOfWeek
      tourId
      memberId
      member {
        shortName
        firstName
        lastName
        hasQualificationLevel
        hasExamination
      }
    }
  }
`);

export type LocalTourStatExecutions_ReviewExecutionMobile = ArrElement<
  TourStatExecutions_ReviewExecutionMobileQuery["tourStatExecutions"]
>;

interface ReviewExecutionMobile1State {
  startDay: string;
  endDay: string;
  distinctTourIds: string[];
  distinctDays: string[];
  tourStatExecutions: LocalTourStatExecutions_ReviewExecutionMobile[];
  loadData: () => void;
  syncSupabase: (day: string) => void;
  setStartEndDay: ({
    startDay,
    endDay,
  }: {
    startDay: string;
    endDay: string;
  }) => void;
}

export const useReviewExecutionMobile1 = create<ReviewExecutionMobile1State>(
  (set, get) => ({
    startDay: "",
    endDay: "",
    distinctTourIds: [],
    distinctDays: [],
    tourStatExecutions: [],

    setStartEndDay: ({ startDay, endDay }) => {
      set({
        startDay: startDay,
        endDay: endDay,
        tourStatExecutions: [],
        distinctTourIds: [],
        distinctDays: [],
      });
    },
    loadData: () => {
      const { startDay, endDay } = get();

      client
        .query(
          TourStatExecutions_ReviewExecutionMobile,
          {
            minDay: startDay,
            maxDay: endDay,
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          if (!result.data?.tourStatExecutions) {
            console.log("No data");
            toast.error("Keine Daten gefunden");
            return;
          }

          const tourStatExecutions =
            _.chain(result.data.tourStatExecutions)
              .orderBy(["tourId", "day"], ["asc", "asc"])
              .value() || [];
          const distinctTourIds =
            _.chain(tourStatExecutions)
              .uniqBy("tourId")
              .map("tourId")
              .orderBy(["tourId"], ["asc"])
              .value() || [];

          const distinctDays = _.chain(tourStatExecutions)
            .uniqBy("day")
            .map("day")
            .orderBy(["day"], ["asc"])
            .value();

          console.log(distinctTourIds, distinctDays);
          console.log(tourStatExecutions);

          set({
            tourStatExecutions: tourStatExecutions,
            distinctTourIds: distinctTourIds,
            distinctDays: distinctDays,
          });
        });

      console.log(startDay, endDay);
    },
    syncSupabase: async (day) => {
      const ret = await client
        .mutation(UpsertSupabaseME_ReviewExecutionMobile, {
          day: day,
        })
        .toPromise();

      toast.success("Mobile Daten aktualisiert " + day);

      console.log(ret);
    },
  })
);
