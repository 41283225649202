import React from "react";

import { useGlobalService } from "./useGlobalService";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";

type Props = {};

const GlobalDaySelect = (props: Props) => {
  const { currentSelectedDate, currentSelectedDay, nextDay, prevDay } =
    useGlobalService();

  return (
    <div className="bg-white flex flex-row items-center h-12">
      <Button
        onClick={() => {
          prevDay();
        }}
      >
        -
      </Button>
      <span>{dayjs(currentSelectedDate).format("dddd DD.MM.YYYY")}</span>
      <Button
        onClick={() => {
          nextDay();
        }}
      >
        +
      </Button>
    </div>
  );
};

export default GlobalDaySelect;
