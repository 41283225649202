import { useCallback, type CSSProperties, type FC, useState } from "react";

import { LocalEditMission_TourBuilder, useTourBuilder } from "./useTourBuilder";
import { useDrag } from "react-dnd";
import produce from "immer";
import update from "immutability-helper";
import { Card } from "./Card";
import classNames from "classnames";
import { Button } from "../UiComponents/Button";
import { rgbToHex } from "../../helpers/colors";

export interface BoxProps {
  name: string;
}

interface DropResult {
  name: string;
}

export const Box: FC<BoxProps> = ({ name }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Box",
    item: { name },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        alert(`You dropped ${item.name} into ${dropResult.name}!`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={drag} className="m-1 cursor-move" data-testid={`box`}>
      {name}
    </div>
  );
};

const style = {
  width: 400,
};

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}
type Props = {};

const TourBuilderEditor = (props: Props) => {
  const {
    tours,
    setTours,
    adjustAllTourTimes,
    selectedTimeRanges,
    removeTour,
    updateAllTours,
  } = useTourBuilder();

  const moveCard = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      itemId: string,
      newTourId: string
    ) => {
      //  console.log("dragIndex", dragIndex, hoverIndex);

      console.log("moveCard", dragIndex, hoverIndex, itemId, newTourId);

      // find the tour that has the item
      console.log("itemId", itemId);
      const tourFrom = tours.find((tour) =>
        tour.missions.find((mission) => mission.dbMission.id === itemId)
      );

      // console.log("tourFrom", tourFrom);
      // console.log("tours", tours);

      if (!tourFrom) return;

      //      console.log("tour in der Item war", tourFrom);

      const _tours = produce(tours, (draft) => {
        // Remove item from old tour
        const tourIndex = draft.findIndex(
          (tour) => tour.tourId === tourFrom?.tourId
        );

        if (tourIndex !== -1) {
          const missionIndex = draft[tourIndex].missions.findIndex(
            (mission) => mission.dbMission.id === itemId
          );

          if (missionIndex !== -1) {
            const oldItem = draft[tourIndex].missions[missionIndex];
            console.log("oldItem", oldItem);

            draft[tourIndex].missions.splice(missionIndex, 1);

            // add item to new tour
            const tourIndexNew = draft.findIndex(
              (tour) => tour.tourId === newTourId
            );

            if (tourIndexNew !== -1) {
              draft[tourIndexNew].missions.splice(hoverIndex, 0, oldItem);
            }
          }
        }
      });

      console.log("new tours", _tours);

      setTours(_tours);
      adjustAllTourTimes();
      updateAllTours();

      //   setCards((prevCards: Item[]) =>
      //     update(prevCards, {
      //       $splice: [
      //         [dragIndex, 1],
      //         [hoverIndex, 0, prevCards[dragIndex] as Item],
      //       ],
      //     })
      //   );
    },
    [tours]
  );

  const renderCard = useCallback(
    (
      mission: LocalEditMission_TourBuilder,
      tourId: string,
      index: number,
      isFound: number
    ) => {
      return (
        <Card
          className={classNames({
            "bg-green-100": isFound !== -1,
          })}
          tourId={tourId}
          key={mission.dbMission.id}
          index={index}
          id={mission.dbMission.id}
          time={mission.newStartTime}
          mission={mission.dbMission}
          travelTime={mission.travelTimeToNext_min}
          moveCard={moveCard}
        />
      );
    },
    [tours]
  );

  return (
    <>
      <div className="flex flex-row h-[700px] overflow-y-hidden m-2 p-2">
        {tours.map((tour) => {
          return (
            <div className="overflow-y-auto">
              <div
                className="sticky top-0 flex flex-row justify-between items-center text-xs"
                style={{
                  backgroundColor: tour.bgColor,
                }}
              >
                <div>
                  {tour.tourId}
                  <Button onClick={() => removeTour(tour.tourId)}>X</Button>
                </div>
              </div>
              <div className="overflow-y-auto">
                <div className="bg-gray-50 overflow-y-auto">
                  {tour.missions.map((mission, i) => {
                    return renderCard(
                      mission,
                      tour.tourId,
                      i,
                      tour.filteredMissions.findIndex(
                        (m) => m.dbMission.id === mission.dbMission.id
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TourBuilderEditor;
