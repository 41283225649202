import React from "react";
import { useGetPatientMissionListQuery } from "../../generated/graphql";

type Props = {
  patientIds: string[];
  day: string;
};

const DisplayPatientsMissionDay = (props: Props) => {
  console.log(props);
  const [result, reexecute] = useGetPatientMissionListQuery({
    variables: {
      filter: {
        minDate: props.day,
        maxDate: props.day,
        patientIds: props.patientIds,
      },
    },
  });

  const { data, fetching, error } = result;

  console.log(data);
  console.log(error);

  return (
    <>
      <div className="text-black">DisplayPatientsMissionDay</div>
      {data?.missionsTimeRange?.map((o) => {
        return (
          <div className="text-black">
            {o.patient?.lastName} {o.patient?.firstName} {o.tourId} {o.time}{" "}
            {o.duration_min}
          </div>
        );
      })}
    </>
  );
};

export default DisplayPatientsMissionDay;
