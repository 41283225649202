import { cn } from "../../../helpers/utils";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  patientId: string;
  className?: string;
};

const DisplayPatientIdWithLink = (props: Props) => {
  return (
    <Link
      target="_blank"
      className={cn("cursor-pointer hover:underline", props.className)}
      to={`/manageschedules/${props.patientId}`}
    >
      {props.patientId}
    </Link>
  );
};

export default DisplayPatientIdWithLink;
