import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { useAnalyzeMission } from "./useAnalyzeMission";
import { Button } from "../UiComponents/Button";
import {
  FaChevronCircleRight,
  FaChevronDown,
  FaChevronRight,
  FaFlagCheckered,
} from "react-icons/fa";
import { DisplayCompactMissions, DisplayMissions } from "./PatientWeekyView";
import _ from "lodash";
import { qualificationWarning } from "../PatientMissionList/PatientMissionList";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { cn } from "../../helpers/utils";

import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

type RowPatientWeeklyView = {
  days: string[];
  isOpen: boolean;
};

type DayHeader = {
  dayName: string;
  isoDayNumber: number;
};

const allDays: DayHeader[] = [
  { dayName: "Mo", isoDayNumber: 1 },
  { dayName: "Di", isoDayNumber: 2 },
  { dayName: "Mi", isoDayNumber: 3 },
  { dayName: "Do", isoDayNumber: 4 },
  { dayName: "Fr", isoDayNumber: 5 },
  { dayName: "Sa", isoDayNumber: 6 },
  { dayName: "So", isoDayNumber: 7 },
];

type Props = {};

const PatientCalendarView = (props: Props) => {
  const {
    getMissionsByDay,
    missions,
    dateRange,
    setCheckStatusByDayRange,
    setCheckStatusByIsoDay,
    missionPublications,
  } = useAnalyzeMission();

  const [rows, setRows] = useState<RowPatientWeeklyView[]>([]);

  useEffect(() => {
    const ankerDay = dateRange.startDate || "2021-01-01";

    const start = dayjs(ankerDay).startOf("month");
    const startOfWeek = start.startOf("week");
    const end = dayjs(ankerDay).endOf("month");
    const endOfWeek = end.endOf("week");
    const countWeeks = endOfWeek.diff(startOfWeek, "week");
    console.log("Weeks ", countWeeks);

    const _rows = new Array<RowPatientWeeklyView>(countWeeks + 1);
    for (let index = 0; index < countWeeks + 1; index++) {
      _rows[index] = {
        days: [],
        isOpen: false,
      };
    }

    // console.log(startOfWeek.format("YYYY-MM-DD"));
    // console.log(endOfWeek.format("YYYY-MM-DD"));

    for (let index = 0; index <= endOfWeek.diff(startOfWeek, "days"); index++) {
      // console.log("Index ", index);
      // console.log("FLoor ", Math.floor(index / 7));
      _rows[Math.floor(index / 7)].days.push(
        startOfWeek.add(index, "day").format("YYYY-MM-DD")
      );
    }

    setRows(_rows);
  }, [dateRange]);

  //const [rows, setRows] = useState<RowPatientWeeklyView[]>(_rows);

  //console.log(rows);

  return (
    <div className=" ">
      <div className=" bg-white sticky top-0 grid grid-cols-[72px,1fr,1fr,1fr,1fr,1fr,1fr,1fr] h-12 items-center text-center  border-2 border-gray-200 ">
        <div className="">
          <div className="flex flex-row gap-2">
            <Button
              variant={"neutral-ghost"}
              size={"compact-sm"}
              className="text-green-300 "
              onClick={() => {
                setCheckStatusByDayRange({
                  startDay: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
                  endDay: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
                  checkStatus: true,
                });
              }}
            >
              <FaFlagCheckered />
            </Button>
            <Button
              variant={"neutral-ghost"}
              size={"compact-sm"}
              className="text-red-300"
              onClick={() => {
                setCheckStatusByDayRange({
                  startDay: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
                  endDay: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
                  checkStatus: false,
                });
              }}
            >
              <FaFlagCheckered />
            </Button>
          </div>
        </div>

        {allDays.map((day) => {
          const countQualificationWarning = _.filter(missions, (mission) => {
            return (
              dayjs(Number(mission.startTS)).isoWeekday() ===
                day.isoDayNumber &&
              qualificationWarning({
                hasRequiredQualification:
                  mission?.member?.hasQualificationLevel!,
                minRequiredQualification: mission?.minReqQualification,
                isPrivate: mission?.patient?.isPrivate || false,
              })
            );
          }).length;

          return (
            <div className="flex flex-row justify-between items-center w-56 ">
              <div className="flex flex-row m-2">
                <Button
                  variant={"neutral-ghost"}
                  size={"compact-sm"}
                  className="text-green-300"
                  onClick={() => {
                    setCheckStatusByIsoDay({
                      isoDayNumber: day.isoDayNumber,
                      checkStatus: true,
                    });
                  }}
                >
                  <FaFlagCheckered className="" />
                </Button>
                <Button
                  variant={"neutral-ghost"}
                  size={"compact-sm"}
                  className="text-red-300 "
                  onClick={() => {
                    setCheckStatusByIsoDay({
                      isoDayNumber: day.isoDayNumber,
                      checkStatus: false,
                    });
                  }}
                >
                  <FaFlagCheckered />
                </Button>
              </div>
              <div className="flex flex-row gap-1">
                {countQualificationWarning !== 0 ? (
                  <ExclamationTriangleIcon className="h-5 w-5 text-white bg-purple-500" />
                ) : null}
              </div>
              {day.dayName}
            </div>
          );
        })}
      </div>

      <div className="">
        {rows.map((row, index) => {
          const _minDay = dayjs(row.days[0])
            .startOf("week")
            .format("YYYY-MM-DD");
          const _maxDay = dayjs(row.days[0]).endOf("week").format("YYYY-MM-DD");
          const countQualificationWarning = _.filter(missions, (mission) => {
            return (
              dayjs(Number(mission.startTS)).week() ===
                dayjs(row.days[0]).week() &&
              qualificationWarning({
                hasRequiredQualification:
                  mission?.member?.hasQualificationLevel!,
                minRequiredQualification: mission?.minReqQualification,
                isPrivate: mission?.patient?.isPrivate || false,
              })
            );
          }).length;
          return (
            <div className="grid grid-cols-[72px,1fr,1fr,1fr,1fr,1fr,1fr,1fr] ">
              <div
                className="
                text-center
                flex flex-col
                justify-center
                bg-white
                p-1 m-1
               "
              >
                <div className="flex flex-col justify-center items-center gap-2">
                  <Button
                    variant={"neutral-ghost"}
                    onClick={() => {
                      const _rows = [...rows];
                      _rows[index].isOpen = !_rows[index].isOpen;
                      setRows(_rows);
                    }}
                  >
                    {row.isOpen ? (
                      <FaChevronRight className="h-5 w-5" />
                    ) : (
                      <FaChevronDown className="h-5 w-5" />
                    )}
                  </Button>
                  <Button
                    variant={"neutral-ghost"}
                    size={"compact-sm"}
                    className="text-green-300"
                    onClick={() => {
                      setCheckStatusByDayRange({
                        startDay: _minDay,
                        endDay: _maxDay,
                        checkStatus: true,
                      });
                    }}
                  >
                    <FaFlagCheckered className="" />
                  </Button>
                  <Button
                    variant={"neutral-ghost"}
                    size={"compact-sm"}
                    className="text-red-300 "
                    onClick={() => {
                      setCheckStatusByDayRange({
                        startDay: _minDay,
                        endDay: _maxDay,
                        checkStatus: false,
                      });
                    }}
                  >
                    <FaFlagCheckered />
                  </Button>
                  <div>{dayjs(row.days[0]).week()}</div>
                  <div className="flex flex-row gap-1">
                    {countQualificationWarning !== 0 ? (
                      <div>
                        <ExclamationTriangleIcon className="h-4 w-4 text-white bg-purple-500" />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {row.days.map((day) => {
                const _missions = getMissionsByDay({ day: day });
                //Hier die Felder
                // console.log(_missions);
                // console.log(day);
                const _missionPublication = _.find(missionPublications, (o) => {
                  return o?.day === day;
                });
                return (
                  <div
                    className={cn(
                      "bg-white p-1 m-1 flex flex-col justify-start ",
                      {
                        "opacity-30 bg-white":
                          _missionPublication?.releaseStatus !== "rework",
                      }
                    )}
                  >
                    <div className="flex felx-row justify-between items-center p-1 bg-gray-200">
                      <div>
                        <Button
                          variant={"neutral-ghost"}
                          size={"compact-sm"}
                          className="text-green-300"
                          onClick={() => {
                            setCheckStatusByDayRange({
                              startDay: day,
                              endDay: day,
                              checkStatus: true,
                            });
                          }}
                        >
                          <FaFlagCheckered className="" />
                        </Button>
                        <Button
                          variant={"neutral-ghost"}
                          size={"compact-sm"}
                          className="text-red-300 "
                          onClick={() => {
                            setCheckStatusByDayRange({
                              startDay: day,
                              endDay: day,
                              checkStatus: false,
                            });
                          }}
                        >
                          <FaFlagCheckered />
                        </Button>
                      </div>
                      {dayjs(day).format("dd DD. MMM")}
                    </div>
                    <div className="flex flex-col justify-center grow w-56 ">
                      {row.isOpen ? (
                        <DisplayMissions missions={_missions} />
                      ) : (
                        <DisplayCompactMissions missions={_missions} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatientCalendarView;
