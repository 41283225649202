import { create } from "zustand";
import { graphql } from "../../../gql";
import { client } from "../../../urqlClient";
import { ArrElement } from "../../../helpers/typeHelpers";
import {
  AllPatientAbsences_ManagePatientAbsenceQuery,
  PatientAbsences_PatientCareIntervalsQuery,
} from "../../../gql/graphql";
import _ from "lodash";
import dayjs from "dayjs";

const PatientAbsences_PatientCareIntervals = graphql(/* GraphQL */ `
  query PatientAbsences_PatientCareIntervals($patientId: String!) {
    patientCareIntervals(patientId: $patientId) {
      id
      endDay
      startDay
      patientId
    }
  }
`);

export type LocalPatientAbsence_PatientCareInverval = ArrElement<
  PatientAbsences_PatientCareIntervalsQuery["patientCareIntervals"]
>;

const PatientAbsences_ManagePatientAbsence = graphql(/* GraphQL */ `
  query PatientAbsences_ManagePatientAbsence(
    $patientId: String!
    $minDay: String
    $maxDay: String
  ) {
    patientAbsences(patientId: $patientId, minDay: $minDay, maxDay: $maxDay) {
      id
      patientId
      startDay
      remark
      endDay
      patient {
        lastName
        firstName
      }
    }
  }
`);

const AllPatientAbsences_ManagePatientAbsence = graphql(/* GraphQL */ `
  query AllPatientAbsences_ManagePatientAbsence {
    allPatientAbsences {
      id
      patientId
      startDay
      remark
      endDay
      patient {
        lastName
        firstName
      }
    }
  }
`);

const DeletePatientAbsence_ManagePatientAbsence = graphql(/* GraphQL */ `
  mutation DeletePatientAbsence_ManagePatientAbsence($id: String!) {
    deletePatientAbsence(id: $id) {
      id
    }
  }
`);

const CreatePatientAbsence_ManagePatientAbsence = graphql(/* GraphQL */ `
  mutation CreatePatientAbsence_ManagePatientAbsence(
    $input: CreatePatientAbsenceInput!
  ) {
    createPatientAbsence(input: $input) {
      id
    }
  }
`);

const UpdatePatientAbsence_ManagePatientAbsence = graphql(/* GraphQL */ `
  mutation UpdatePatientAbsence_ManagePatientAbsence(
    $id: String!
    $input: UpdatePatientAbsenceInput!
  ) {
    updatePatientAbsence(id: $id, input: $input) {
      id
    }
  }
`);

export type LocalPatientAbsence_ManagePatientAbsence = ArrElement<
  AllPatientAbsences_ManagePatientAbsenceQuery["allPatientAbsences"]
>;

type PatientAbsenceState = {
  allPatientAbsences: LocalPatientAbsence_ManagePatientAbsence[];
  patientAbsences: LocalPatientAbsence_ManagePatientAbsence[];
  patientId: string;
  activePatientAbsence: LocalPatientAbsence_ManagePatientAbsence | null;
  patientCareIntervals: LocalPatientAbsence_PatientCareInverval[] | null;
  setPatientId: (patientId: string) => void;
  loadAllPatientAbsences: () => void;
  loadPatientAbsences: () => void;
  deletePatientAbsence: (id: string, update?: () => void) => void;
  createPatientAbsence: (
    input: {
      patientId: string;
      startDay: string;
      endDay: string;
      remark?: string;
    },
    update?: () => void
  ) => void;
  updatePatientAbsence: (
    id: string,
    input: {
      startDay: string;
      endDay: string;
      remark?: string;
    },
    update?: () => void
  ) => void;
};

export const usePatientAbsence = create<PatientAbsenceState>((set, get) => ({
  allPatientAbsences: [],
  activePatientAbsence: null,
  patientAbsences: [],
  patientId: "",
  patientCareIntervals: null,
  loadAllPatientAbsences: async () => {
    client
      .query(
        AllPatientAbsences_ManagePatientAbsence,
        {},
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((result) => {
        const _sortedAllPatientAbsences = _.orderBy(
          result?.data?.allPatientAbsences,
          ["startDay"],
          ["desc"]
        );

        set({
          allPatientAbsences: result?.data?.allPatientAbsences || [],
        });
      });
  },
  deletePatientAbsence: async (id: string, update?: () => void) => {
    client
      .mutation(DeletePatientAbsence_ManagePatientAbsence, { id })
      .toPromise()
      .then((result) => {
        get().loadAllPatientAbsences();
        get().loadPatientAbsences();
        if (update) update();
      });
  },
  createPatientAbsence: async (input, update?: () => void) => {
    client
      .mutation(CreatePatientAbsence_ManagePatientAbsence, { input })
      .toPromise()
      .then((result) => {
        get().loadAllPatientAbsences();
        get().loadPatientAbsences();
      });
  },
  setPatientId: (patientId: string) => {
    set({ patientId });
    get().loadPatientAbsences();
  },

  loadPatientAbsences: async () => {
    client
      .query(
        PatientAbsences_PatientCareIntervals,
        {
          patientId: get().patientId,
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((result) => {
        set({
          patientCareIntervals: result?.data?.patientCareIntervals || null,
        });
      });

    client
      .query(
        PatientAbsences_ManagePatientAbsence,
        {
          patientId: get().patientId,
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((result) => {
        const _sortedPatientAbsences = _.orderBy(
          result?.data?.patientAbsences,
          ["startDay"],
          ["desc"]
        );

        const _activePatientAbsence = _sortedPatientAbsences.find(
          (pa) => pa.endDay >= dayjs().format("YYYY-MM-DD")
        );

        set({
          patientAbsences: _sortedPatientAbsences || [],
          activePatientAbsence: _activePatientAbsence || null,
        });
      });
  },
  updatePatientAbsence: async (id, input, update) => {
    client
      .mutation(UpdatePatientAbsence_ManagePatientAbsence, { id, input })
      .toPromise()
      .then((result) => {
        get().loadAllPatientAbsences();
        get().loadPatientAbsences();
        if (update) update();
      });
  },
}));
