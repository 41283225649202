import React from "react";
import {
  LocalScheduleEvery_PlanSchedule,
  usePlanSchedule,
} from "./usePlanSchedule";
import { shallowCopy } from "immer/dist/internal";
import TimeSelectorPlanSchedule from "./TimeSelectorPlanSchedule";
import { Button } from "../../UiComponents/Button";
import { toast } from "react-toastify";
import DisplayActionCompact from "../../Action/DisplayActionCompact";
import { useActionClipboard } from "../../ActionClipboard/useActionClipboard";
import { TrashIcon } from "@radix-ui/react-icons";

type Props = {
  schedule: LocalScheduleEvery_PlanSchedule;
};

const PlanScheduleEveryRow = (props: Props) => {
  const { updateScheduleEvery, deleteScheduleEvery } = usePlanSchedule();
  const { getActionClipbaordContentAsCoded } = useActionClipboard();
  const { schedule } = props;
  const { every, everyType } = schedule;

  const dayArray = [];

  for (let i = 1; i < every; i++) {
    dayArray.push(i);
  }

  return (
    <div className="">
      <div className="bg-white gap-2 flex items-center">
        <TimeSelectorPlanSchedule
          minStartTime={schedule.minStartTime}
          maxStartTime={schedule.maxStartTime}
          acceptedMaxStartTime={schedule.acceptedMaxStartTime}
          acceptedMinStartTime={schedule.acceptedMinStartTime}
          scheduleId={schedule.id}
          shift={schedule.shift}
          updateSchedule={updateScheduleEvery}
        />

        <Button
          variant={"neutral-ghost"}
          onClick={() => {
            if (every == 2) {
              toast.error("Mindetens jeder 2. Tag nötig");
              return;
            }
            updateScheduleEvery({
              id: schedule.id,
              input: {
                every: every - 1,
              },
            });
          }}
        >
          -
        </Button>

        {schedule.every}
        <Button
          variant={"neutral-ghost"}
          onClick={() => {
            if (every > 4) {
              toast.error("Maximal jeder 5. Tag möglich");
              return;
            }
            updateScheduleEvery({
              id: schedule.id,
              input: {
                every: every + 1,
              },
            });
          }}
        >
          +
        </Button>

        <div className="flex flex-row gap-2 items-center">
          <div className="border-2 border-gray-500 text-gray-600 h-10 w-10 p-2 text-center rounded-full ">
            V
          </div>
          {dayArray.map((day) => {
            return (
              <div className="flex flex-row gap-2">
                <div className="bg-gray-100 p-2 h-10 w-10 text-center rounded-full">
                  {day}
                </div>
              </div>
            );
          })}
          <div className="border-2 border-gray-500 text-gray-600 h-10 w-10 p-2 text-center rounded-full">
            N
          </div>
        </div>

        <div className="w-64 flex flex-wrap gap-2">
          {schedule.actionsWithQuantity?.map((awq) => {
            return (
              <>
                <DisplayActionCompact action={awq} />
                <div className="text-xs">({awq.lastDateOfMission})</div>
              </>
            );
          })}
        </div>
        <Button
          onClick={() => {
            updateScheduleEvery({
              id: schedule.id,
              input: { allCodedActions: getActionClipbaordContentAsCoded() },
            });
          }}
        >
          Leistungen
        </Button>
        <Button
          variant={"danger-ghost"}
          onClick={() => {
            deleteScheduleEvery(schedule.id);
          }}
        >
          <TrashIcon className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
};

export default PlanScheduleEveryRow;
