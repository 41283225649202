import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, DialogContent } from "./Dialog";
import inMemoryJwt from "../Auth0/inMemoryJwt";

import { io, Socket } from "socket.io-client";
import { useAuth0 } from "@auth0/auth0-react";
import ServerMessage from "../ServerMessage";

interface Props {
  open: boolean;
}

export const MyDimmer = (props: Props) => {
  const { open } = props;

  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [infoText, setInfoText] = React.useState("");

  // useEffect(() => {
  //   if (isLoading) {
  //     return;
  //   }

  //   if (!isAuthenticated) {
  //     return;
  //   }

  //   getAccessTokenSilently()
  //     .then((result) => {
  //       console.log("token in jwt Token", result);
  //       inMemoryJwt.setToken(result);
  //     })
  //     .catch((err) => {
  //       console.log("login required", err);
  //     });
  // }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  // useEffect(() => {
  //   const _socket = io("http://localhost:3333", {
  //     auth: {
  //       token: inMemoryJwt.getToken(),
  //     },
  //   });

  //   _socket.on("connect", () => {
  //     console.log("connected");

  //     _socket.on("disconnect", () => {
  //       console.log("disconnected");
  //     });
  //   });

  //   _socket.on("hurga", (data) => {
  //     console.log("hurga", data);
  //     setInfoText(JSON.stringify(data, null, 2));
  //   });
  // }, []);

  return (
    <div className="">
      <Dialog open={open}>
        <DialogContent className="h-96 w-96">
          <div className="flex items-center justify-center  bg-white">
            Loading
            <ServerMessage />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
