import dayjs from "dayjs";
import produce from "immer";
import _ from "lodash";
import { create } from "zustand";

import customParseFormat from "dayjs";

import {
  GetAnalyseMissionDocument,
  GetAnalyseMissionQuery,
  GetAnalyseMissionQueryVariables,
  AddMissionToPublicationIdDocument,
  AddMissionToPublicationIdMutationVariables,
  AddMissionToPublicationIdMutation,
  MissionSetIsActiveMutationVariables,
  MissionSetIsActiveMutation,
  MissionSetIsActiveDocument,
  GetMissionsByIdsDocument,
  GetMissionsByIdsQueryVariables,
  GetMissionsByIdsQuery,
  MissionSetActionDocument,
  MissionSetActionMutationVariables,
  MissionSetActionMutation,
} from "../../generated/graphql";

import { graphql, DocumentType } from "../../gql";
import { client } from "../../urqlClient";
import { LocalActionWithQuantity } from "../../type-definitions";
import { ts2hhmmss } from "../../helpers/datetime";
import { ArrElement } from "../../helpers/typeHelpers";
import {
  MissionPublications_AnalyzeMissionQuery,
  MissionPublications_AnalyzeMissionQueryVariables,
  PatientInfoAnalyzeMissionQuery,
  PatientInfoAnalyzeMissionQueryVariables,
} from "../../gql/graphql";

dayjs.extend(customParseFormat);

export type LocalGetAnalyseMission = ArrElement<
  GetAnalyseMissionQuery["missionsTimeRange"]
>;

const PatientInfoAnalyzeMission = graphql(/* GraphQL */ `
  query PatientInfoAnalyzeMission($patientId: String!) {
    patient(patientId: $patientId) {
      patientId
      lastName
      firstName
      kkType
    }
  }
`);

const SetCheckStatusMissions_AnalyzeMission = graphql(/* GraphQL */ `
  mutation SetCheckStatusMissions_AnalyzeMission(
    $missionIds: [String!]!
    $checkStatus: Boolean!
  ) {
    setCheckStatusMissions(missionIds: $missionIds, checkStatus: $checkStatus) {
      id
    }
  }
`);

const MissionPublications_AnalyzeMission = graphql(/* GraphQL */ `
  query MissionPublications_AnalyzeMission(
    $filter: MostRecentMissionPublicationFilter!
  ) {
    mostRecentMissionPublications(filter: $filter) {
      id
      releaseStatus
      releaseStatusName
      day
    }
  }
`);

type LocalAnalyzeMissionPatient = PatientInfoAnalyzeMissionQuery["patient"];

type AddMissionInput = {
  publicationId: string;
  patientId: string;
  memberId: string;
  startTS: string;
  endTS: string;
  tourId: string;
  actionsWithQuantity: any[];
};

export type WeekRow = {
  week: string;
  "1": LocalGetAnalyseMission[];
  "2": LocalGetAnalyseMission[];
  "3": LocalGetAnalyseMission[];
  "4": LocalGetAnalyseMission[];
  "5": LocalGetAnalyseMission[];
  "6": LocalGetAnalyseMission[];
  "0": LocalGetAnalyseMission[];
};

type MonthlyOverview = {
  toatalCashValue: number;
  totalCashValueComplexed: number;
  totalCashValueBehandlung: number;
  totalCashValuePauschale: number;
  totalCashValueTimed: number;
};

type IAnalyzeMission = {
  dateRange: { startDate: Date; endDate: Date };
  patientId: string;
  patient: LocalAnalyzeMissionPatient | null;
  setDateRange: (range: { startDate: Date; endDate: Date }) => void;
  missionPublications: MissionPublications_AnalyzeMissionQuery["mostRecentMissionPublications"];
  missions: LocalGetAnalyseMission[];
  missionsByDay: WeekRow[];
  monthyOverview: MonthlyOverview | null;
  loadMissions: () => void;
  setPatientId: (id: string) => void;
  setMissonActive: (mission: LocalGetAnalyseMission) => Promise<boolean>;
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  selectedMissionId: string;
  setSelectedMissionId: (id: string) => void;
  addMissionToPublicationId: (
    refMission: LocalGetAnalyseMission | undefined,
    refDay: string
  ) => void;
  setMissionStatus: (
    missionId: string,
    status: boolean,
    updatedAt: string
  ) => void;
  updateMission: () => void;
  addNewMissions: () => void;
  lastUpdatedAt: string;
  setActionsOnMission: (
    mission: LocalGetAnalyseMission,
    actions: LocalActionWithQuantity[]
  ) => void;
  errorOnUPdate: boolean;
  setErrorOnUpdate: (status: boolean) => void;
  patientsWithNoActions: any[];
  setCheckStatusByIsoDay: ({
    isoDayNumber,
    checkStatus,
  }: {
    isoDayNumber: number;
    checkStatus: boolean;
  }) => void;
  setCheckStatusByDayRange: ({
    startDay,
    endDay,
    checkStatus,
  }: {
    startDay: string;
    endDay: string;
    checkStatus: boolean;
  }) => void;
  setCheckStatusMissions: ({
    missionIds,
    checkStatus,
  }: {
    missionIds: string[];
    checkStatus: boolean;
  }) => void;
  setCheckStatusAll({ checkStatus }: { checkStatus: boolean }): void;
  getMissionsByDay: ({ day }: { day: string }) => LocalGetAnalyseMission[];
  loadMissionPublications: () => void;
  calculateMonthlyOverview: () => void;
};

export const useAnalyzeMission = create<IAnalyzeMission>((set, get) => ({
  dateRange: {
    startDate: dayjs().startOf("week").add(1, "day").toDate(),
    endDate: dayjs().endOf("week").add(1, "day").toDate(),
  },
  lastUpdatedAt: (dayjs().unix() * 1000).toString(),
  missionPublications: undefined,
  patientId: "",
  patient: null,
  selectedMissionId: "",
  monthyOverview: null,
  missions: [],
  missionsByDay: [],
  errorOnUPdate: false,
  isLoading: false,
  patientsWithNoActions: [],
  setDateRange: (dateRange: { startDate: Date; endDate: Date }) => {
    console.log(dateRange);
    set(() => ({ dateRange, missions: [] }));
    get().loadMissionPublications();
  },
  setPatientId: async (id: string) => {
    const ret = await client
      .query<
        PatientInfoAnalyzeMissionQuery,
        PatientInfoAnalyzeMissionQueryVariables
      >(PatientInfoAnalyzeMission, { patientId: id })
      .toPromise();
    set(() => ({ patientId: id, patient: ret?.data?.patient }));
    get().loadMissions();

    //set(() => ({ patientId: id }));
  },
  setErrorOnUpdate: (status: boolean) => {
    set(() => ({ errorOnUPdate: status }));
  },
  setSelectedMissionId: (id: string) => {
    set(() => ({ selectedMissionId: id }));
  },

  setActionsOnMission: (
    mission: LocalGetAnalyseMission,
    actions: LocalActionWithQuantity[]
  ) => {
    const updateMission = get().updateMission;
    set(() => ({ isLoading: true }));
    const ret = client
      .mutation<MissionSetActionMutation, MissionSetActionMutationVariables>(
        MissionSetActionDocument,
        {
          id: mission.id,
          actionIdsWQ: actions.map((a) => ({
            lnr: a.action.lnr,
            quantity: a.quantity,
          })),
          updatedAt: mission.updatedAt,
        }
      )
      .toPromise()
      .then((res) => {
        console.log(res);
        updateMission();
      });
    set(() => ({ isLoading: false }));
  },

  addNewMissions: async () => {
    const missions = get().missions;
    const lastUpdatedAt = get().lastUpdatedAt;
    set(() => ({ isLoading: true }));

    await client
      .query<GetAnalyseMissionQuery, GetAnalyseMissionQueryVariables>(
        GetAnalyseMissionDocument,
        {
          filter: {
            minDate: dayjs(get().dateRange.startDate).format("YYYY-MM-DD"),
            maxDate: dayjs(get().dateRange.endDate).format("YYYY-MM-DD"),
            patientIds: [get().patientId],
            updatedLater: lastUpdatedAt,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((res) => {
        console.log(res.data?.missionsTimeRange);
        res?.data?.missionsTimeRange?.forEach((mission) => {
          const _newMissions = produce(missions, (draft) => {
            const index = draft.findIndex((m) => m.id === mission.id);
            if (index === -1) {
              draft.push(mission);
            }
          });
          set(() => ({ missions: _newMissions }));
          set(() => ({ lastUpdatedAt: (dayjs().unix() * 1000).toString() }));
          set(() => ({ isLoading: false }));
        });
      });
  },
  /**
   * Update Mission
   */
  updateMission: async () => {
    const missions = get().missions;
    const lastUpdatedAt = get().lastUpdatedAt;
    console.log("updateMission");
    set(() => ({ isLoading: true }));
    console.log("lastUpdatedAt", ts2hhmmss(lastUpdatedAt));

    await client
      .query<GetMissionsByIdsQuery, GetMissionsByIdsQueryVariables>(
        GetMissionsByIdsDocument,
        {
          filter: {
            missionIds: missions.map((m) => m.id),
            updatedLater: lastUpdatedAt,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((res) => {
        console.log("Geänderte Missions", res.data?.missionsByIds);

        const retMissions = res.data?.missionsByIds;

        const _newMissions = produce(missions, (draft) => {
          retMissions?.forEach((mission) => {
            const index = draft.findIndex((m) => m.id === mission?.id);
            if (index !== -1 && mission !== null) {
              // console.log(mission);
              // console.log(draft[index]);
              draft[index] = {
                ...(mission as LocalGetAnalyseMission),
              };
            }
          });

          return draft;
        });

        set(() => ({ missions: _newMissions }));
        set(() => ({
          lastUpdatedAt: (dayjs().unix() * 1000).toString(),
        }));
        set(() => ({ isLoading: false }));

        // res?.data?.missionsByIds?.forEach((mission) => {
        //   const _newMissions = produce(missions, (draft) => {
        //     const index = draft.findIndex((m) => m.id === mission?.id);
        //     if (index !== -1 && mission !== null) {
        //       // console.log(mission);
        //       // console.log(draft[index]);
        //       draft[index] = { ...(mission as LocalGetAnalyseMission) };
        //     }
        //   });

        //   set(() => ({ missions: _newMissions }));
        //   set(() => ({ lastUpdatedAt: (dayjs().unix() * 1000).toString() }));
        //   set(() => ({ isLoading: false }));
        // });
      });
  },

  setMissionStatus: (missionId: string, status: boolean, updatedAt: string) => {
    const updateMissions = get().updateMission;
    console.log(updatedAt);
    set(() => ({ isLoading: true }));

    const ret = client
      .mutation<
        MissionSetIsActiveMutation,
        MissionSetIsActiveMutationVariables
      >(MissionSetIsActiveDocument, {
        id: missionId,
        isActive: status,
        updatedAt: updatedAt,
      })
      .toPromise()
      .then((res) => {
        console.log(res);

        updateMissions();
        if (res.error) {
          set(() => ({ errorOnUPdate: true }));
          // throw new Error(
          //   "Fehler, Daten waren nicht aktuell. Daten wurden aktualisiert"
          // );
        }
      })
      .catch((err) => {
        console.log("Error", err);
        // throw new Error("Error, Daten waren nicht aktuell");
      });
    set(() => ({ isLoading: false }));
  },

  addMissionToPublicationId: (
    refMission: LocalGetAnalyseMission | undefined,
    refDay: string
  ) => {
    if (refMission === undefined) {
      return;
    }

    console.log(refDay);
    console.log(dayjs(Number(refMission.startTS)).format("HH:mm"));

    const newStartDate = dayjs(
      refDay + " " + dayjs(Number(refMission.startTS)).format("HH:mm"),
      "YYYY-MM-DD HH:mm"
    );
    const newEndtDate = dayjs(
      refDay + " " + dayjs(Number(refMission.endTS)).format("HH:mm"),
      "YYYY-MM-DD HH:mm"
    );

    const newMission = {
      publicationId: refMission.missionPublicationId,
      tourId: "F_B_NeueTour",
      patientId: refMission.patientId,
      memberId: refMission.memberId,
      startTS: newStartDate.toISOString(),
      endTS: newEndtDate.toISOString(),
      actionsWithQuantity: [],
    } as AddMissionInput;

    const updateMissions = get().updateMission;
    const addNewMissions = get().addNewMissions;

    const ret = client
      .mutation<
        AddMissionToPublicationIdMutation,
        AddMissionToPublicationIdMutationVariables
      >(AddMissionToPublicationIdDocument, {
        input: {
          publicationId: refMission.missionPublicationId,
          mission: {
            tourId: newMission.tourId,
            patientId: newMission.patientId,
            memberId: newMission.memberId,
            startTS: newMission.startTS,
            endTS: newMission.endTS,
            actionsWithQantity: newMission.actionsWithQuantity,
          },
        },
      })
      .toPromise();

    ret.then((res) => {
      console.log(res);
      addNewMissions();
      updateMissions();
    });

    console.log(newMission);
  },

  setIsLoading: (status: boolean) => {
    set(() => ({ isLoading: status }));
  },

  setMissonActive: async (mission) => {
    set(() => ({ isLoading: true }));
    const myPromise = new Promise<boolean>((resolve, reject) => {
      console.log("Vor Suchen", mission);

      const _newMissions = produce(get().missions, (draft) => {
        const index = draft.findIndex((m) => m.id === mission.id);
        if (index !== -1) {
          draft[index].isActive = !draft[index].isActive;
        }
        return draft;
      });

      console.log("Geändert");

      set(() => ({ missions: _newMissions }));
      setTimeout(() => {
        resolve(true);
      }, 1);
    });
    return myPromise;
  },

  loadMissions: async () => {
    console.log("loadMissions start", get().dateRange);

    set(() => ({ lastUpdatedAt: (dayjs().unix() * 1000).toString() }));

    set(() => ({ missions: [], isLoading: true }));
    const ret = await client
      .query<GetAnalyseMissionQuery, GetAnalyseMissionQueryVariables>(
        GetAnalyseMissionDocument,
        {
          filter: {
            minDate: dayjs(get().dateRange.startDate).format("YYYY-MM-DD"),
            maxDate: dayjs(get().dateRange.endDate).format("YYYY-MM-DD"),
            patientIds: [get().patientId],
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((res) => {
        console.log(res);
        if (res.data?.missionsTimeRange !== undefined) {
          console.log(res.data?.missionsTimeRange);
          set(() => ({
            missions:
              _.orderBy(res.data?.missionsTimeRange, ["startTS"], ["asc"]) ||
              [],
            isLoading: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    console.log("loadMissions end");
  },
  setCheckStatusByIsoDay: ({ isoDayNumber, checkStatus }) => {
    const _missions = get().missions;
    set(() => ({ isLoading: true }));

    const _selectedMissions = _missions.filter(
      (m) => dayjs(Number(m.startTS)).day() === isoDayNumber
    );

    //console.log(_selectedMissions);
    get().setCheckStatusMissions({
      missionIds: _selectedMissions.map((m) => m.id),
      checkStatus: checkStatus,
    });
  },
  setCheckStatusByDayRange: ({ startDay, endDay, checkStatus }) => {
    console.log(startDay, endDay);
    set(() => ({ isLoading: true }));

    const _missions = get().missions;

    const _selectedMissions = _missions.filter((m) => {
      const _day = dayjs(Number(m.startTS)).format("YYYY-MM-DD");

      return _day >= startDay && _day <= endDay;
    });

    console.log(_selectedMissions);
    get().setCheckStatusMissions({
      missionIds: _selectedMissions.map((m) => m.id),
      checkStatus: checkStatus,
    });
  },
  setCheckStatusMissions: ({ missionIds, checkStatus }) => {
    //  console.log(missionIds, checkStatus);

    const ret = client
      .mutation<{}, {}>(SetCheckStatusMissions_AnalyzeMission, {
        missionIds: missionIds,
        checkStatus: checkStatus,
      })
      .toPromise()
      .then((res) => {
        console.log(res);
        get().updateMission();
      });
  },

  setCheckStatusAll: ({ checkStatus }: { checkStatus: boolean }) => {
    set(() => ({ isLoading: true }));

    const _missions = get().missions;

    get().setCheckStatusMissions({
      missionIds: _missions.map((m) => m.id),
      checkStatus: checkStatus,
    });
  },
  getMissionsByDay: ({ day }: { day: string }) => {
    const _missions = get().missions;
    const _selectedMissions = _missions.filter(
      (m) => dayjs(Number(m.startTS)).format("YYYY-MM-DD") === day
    );
    return _selectedMissions;
  },
  loadMissionPublications: async () => {
    const ret = await client
      .query<
        MissionPublications_AnalyzeMissionQuery,
        MissionPublications_AnalyzeMissionQueryVariables
      >(MissionPublications_AnalyzeMission, {
        filter: {
          maxDate: dayjs(get().dateRange.endDate).format("YYYY-MM-DD"),
          minDate: dayjs(get().dateRange.startDate).format("YYYY-MM-DD"),
        },
      })
      .toPromise();
    if (ret.data?.mostRecentMissionPublications) {
      set(() => ({
        missionPublications: ret.data?.mostRecentMissionPublications,
      }));
    }
  },
  calculateMonthlyOverview: () => {
    const _missions = get().missions;

    const _monthlyOverview = {
      toatalCashValue: 0,
      totalCashValueComplexed: 0,
      totalCashValueBehandlung: 0,
      totalCashValuePauschale: 0,
      totalCashValueTimed: 0,
    } as MonthlyOverview;

    _missions.forEach((m) => {
      _monthlyOverview.toatalCashValue +=
        m.missionAnalytic?.totalCashValue || 0;
      _monthlyOverview.totalCashValueComplexed +=
        m.missionAnalytic?.grundCashValue || 0;
      _monthlyOverview.totalCashValueBehandlung +=
        (m.missionAnalytic?.behandlungMaxCashValue || 0) +
        (m.missionAnalytic?.behandlungAdditionalCashValue || 0);
      _monthlyOverview.totalCashValuePauschale +=
        m.missionAnalytic?.totalPauschaleCashValue || 0;
      _monthlyOverview.totalCashValueTimed +=
        m.missionAnalytic?.timedCashValue || 0;
    });

    set(() => ({ monthyOverview: _monthlyOverview }));
  },
}));
