import React from "react";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import { Search } from "lucide-react";
import MemberSearchbox from "../MemberSearchbox/MemberSearchbox";
import { Button } from "../UiComponents/Button";
import { useMissionEasyPlan } from "./useMissionEasyPlan";
import { useMemberSearchbox } from "../MemberSearchbox/useMemberSearchbox";

type Props = {
  children: React.ReactNode;
  currentMemberId: string;
  tourId: string;
  chlidren?: React.ReactNode;
};

const ChangeMember = (props: Props) => {
  const { assignMemberIdToTour } = useMissionEasyPlan();
  const { selectedMember } = useMemberSearchbox();

  const [choosenMemberId, setChoosenMemberId] = React.useState<string>(
    props.currentMemberId
  );

  return (
    <div>
      <Dialog>
        <DialogTrigger>{props.children}</DialogTrigger>

        <DialogContent className="w-96 h-96">
          <div className="flex flex-col justify-start">
            <div>Neuen Mitarbeiter zuordnen</div>
            <div>{props.tourId}</div>
            <MemberSearchbox
              currentMemberId={choosenMemberId}
              setMemberId={setChoosenMemberId}
            />
            <Button
              onClick={() => {
                assignMemberIdToTour({
                  tourId: props.tourId,
                  member: selectedMember,
                  memberId: choosenMemberId,
                });
              }}
            >
              Zuordnen
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangeMember;
