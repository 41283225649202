("use client");

import * as React from "react";
import dayjs from "dayjs";

import { DateRange } from "react-day-picker";

import { cn } from "../../helpers/utils";
import { Button } from "./Button";
import { Calendar } from "./Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import { CalendarIcon } from "@radix-ui/react-icons";

import { PopoverClose } from "@radix-ui/react-popover";

type Props = {
  className?: string;
  dateRange: DateRange | undefined;
  setDateRange: (dateRange: DateRange | undefined) => void;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
} & React.HTMLAttributes<HTMLDivElement>;

export const DatePickerWithRange = ({
  className,
  dateRange,
  setDateRange,
  weekStartsOn,
}: Props) => {
  //   const [date, setDate] = React.useState<DateRange | undefined>({
  //     from: dayjs().toDate(),
  //     to: dayjs().add(7, "day").toDate(),
  //   });

  return (
    <div className={cn("gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"neutral-ghost"}
            className={cn(
              "justify-start text-left font-normal",
              !dateRange && "text-muted-foreground"
            )}
          >
            <div className="flex flex-row justify-between items-center gap-2">
              <div>
                {dateRange?.from ? (
                  dateRange.to ? (
                    <>
                      {dayjs(dateRange.from).format("DD MMM YYYY")} -{" "}
                      {dayjs(dateRange.to).format("DD MMM YYYY")}
                    </>
                  ) : (
                    dayjs(dateRange.from).format("DD MMMM YYYY")
                  )
                ) : (
                  <span>Zeitraum wählen</span>
                )}
              </div>
              <div>
                <CalendarIcon className="mr-2 h-4 w-4" />
              </div>
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            //initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={(value) => {
              setDateRange(value);
            }}
            numberOfMonths={1}
            weekStartsOn={weekStartsOn}
          />
          <div className="flex flex-row justify-center p-2">
            <PopoverClose>
              <Button>Okay</Button>
            </PopoverClose>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
