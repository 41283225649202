import React, { useEffect } from "react";

import { useAnalyzeMission } from "./useAnalyzeMission";

type Props = {};

const MonthlyOverview = (props: Props) => {
  const { monthyOverview, calculateMonthlyOverview } = useAnalyzeMission();

  useEffect(() => {
    calculateMonthlyOverview();
  }, []);

  return (
    <div>
      <div>Overview</div>
      <div> Summe EUR: {monthyOverview?.toatalCashValue}</div>
      <div>Behandlungs EUR: {monthyOverview?.totalCashValueBehandlung}</div>
      <div>Grundpflege EUR: {monthyOverview?.totalCashValueComplexed}</div>
      <div>Zeitbasiert EUR: {monthyOverview?.totalCashValueTimed}</div>
      <div>Pauschelen EUR: {monthyOverview?.totalCashValuePauschale}</div>
    </div>
  );
};

export default MonthlyOverview;
