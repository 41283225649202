import React, { Children, useEffect } from "react";

import { useDisplayMissionAnalytic } from "./useDisplayMissionAnalytic";
import DisplayAction from "../Action/DisplayAction";
import { nanoid } from "nanoid";

export const DisplayActionString = (props: {
  title: string;
  actionString: string | undefined;
}) => {
  if (props.actionString === undefined) return <div>n/a</div>;

  const { title, actionString } = props;
  const _actionsWithQantitiy = actionString?.split("_");

  return (
    <div className="px-1 flex flex-row gap-2">
      {title}:{" "}
      {_actionsWithQantitiy?.map((actionWithQuantity, index) => {
        const [quantity, actionComplex] = actionWithQuantity.split("x");

        if (Number(quantity) === 1) {
          return <div>{actionComplex}</div>;
        } else {
          return (
            <div key={nanoid()}>
              {actionComplex}({quantity})
            </div>
          );
        }
      })}
    </div>
  );
};

type Props = {
  missionId: string;
  children?: React.ReactNode;
};

const DisplayMissionAnalytic = (props: Props) => {
  const { loadMissionAnalyticInfo, mission } = useDisplayMissionAnalytic();

  useEffect(() => {
    console.log("load hook");
    loadMissionAnalyticInfo(props.missionId);
  }, [props.missionId]);

  if (!mission) return <div>Loading {props.missionId}</div>;

  return (
    <div className="w-96 ">
      <div className="text-gray-400 text-xs">{mission.id}</div>
      <div className="bg-gray-50 flex flex-row items-center justify-between mx-2 px-2">
        <div>Ist: {mission.duration_min} </div>
        <div>Soll: {mission.missionAnalytic?.expectedDuration_NOSAFE} </div>

        <div>Betrag: {mission?.missionAnalytic?.totalCashValue_NOSAFE} EUR</div>
        <div>PerMin: {mission.missionAnalytic?.cashPerMinute_NOSAFE}</div>
      </div>
      <div className="bg-gray-100 mx-2 ">{props.children}</div>
      <div className="m-2 p2- bg-white">
        <span className=" flex flex-row gap-2 text-xs">
          <span className=" px-1">Pauschalen: </span>
          <span className=" px-1">
            {mission.missionAnalytic?.pauschaleHausType}{" "}
          </span>
          <span className="px-1">
            {mission.missionAnalytic?.pauschaleSaveType}
          </span>{" "}
          ({mission.missionAnalytic?.totalPauschaleCashValue})
        </span>

        <span className="text-xs flex flex-row">
          <DisplayActionString
            title="Alle"
            actionString={mission.missionAnalytic?.allActions}
          />
          ({mission.missionAnalytic?.totalCashValue})
        </span>
        <span className="text-xs flex flex-row">
          <DisplayActionString
            title="Komplexe"
            actionString={mission.missionAnalytic?.complexedGrundElements}
          />
          ({mission.missionAnalytic?.grundCashValue})
        </span>
        <span className="text-xs flex flex-row">
          <DisplayActionString
            title="Behandlung Max"
            actionString={mission.missionAnalytic?.behandlungMaxElements}
          />
          ({mission.missionAnalytic?.behandlungMaxCashValue})
        </span>
        <span className="text-xs flex flex-row">
          <DisplayActionString
            title="Behandlung Add"
            actionString={mission.missionAnalytic?.behandlungAdditionalElements}
          />
          ({mission.missionAnalytic?.behandlungAdditionalCashValue})
        </span>
        <span className="text-xs flex flex-row">
          <DisplayActionString
            title="Zeit"
            actionString={mission.missionAnalytic?.timedElements}
          />
          ({mission.missionAnalytic?.timedCashValue})
        </span>
      </div>
      <div className="text-xs flex flex-row flex-wrap">
        {mission.actionsWithQuantity?.map((action) => (
          <span key={nanoid()}>
            <DisplayAction action={action as any} />
          </span>
        ))}
      </div>
      <div className="text-gray-300">{mission.tourId}</div>
    </div>
  );
};

export default DisplayMissionAnalytic;
