import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../UiComponents/Button";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  // const {
  //   login,
  //   logout,
  //   register,
  //   user,
  //   isLoading,
  //   isAuthenticated,
  //   getToken,
  // } = useKindeAuth();

  // const showToken = async () => {
  //   const token = await getToken();
  //   console.log("token", token);
  // };

  // useEffect(() => {
  //   console.log("user", user);
  //   showToken();
  // }, [isAuthenticated]);

  // if (!loginWithRedirect) return null;
  // if (!login) return null;
  // if (!register) return null;

  return (
    <div>
      <Button
        className="btn btn-primary"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "login",
            },
          })
        }
      >
        Auth 0 Log In
      </Button>

      {/* <div className="flex flex-col gap-2">
        <div>{JSON.stringify(user, null, 2)}</div>
        <Button onClick={() => register()} type="button">
          Register
        </Button>
        <Button onClick={() => login()} type="button">
          Log In
        </Button>
        <Button onClick={() => logout()} type="button">
          Log Out
        </Button>
      </div> */}
    </div>
  );
};

export default LoginButton;
