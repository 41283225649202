import { CopyIcon, TrashIcon } from "@radix-ui/react-icons";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect } from "react";
import DisplayAction from "../Action/DisplayAction";
import { Button } from "../UiComponents/Button";
import {
  PopoverRadix,
  PopoverRadixContent,
  PopoverRadixTrigger,
} from "../UiComponents/PopoverRadix";

import { usePatientDenyGender } from "./usePatientDenyGender";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";

type Props = {
  patientId: string;
};

const PatientDenyGender = (props: Props) => {
  const { patientId } = props;
  const {
    allGenders,
    setPatientId,
    patientDenyGenders,
    distinctPatientDenyGenders,
    setActions,
  } = usePatientDenyGender();

  const { actionsClipboardContent } = useActionClipboard();

  useEffect(() => {
    setPatientId(patientId);
  }, [patientId]);

  return (
    <div className=" p-2">
      {allGenders?.map((gender) => {
        const _filterdPatientsDenyGenders = _.filter(
          patientDenyGenders,
          (pdg) => {
            return pdg.genderId === gender.genderId;
          }
        );

        //     console.log(patientDenyGenders);

        return (
          <PopoverRadix>
            <PopoverRadixTrigger>
              <span
                className={classNames(
                  "m2 p-2",
                  { "bg-green-200": _filterdPatientsDenyGenders.length === 0 },
                  { "bg-red-200": _filterdPatientsDenyGenders.length > 0 }
                )}
              >
                {gender.displayName}
              </span>
            </PopoverRadixTrigger>
            <PopoverRadixContent>
              <div className="bg-gray-50 m-2 p-2 ">
                <div className="">
                  {_filterdPatientsDenyGenders.map((pdg) => {
                    //          console.log(pdg);
                    return (
                      <div className="text-xs">
                        <DisplayAction
                          action={{
                            action: pdg.action,
                            quantity: 1,
                          }}
                        />
                      </div>
                    );
                  })}

                  <Button variant="neutral-ghost">
                    <TrashIcon />
                  </Button>
                  <Button
                    variant="neutral-ghost"
                    onClick={() => {
                      setActions(
                        actionsClipboardContent.map((o) => o.action.lnr),
                        gender.genderId
                      );
                    }}
                  >
                    <CopyIcon />
                  </Button>
                </div>
              </div>
            </PopoverRadixContent>
          </PopoverRadix>
        );
      })}
    </div>
  );
};

export default PatientDenyGender;
