import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import gql from 'graphql-tag';
import * as React from 'react';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
};

export type A_Einsatz = {
  __typename?: 'A_Einsatz';
  Bis?: Maybe<Scalars['String']['output']>;
  Dauer?: Maybe<Scalars['String']['output']>;
  ENR: Scalars['String']['output'];
  KDNR?: Maybe<Scalars['String']['output']>;
  Klasse?: Maybe<Scalars['String']['output']>;
  Schicht?: Maybe<Scalars['String']['output']>;
  Schluessel_notwendig?: Maybe<Scalars['Boolean']['output']>;
  Von?: Maybe<Scalars['String']['output']>;
  WTNR?: Maybe<Scalars['String']['output']>;
};

export type A_EinsatzInput = {
  Bis?: InputMaybe<Scalars['String']['input']>;
  Dauer?: InputMaybe<Scalars['String']['input']>;
  ENR: Scalars['String']['input'];
  KDNR?: InputMaybe<Scalars['String']['input']>;
  Klasse?: InputMaybe<Scalars['String']['input']>;
  Schicht?: InputMaybe<Scalars['String']['input']>;
  Schluessel_notwendig?: InputMaybe<Scalars['Boolean']['input']>;
  Von?: InputMaybe<Scalars['String']['input']>;
  WTNR?: InputMaybe<Scalars['String']['input']>;
};

export type A_SollLeistung = {
  __typename?: 'A_SollLeistung';
  ENR?: Maybe<Scalars['String']['output']>;
  LNR?: Maybe<Scalars['String']['output']>;
  SLNR: Scalars['String']['output'];
};

export type A_SollLeistungenInput = {
  ENR?: InputMaybe<Scalars['String']['input']>;
  LNR?: InputMaybe<Scalars['String']['input']>;
  SLNR: Scalars['String']['input'];
};

/** Leistungen */
export type Action = {
  __typename?: 'Action';
  examinationRequired: Scalars['Boolean']['output'];
  executable: Scalars['Boolean']['output'];
  leistungsart: Scalars['String']['output'];
  leistungsinhalte: Scalars['String']['output'];
  leistungskomplex: Scalars['String']['output'];
  lnr: Scalars['String']['output'];
  mobileDisplayGroup?: Maybe<Scalars['String']['output']>;
  requiredQualificationLevel: Scalars['String']['output'];
};

export type ActionCashInput = {
  actionsWq?: InputMaybe<Array<ActionWqCashInput>>;
};

/** Actions with CashValues */
export type ActionCashValue = {
  __typename?: 'ActionCashValue';
  cashflowValue: Scalars['Float']['output'];
  lnr: Scalars['String']['output'];
  rule: Scalars['String']['output'];
};

export type ActionCreateInput = {
  behandlungsPflegeGruppe: Scalars['String']['input'];
  cashValue: Scalars['Float']['input'];
  examinationRequired: Scalars['Boolean']['input'];
  executable: Scalars['Boolean']['input'];
  leistungsart: Scalars['String']['input'];
  leistungsinhalte: Scalars['String']['input'];
  leistungskomplex: Scalars['String']['input'];
  lnr: Scalars['String']['input'];
  mobileDisplayGroup?: InputMaybe<Scalars['String']['input']>;
};

export type ActionFilter = {
  executable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActionIdWithQuantity = {
  lnr: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

/** ActionTime */
export type ActionTime = {
  __typename?: 'ActionTime';
  actions?: Maybe<Array<Action>>;
  combinedLnrs?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  time_min: Scalars['Int']['output'];
  time_sec: Scalars['Int']['output'];
};

export type ActionUpdateInput = {
  examinationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  executable?: InputMaybe<Scalars['Boolean']['input']>;
  leistungsart?: InputMaybe<Scalars['String']['input']>;
  leistungsinhalte?: InputMaybe<Scalars['String']['input']>;
  leistungskomplex?: InputMaybe<Scalars['String']['input']>;
  lnr: Scalars['String']['input'];
  mobileDisplayGroup?: InputMaybe<Scalars['String']['input']>;
};

export type ActionWithCash = {
  __typename?: 'ActionWithCash';
  actionLnrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  calculatedDuration_min?: Maybe<Scalars['Float']['output']>;
  cashflowValue?: Maybe<Scalars['Float']['output']>;
  complexedLnrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  valuableLnrs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ActionWithQuantity = {
  __typename?: 'ActionWithQuantity';
  action: Action;
  actionLnr: Scalars['String']['output'];
  examinationRequired: Scalars['Boolean']['output'];
  executable: Scalars['Boolean']['output'];
  lastDateOfMission?: Maybe<Scalars['String']['output']>;
  leistungsart: Scalars['String']['output'];
  leistungskomplex: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ActionWithQuantitySchedule = {
  __typename?: 'ActionWithQuantitySchedule';
  examinationRequired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  leistungsart: Scalars['String']['output'];
  leistungsinhalte: Scalars['String']['output'];
  leistungskomplex: Scalars['String']['output'];
  lnr: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type ActionWqCashInput = {
  actionLnr: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type ActionWqLnr = {
  actionLnr: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type AddMissionToDayInput = {
  day: Scalars['String']['input'];
  mission: MissionInput;
};

export type AddMissionToPublicationInput = {
  mission: MissionInput;
  publicationId: Scalars['String']['input'];
};

export type AllMissionsByMemberPerWeekFilter = {
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  maxDate?: InputMaybe<Scalars['String']['input']>;
  memberIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minDate?: InputMaybe<Scalars['String']['input']>;
};

export type AssigendActionMissionExecutedUpsertBulkInput = {
  actions: Array<ActionIdWithQuantity>;
  missionId: Scalars['String']['input'];
};

export type AssignedActionMission = {
  __typename?: 'AssignedActionMission';
  action?: Maybe<Action>;
  actionLnr: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type AssignedActionMissionExecuted = {
  __typename?: 'AssignedActionMissionExecuted';
  action?: Maybe<Action>;
  actionLnr: Scalars['String']['output'];
  missionId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
};

export type AssignedActionSchedule = {
  __typename?: 'AssignedActionSchedule';
  action?: Maybe<Action>;
  actionLnr: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

/** CashFlowMission */
export type CashFlowMission = {
  __typename?: 'CashFlowMission';
  cashWithPauschale: Scalars['Float']['output'];
  cashWithoutPauschale: Scalars['Float']['output'];
  durationDeviation_min: Scalars['Int']['output'];
  durationToBeWithPauschale_min: Scalars['Int']['output'];
  missionId: Scalars['ID']['output'];
  plannedCashPerMinuteWithPauschale: Scalars['Float']['output'];
  plannedPauschale?: Maybe<Scalars['String']['output']>;
};

export type CashPerMinuteRangeFilter = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type ChangeLogSyncMsql = {
  __typename?: 'ChangeLogSyncMSQL';
  changeLogId: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdAt_ISO: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ChangeLogSyncMsqlCreateInput = {
  changeLogId: Scalars['String']['input'];
  updateData: Scalars['String']['input'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  coordinate?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type CreatePatientAbsenceInput = {
  endDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  startDay: Scalars['String']['input'];
};

export type CreatePatientScheduleExceptionInput = {
  allCodedActions?: InputMaybe<Scalars['String']['input']>;
  endDay: Scalars['String']['input'];
  endTime?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  startDay: Scalars['String']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTourPauseInput = {
  endDate: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  missionPublicationId: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type CriteriaPauschale = {
  __typename?: 'CriteriaPauschale';
  hasAtLeastOneGrundpflege: Scalars['Boolean']['output'];
  hasAtLeastOne_31_32_33: Scalars['Boolean']['output'];
  hasSolitaer_3_to_33: Scalars['Boolean']['output'];
  hasSolitaer_27_28_39_or_30: Scalars['Boolean']['output'];
  has_29_30: Scalars['Boolean']['output'];
  isFirstOfDay: Scalars['Boolean']['output'];
  isSecondOfDay: Scalars['Boolean']['output'];
};

export type CsvMission = {
  __typename?: 'CsvMission';
  day: Scalars['String']['output'];
  duration_5m: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
  travelToNext_5m: Scalars['String']['output'];
  weekDayId: Scalars['String']['output'];
};

/** DayMobile */
export type DayMobile = {
  __typename?: 'DayMobile';
  day: Scalars['String']['output'];
  tasks: Array<MobileTask>;
  workEnd: Scalars['String']['output'];
  workStart: Scalars['String']['output'];
};

export type District = {
  __typename?: 'District';
  colorHex: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

/** EffectiveActionScheduleDay */
export type EffectiveActionScheduleDay = {
  __typename?: 'EffectiveActionScheduleDay';
  actionLnrsCodedEffectiv: Scalars['String']['output'];
  actionLnrsCodedSchedule: Scalars['String']['output'];
  day: Scalars['String']['output'];
  effectiveActionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  exceptionId?: Maybe<Scalars['String']['output']>;
  hasException: Scalars['Boolean']['output'];
  maxStartTime: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  timeGroupId: Scalars['String']['output'];
};

/** Aus den Plaungen und Abwesenheiten ermittelte Einsätze */
export type EffectiveSchedule = {
  __typename?: 'EffectiveSchedule';
  allCodedActions: Scalars['String']['output'];
  dayId: Scalars['String']['output'];
  maybeCodedActions: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  preferedMaxStartTime: Scalars['String']['output'];
  preferedMinStartTime: Scalars['String']['output'];
  scheduleIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type ExcelMission = {
  __typename?: 'ExcelMission';
  createdAt?: Maybe<Scalars['String']['output']>;
  dauer?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  dayId?: Maybe<Scalars['String']['output']>;
  einsatzDatum?: Maybe<Scalars['String']['output']>;
  einsatzUhrzeit?: Maybe<Scalars['String']['output']>;
  endZeitpunkt?: Maybe<Scalars['String']['output']>;
  excelENR?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  mitarbeiter?: Maybe<Scalars['String']['output']>;
  mitarbeiterId?: Maybe<Scalars['String']['output']>;
  mitarbeiterName?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  rowIndex?: Maybe<Scalars['String']['output']>;
  startZeitpunkt?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ExcelMissionBulkInput = {
  dauer: Scalars['Int']['input'];
  day: Scalars['String']['input'];
  dayId: Scalars['String']['input'];
  einsatzDatum: Scalars['String']['input'];
  einsatzUhrzeit: Scalars['String']['input'];
  einsatzZeitpunkt: Scalars['String']['input'];
  endZeitpunkt: Scalars['String']['input'];
  excelENR: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mitarbeiter: Scalars['String']['input'];
  mitarbeiterId: Scalars['String']['input'];
  mitarbeiterName: Scalars['String']['input'];
  patient: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  patientName: Scalars['String']['input'];
  rowIndex: Scalars['Int']['input'];
  sheetName: Scalars['String']['input'];
  startZeitpunkt: Scalars['String']['input'];
  time: Scalars['String']['input'];
  uniqueId: Scalars['String']['input'];
};

export type ExcelMissionPublication = {
  __typename?: 'ExcelMissionPublication';
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** MobileExecution */
export type ExecutionMobile = {
  __typename?: 'ExecutionMobile';
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  codedActions: Scalars['String']['output'];
  day: Scalars['String']['output'];
  duration_min: Scalars['Int']['output'];
  endMinuteOfDay: Scalars['Int']['output'];
  endTS_iso: Scalars['String']['output'];
  id: Scalars['String']['output'];
  infoMessage?: Maybe<ExecutionMobileInfoMessage>;
  isInvalid: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  result: Scalars['String']['output'];
  source: Scalars['String']['output'];
  sourceDisplay: Scalars['String']['output'];
  startMinuteOfDay: Scalars['Int']['output'];
  startTS_iso: Scalars['String']['output'];
  waitTimeToOpen: Scalars['Int']['output'];
};

/** MobileExecutionAction */
export type ExecutionMobileAction = {
  __typename?: 'ExecutionMobileAction';
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  codedActions: Scalars['String']['output'];
  executionMobileId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
};

export type ExecutionMobileInfoMessage = {
  __typename?: 'ExecutionMobileInfoMessage';
  actionRequired: Scalars['Boolean']['output'];
  executionMobileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type ExecutionMobileTourStat = {
  __typename?: 'ExecutionMobileTourStat';
  countFailedExecutions: Scalars['Int']['output'];
  countMissions: Scalars['Int']['output'];
  countMissionsNoExecutions: Scalars['Int']['output'];
  countSuccessExecutions: Scalars['Int']['output'];
  day: Scalars['String']['output'];
  ratioDurationPlan_Execution: Scalars['Float']['output'];
  totalDeviantionPlan_execution_min: Scalars['Int']['output'];
  totalExecutionDurationFailed: Scalars['Int']['output'];
  totalExecutionDurationSuccess: Scalars['Int']['output'];
  totalMissionDurationPlan: Scalars['Int']['output'];
  totalTourDurationExecution_min: Scalars['Int']['output'];
  totalTourDurationPlan_min: Scalars['Int']['output'];
  tourId: Scalars['String']['output'];
};

/** ExecutionFaktura */
export type Faktura = {
  __typename?: 'Faktura';
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  behandlungAdditionalActionCoded: Scalars['String']['output'];
  behandlungMaxActionCoded: Scalars['String']['output'];
  day: Scalars['String']['output'];
  duration: Scalars['String']['output'];
  endTS_iso: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  executedActionCoded: Scalars['String']['output'];
  generatedFrom: FakturaSource;
  grundActionCoded: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  mission?: Maybe<Mission>;
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  pauschalenActionCoded: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  referenceType?: Maybe<Scalars['String']['output']>;
  remark?: Maybe<Scalars['String']['output']>;
  startTS_iso: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  status: FakturaStatus;
  timedActionCoded: Scalars['String']['output'];
  weekday: Scalars['String']['output'];
};

export enum FakturaSource {
  Execution = 'EXECUTION',
  Manual = 'MANUAL',
  Mission = 'MISSION',
  Unknown = 'UNKNOWN'
}

export enum FakturaStatus {
  Created = 'CREATED',
  Finalized = 'FINALIZED',
  Touched = 'TOUCHED'
}

export type FileInBucket = {
  __typename?: 'FileInBucket';
  buildIsoDate: Scalars['String']['output'];
  dir: Scalars['String']['output'];
  etag: Scalars['String']['output'];
  ext: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  lastModified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  root: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type FilterTourPauses = {
  tourIds: Array<Scalars['String']['input']>;
};

export type FilterTours = {
  day: Scalars['String']['input'];
};

export type FromExcelMission = {
  __typename?: 'FromExcelMission';
  actionLnrsCoded: Scalars['String']['output'];
  day: Scalars['String']['output'];
  duration_min: Scalars['Int']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
};

/** Gender */
export type Gender = {
  __typename?: 'Gender';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  genderId: Scalars['String']['output'];
};

export type GeoPatient = {
  __typename?: 'GeoPatient';
  features?: Maybe<GeoPatientsData>;
  geometry?: Maybe<PointGeometry>;
  properties: GeoPatientProperties;
  type: Scalars['String']['output'];
};

export type GeoPatientFeatureCollection = {
  __typename?: 'GeoPatientFeatureCollection';
  features?: Maybe<Array<Maybe<GeoPatient>>>;
  type: Scalars['String']['output'];
};

export type GeoPatientProperties = {
  __typename?: 'GeoPatientProperties';
  colorHex?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  patientId: Scalars['String']['output'];
};

export type GeoPatientsData = {
  __typename?: 'GeoPatientsData';
  geometry?: Maybe<Point>;
  type: Scalars['String']['output'];
};

export type GeoTour = {
  __typename?: 'GeoTour';
  geometry?: Maybe<PointGeometry>;
  properties: GeoTourProperties;
  type: Scalars['String']['output'];
};

export type GeoTourArcLayer = {
  __typename?: 'GeoTourArcLayer';
  elements?: Maybe<Array<Maybe<GeoTourArcLayerElement>>>;
  memberId: Scalars['String']['output'];
};

export type GeoTourArcLayerData = {
  __typename?: 'GeoTourArcLayerData';
  data?: Maybe<Array<Maybe<GeoTourArcLayerElement>>>;
};

export type GeoTourArcLayerElement = {
  __typename?: 'GeoTourArcLayerElement';
  duration_from: Scalars['Float']['output'];
  from?: Maybe<GeoTourArcLayerElementProp>;
  inbound: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  outbound: Scalars['String']['output'];
  to?: Maybe<GeoTourArcLayerElementProp>;
};

export type GeoTourArcLayerElementProp = {
  __typename?: 'GeoTourArcLayerElementProp';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  name: Scalars['String']['output'];
  startTS: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type GeoTourFeatureCollection = {
  __typename?: 'GeoTourFeatureCollection';
  features?: Maybe<Array<Maybe<GeoTour>>>;
  type: Scalars['String']['output'];
};

export type GeoTourLineString = {
  __typename?: 'GeoTourLineString';
  geometry?: Maybe<LineGeometry>;
  properties?: Maybe<GeoTourProperties>;
  type: Scalars['String']['output'];
};

export type GeoTourProperties = {
  __typename?: 'GeoTourProperties';
  index?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

export type GlobalTimeRangeFilter = {
  cashPerMinuteRangeFilter?: InputMaybe<CashPerMinuteRangeFilter>;
  hasAction?: InputMaybe<Scalars['Boolean']['input']>;
  hasMobileExecution?: InputMaybe<Scalars['Boolean']['input']>;
  includeEarly?: Scalars['Boolean']['input'];
  includeLate?: Scalars['Boolean']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxDate?: InputMaybe<Scalars['String']['input']>;
  memberIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minDate?: InputMaybe<Scalars['String']['input']>;
  patientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  publicationId?: InputMaybe<Scalars['String']['input']>;
  specificDays?: InputMaybe<Array<Scalars['String']['input']>>;
  tourIds?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedLater?: InputMaybe<Scalars['String']['input']>;
};

export type InputCreateExecutionMobile = {
  codedActions: Scalars['String']['input'];
  endTS: Scalars['String']['input'];
  id: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
  referenceType: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  result: Scalars['String']['input'];
  startTS: Scalars['String']['input'];
};

export type InputCreateExecutionMobileAction = {
  codedActions: Scalars['String']['input'];
  executionMobileId: Scalars['String']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type InputCreateExecutionMobileInfoMessage = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  executionMobileId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type InputCreatePatientActionDuration = {
  actionQuantityCoded: Scalars['String']['input'];
  duration_min: Scalars['Int']['input'];
  endDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  startDay: Scalars['String']['input'];
};

/** InputCreateScheduleDay */
export type InputCreateScheduleDay = {
  acceptedMaxStartTime: Scalars['String']['input'];
  acceptedMinStartTime: Scalars['String']['input'];
  allCodedActions: Scalars['String']['input'];
  allDayIds: Array<Scalars['String']['input']>;
  count: Scalars['Int']['input'];
  patientId: Scalars['String']['input'];
  preferedDayIds: Array<Scalars['String']['input']>;
  preferedMaxStartTime: Scalars['String']['input'];
  preferedMinStartTime: Scalars['String']['input'];
  shiftId: Scalars['String']['input'];
  weekTurnus: Scalars['Int']['input'];
};

/** InputCreateScheduleEvery */
export type InputCreateScheduleEvery = {
  acceptedMaxStartTime: Scalars['String']['input'];
  acceptedMinStartTime: Scalars['String']['input'];
  allCodedActions: Scalars['String']['input'];
  every: Scalars['Int']['input'];
  everyType: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  preferedMaxStartTime: Scalars['String']['input'];
  preferedMinStartTime: Scalars['String']['input'];
  shiftId: Scalars['String']['input'];
};

export type InputEffectiveSchedule = {
  dayIds: Array<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};

export type InputSplitMissions = {
  missionIds: Array<Scalars['String']['input']>;
};

export type InputUpdateExecutionMobileAction = {
  codedActions: Scalars['String']['input'];
};

/** InputUpdateScheduleDay */
export type InputUpdateScheduleDay = {
  acceptedMaxStartTime?: InputMaybe<Scalars['String']['input']>;
  acceptedMinStartTime?: InputMaybe<Scalars['String']['input']>;
  allCodedActions?: InputMaybe<Scalars['String']['input']>;
  allDayIds?: InputMaybe<Array<Scalars['String']['input']>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  preferedDayIds?: InputMaybe<Array<Scalars['String']['input']>>;
  preferedMaxStartTime?: InputMaybe<Scalars['String']['input']>;
  preferedMinStartTime?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
  weekTurnus?: InputMaybe<Scalars['Int']['input']>;
};

/** InputUpdateScheduleEvery */
export type InputUpdateScheduleEvery = {
  acceptedMaxStartTime?: InputMaybe<Scalars['String']['input']>;
  acceptedMinStartTime?: InputMaybe<Scalars['String']['input']>;
  allCodedActions?: InputMaybe<Scalars['String']['input']>;
  every?: InputMaybe<Scalars['Int']['input']>;
  everyType?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  preferedMaxStartTime?: InputMaybe<Scalars['String']['input']>;
  preferedMinStartTime?: InputMaybe<Scalars['String']['input']>;
  shiftId?: InputMaybe<Scalars['String']['input']>;
};

export type LineGeometry = {
  __typename?: 'LineGeometry';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  type: Scalars['String']['output'];
};

export type MedifoxStatusAbsence = {
  __typename?: 'MedifoxStatusAbsence';
  day: Scalars['String']['output'];
  isAbsence: Scalars['Boolean']['output'];
  isInBetreuung: Scalars['Boolean']['output'];
  patientId: Scalars['String']['output'];
};

/** Member */
export type Member = {
  __typename?: 'Member';
  firstName: Scalars['String']['output'];
  hasExamination: Scalars['Boolean']['output'];
  hasQualificationLevel: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  qualification?: Maybe<MemberQualification>;
  qualifications: Array<MemberQualification>;
  shortName: Scalars['String']['output'];
  signId: Scalars['String']['output'];
};

export type MemberCreateInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  qualification: Scalars['String']['input'];
  signId: Scalars['String']['input'];
};

/** MemberQualification */
export type MemberQualification = {
  __typename?: 'MemberQualification';
  allowedAdditionalActions: Array<Action>;
  id: Scalars['ID']['output'];
  memberId: Scalars['String']['output'];
  memberQualificationType?: Maybe<MemberQualificationType>;
  qualificationTypeId: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  validFromDay: Scalars['String']['output'];
  validToDay: Scalars['String']['output'];
};

/** MemberQualificationType */
export type MemberQualificationType = {
  __typename?: 'MemberQualificationType';
  displayName: Scalars['String']['output'];
  qualificationLevel: Scalars['String']['output'];
  qualificationTypeId: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
};

export type MemberUpdateInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  qualification?: InputMaybe<Scalars['String']['input']>;
  signId?: InputMaybe<Scalars['String']['input']>;
};

export type MembersFilter = {
  memberIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Geplanete Einsätze */
export type Mission = {
  __typename?: 'Mission';
  actionsWithQuantity?: Maybe<Array<Maybe<AssignedActionMission>>>;
  cash?: Maybe<ActionWithCash>;
  changes?: Maybe<Array<OperationalChanges>>;
  codedActions: Scalars['String']['output'];
  connectedMission?: Maybe<Mission>;
  day: Scalars['String']['output'];
  duration_min: Scalars['Int']['output'];
  editAllowed: Scalars['Boolean']['output'];
  endMinuteOfDay: Scalars['Float']['output'];
  endTS: Scalars['String']['output'];
  excelENR?: Maybe<Scalars['String']['output']>;
  executionMobiles: Array<ExecutionMobile>;
  hasAction?: Maybe<Scalars['Boolean']['output']>;
  hasConflictAbsence: Scalars['Boolean']['output'];
  hasGrundPflege: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isChecked: Scalars['Boolean']['output'];
  maxDuration_min: Scalars['Int']['output'];
  member?: Maybe<Member>;
  memberHasQualificationLevel: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  memberQuallificationCheck: Scalars['Boolean']['output'];
  minReqQualification: Scalars['Int']['output'];
  missionAnalytic?: Maybe<MissionAnalytic>;
  missionPublicationId: Scalars['String']['output'];
  missionStatus?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  startMinuteOfDay: Scalars['Float']['output'];
  startTS: Scalars['String']['output'];
  start_ISO?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  tourId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

/** Finanzkennzahlen der Missionen */
export type MissionAnalytic = {
  __typename?: 'MissionAnalytic';
  allActions: Scalars['String']['output'];
  allActions_c: Scalars['String']['output'];
  behandlungAdditionalCashValue: Scalars['Float']['output'];
  behandlungAdditionalElements: Scalars['String']['output'];
  behandlungAdditionalElements_c: Scalars['String']['output'];
  behandlungMaxCashValue: Scalars['Float']['output'];
  behandlungMaxElements: Scalars['String']['output'];
  behandlungMaxElements_c: Scalars['String']['output'];
  cashPerMinute: Scalars['Float']['output'];
  cashPerMinute_NOSAFE: Scalars['Float']['output'];
  complexedGrundElements: Scalars['String']['output'];
  complexedGrundElements_c: Scalars['String']['output'];
  durationCorrection_min: Scalars['Int']['output'];
  durationDeviation_min: Scalars['Int']['output'];
  duration_min: Scalars['Int']['output'];
  expectedDuration: Scalars['Int']['output'];
  expectedDurationCalc_NOSAFE: Scalars['Int']['output'];
  expectedDuration_NOSAFE: Scalars['Int']['output'];
  grundCashValue: Scalars['Float']['output'];
  maxBehandlungsGruppe: Scalars['Float']['output'];
  missionId: Scalars['ID']['output'];
  pauschaleHausCashValue: Scalars['Float']['output'];
  pauschaleHausType: Scalars['String']['output'];
  pauschaleSaveCashValue: Scalars['Float']['output'];
  pauschaleSaveType: Scalars['String']['output'];
  timedCashValue: Scalars['Float']['output'];
  timedElements: Scalars['String']['output'];
  timedElements_c: Scalars['String']['output'];
  totalCashValue: Scalars['Float']['output'];
  totalCashValue_NOSAFE: Scalars['Float']['output'];
  totalPauschaleCashValue: Scalars['Float']['output'];
  totalPauschaleElements: Scalars['String']['output'];
};

export type MissionCreateBulkInput = {
  countMissionsToBe: Scalars['Int']['input'];
  day: Scalars['String']['input'];
  missions: Array<MissionInput>;
};

export type MissionFilter = {
  date?: InputMaybe<Scalars['String']['input']>;
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  onlyNoActions?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  patientsIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publicationId?: InputMaybe<Scalars['String']['input']>;
  updatedLater?: InputMaybe<Scalars['String']['input']>;
};

export type MissionIdNoAction = {
  __typename?: 'MissionIdNoAction';
  mission?: Maybe<Mission>;
};

export type MissionInput = {
  actionsWithQantity: Array<ActionIdWithQuantity>;
  endTS: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  startTS: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type MissionLowPerforming = {
  __typename?: 'MissionLowPerforming';
  mission?: Maybe<Mission>;
  missionId: Scalars['String']['output'];
};

export type MissionOptimize = {
  __typename?: 'MissionOptimize';
  calculatedDuration_sec: Scalars['Float']['output'];
  day: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  mission?: Maybe<Mission>;
  missionId: Scalars['String']['output'];
  missionPublicationId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  plannedDuration_sec: Scalars['Float']['output'];
  tourId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MissionOptimizeResult = {
  __typename?: 'MissionOptimizeResult';
  calculatedTravelDistance_m: Scalars['Float']['output'];
  calculatedTravelTime_sec: Scalars['Float']['output'];
  deviationTravelTime_sec: Scalars['Float']['output'];
  from?: Maybe<MissionOptimize>;
  fromPatient?: Maybe<Patient>;
  plannedTravelTime_sec: Scalars['Float']['output'];
  to?: Maybe<MissionOptimize>;
  toPatient?: Maybe<Patient>;
  travelSpeed_kmh: Scalars['Float']['output'];
};

/** MissionPublication */
export type MissionPublication = {
  __typename?: 'MissionPublication';
  countExecutions?: Maybe<Scalars['Int']['output']>;
  countMissionsTotal?: Maybe<Scalars['Int']['output']>;
  countMissionsWithActions?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdAt_ISO?: Maybe<Scalars['String']['output']>;
  day?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  missions?: Maybe<Array<Maybe<Mission>>>;
  nextStatus_s: Array<MissionPublicationStatus>;
  releaseStatus: Scalars['String']['output'];
  releaseStatusName: Scalars['String']['output'];
};

export type MissionPublicationFilter = {
  date?: InputMaybe<Scalars['String']['input']>;
  maxDate?: InputMaybe<Scalars['String']['input']>;
  minDate?: InputMaybe<Scalars['String']['input']>;
};

export type MissionPublicationStatus = {
  __typename?: 'MissionPublicationStatus';
  description: Scalars['String']['output'];
  releaseStatus: Scalars['String']['output'];
  releaseStatusName: Scalars['String']['output'];
};

export type MissionReturnBulkUpdate = {
  __typename?: 'MissionReturnBulkUpdate';
  endTS?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  startTS?: Maybe<Scalars['String']['output']>;
  tourId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type MissionUpdateBulkInput = {
  endTS?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  startTS?: InputMaybe<Scalars['String']['input']>;
  tourId?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
};

export type MissionUpdateBulkInput1 = {
  endTS: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  startTS: Scalars['String']['input'];
  tourId?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
};

export type MissionWithActionExecuted = {
  __typename?: 'MissionWithActionExecuted';
  actionsWithQuantityExecuted?: Maybe<Array<Maybe<AssignedActionMissionExecuted>>>;
  fromServer: Scalars['Boolean']['output'];
  missionId: Scalars['String']['output'];
};

export type MissionWithPauschale = {
  __typename?: 'MissionWithPauschale';
  actionLnrs?: Maybe<Array<Scalars['String']['output']>>;
  criteriaPauschale?: Maybe<CriteriaPauschale>;
  day: Scalars['String']['output'];
  mission?: Maybe<Mission>;
  missionId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  pauschale: Scalars['String']['output'];
  publicationId: Scalars['String']['output'];
  reasonPauschale: Scalars['String']['output'];
  time: Scalars['String']['output'];
  valuePauschale: Scalars['Float']['output'];
};

export type MissionsByIdsFilter = {
  missionIds: Array<Scalars['String']['input']>;
  updatedLater: Scalars['String']['input'];
};

export type MissionsWithPauschaleByIdsInput = {
  missionsIds: Array<Scalars['String']['input']>;
};

export type MissionsWithPauschaleInput = {
  day: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};

export type MissonChange = {
  __typename?: 'MissonChange';
  actionChanged?: Maybe<Scalars['Boolean']['output']>;
  added?: Maybe<Scalars['Boolean']['output']>;
  canceled?: Maybe<Scalars['Boolean']['output']>;
  mission?: Maybe<Mission>;
  missionId: Scalars['String']['output'];
  newTime?: Maybe<Scalars['String']['output']>;
  oldTime?: Maybe<Scalars['String']['output']>;
  patientId: Scalars['String']['output'];
  timeChanged?: Maybe<Scalars['Boolean']['output']>;
};

/** MobileTask */
export type MobileTask = {
  __typename?: 'MobileTask';
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  actions_coded: Scalars['String']['output'];
  day: Scalars['String']['output'];
  duration_min: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  patient: Patient;
  patientId: Scalars['String']['output'];
  time: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MostRecentMissionPublicationFilter = {
  maxDate?: InputMaybe<Scalars['String']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  minDate?: InputMaybe<Scalars['String']['input']>;
};

export type MostRecentSyncInfo = {
  __typename?: 'MostRecentSyncInfo';
  einsatzFileName: Scalars['String']['output'];
  leistungFileName: Scalars['String']['output'];
  medifoxBetreuungszeitFileName: Scalars['String']['output'];
  medifoxPatientFileName: Scalars['String']['output'];
  medifoxUnterbrechungszeitFileName: Scalars['String']['output'];
  memberFileName: Scalars['String']['output'];
  patientFileName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addMissionToDay?: Maybe<Mission>;
  addMissionToPublicationId?: Maybe<Mission>;
  closePatientInfoMessage: PatientInfoMessage;
  createActionTime: ActionTime;
  createExecutionMobileAction?: Maybe<ExecutionMobileAction>;
  createExecutionMobileInfoMessage?: Maybe<ExecutionMobileInfoMessage>;
  createManyExcelMissions?: Maybe<Array<Maybe<ExcelMission>>>;
  createManyExecutionOfMission?: Maybe<Array<Maybe<Mission>>>;
  createManyMissions?: Maybe<Array<Maybe<Mission>>>;
  createManySchedules?: Maybe<Scalars['Int']['output']>;
  createOneA_Einsatz?: Maybe<A_Einsatz>;
  createOneA_SollLeistung?: Maybe<A_SollLeistung>;
  createOneAction?: Maybe<Action>;
  createOneChangeLog?: Maybe<ChangeLogSyncMsql>;
  createOneMember?: Maybe<Member>;
  createOnePatient?: Maybe<Patient>;
  createOneSchedule: Schedule;
  createOneWeekday: Weekday;
  createOrUpdateExecutionMobile: ExecutionMobile;
  createOrUpdateTourReviewStatus: TourReviewStatus;
  createPatientAbsence: PatientAbsence;
  createPatientActionDuration: PatientActionDuration;
  createPatientDenyGender: PatientDenyGender;
  createPatientInfoMessage: PatientInfoMessage;
  createPatientInfoMessageResponse: PatientInfoMessageResponse;
  createPatientScheduleException: PatientScheduleException;
  createPatientUrl: PatientUrl;
  createScheduleDay?: Maybe<ScheduleDay>;
  createScheduleDayFromLegacy?: Maybe<Scalars['Boolean']['output']>;
  createScheduleEvery: ScheduleEvery;
  createSupabaseMember?: Maybe<SupabaseMember>;
  createTourInfoMessage: TourInfoMessage;
  deleteActionTime: ActionTime;
  deleteAllSchedules?: Maybe<Scalars['Int']['output']>;
  deleteExecutionMobile: ExecutionMobile;
  deleteManyExecutionOfMission?: Maybe<Array<Maybe<Mission>>>;
  deleteMemberQualification: Scalars['Boolean']['output'];
  deleteOneA_Einsatz?: Maybe<Scalars['Boolean']['output']>;
  deleteOneA_SollLeistung?: Maybe<Scalars['Boolean']['output']>;
  deleteOneSchedule?: Maybe<Schedule>;
  deleteOneTourPause: TourPause;
  deletePatientAbsence: PatientAbsence;
  deletePatientActionDuration: PatientActionDuration;
  deletePatientScheduleException: PatientScheduleException;
  deletePatientUrl?: Maybe<PatientUrl>;
  deleteScheduleDay?: Maybe<ScheduleDay>;
  deleteScheduleEvery?: Maybe<ScheduleEvery>;
  export2Access: Scalars['String']['output'];
  generateActionTimes: Scalars['Int']['output'];
  generateAssignTime?: Maybe<Scalars['Int']['output']>;
  generateCashflowTable?: Maybe<Scalars['Int']['output']>;
  generateCashflowTablesByTimeRange?: Maybe<Scalars['Int']['output']>;
  generateCashflowTablesByTimeRange_NEU?: Maybe<Scalars['Int']['output']>;
  inactivateMissionPublication?: Maybe<MissionPublication>;
  migrateDates: Scalars['Boolean']['output'];
  missionSetAction?: Maybe<Mission>;
  missionSetIsActive?: Maybe<Scalars['Boolean']['output']>;
  moveFileToProcessed: Scalars['Boolean']['output'];
  patientSetDistrict: Patient;
  purgeMissionPublication?: Maybe<Scalars['Boolean']['output']>;
  removeAllRetention: Scalars['Boolean']['output'];
  schedules2ExcelMission?: Maybe<Scalars['Int']['output']>;
  setActivePatients?: Maybe<Scalars['Int']['output']>;
  setCheckStatusMissions: Array<Mission>;
  setMemberQualification?: Maybe<Member>;
  setMemberQualificationAllowedActions: Scalars['Boolean']['output'];
  setMemberQualificationValidToDay: Scalars['Boolean']['output'];
  setMissionPublicationCancel?: Maybe<MissionPublication>;
  setMissionPublicationDraft?: Maybe<MissionPublication>;
  setMissionPublicationRework?: Maybe<MissionPublication>;
  setMissionPublicationStatus?: Maybe<MissionPublication>;
  setMostRecentMissionPublicationDraft?: Maybe<MissionPublication>;
  setPatientDenyGenderActions: Array<PatientDenyGender>;
  setRetention: Scalars['Boolean']['output'];
  setValidationOneExecutionMobile: ExecutionMobile;
  smartUpdateCashFlowMisson: Scalars['Int']['output'];
  smartUpdateMissionAnalytic: Scalars['Int']['output'];
  splitMission: Scalars['Boolean']['output'];
  splitMissions?: Maybe<Array<Mission>>;
  syncManyTourPauses?: Maybe<TourPause>;
  updateAbsenceWithCsv: Scalars['String']['output'];
  updateActionTime: ActionTime;
  updateExecutionMobileAction?: Maybe<ExecutionMobileAction>;
  updateManyMissions?: Maybe<Array<Maybe<Mission>>>;
  updateManyMissionsEnhanced?: Maybe<ResultMissionUpdateMany>;
  updateMedifoxPatientsWithCsv: Scalars['String']['output'];
  updateMembersWithCsv: Scalars['Boolean']['output'];
  updateMissionSupabase: Scalars['String']['output'];
  updateOneA_Einsatz?: Maybe<A_Einsatz>;
  updateOneA_SollLeistung?: Maybe<A_SollLeistung>;
  updateOneAction: Action;
  updateOneMember?: Maybe<Member>;
  updateOneMission?: Maybe<Mission>;
  updateOnePatient?: Maybe<Patient>;
  updateOneSchedule?: Maybe<Schedule>;
  updatePatientAbsence: PatientAbsence;
  updatePatientActionDuration: PatientActionDuration;
  updatePatientGeo?: Maybe<Patient>;
  updatePatientInfoMessage: PatientInfoMessage;
  updatePatientScheduleException: PatientScheduleException;
  updatePatientUrl: PatientUrl;
  updatePatientsWithCsv: Scalars['Boolean']['output'];
  updateScheduleDay?: Maybe<ScheduleDay>;
  updateScheduleEvery: ScheduleEvery;
  uploadFile: Scalars['Boolean']['output'];
  upsertBulkAssigendActionMissionExecuted?: Maybe<Array<Maybe<AssignedActionMissionExecuted>>>;
  upsertFaktura: Scalars['Boolean']['output'];
  upsertFakturaRange: Scalars['Boolean']['output'];
  upsertOneTourPause?: Maybe<TourPause>;
  upsertSupabaseExecutionMobile: Array<ExecutionMobile>;
  writeAccessFiles: Scalars['String']['output'];
};


export type MutationAddMissionToDayArgs = {
  input: AddMissionToDayInput;
};


export type MutationAddMissionToPublicationIdArgs = {
  input: AddMissionToPublicationInput;
};


export type MutationClosePatientInfoMessageArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateActionTimeArgs = {
  combinedLnrs: Array<Scalars['String']['input']>;
  time_sec: Scalars['Int']['input'];
};


export type MutationCreateExecutionMobileActionArgs = {
  data: InputCreateExecutionMobileAction;
};


export type MutationCreateExecutionMobileInfoMessageArgs = {
  input: InputCreateExecutionMobileInfoMessage;
};


export type MutationCreateManyExcelMissionsArgs = {
  fileChangeTime: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  input: Array<ExcelMissionBulkInput>;
};


export type MutationCreateManyExecutionOfMissionArgs = {
  input: InputBulkOperationExecutionOfMission;
};


export type MutationCreateManyMissionsArgs = {
  input: MissionCreateBulkInput;
};


export type MutationCreateManySchedulesArgs = {
  input: ScheduleCreateBulkInput;
};


export type MutationCreateOneA_EinsatzArgs = {
  input: A_EinsatzInput;
};


export type MutationCreateOneA_SollLeistungArgs = {
  input: A_SollLeistungenInput;
};


export type MutationCreateOneActionArgs = {
  data: ActionCreateInput;
};


export type MutationCreateOneChangeLogArgs = {
  input: ChangeLogSyncMsqlCreateInput;
};


export type MutationCreateOneMemberArgs = {
  input: MemberCreateInput;
};


export type MutationCreateOnePatientArgs = {
  input: PatientCreateInput;
};


export type MutationCreateOneScheduleArgs = {
  input: ScheduleCreateInput;
};


export type MutationCreateOneWeekdayArgs = {
  data: WeekdayCreateInput;
};


export type MutationCreateOrUpdateExecutionMobileArgs = {
  data: InputCreateExecutionMobile;
};


export type MutationCreateOrUpdateTourReviewStatusArgs = {
  day: Scalars['String']['input'];
  status: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type MutationCreatePatientAbsenceArgs = {
  input: CreatePatientAbsenceInput;
};


export type MutationCreatePatientActionDurationArgs = {
  input: InputCreatePatientActionDuration;
};


export type MutationCreatePatientDenyGenderArgs = {
  actionLnr: Scalars['String']['input'];
  genderId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationCreatePatientInfoMessageArgs = {
  input: PatientInfoMessageInput;
};


export type MutationCreatePatientInfoMessageResponseArgs = {
  input: PatientInfoMessageResponseInput;
};


export type MutationCreatePatientScheduleExceptionArgs = {
  input: CreatePatientScheduleExceptionInput;
};


export type MutationCreatePatientUrlArgs = {
  patientId: Scalars['String']['input'];
  urlId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateScheduleDayArgs = {
  input: InputCreateScheduleDay;
};


export type MutationCreateScheduleDayFromLegacyArgs = {
  patientId: Scalars['String']['input'];
};


export type MutationCreateScheduleEveryArgs = {
  input: InputCreateScheduleEvery;
};


export type MutationCreateSupabaseMemberArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


export type MutationCreateTourInfoMessageArgs = {
  day: Scalars['String']['input'];
  message: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type MutationDeleteActionTimeArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteExecutionMobileArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteManyExecutionOfMissionArgs = {
  input: InputBulkOperationExecutionOfMission;
};


export type MutationDeleteMemberQualificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneA_EinsatzArgs = {
  input: A_EinsatzInput;
};


export type MutationDeleteOneA_SollLeistungArgs = {
  input: A_SollLeistungenInput;
};


export type MutationDeleteOneScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneTourPauseArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientAbsenceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientActionDurationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientScheduleExceptionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePatientUrlArgs = {
  patientId: Scalars['String']['input'];
};


export type MutationDeleteScheduleDayArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteScheduleEveryArgs = {
  id: Scalars['String']['input'];
};


export type MutationExport2AccessArgs = {
  endDay: Scalars['String']['input'];
  startDay: Scalars['String']['input'];
};


export type MutationGenerateCashflowTableArgs = {
  missionPublicationId: Scalars['String']['input'];
};


export type MutationGenerateCashflowTablesByTimeRangeArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type MutationGenerateCashflowTablesByTimeRange_NeuArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};


export type MutationInactivateMissionPublicationArgs = {
  id: Scalars['String']['input'];
};


export type MutationMissionSetActionArgs = {
  actionIdsWQ: Array<ActionIdWithQuantity>;
  id: Scalars['String']['input'];
  updatedAt: Scalars['String']['input'];
};


export type MutationMissionSetIsActiveArgs = {
  id: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  updatedAt: Scalars['String']['input'];
};


export type MutationMoveFileToProcessedArgs = {
  fullname: Scalars['String']['input'];
};


export type MutationPatientSetDistrictArgs = {
  districtId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationPurgeMissionPublicationArgs = {
  missionPublicationId: Scalars['String']['input'];
};


export type MutationSetActivePatientsArgs = {
  input: ScheduleActivePatientInput;
};


export type MutationSetCheckStatusMissionsArgs = {
  checkStatus: Scalars['Boolean']['input'];
  missionIds: Array<Scalars['String']['input']>;
};


export type MutationSetMemberQualificationArgs = {
  memberId: Scalars['String']['input'];
  qualificationTypeId: Scalars['String']['input'];
  validFrom: Scalars['String']['input'];
};


export type MutationSetMemberQualificationAllowedActionsArgs = {
  actionLnrs: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationSetMemberQualificationValidToDayArgs = {
  id: Scalars['String']['input'];
  validToDay: Scalars['String']['input'];
};


export type MutationSetMissionPublicationCancelArgs = {
  missionPublicationId: Scalars['String']['input'];
};


export type MutationSetMissionPublicationDraftArgs = {
  missionPublicationId: Scalars['String']['input'];
};


export type MutationSetMissionPublicationReworkArgs = {
  missionPublicationId: Scalars['String']['input'];
};


export type MutationSetMissionPublicationStatusArgs = {
  missionPublicationId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationSetPatientDenyGenderActionsArgs = {
  actionLnrs: Array<Scalars['String']['input']>;
  genderId: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationSetValidationOneExecutionMobileArgs = {
  id: Scalars['String']['input'];
  isInvalid: Scalars['Boolean']['input'];
};


export type MutationSmartUpdateMissionAnalyticArgs = {
  endDay?: InputMaybe<Scalars['String']['input']>;
  startDay?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSplitMissionArgs = {
  missionId: Scalars['String']['input'];
  updatedAt: Scalars['String']['input'];
};


export type MutationSplitMissionsArgs = {
  input: InputSplitMissions;
};


export type MutationSyncManyTourPausesArgs = {
  input: Array<CreateTourPauseInput>;
  publicationDays: Array<PublicationDayInput>;
  tourIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateActionTimeArgs = {
  combinedLnrs: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  time_sec: Scalars['Int']['input'];
};


export type MutationUpdateExecutionMobileActionArgs = {
  data: InputUpdateExecutionMobileAction;
  id: Scalars['String']['input'];
};


export type MutationUpdateManyMissionsArgs = {
  input: Array<MissionUpdateBulkInput>;
};


export type MutationUpdateManyMissionsEnhancedArgs = {
  input: Array<MissionUpdateBulkInput1>;
};


export type MutationUpdateMedifoxPatientsWithCsvArgs = {
  einsatzFileName?: InputMaybe<Scalars['String']['input']>;
  leistungFileName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMissionSupabaseArgs = {
  day: Scalars['String']['input'];
  tourIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateOneA_EinsatzArgs = {
  input: A_EinsatzInput;
};


export type MutationUpdateOneA_SollLeistungArgs = {
  input: A_SollLeistungenInput;
};


export type MutationUpdateOneActionArgs = {
  data: ActionUpdateInput;
};


export type MutationUpdateOneMemberArgs = {
  input: MemberUpdateInput;
  memberId: Scalars['String']['input'];
};


export type MutationUpdateOneMissionArgs = {
  actionIdsWQ?: InputMaybe<Array<ActionIdWithQuantity>>;
  endTS?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  startTS?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
};


export type MutationUpdateOnePatientArgs = {
  input: PatientUpdateInput;
  patientId: Scalars['String']['input'];
};


export type MutationUpdateOneScheduleArgs = {
  id: Scalars['String']['input'];
  input: ScheduleUpdateInput;
};


export type MutationUpdatePatientAbsenceArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientAbsenceInput;
};


export type MutationUpdatePatientActionDurationArgs = {
  id: Scalars['String']['input'];
  input: InputCreatePatientActionDuration;
};


export type MutationUpdatePatientGeoArgs = {
  geoString: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type MutationUpdatePatientInfoMessageArgs = {
  id: Scalars['String']['input'];
  input: PatientInfoMessageUpdateInput;
};


export type MutationUpdatePatientScheduleExceptionArgs = {
  id: Scalars['String']['input'];
  input: UpdatePatientScheduleExceptionInput;
};


export type MutationUpdatePatientUrlArgs = {
  patientId: Scalars['String']['input'];
  urlId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateScheduleDayArgs = {
  id: Scalars['String']['input'];
  input: InputUpdateScheduleDay;
};


export type MutationUpdateScheduleEveryArgs = {
  id: Scalars['String']['input'];
  input: InputUpdateScheduleEvery;
};


export type MutationUpsertBulkAssigendActionMissionExecutedArgs = {
  input: Array<AssigendActionMissionExecutedUpsertBulkInput>;
};


export type MutationUpsertFakturaArgs = {
  day: Scalars['String']['input'];
};


export type MutationUpsertFakturaRangeArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
};


export type MutationUpsertOneTourPauseArgs = {
  input: CreateTourPauseInput;
};


export type MutationUpsertSupabaseExecutionMobileArgs = {
  day: Scalars['String']['input'];
};

export type NewAssignedActionSchedule = {
  __typename?: 'NewAssignedActionSchedule';
  action?: Maybe<Action>;
  actionLnr: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type NewSchedule = {
  __typename?: 'NewSchedule';
  actionsWithQuantity?: Maybe<Array<NewAssignedActionSchedule>>;
  cash?: Maybe<ActionWithCash>;
  days?: Maybe<Array<Weekday>>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  maxStartTime: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  plannedDuration: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** Operational Changes */
export type OperationalChanges = {
  __typename?: 'OperationalChanges';
  id: Scalars['String']['output'];
  mutationType: Scalars['String']['output'];
  newData?: Maybe<Scalars['Json']['output']>;
  oldData?: Maybe<Scalars['Json']['output']>;
  referenceId: Scalars['String']['output'];
  referenceType: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type PActionWithQuantity = {
  __typename?: 'PActionWithQuantity';
  action?: Maybe<Action>;
  actionLnr?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** Patient */
export type Patient = {
  __typename?: 'Patient';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  colorBg?: Maybe<Scalars['String']['output']>;
  colorTxt?: Maybe<Scalars['String']['output']>;
  district?: Maybe<District>;
  districtId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  genderDeny?: Maybe<Array<PatientDenyGender>>;
  hasGenderDeny: Scalars['Boolean']['output'];
  hasOpenInfoMessage?: Maybe<Scalars['Boolean']['output']>;
  hasSchedule: Scalars['Boolean']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDa?: Maybe<Scalars['Boolean']['output']>;
  isInBetreuung?: Maybe<Scalars['Boolean']['output']>;
  isIncare?: Maybe<Scalars['Boolean']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  kkId?: Maybe<Scalars['String']['output']>;
  kkName?: Maybe<Scalars['String']['output']>;
  kkType?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lat_long?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  medifoxPatientInfo?: Maybe<Scalars['String']['output']>;
  patientId: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PatientAbsence = {
  __typename?: 'PatientAbsence';
  endDay: Scalars['String']['output'];
  id: Scalars['String']['output'];
  patient: Patient;
  patientId: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  startDay: Scalars['String']['output'];
};

/** PatientActionDuration */
export type PatientActionDuration = {
  __typename?: 'PatientActionDuration';
  actionQuantityCoded: Scalars['String']['output'];
  actionsWithQuantity: Array<ActionWithQuantity>;
  duration_min: Scalars['Int']['output'];
  endDay: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isEffective: Scalars['Boolean']['output'];
  patient: Patient;
  patientId: Scalars['String']['output'];
  startDay: Scalars['String']['output'];
};

export type PatientCareInterval = {
  __typename?: 'PatientCareInterval';
  endDay: Scalars['String']['output'];
  id: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
  startDay: Scalars['String']['output'];
};

export type PatientCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  kkId?: InputMaybe<Scalars['String']['input']>;
  kkName?: InputMaybe<Scalars['String']['input']>;
  kkType?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type PatientDay = {
  __typename?: 'PatientDay';
  day: Scalars['String']['output'];
  fromExcelMissions: Array<FromExcelMission>;
  isAbsent: Scalars['Boolean']['output'];
  missionPublication?: Maybe<MissionPublication>;
  patient: Patient;
  patientId: Scalars['String']['output'];
  schedules: Array<ScheduleByDay>;
};

/** PatientDenyGender */
export type PatientDenyGender = {
  __typename?: 'PatientDenyGender';
  action?: Maybe<Action>;
  actionLnr: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  genderId: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
};

export type PatientInfoMessage = {
  __typename?: 'PatientInfoMessage';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  endDay?: Maybe<Scalars['String']['output']>;
  hasMatchingAbsence: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  messageType: Scalars['String']['output'];
  ownedBy: Scalars['String']['output'];
  patient: Patient;
  patientId: Scalars['String']['output'];
  patientInfoStatus: PatientInfoState;
  responses: Array<PatientInfoMessageResponse>;
  startDay?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type PatientInfoMessageInput = {
  endDay?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  messageType?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  startDay?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type PatientInfoMessageResponse = {
  __typename?: 'PatientInfoMessageResponse';
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  response: Scalars['String']['output'];
};

export type PatientInfoMessageResponseInput = {
  infoMessageId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type PatientInfoMessageUpdateInput = {
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type PatientNoAction = {
  __typename?: 'PatientNoAction';
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['String']['output']>;
};

export type PatientPerformanceAnalyzis = {
  __typename?: 'PatientPerformanceAnalyzis';
  avgCashPerMinute: Scalars['Float']['output'];
  avgCashPerMinute_NOSAVE: Scalars['Float']['output'];
  countAllMissions: Scalars['Int']['output'];
  countNoAction: Scalars['Int']['output'];
  countOverPerf: Scalars['Int']['output'];
  countUnderPerf: Scalars['Int']['output'];
  noActionMissions: Array<Mission>;
  overPerformanceMissions: Array<Mission>;
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  sumCashValue_NOSAVE: Scalars['Float']['output'];
  sumTime: Scalars['Int']['output'];
  sumTotalCashValue: Scalars['Float']['output'];
  totalCompensationOverPerf_min: Scalars['Int']['output'];
  totalCompensationUnderPerf_min: Scalars['Int']['output'];
  totalOverPerf_min: Scalars['Int']['output'];
  totalUnderPerf_min: Scalars['Int']['output'];
  underPerformanceMissions: Array<Mission>;
};

export type PatientScheduleException = {
  __typename?: 'PatientScheduleException';
  allCodedActions?: Maybe<Scalars['String']['output']>;
  day: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  patient: Patient;
  patientId: Scalars['String']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  startDay: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type PatientTravelInput = {
  travelPatientIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PatientTravelXxx = {
  __typename?: 'PatientTravelXXX';
  sourcePatient?: Maybe<Patient>;
  sourcePatientId: Scalars['String']['output'];
  targetPatient?: Maybe<Patient>;
  targetPatientId: Scalars['String']['output'];
  travelId: Scalars['String']['output'];
  travelTime?: Maybe<Scalars['Float']['output']>;
};

export type PatientUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  kkId?: InputMaybe<Scalars['String']['input']>;
  kkName?: InputMaybe<Scalars['String']['input']>;
  kkType?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** PatientUrl */
export type PatientUrl = {
  __typename?: 'PatientUrl';
  patientId: Scalars['String']['output'];
  urlId: Scalars['String']['output'];
};

export type PatientsFilter = {
  daysOfLastMission?: InputMaybe<Scalars['Int']['input']>;
  hasGeo?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Point = {
  __typename?: 'Point';
  coordinates: Array<Maybe<Scalars['Float']['output']>>;
  type: Scalars['String']['output'];
};

export type PointGeometry = {
  __typename?: 'PointGeometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type: Scalars['String']['output'];
};

export type PublicationDayInput = {
  day: Scalars['String']['input'];
  missionPublicationId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  A_Einsaetze?: Maybe<Array<Maybe<A_Einsatz>>>;
  A_SollLeistungen?: Maybe<Array<Maybe<A_SollLeistung>>>;
  actionCashValue?: Maybe<ActionCashValue>;
  actionCashValues: Array<ActionCashValue>;
  actionTime?: Maybe<ActionTime>;
  actionTimeDuration_min?: Maybe<Scalars['Int']['output']>;
  actionTimes: Array<ActionTime>;
  actions?: Maybe<Array<Maybe<Action>>>;
  activeMembers: Array<Member>;
  akLeistCsv?: Maybe<Scalars['String']['output']>;
  allCurrentInBetreuungPatients: Array<Patient>;
  allCurrentPatientAbsences: Array<PatientAbsence>;
  allGenders: Array<Gender>;
  allMissionsByMemberPerRange?: Maybe<Array<Maybe<Mission>>>;
  allPatientAbsences: Array<PatientAbsence>;
  allPatientScheduleExceptions: Array<PatientScheduleException>;
  cashActions?: Maybe<ActionWithCash>;
  changeLogSyncMSQL?: Maybe<ChangeLogSyncMsql>;
  changeLogSyncMSQLs?: Maybe<Array<Maybe<ChangeLogSyncMsql>>>;
  distinctMembersMobileMission?: Maybe<Array<Maybe<Member>>>;
  districts: Array<District>;
  effectiveActionScheduleDay?: Maybe<Array<EffectiveActionScheduleDay>>;
  effectiveSchedules?: Maybe<Array<EffectiveSchedule>>;
  excelMission?: Maybe<ExcelMission>;
  excelMissionPublication?: Maybe<ExcelMissionPublication>;
  excelMissionPublications?: Maybe<Array<Maybe<ExcelMissionPublication>>>;
  executionMobile: ExecutionMobile;
  executionMobileAction?: Maybe<ExecutionMobileAction>;
  executionMobileTourStat?: Maybe<ExecutionMobileTourStat>;
  executionMobiles: Array<ExecutionMobile>;
  fakturasByPatient: Array<Faktura>;
  geoPatients?: Maybe<GeoPatientFeatureCollection>;
  geoTourArcLayers?: Maybe<Array<Maybe<GeoTourArcLayer>>>;
  getMostRecentSyncFileNames?: Maybe<MostRecentSyncInfo>;
  getTourenByFileName?: Maybe<Array<PatientDay>>;
  latestMissionWithAction?: Maybe<Mission>;
  listBuckets: Array<Scalars['String']['output']>;
  listFiles?: Maybe<Array<FileInBucket>>;
  medifoxStatusAbsences: Array<MedifoxStatusAbsence>;
  member?: Maybe<Member>;
  memberQualificationTypes: Array<MemberQualificationType>;
  memberQualifications: Array<MemberQualification>;
  members?: Maybe<Array<Maybe<Member>>>;
  mission?: Maybe<Mission>;
  missionDistinctMemberIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  missionDistinctMembers?: Maybe<Array<Maybe<Member>>>;
  missionPublication?: Maybe<MissionPublication>;
  missionPublications?: Maybe<Array<Maybe<MissionPublication>>>;
  missionWoActionsPatientIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  missions?: Maybe<Array<Maybe<Mission>>>;
  missionsByIds?: Maybe<Array<Maybe<Mission>>>;
  missionsTimeRange?: Maybe<Array<Mission>>;
  missionsWithPauschale: Array<MissionWithPauschale>;
  missionsWithPauschaleByIds: Array<MissionWithPauschale>;
  mostRecentMissionPublications?: Maybe<Array<Maybe<MissionPublication>>>;
  nearByPatients: Array<Patient>;
  newSchedules?: Maybe<Array<Maybe<NewSchedule>>>;
  opChange?: Maybe<OperationalChanges>;
  opChanges: Array<OperationalChanges>;
  patient?: Maybe<Patient>;
  patientAbsences: Array<PatientAbsence>;
  patientActionDuration?: Maybe<PatientActionDuration>;
  patientActionDurations: Array<PatientActionDuration>;
  patientCareIntervals: Array<PatientCareInterval>;
  patientDenyGenders: Array<PatientDenyGender>;
  patientInfoMessages: Array<PatientInfoMessage>;
  patientScheduleExceptions: Array<PatientScheduleException>;
  patientUrl?: Maybe<PatientUrl>;
  patients?: Maybe<Array<Maybe<Patient>>>;
  patientsForReview?: Maybe<Array<Maybe<Patient>>>;
  performanceIssues: Array<PatientPerformanceAnalyzis>;
  processEinsatzFile: Scalars['String']['output'];
  recentMissionPublication?: Maybe<MissionPublication>;
  schedule?: Maybe<Schedule>;
  scheduleDays: Array<ScheduleDay>;
  scheduleEvery?: Maybe<ScheduleEvery>;
  scheduleEverys?: Maybe<Array<ScheduleEvery>>;
  scheduleForDaysByPatient?: Maybe<Array<ScheduleDay>>;
  schedules?: Maybe<Array<Maybe<Schedule>>>;
  singleDistance?: Maybe<SingleDistance>;
  statistic: StatisticData;
  supabaseMember?: Maybe<SupabaseMember>;
  supabaseMembers?: Maybe<Array<SupabaseMember>>;
  syncMobile: SyncMobile;
  tour?: Maybe<Tour>;
  tourAutorouting: Array<MissionOptimizeResult>;
  tourData?: Maybe<TourData>;
  tourDatas: Array<TourData>;
  tourIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tourInfoMessage?: Maybe<TourInfoMessage>;
  tourInfoMessages: Array<TourInfoMessage>;
  tourPause?: Maybe<TourPause>;
  tourPauses: Array<TourPause>;
  tourReviewStatus?: Maybe<TourReviewStatus>;
  tourStatExecutions: Array<TourStatExecution>;
  tourStatMissions: Array<TourStatMission>;
  tours?: Maybe<Array<Maybe<Tour>>>;
  transferMissions?: Maybe<Array<Maybe<Mission>>>;
  travelPatients?: Maybe<Array<Maybe<PatientTravelXxx>>>;
  weekdays?: Maybe<Array<Maybe<Weekday>>>;
  whoami: Member;
};


export type QueryActionCashValueArgs = {
  lnr: Scalars['String']['input'];
};


export type QueryActionTimeArgs = {
  id: Scalars['String']['input'];
};


export type QueryActionTimeDuration_MinArgs = {
  actionLnrs: Array<Scalars['String']['input']>;
};


export type QueryActionTimesArgs = {
  actionLnrs: Array<Scalars['String']['input']>;
};


export type QueryActionsArgs = {
  filter?: InputMaybe<ActionFilter>;
};


export type QueryAllMissionsByMemberPerRangeArgs = {
  filter: AllMissionsByMemberPerWeekFilter;
};


export type QueryCashActionsArgs = {
  input: ActionCashInput;
};


export type QueryChangeLogSyncMsqlArgs = {
  id: Scalars['String']['input'];
};


export type QueryEffectiveActionScheduleDayArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type QueryEffectiveSchedulesArgs = {
  input: InputEffectiveSchedule;
};


export type QueryExcelMissionArgs = {
  id: Scalars['String']['input'];
};


export type QueryExcelMissionPublicationArgs = {
  id: Scalars['String']['input'];
};


export type QueryExecutionMobileArgs = {
  id: Scalars['String']['input'];
};


export type QueryExecutionMobileActionArgs = {
  id: Scalars['String']['input'];
};


export type QueryExecutionMobileTourStatArgs = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type QueryExecutionMobilesArgs = {
  day: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};


export type QueryFakturasByPatientArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type QueryGeoPatientsArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryGeoTourArcLayersArgs = {
  day: Scalars['String']['input'];
  tourIds: Array<Scalars['String']['input']>;
};


export type QueryGetTourenByFileNameArgs = {
  fileName: Scalars['String']['input'];
};


export type QueryLatestMissionWithActionArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryListFilesArgs = {
  bucket: Scalars['String']['input'];
  directory?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMedifoxStatusAbsencesArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type QueryMemberArgs = {
  memberId: Scalars['String']['input'];
};


export type QueryMembersArgs = {
  filter?: InputMaybe<MembersFilter>;
};


export type QueryMissionArgs = {
  id: Scalars['String']['input'];
};


export type QueryMissionDistinctMemberIdsArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryMissionDistinctMembersArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryMissionPublicationArgs = {
  id: Scalars['String']['input'];
  onlyNoActions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMissionPublicationsArgs = {
  filter?: InputMaybe<MissionPublicationFilter>;
};


export type QueryMissionWoActionsPatientIdsArgs = {
  publicationId: Scalars['String']['input'];
};


export type QueryMissionsArgs = {
  filter: MissionFilter;
};


export type QueryMissionsByIdsArgs = {
  filter: MissionsByIdsFilter;
};


export type QueryMissionsTimeRangeArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryMissionsWithPauschaleArgs = {
  input: MissionsWithPauschaleInput;
};


export type QueryMissionsWithPauschaleByIdsArgs = {
  input: MissionsWithPauschaleByIdsInput;
};


export type QueryMostRecentMissionPublicationsArgs = {
  filter: MostRecentMissionPublicationFilter;
};


export type QueryNearByPatientsArgs = {
  minutes: Scalars['Int']['input'];
  patientId: Scalars['String']['input'];
};


export type QueryNewSchedulesArgs = {
  filter: ScheduleFilter;
};


export type QueryOpChangeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryPatientAbsencesArgs = {
  maxDay?: InputMaybe<Scalars['String']['input']>;
  minDay?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['String']['input'];
};


export type QueryPatientActionDurationArgs = {
  id: Scalars['String']['input'];
};


export type QueryPatientActionDurationsArgs = {
  countDaysBack?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientCareIntervalsArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryPatientDenyGendersArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryPatientInfoMessagesArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientScheduleExceptionsArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryPatientUrlArgs = {
  patientId: Scalars['String']['input'];
};


export type QueryPatientsArgs = {
  filter?: InputMaybe<PatientsFilter>;
};


export type QueryPatientsForReviewArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryPerformanceIssuesArgs = {
  debugPatientId?: InputMaybe<Scalars['String']['input']>;
  maxDate: Scalars['String']['input'];
  minDate: Scalars['String']['input'];
};


export type QueryProcessEinsatzFileArgs = {
  einsatzFileName: Scalars['String']['input'];
  leistungFileName: Scalars['String']['input'];
};


export type QueryRecentMissionPublicationArgs = {
  filter: MissionPublicationFilter;
};


export type QueryScheduleArgs = {
  id: Scalars['String']['input'];
};


export type QueryScheduleDaysArgs = {
  patientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryScheduleEveryArgs = {
  id: Scalars['String']['input'];
};


export type QueryScheduleEverysArgs = {
  patientId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryScheduleForDaysByPatientArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
};


export type QuerySchedulesArgs = {
  filter: ScheduleFilter;
};


export type QuerySingleDistanceArgs = {
  fromPatientId: Scalars['String']['input'];
  toPatientId: Scalars['String']['input'];
};


export type QueryStatisticArgs = {
  input: StatisticInput;
};


export type QuerySupabaseMemberArgs = {
  memberId: Scalars['String']['input'];
};


export type QuerySyncMobileArgs = {
  memberId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTourArgs = {
  filter: TourFilter;
};


export type QueryTourAutoroutingArgs = {
  input: TourAutoroutingInput;
};


export type QueryTourDataArgs = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type QueryTourDatasArgs = {
  day: Scalars['String']['input'];
};


export type QueryTourIdsArgs = {
  filter: GlobalTimeRangeFilter;
};


export type QueryTourInfoMessageArgs = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type QueryTourInfoMessagesArgs = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type QueryTourPauseArgs = {
  id: Scalars['String']['input'];
};


export type QueryTourPausesArgs = {
  publicationDays: Array<PublicationDayInput>;
  tourIds: Array<Scalars['String']['input']>;
};


export type QueryTourReviewStatusArgs = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};


export type QueryTourStatExecutionsArgs = {
  maxDay: Scalars['String']['input'];
  minDay: Scalars['String']['input'];
};


export type QueryTourStatMissionsArgs = {
  maxDate: Scalars['String']['input'];
  minDate: Scalars['String']['input'];
  tourIds: Array<Scalars['String']['input']>;
  updatedLater?: InputMaybe<Scalars['String']['input']>;
};


export type QueryToursArgs = {
  filter: FilterTours;
};


export type QueryTransferMissionsArgs = {
  filter: AllMissionsByMemberPerWeekFilter;
};


export type QueryTravelPatientsArgs = {
  input: PatientTravelInput;
};

export type ResultMissionUpdateMany = {
  __typename?: 'ResultMissionUpdateMany';
  canBeWritten?: Maybe<Array<Maybe<MissionReturnBulkUpdate>>>;
  dataWritten: Scalars['Boolean']['output'];
  notCanBeWritten?: Maybe<Array<Maybe<MissionReturnBulkUpdate>>>;
  written?: Maybe<Array<Maybe<MissionReturnBulkUpdate>>>;
};

export type Schedule = {
  __typename?: 'Schedule';
  actionsWithQuantity?: Maybe<Array<AssignedActionSchedule>>;
  days?: Maybe<Array<Weekday>>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  maxStartTime: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  plannedDuration: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ScheduleActivePatientInput = {
  patientIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScheduleByDay = {
  __typename?: 'ScheduleByDay';
  allActions_c: Scalars['String']['output'];
  day: Scalars['String']['output'];
  hasMission: Scalars['Boolean']['output'];
  maxStartTime: Scalars['String']['output'];
  maxStartTimeExtended: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  minStartTimeExtended: Scalars['String']['output'];
  patientId: Scalars['String']['output'];
};

export type ScheduleCreateBulkInput = {
  countSchedulesToBe: Scalars['Int']['input'];
  schedules: Array<ScheduleCreateInput>;
};

export type ScheduleCreateInput = {
  actionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  actionsWithQantity?: InputMaybe<Array<ActionIdWithQuantity>>;
  dayIds: Array<InputMaybe<Scalars['String']['input']>>;
  maxStartTime: Scalars['String']['input'];
  minStartTime: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
  plannedDuration: Scalars['String']['input'];
};

/** ScheduleNext */
export type ScheduleDay = {
  __typename?: 'ScheduleDay';
  acceptedMaxStartTime: Scalars['String']['output'];
  acceptedMinStartTime: Scalars['String']['output'];
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  allCodedActions: Scalars['String']['output'];
  allDays: Array<Weekday>;
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  maxStartTime: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['ID']['output'];
  plannedDuration: Scalars['String']['output'];
  preferedDays: Array<Weekday>;
  preferedMaxStartTime: Scalars['String']['output'];
  preferedMinStartTime: Scalars['String']['output'];
  shift: Shift;
  shiftId: Scalars['String']['output'];
  timeGroup: Scalars['String']['output'];
  weekTurnus: Scalars['Int']['output'];
};

/** ScheduleEvery */
export type ScheduleEvery = {
  __typename?: 'ScheduleEvery';
  acceptedMaxStartTime: Scalars['String']['output'];
  acceptedMinStartTime: Scalars['String']['output'];
  actionsWithQuantity?: Maybe<Array<ActionWithQuantity>>;
  every: Scalars['Int']['output'];
  everyType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxStartTime: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  patientId: Scalars['String']['output'];
  plannedDuration: Scalars['String']['output'];
  preferedMaxStartTime: Scalars['String']['output'];
  preferedMinStartTime: Scalars['String']['output'];
  shift: Shift;
  shiftId: Scalars['String']['output'];
};

export type ScheduleFilter = {
  date?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  patientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeMax?: InputMaybe<Scalars['String']['input']>;
  startTimeMin?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleUpdateInput = {
  actionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  actionsWithQantity?: InputMaybe<Array<ActionIdWithQuantity>>;
  dayIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  maxStartTime?: InputMaybe<Scalars['String']['input']>;
  minStartTime?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  plannedDuration?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
};

export type Shift = {
  __typename?: 'Shift';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shiftEndTime: Scalars['String']['output'];
  shiftStartTime: Scalars['String']['output'];
};

export type SingleDistance = {
  __typename?: 'SingleDistance';
  distance_km: Scalars['Float']['output'];
  distance_m: Scalars['Float']['output'];
  fromPatientId: Scalars['String']['output'];
  toPatientId: Scalars['String']['output'];
  travelTime_min: Scalars['Float']['output'];
  travelTime_sec: Scalars['Float']['output'];
};

export type StatisticData = {
  __typename?: 'StatisticData';
  countActionLnr: Array<PActionWithQuantity>;
  countMission: Scalars['Int']['output'];
  countUniqueAction: Scalars['Int']['output'];
  countUniqueMember: Scalars['Int']['output'];
  countUniquePatient: Scalars['Int']['output'];
  missionLowPerforming?: Maybe<Array<MissionLowPerforming>>;
  missionNoAction?: Maybe<Array<MissionIdNoAction>>;
  patientNoAction?: Maybe<Array<PatientNoAction>>;
  totalTimeAtPatient_hour: Scalars['Float']['output'];
};

export type StatisticInput = {
  maxDate: Scalars['String']['input'];
  minDate: Scalars['String']['input'];
};

export type SupabaseMember = {
  __typename?: 'SupabaseMember';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  member_id?: Maybe<Scalars['String']['output']>;
};

/** SyncMobile */
export type SyncMobile = {
  __typename?: 'SyncMobile';
  currentDay: DayMobile;
  member: Member;
  memberId: Scalars['ID']['output'];
  tomorrow: DayMobile;
  yesterday: DayMobile;
};

export type Tour = {
  __typename?: 'Tour';
  date: Scalars['String']['output'];
  maxStart?: Maybe<Scalars['String']['output']>;
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  minStart?: Maybe<Scalars['String']['output']>;
  tourId: Scalars['ID']['output'];
};

export type TourAutoroutingInput = {
  day: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

export type TourData = {
  __typename?: 'TourData';
  canSentToMobile: Scalars['Boolean']['output'];
  day: Scalars['String']['output'];
  latesUpdateSupabase?: Maybe<Scalars['String']['output']>;
  maxEndTime: Scalars['String']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  minStartTime: Scalars['String']['output'];
  missionChanges?: Maybe<Array<MissonChange>>;
  shift: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
  tourName: Scalars['String']['output'];
};

export type TourFilter = {
  date: Scalars['String']['input'];
  tourId: Scalars['String']['input'];
};

/** TourInfoMessage */
export type TourInfoMessage = {
  __typename?: 'TourInfoMessage';
  day: Scalars['String']['output'];
  id: Scalars['String']['output'];
  infoMessageResponse: Array<TourInfoMessageResponse>;
  message: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** TourInfoMessageResponse */
export type TourInfoMessageResponse = {
  __typename?: 'TourInfoMessageResponse';
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  infoMessage: TourInfoMessage;
  infoMessageId: Scalars['String']['output'];
  response: Scalars['String']['output'];
  responseType: Scalars['String']['output'];
};

/** TourPause */
export type TourPause = {
  __typename?: 'TourPause';
  day: Scalars['String']['output'];
  duration_min: Scalars['Int']['output'];
  endDate: Scalars['String']['output'];
  endMinuteOfDay: Scalars['Float']['output'];
  endTS: Scalars['Float']['output'];
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  memberId: Scalars['String']['output'];
  missionPublicationId: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  startMinuteOfDay: Scalars['Float']['output'];
  startTS: Scalars['Float']['output'];
  tourId: Scalars['String']['output'];
};

/** TourReviewStatus */
export type TourReviewStatus = {
  __typename?: 'TourReviewStatus';
  day: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
};

export type TourStatExecution = {
  __typename?: 'TourStatExecution';
  countExecutions: Scalars['Int']['output'];
  countMissions: Scalars['Int']['output'];
  countOpenIssues: Scalars['Int']['output'];
  day: Scalars['String']['output'];
  dayOfWeek: Scalars['String']['output'];
  diffWorkTime: Scalars['Int']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  ratioCountExecuted: Scalars['Float']['output'];
  ratioTimeExecuted: Scalars['Float']['output'];
  sumWorkTimeExecuted: Scalars['Int']['output'];
  sumWorkTimePlanned: Scalars['Int']['output'];
  tourId: Scalars['String']['output'];
  tourReviewStatus?: Maybe<Scalars['String']['output']>;
};

export type TourStatMission = {
  __typename?: 'TourStatMission';
  actionExecutedCoded: Scalars['String']['output'];
  actionPLannedCoded: Scalars['String']['output'];
  day: Scalars['String']['output'];
  diffDuration_min: Scalars['Int']['output'];
  durationExecuted_min: Scalars['Int']['output'];
  durationPlanned_min: Scalars['Int']['output'];
  hasSuccessExecution: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId: Scalars['String']['output'];
  mission?: Maybe<Mission>;
  missionId: Scalars['String']['output'];
  tourId: Scalars['String']['output'];
  tourReviewStatus?: Maybe<Scalars['String']['output']>;
};

export type UpdatePatientAbsenceInput = {
  endDay: Scalars['String']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  startDay: Scalars['String']['input'];
};

export type UpdatePatientScheduleExceptionInput = {
  allCodedActions?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Weekday = {
  __typename?: 'Weekday';
  id: Scalars['String']['output'];
  isoDayNumber: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type WeekdayCreateInput = {
  id: Scalars['String']['input'];
  isoDayNumber: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type InputBulkOperationExecutionOfMission = {
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  publicationId: Scalars['String']['input'];
};

export type PatientInfoState = {
  __typename?: 'patientInfoState';
  description: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type UpdateOneScheduleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: ScheduleUpdateInput;
}>;


export type UpdateOneScheduleMutation = { __typename?: 'Mutation', updateOneSchedule?: { __typename?: 'Schedule', id: string, minStartTime: string, maxStartTime: string, days?: Array<{ __typename?: 'Weekday', id: string, isoDayNumber: number, name: string }> | null } | null };

export type SchedulesByPatientQueryVariables = Exact<{
  filter: ScheduleFilter;
}>;


export type SchedulesByPatientQuery = { __typename?: 'Query', newSchedules?: Array<{ __typename?: 'NewSchedule', id: string, patientId: string, minStartTime: string, maxStartTime: string, plannedDuration: string, days?: Array<{ __typename?: 'Weekday', id: string, isoDayNumber: number }> | null, actionsWithQuantity?: Array<{ __typename?: 'NewAssignedActionSchedule', quantity: number, action?: { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string, leistungsinhalte: string, examinationRequired: boolean } | null }> | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null } | null } | null> | null };

export type CreateEmptyScheduleMutationVariables = Exact<{
  patientId: Scalars['String']['input'];
}>;


export type CreateEmptyScheduleMutation = { __typename?: 'Mutation', createOneSchedule: { __typename?: 'Schedule', id: string } };

export type DeleteOneScheduleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteOneScheduleMutation = { __typename?: 'Mutation', deleteOneSchedule?: { __typename?: 'Schedule', id: string } | null };

export type GetOnePatientQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOnePatientQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', patientId: string, lastName?: string | null, firstName?: string | null, zipCode?: string | null, city?: string | null, address?: string | null, isActive?: boolean | null, longitude?: number | null, latitude?: number | null, kkType?: string | null, districtId?: string | null, medifoxPatientInfo?: string | null } | null };

export type GetAllPatientsQueryVariables = Exact<{
  filter?: InputMaybe<PatientsFilter>;
}>;


export type GetAllPatientsQuery = { __typename?: 'Query', patients?: Array<{ __typename?: 'Patient', patientId: string, lastName?: string | null, firstName?: string | null, zipCode?: string | null, city?: string | null, address?: string | null } | null> | null };

export type UpdatePatientGeoDataMutationVariables = Exact<{
  patientId: Scalars['String']['input'];
  geoString: Scalars['String']['input'];
}>;


export type UpdatePatientGeoDataMutation = { __typename?: 'Mutation', updatePatientGeo?: { __typename?: 'Patient', patientId: string, lastName?: string | null, firstName?: string | null, zipCode?: string | null, city?: string | null, address?: string | null, isActive?: boolean | null, longitude?: number | null, latitude?: number | null } | null };

export type ActionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActionsQuery = { __typename?: 'Query', actions?: Array<{ __typename?: 'Action', leistungsart: string, leistungskomplex: string, lnr: string } | null> | null };

export type GetMissionsDayManagerQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedLater?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMissionsDayManagerQuery = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', updatedAt: string, memberId: string, isActive?: boolean | null, startTS: string, endTS: string, patientId: string, id: string, hasAction?: boolean | null, patient?: { __typename?: 'Patient', lastName?: string | null, longitude?: number | null, latitude?: number | null, colorBg?: string | null, colorTxt?: string | null } | null, member?: { __typename?: 'Member', lastName: string } | null }> | null };

export type UpdateOneMissionDayManagerMutationVariables = Exact<{
  id: Scalars['String']['input'];
  startTS?: InputMaybe<Scalars['String']['input']>;
  endTS?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateOneMissionDayManagerMutation = { __typename?: 'Mutation', updateOneMission?: { __typename?: 'Mission', id: string, start_ISO?: string | null, isActive?: boolean | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string } | null> | null } | null };

export type UpdateManyMissionsDayManagerMutationVariables = Exact<{
  input: Array<MissionUpdateBulkInput> | MissionUpdateBulkInput;
}>;


export type UpdateManyMissionsDayManagerMutation = { __typename?: 'Mutation', updateManyMissions?: Array<{ __typename?: 'Mission', id: string, start_ISO?: string | null, updatedAt: string, startTS: string, endTS: string } | null> | null };

export type UpdateManyMissionsTourManagerMutationVariables = Exact<{
  input: Array<MissionUpdateBulkInput1> | MissionUpdateBulkInput1;
}>;


export type UpdateManyMissionsTourManagerMutation = { __typename?: 'Mutation', updateManyMissionsEnhanced?: { __typename?: 'ResultMissionUpdateMany', dataWritten: boolean, canBeWritten?: Array<{ __typename?: 'MissionReturnBulkUpdate', id: string } | null> | null, notCanBeWritten?: Array<{ __typename?: 'MissionReturnBulkUpdate', id: string } | null> | null, written?: Array<{ __typename?: 'MissionReturnBulkUpdate', id: string } | null> | null } | null };

export type MissionDistinctMemberIdsQueryVariables = Exact<{
  publicationId: Scalars['String']['input'];
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MissionDistinctMemberIdsQuery = { __typename?: 'Query', missionDistinctMemberIds?: Array<string | null> | null };

export type MissionDistinctMembersQueryVariables = Exact<{
  publicationId?: InputMaybe<Scalars['String']['input']>;
  minDate?: InputMaybe<Scalars['String']['input']>;
  maxDate?: InputMaybe<Scalars['String']['input']>;
  includeLate?: InputMaybe<Scalars['Boolean']['input']>;
  includeEarly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MissionDistinctMembersQuery = { __typename?: 'Query', missionDistinctMembers?: Array<{ __typename?: 'Member', memberId: string, lastName: string } | null> | null };

export type SchedulesQueryVariables = Exact<{
  filter: ScheduleFilter;
}>;


export type SchedulesQuery = { __typename?: 'Query', newSchedules?: Array<{ __typename?: 'NewSchedule', id: string, patientId: string, minStartTime: string, maxStartTime: string, plannedDuration: string, days?: Array<{ __typename?: 'Weekday', id: string, isoDayNumber: number }> | null, actionsWithQuantity?: Array<{ __typename?: 'NewAssignedActionSchedule', quantity: number, actionLnr: string, action?: { __typename?: 'Action', examinationRequired: boolean, leistungsart: string } | null }> | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null } | null } | null> | null };

export type CreateManyMissionsMutationVariables = Exact<{
  input: MissionCreateBulkInput;
}>;


export type CreateManyMissionsMutation = { __typename?: 'Mutation', createManyMissions?: Array<{ __typename?: 'Mission', id: string } | null> | null };

export type GetMemberNameQueryVariables = Exact<{
  memberId: Scalars['String']['input'];
}>;


export type GetMemberNameQuery = { __typename?: 'Query', member?: { __typename?: 'Member', lastName: string, firstName: string, shortName: string } | null };

export type GetPatientsQueryVariables = Exact<{
  filter?: InputMaybe<PatientsFilter>;
}>;


export type GetPatientsQuery = { __typename?: 'Query', patients?: Array<{ __typename?: 'Patient', patientId: string, lastName?: string | null, firstName?: string | null, address?: string | null, city?: string | null, zipCode?: string | null, isActive?: boolean | null } | null> | null };

export type GetMembersQueryVariables = Exact<{
  filter?: InputMaybe<MembersFilter>;
}>;


export type GetMembersQuery = { __typename?: 'Query', members?: Array<{ __typename?: 'Member', memberId: string, shortName: string, lastName: string, firstName: string, hasExamination: boolean, hasQualificationLevel: string } | null> | null };

export type CashActionsQueryVariables = Exact<{
  input: ActionCashInput;
}>;


export type CashActionsQuery = { __typename?: 'Query', cashActions?: { __typename?: 'ActionWithCash', actionLnrs?: Array<string | null> | null, complexedLnrs?: Array<string | null> | null, cashflowValue?: number | null, calculatedDuration_min?: number | null } | null };

export type GenerateCashFlowTableMutationVariables = Exact<{
  missionPublicationId: Scalars['String']['input'];
}>;


export type GenerateCashFlowTableMutation = { __typename?: 'Mutation', generateCashflowTable?: number | null };

export type MissionWoActionPatientsIdsQueryVariables = Exact<{
  publicationId: Scalars['String']['input'];
}>;


export type MissionWoActionPatientsIdsQuery = { __typename?: 'Query', missionWoActionsPatientIds?: Array<string | null> | null };

export type GetMissionsTourManager1QueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetMissionsTourManager1Query = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', missionPublicationId: string, id: string, updatedAt: string, memberId: string, tourId?: string | null, isActive?: boolean | null, duration_min: number, patientId: string, minReqQualification: number, memberHasQualificationLevel: string, day: string, time: string, startTS: string, endTS: string, member?: { __typename?: 'Member', memberId: string, shortName: string, firstName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null, cashflowValue?: number | null } | null, patient?: { __typename?: 'Patient', isPrivate: boolean, lastName?: string | null, firstName?: string | null, colorBg?: string | null, colorTxt?: string | null, longitude?: number | null, latitude?: number | null } | null }> | null };

export type GetMissionsByIdsTourManager1QueryVariables = Exact<{
  filter: MissionsByIdsFilter;
}>;


export type GetMissionsByIdsTourManager1Query = { __typename?: 'Query', missionsByIds?: Array<{ __typename?: 'Mission', missionPublicationId: string, id: string, updatedAt: string, memberId: string, tourId?: string | null, isActive?: boolean | null, duration_min: number, patientId: string, minReqQualification: number, memberHasQualificationLevel: string, day: string, time: string, startTS: string, endTS: string, member?: { __typename?: 'Member', memberId: string, shortName: string, firstName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null, cashflowValue?: number | null } | null, patient?: { __typename?: 'Patient', isPrivate: boolean, lastName?: string | null, firstName?: string | null, colorBg?: string | null, colorTxt?: string | null, longitude?: number | null, latitude?: number | null } | null } | null> | null };

export type Mission_TourManagerFragment = { __typename?: 'Mission', id: string, updatedAt: string, memberId: string, tourId?: string | null, isActive?: boolean | null, duration_min: number, patientId: string, minReqQualification: number, memberHasQualificationLevel: string, day: string, time: string, startTS: string, endTS: string, member?: { __typename?: 'Member', memberId: string, shortName: string, firstName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null, cashflowValue?: number | null } | null, patient?: { __typename?: 'Patient', isPrivate: boolean, lastName?: string | null, firstName?: string | null, colorBg?: string | null, colorTxt?: string | null, longitude?: number | null, latitude?: number | null } | null };

export type GetMissionsTourManagerQueryVariables = Exact<{
  filter: AllMissionsByMemberPerWeekFilter;
}>;


export type GetMissionsTourManagerQuery = { __typename?: 'Query', allMissionsByMemberPerRange?: Array<{ __typename?: 'Mission', id: string, updatedAt: string, memberId: string, tourId?: string | null, isActive?: boolean | null, duration_min: number, patientId: string, minReqQualification: number, memberHasQualificationLevel: string, day: string, time: string, startTS: string, endTS: string, member?: { __typename?: 'Member', memberId: string, shortName: string, firstName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null, cashflowValue?: number | null } | null, patient?: { __typename?: 'Patient', isPrivate: boolean, lastName?: string | null, firstName?: string | null, colorBg?: string | null, colorTxt?: string | null, longitude?: number | null, latitude?: number | null } | null } | null> | null };

export type GetPatientsMapQueryVariables = Exact<{
  filter?: InputMaybe<PatientsFilter>;
}>;


export type GetPatientsMapQuery = { __typename?: 'Query', patients?: Array<{ __typename?: 'Patient', patientId: string, lastName?: string | null, longitude?: number | null, latitude?: number | null } | null> | null };

export type GetTravelDistancesQueryVariables = Exact<{
  input: PatientTravelInput;
}>;


export type GetTravelDistancesQuery = { __typename?: 'Query', travelPatients?: Array<{ __typename?: 'PatientTravelXXX', sourcePatientId: string, targetPatientId: string, travelTime?: number | null, travelId: string, sourcePatient?: { __typename?: 'Patient', lastName?: string | null, latitude?: number | null, longitude?: number | null, address?: string | null, zipCode?: string | null, lat_long?: string | null } | null, targetPatient?: { __typename?: 'Patient', lastName?: string | null, latitude?: number | null, longitude?: number | null, address?: string | null, zipCode?: string | null, lat_long?: string | null } | null } | null> | null };

export type GetMissionsTransferQueryVariables = Exact<{
  filter: AllMissionsByMemberPerWeekFilter;
}>;


export type GetMissionsTransferQuery = { __typename?: 'Query', allMissionsByMemberPerRange?: Array<{ __typename?: 'Mission', id: string, memberId: string, patientId: string, day: string, time: string, startTS: string, endTS: string, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string } | null> | null } | null> | null };

export type Mission_TransferFragment = { __typename?: 'Mission', id: string, memberId: string, patientId: string, day: string, time: string, startTS: string, endTS: string, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string } | null> | null };

export type GetGeoPatientsQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetGeoPatientsQuery = { __typename?: 'Query', geoPatients?: { __typename?: 'GeoPatientFeatureCollection', type: string, features?: Array<{ __typename?: 'GeoPatient', type: string, geometry?: { __typename?: 'PointGeometry', type: string, coordinates?: Array<number | null> | null } | null, properties: { __typename?: 'GeoPatientProperties', patientId: string, lastName?: string | null, colorHex?: string | null } } | null> | null } | null };

export type GetArcLayerQueryVariables = Exact<{
  day: Scalars['String']['input'];
  tourIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetArcLayerQuery = { __typename?: 'Query', geoTourArcLayers?: Array<{ __typename?: 'GeoTourArcLayer', memberId: string, elements?: Array<{ __typename?: 'GeoTourArcLayerElement', inbound: string, outbound: string, memberId: string, duration_from: number, from?: { __typename?: 'GeoTourArcLayerElementProp', name: string, coordinates?: Array<number | null> | null, startTime: string, startTS: string } | null, to?: { __typename?: 'GeoTourArcLayerElementProp', name: string, coordinates?: Array<number | null> | null, startTime: string } | null } | null> | null } | null> | null };

export type GetTourInfo1QueryVariables = Exact<{
  filter: TourFilter;
}>;


export type GetTourInfo1Query = { __typename?: 'Query', tour?: { __typename?: 'Tour', tourId: string, date: string, memberId: string, member?: { __typename?: 'Member', lastName: string, firstName: string, shortName: string, hasExamination: boolean } | null } | null };

export type GetPatientMissionListByIdsQueryVariables = Exact<{
  filter: MissionsByIdsFilter;
}>;


export type GetPatientMissionListByIdsQuery = { __typename?: 'Query', missionsByIds?: Array<{ __typename?: 'Mission', patientId: string, id: string, startTS: string, endTS: string, day: string, time: string, duration_min: number, minReqQualification: number, tourId?: string | null, hasAction?: boolean | null, isActive?: boolean | null, updatedAt: string, member?: { __typename?: 'Member', memberId: string, shortName: string, hasExamination: boolean, hasQualificationLevel: string } | null, patient?: { __typename?: 'Patient', lastName?: string | null, firstName?: string | null, isPrivate: boolean } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null } | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, action?: { __typename?: 'Action', lnr: string, leistungsart: string, examinationRequired: boolean, leistungsinhalte: string, leistungskomplex: string, executable: boolean, mobileDisplayGroup?: string | null } | null } | null> | null } | null> | null };

export type GetPatientMissionListQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetPatientMissionListQuery = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', patientId: string, id: string, startTS: string, endTS: string, day: string, time: string, duration_min: number, tourId?: string | null, minReqQualification: number, hasAction?: boolean | null, isActive?: boolean | null, updatedAt: string, member?: { __typename?: 'Member', memberId: string, shortName: string, hasExamination: boolean, hasQualificationLevel: string } | null, patient?: { __typename?: 'Patient', lastName?: string | null, firstName?: string | null, isPrivate: boolean } | null, cash?: { __typename?: 'ActionWithCash', calculatedDuration_min?: number | null } | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, action?: { __typename?: 'Action', lnr: string, leistungsart: string, examinationRequired: boolean, leistungsinhalte: string, leistungskomplex: string, executable: boolean, mobileDisplayGroup?: string | null } | null } | null> | null }> | null };

export type MissionSetIsActiveMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  updatedAt: Scalars['String']['input'];
}>;


export type MissionSetIsActiveMutation = { __typename?: 'Mutation', missionSetIsActive?: boolean | null };

export type GetPatientIdsNoActionQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetPatientIdsNoActionQuery = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', patientId: string, hasAction?: boolean | null, patient?: { __typename?: 'Patient', patientId: string, hasSchedule: boolean } | null }> | null };

export type ActionFullFragment = { __typename?: 'Action', lnr: string, leistungsart: string, examinationRequired: boolean, leistungsinhalte: string, leistungskomplex: string, executable: boolean, mobileDisplayGroup?: string | null };

export type ActionMinFragment = { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string };

export type PatientMinFragment = { __typename?: 'Patient', firstName?: string | null, lastName?: string | null };

export type GetAnalyseMissionQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetAnalyseMissionQuery = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', missionPublicationId: string, updatedAt: string, startTS: string, endTS: string, day: string, duration_min: number, memberId: string, isActive?: boolean | null, isChecked: boolean, editAllowed: boolean, patientId: string, tourId?: string | null, minReqQualification: number, id: string, hasAction?: boolean | null, patient?: { __typename?: 'Patient', patientId: string, firstName?: string | null, lastName?: string | null, kkType?: string | null, isPrivate: boolean } | null, member?: { __typename?: 'Member', shortName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string, action?: { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string, examinationRequired: boolean } | null } | null> | null, missionAnalytic?: { __typename?: 'MissionAnalytic', totalCashValue: number, totalCashValue_NOSAFE: number, pauschaleHausType: string, pauschaleSaveType: string, duration_min: number, expectedDuration: number, expectedDuration_NOSAFE: number, cashPerMinute: number, cashPerMinute_NOSAFE: number, durationDeviation_min: number, totalPauschaleElements: string, totalPauschaleCashValue: number, timedElements: string, complexedGrundElements: string, behandlungMaxElements: string, behandlungAdditionalElements: string, allActions: string, grundCashValue: number, behandlungMaxCashValue: number, timedCashValue: number, behandlungAdditionalCashValue: number } | null }> | null };

export type Max_AssigendActionMissionExecutedFragment = { __typename?: 'AssignedActionMissionExecuted', quantity: number, actionLnr: string, action?: { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string, examinationRequired: boolean } | null };

export type Max_AssigendActionMissionFragment = { __typename?: 'AssignedActionMission', quantity: number, actionLnr: string, action?: { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string, examinationRequired: boolean } | null };

export type GetActiveMembersQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetActiveMembersQuery = { __typename?: 'Query', missionDistinctMembers?: Array<{ __typename?: 'Member', memberId: string, shortName: string, firstName: string, lastName: string } | null> | null };

export type GetTourIdsQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetTourIdsQuery = { __typename?: 'Query', tourIds?: Array<string | null> | null };

export type AddMissionToPublicationIdMutationVariables = Exact<{
  input: AddMissionToPublicationInput;
}>;


export type AddMissionToPublicationIdMutation = { __typename?: 'Mutation', addMissionToPublicationId?: { __typename?: 'Mission', id: string } | null };

export type AddMissionToDayMutationVariables = Exact<{
  input: AddMissionToDayInput;
}>;


export type AddMissionToDayMutation = { __typename?: 'Mutation', addMissionToDay?: { __typename?: 'Mission', id: string } | null };

export type MissionsWithPauschaleQueryVariables = Exact<{
  day: Scalars['String']['input'];
  patientId: Scalars['String']['input'];
}>;


export type MissionsWithPauschaleQuery = { __typename?: 'Query', missionsWithPauschale: Array<{ __typename?: 'MissionWithPauschale', time: string, actionLnrs?: Array<string> | null, pauschale: string, reasonPauschale: string, criteriaPauschale?: { __typename?: 'CriteriaPauschale', hasAtLeastOneGrundpflege: boolean, hasAtLeastOne_31_32_33: boolean, hasSolitaer_3_to_33: boolean, has_29_30: boolean, hasSolitaer_27_28_39_or_30: boolean } | null }> };

export type GetMissionsByIdsQueryVariables = Exact<{
  filter: MissionsByIdsFilter;
}>;


export type GetMissionsByIdsQuery = { __typename?: 'Query', missionsByIds?: Array<{ __typename?: 'Mission', missionPublicationId: string, updatedAt: string, startTS: string, endTS: string, duration_min: number, memberId: string, isActive?: boolean | null, isChecked: boolean, editAllowed: boolean, patientId: string, id: string, tourId?: string | null, minReqQualification: number, hasAction?: boolean | null, patient?: { __typename?: 'Patient', patientId: string, firstName?: string | null, lastName?: string | null, kkType?: string | null, isPrivate: boolean } | null, member?: { __typename?: 'Member', shortName: string, lastName: string, hasExamination: boolean, hasQualificationLevel: string } | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string, action?: { __typename?: 'Action', lnr: string, leistungsart: string, leistungskomplex: string, examinationRequired: boolean } | null } | null> | null, missionAnalytic?: { __typename?: 'MissionAnalytic', totalCashValue: number, totalCashValue_NOSAFE: number, pauschaleHausType: string, pauschaleSaveType: string, duration_min: number, expectedDuration: number, expectedDuration_NOSAFE: number, cashPerMinute: number, cashPerMinute_NOSAFE: number, durationDeviation_min: number, totalPauschaleElements: string, totalPauschaleCashValue: number, timedElements: string, complexedGrundElements: string, behandlungMaxElements: string, behandlungAdditionalElements: string, allActions: string, grundCashValue: number, behandlungMaxCashValue: number, timedCashValue: number, behandlungAdditionalCashValue: number } | null } | null> | null };

export type AnalyzeMissionPatientFragment = { __typename?: 'Patient', patientId: string, firstName?: string | null, lastName?: string | null, kkType?: string | null };

export type SplitMissionsMutationVariables = Exact<{
  input: InputSplitMissions;
}>;


export type SplitMissionsMutation = { __typename?: 'Mutation', splitMissions?: Array<{ __typename?: 'Mission', id: string, tourId?: string | null }> | null };

export type GetOneMissionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOneMissionQuery = { __typename?: 'Query', mission?: { __typename?: 'Mission', id: string, patientId: string, memberId: string, startTS: string, endTS: string, day: string, isActive?: boolean | null, isChecked: boolean, updatedAt: string, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string, action?: { __typename?: 'Action', leistungsart: string, leistungskomplex: string, leistungsinhalte: string, examinationRequired: boolean } | null } | null> | null } | null };

export type MissionPublicationEditQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type MissionPublicationEditQuery = { __typename?: 'Query', missionPublication?: { __typename?: 'MissionPublication', id?: string | null, day?: string | null } | null };

export type DayInfoFragment = { __typename?: 'Weekday', id: string, isoDayNumber: number };

export type MissionDataFragment = { __typename?: 'Mission', id: string, updatedAt: string, memberId: string, start_ISO?: string | null, startTS: string, endTS: string, day: string, isActive?: boolean | null, patientId: string, patient?: { __typename?: 'Patient', lastName?: string | null, firstName?: string | null } | null, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, action?: { __typename?: 'Action', leistungskomplex: string, leistungsart: string, lnr: string, leistungsinhalte: string } | null } | null> | null };

export type GetMissionsTimeRangeQueryVariables = Exact<{
  filter: GlobalTimeRangeFilter;
}>;


export type GetMissionsTimeRangeQuery = { __typename?: 'Query', missionsTimeRange?: Array<{ __typename?: 'Mission', updatedAt: string, startTS: string, endTS: string, duration_min: number, memberId: string, isActive?: boolean | null, patientId: string, id: string, hasAction?: boolean | null, patient?: { __typename?: 'Patient', lastName?: string | null, firstName?: string | null, longitude?: number | null, latitude?: number | null, colorBg?: string | null, colorTxt?: string | null } | null, member?: { __typename?: 'Member', lastName: string } | null }> | null };

export type MissionSetActionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  actionIdsWQ: Array<ActionIdWithQuantity> | ActionIdWithQuantity;
  updatedAt: Scalars['String']['input'];
}>;


export type MissionSetActionMutation = { __typename?: 'Mutation', missionSetAction?: { __typename?: 'Mission', id: string, actionsWithQuantity?: Array<{ __typename?: 'AssignedActionMission', quantity: number, actionLnr: string } | null> | null } | null };



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  A_Einsatz: ResolverTypeWrapper<A_Einsatz>;
  A_EinsatzInput: A_EinsatzInput;
  A_SollLeistung: ResolverTypeWrapper<A_SollLeistung>;
  A_SollLeistungenInput: A_SollLeistungenInput;
  Action: ResolverTypeWrapper<Action>;
  ActionCashInput: ActionCashInput;
  ActionCashValue: ResolverTypeWrapper<ActionCashValue>;
  ActionCreateInput: ActionCreateInput;
  ActionFilter: ActionFilter;
  ActionIdWithQuantity: ActionIdWithQuantity;
  ActionTime: ResolverTypeWrapper<ActionTime>;
  ActionUpdateInput: ActionUpdateInput;
  ActionWithCash: ResolverTypeWrapper<ActionWithCash>;
  ActionWithQuantity: ResolverTypeWrapper<ActionWithQuantity>;
  ActionWithQuantitySchedule: ResolverTypeWrapper<ActionWithQuantitySchedule>;
  ActionWqCashInput: ActionWqCashInput;
  ActionWqLnr: ActionWqLnr;
  AddMissionToDayInput: AddMissionToDayInput;
  AddMissionToPublicationInput: AddMissionToPublicationInput;
  AllMissionsByMemberPerWeekFilter: AllMissionsByMemberPerWeekFilter;
  AssigendActionMissionExecutedUpsertBulkInput: AssigendActionMissionExecutedUpsertBulkInput;
  AssignedActionMission: ResolverTypeWrapper<AssignedActionMission>;
  AssignedActionMissionExecuted: ResolverTypeWrapper<AssignedActionMissionExecuted>;
  AssignedActionSchedule: ResolverTypeWrapper<AssignedActionSchedule>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CashFlowMission: ResolverTypeWrapper<CashFlowMission>;
  CashPerMinuteRangeFilter: CashPerMinuteRangeFilter;
  ChangeLogSyncMSQL: ResolverTypeWrapper<ChangeLogSyncMsql>;
  ChangeLogSyncMSQLCreateInput: ChangeLogSyncMsqlCreateInput;
  Coordinate: ResolverTypeWrapper<Coordinate>;
  CreatePatientAbsenceInput: CreatePatientAbsenceInput;
  CreatePatientScheduleExceptionInput: CreatePatientScheduleExceptionInput;
  CreateTourPauseInput: CreateTourPauseInput;
  CriteriaPauschale: ResolverTypeWrapper<CriteriaPauschale>;
  CsvMission: ResolverTypeWrapper<CsvMission>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DayMobile: ResolverTypeWrapper<DayMobile>;
  District: ResolverTypeWrapper<District>;
  EffectiveActionScheduleDay: ResolverTypeWrapper<EffectiveActionScheduleDay>;
  EffectiveSchedule: ResolverTypeWrapper<EffectiveSchedule>;
  ExcelMission: ResolverTypeWrapper<ExcelMission>;
  ExcelMissionBulkInput: ExcelMissionBulkInput;
  ExcelMissionPublication: ResolverTypeWrapper<ExcelMissionPublication>;
  ExecutionMobile: ResolverTypeWrapper<ExecutionMobile>;
  ExecutionMobileAction: ResolverTypeWrapper<ExecutionMobileAction>;
  ExecutionMobileInfoMessage: ResolverTypeWrapper<ExecutionMobileInfoMessage>;
  ExecutionMobileTourStat: ResolverTypeWrapper<ExecutionMobileTourStat>;
  Faktura: ResolverTypeWrapper<Faktura>;
  FakturaSource: FakturaSource;
  FakturaStatus: FakturaStatus;
  FileInBucket: ResolverTypeWrapper<FileInBucket>;
  FilterTourPauses: FilterTourPauses;
  FilterTours: FilterTours;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FromExcelMission: ResolverTypeWrapper<FromExcelMission>;
  Gender: ResolverTypeWrapper<Gender>;
  GeoPatient: ResolverTypeWrapper<GeoPatient>;
  GeoPatientFeatureCollection: ResolverTypeWrapper<GeoPatientFeatureCollection>;
  GeoPatientProperties: ResolverTypeWrapper<GeoPatientProperties>;
  GeoPatientsData: ResolverTypeWrapper<GeoPatientsData>;
  GeoTour: ResolverTypeWrapper<GeoTour>;
  GeoTourArcLayer: ResolverTypeWrapper<GeoTourArcLayer>;
  GeoTourArcLayerData: ResolverTypeWrapper<GeoTourArcLayerData>;
  GeoTourArcLayerElement: ResolverTypeWrapper<GeoTourArcLayerElement>;
  GeoTourArcLayerElementProp: ResolverTypeWrapper<GeoTourArcLayerElementProp>;
  GeoTourFeatureCollection: ResolverTypeWrapper<GeoTourFeatureCollection>;
  GeoTourLineString: ResolverTypeWrapper<GeoTourLineString>;
  GeoTourProperties: ResolverTypeWrapper<GeoTourProperties>;
  GlobalTimeRangeFilter: GlobalTimeRangeFilter;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  InputCreateExecutionMobile: InputCreateExecutionMobile;
  InputCreateExecutionMobileAction: InputCreateExecutionMobileAction;
  InputCreateExecutionMobileInfoMessage: InputCreateExecutionMobileInfoMessage;
  InputCreatePatientActionDuration: InputCreatePatientActionDuration;
  InputCreateScheduleDay: InputCreateScheduleDay;
  InputCreateScheduleEvery: InputCreateScheduleEvery;
  InputEffectiveSchedule: InputEffectiveSchedule;
  InputSplitMissions: InputSplitMissions;
  InputUpdateExecutionMobileAction: InputUpdateExecutionMobileAction;
  InputUpdateScheduleDay: InputUpdateScheduleDay;
  InputUpdateScheduleEvery: InputUpdateScheduleEvery;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Json: ResolverTypeWrapper<Scalars['Json']['output']>;
  LineGeometry: ResolverTypeWrapper<LineGeometry>;
  MedifoxStatusAbsence: ResolverTypeWrapper<MedifoxStatusAbsence>;
  Member: ResolverTypeWrapper<Member>;
  MemberCreateInput: MemberCreateInput;
  MemberQualification: ResolverTypeWrapper<MemberQualification>;
  MemberQualificationType: ResolverTypeWrapper<MemberQualificationType>;
  MemberUpdateInput: MemberUpdateInput;
  MembersFilter: MembersFilter;
  Mission: ResolverTypeWrapper<Mission>;
  MissionAnalytic: ResolverTypeWrapper<MissionAnalytic>;
  MissionCreateBulkInput: MissionCreateBulkInput;
  MissionFilter: MissionFilter;
  MissionIdNoAction: ResolverTypeWrapper<MissionIdNoAction>;
  MissionInput: MissionInput;
  MissionLowPerforming: ResolverTypeWrapper<MissionLowPerforming>;
  MissionOptimize: ResolverTypeWrapper<MissionOptimize>;
  MissionOptimizeResult: ResolverTypeWrapper<MissionOptimizeResult>;
  MissionPublication: ResolverTypeWrapper<MissionPublication>;
  MissionPublicationFilter: MissionPublicationFilter;
  MissionPublicationStatus: ResolverTypeWrapper<MissionPublicationStatus>;
  MissionReturnBulkUpdate: ResolverTypeWrapper<MissionReturnBulkUpdate>;
  MissionUpdateBulkInput: MissionUpdateBulkInput;
  MissionUpdateBulkInput1: MissionUpdateBulkInput1;
  MissionWithActionExecuted: ResolverTypeWrapper<MissionWithActionExecuted>;
  MissionWithPauschale: ResolverTypeWrapper<MissionWithPauschale>;
  MissionsByIdsFilter: MissionsByIdsFilter;
  MissionsWithPauschaleByIdsInput: MissionsWithPauschaleByIdsInput;
  MissionsWithPauschaleInput: MissionsWithPauschaleInput;
  MissonChange: ResolverTypeWrapper<MissonChange>;
  MobileTask: ResolverTypeWrapper<MobileTask>;
  MostRecentMissionPublicationFilter: MostRecentMissionPublicationFilter;
  MostRecentSyncInfo: ResolverTypeWrapper<MostRecentSyncInfo>;
  Mutation: ResolverTypeWrapper<{}>;
  NewAssignedActionSchedule: ResolverTypeWrapper<NewAssignedActionSchedule>;
  NewSchedule: ResolverTypeWrapper<NewSchedule>;
  OperationalChanges: ResolverTypeWrapper<OperationalChanges>;
  PActionWithQuantity: ResolverTypeWrapper<PActionWithQuantity>;
  Patient: ResolverTypeWrapper<Patient>;
  PatientAbsence: ResolverTypeWrapper<PatientAbsence>;
  PatientActionDuration: ResolverTypeWrapper<PatientActionDuration>;
  PatientCareInterval: ResolverTypeWrapper<PatientCareInterval>;
  PatientCreateInput: PatientCreateInput;
  PatientDay: ResolverTypeWrapper<PatientDay>;
  PatientDenyGender: ResolverTypeWrapper<PatientDenyGender>;
  PatientInfoMessage: ResolverTypeWrapper<PatientInfoMessage>;
  PatientInfoMessageInput: PatientInfoMessageInput;
  PatientInfoMessageResponse: ResolverTypeWrapper<PatientInfoMessageResponse>;
  PatientInfoMessageResponseInput: PatientInfoMessageResponseInput;
  PatientInfoMessageUpdateInput: PatientInfoMessageUpdateInput;
  PatientNoAction: ResolverTypeWrapper<PatientNoAction>;
  PatientPerformanceAnalyzis: ResolverTypeWrapper<PatientPerformanceAnalyzis>;
  PatientScheduleException: ResolverTypeWrapper<PatientScheduleException>;
  PatientTravelInput: PatientTravelInput;
  PatientTravelXXX: ResolverTypeWrapper<PatientTravelXxx>;
  PatientUpdateInput: PatientUpdateInput;
  PatientUrl: ResolverTypeWrapper<PatientUrl>;
  PatientsFilter: PatientsFilter;
  Point: ResolverTypeWrapper<Point>;
  PointGeometry: ResolverTypeWrapper<PointGeometry>;
  PublicationDayInput: PublicationDayInput;
  Query: ResolverTypeWrapper<{}>;
  ResultMissionUpdateMany: ResolverTypeWrapper<ResultMissionUpdateMany>;
  Schedule: ResolverTypeWrapper<Schedule>;
  ScheduleActivePatientInput: ScheduleActivePatientInput;
  ScheduleByDay: ResolverTypeWrapper<ScheduleByDay>;
  ScheduleCreateBulkInput: ScheduleCreateBulkInput;
  ScheduleCreateInput: ScheduleCreateInput;
  ScheduleDay: ResolverTypeWrapper<ScheduleDay>;
  ScheduleEvery: ResolverTypeWrapper<ScheduleEvery>;
  ScheduleFilter: ScheduleFilter;
  ScheduleUpdateInput: ScheduleUpdateInput;
  Shift: ResolverTypeWrapper<Shift>;
  SingleDistance: ResolverTypeWrapper<SingleDistance>;
  StatisticData: ResolverTypeWrapper<StatisticData>;
  StatisticInput: StatisticInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SupabaseMember: ResolverTypeWrapper<SupabaseMember>;
  SyncMobile: ResolverTypeWrapper<SyncMobile>;
  Tour: ResolverTypeWrapper<Tour>;
  TourAutoroutingInput: TourAutoroutingInput;
  TourData: ResolverTypeWrapper<TourData>;
  TourFilter: TourFilter;
  TourInfoMessage: ResolverTypeWrapper<TourInfoMessage>;
  TourInfoMessageResponse: ResolverTypeWrapper<TourInfoMessageResponse>;
  TourPause: ResolverTypeWrapper<TourPause>;
  TourReviewStatus: ResolverTypeWrapper<TourReviewStatus>;
  TourStatExecution: ResolverTypeWrapper<TourStatExecution>;
  TourStatMission: ResolverTypeWrapper<TourStatMission>;
  UpdatePatientAbsenceInput: UpdatePatientAbsenceInput;
  UpdatePatientScheduleExceptionInput: UpdatePatientScheduleExceptionInput;
  Weekday: ResolverTypeWrapper<Weekday>;
  WeekdayCreateInput: WeekdayCreateInput;
  inputBulkOperationExecutionOfMission: InputBulkOperationExecutionOfMission;
  patientInfoState: ResolverTypeWrapper<PatientInfoState>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  A_Einsatz: A_Einsatz;
  A_EinsatzInput: A_EinsatzInput;
  A_SollLeistung: A_SollLeistung;
  A_SollLeistungenInput: A_SollLeistungenInput;
  Action: Action;
  ActionCashInput: ActionCashInput;
  ActionCashValue: ActionCashValue;
  ActionCreateInput: ActionCreateInput;
  ActionFilter: ActionFilter;
  ActionIdWithQuantity: ActionIdWithQuantity;
  ActionTime: ActionTime;
  ActionUpdateInput: ActionUpdateInput;
  ActionWithCash: ActionWithCash;
  ActionWithQuantity: ActionWithQuantity;
  ActionWithQuantitySchedule: ActionWithQuantitySchedule;
  ActionWqCashInput: ActionWqCashInput;
  ActionWqLnr: ActionWqLnr;
  AddMissionToDayInput: AddMissionToDayInput;
  AddMissionToPublicationInput: AddMissionToPublicationInput;
  AllMissionsByMemberPerWeekFilter: AllMissionsByMemberPerWeekFilter;
  AssigendActionMissionExecutedUpsertBulkInput: AssigendActionMissionExecutedUpsertBulkInput;
  AssignedActionMission: AssignedActionMission;
  AssignedActionMissionExecuted: AssignedActionMissionExecuted;
  AssignedActionSchedule: AssignedActionSchedule;
  Boolean: Scalars['Boolean']['output'];
  CashFlowMission: CashFlowMission;
  CashPerMinuteRangeFilter: CashPerMinuteRangeFilter;
  ChangeLogSyncMSQL: ChangeLogSyncMsql;
  ChangeLogSyncMSQLCreateInput: ChangeLogSyncMsqlCreateInput;
  Coordinate: Coordinate;
  CreatePatientAbsenceInput: CreatePatientAbsenceInput;
  CreatePatientScheduleExceptionInput: CreatePatientScheduleExceptionInput;
  CreateTourPauseInput: CreateTourPauseInput;
  CriteriaPauschale: CriteriaPauschale;
  CsvMission: CsvMission;
  DateTime: Scalars['DateTime']['output'];
  DayMobile: DayMobile;
  District: District;
  EffectiveActionScheduleDay: EffectiveActionScheduleDay;
  EffectiveSchedule: EffectiveSchedule;
  ExcelMission: ExcelMission;
  ExcelMissionBulkInput: ExcelMissionBulkInput;
  ExcelMissionPublication: ExcelMissionPublication;
  ExecutionMobile: ExecutionMobile;
  ExecutionMobileAction: ExecutionMobileAction;
  ExecutionMobileInfoMessage: ExecutionMobileInfoMessage;
  ExecutionMobileTourStat: ExecutionMobileTourStat;
  Faktura: Faktura;
  FileInBucket: FileInBucket;
  FilterTourPauses: FilterTourPauses;
  FilterTours: FilterTours;
  Float: Scalars['Float']['output'];
  FromExcelMission: FromExcelMission;
  Gender: Gender;
  GeoPatient: GeoPatient;
  GeoPatientFeatureCollection: GeoPatientFeatureCollection;
  GeoPatientProperties: GeoPatientProperties;
  GeoPatientsData: GeoPatientsData;
  GeoTour: GeoTour;
  GeoTourArcLayer: GeoTourArcLayer;
  GeoTourArcLayerData: GeoTourArcLayerData;
  GeoTourArcLayerElement: GeoTourArcLayerElement;
  GeoTourArcLayerElementProp: GeoTourArcLayerElementProp;
  GeoTourFeatureCollection: GeoTourFeatureCollection;
  GeoTourLineString: GeoTourLineString;
  GeoTourProperties: GeoTourProperties;
  GlobalTimeRangeFilter: GlobalTimeRangeFilter;
  ID: Scalars['ID']['output'];
  InputCreateExecutionMobile: InputCreateExecutionMobile;
  InputCreateExecutionMobileAction: InputCreateExecutionMobileAction;
  InputCreateExecutionMobileInfoMessage: InputCreateExecutionMobileInfoMessage;
  InputCreatePatientActionDuration: InputCreatePatientActionDuration;
  InputCreateScheduleDay: InputCreateScheduleDay;
  InputCreateScheduleEvery: InputCreateScheduleEvery;
  InputEffectiveSchedule: InputEffectiveSchedule;
  InputSplitMissions: InputSplitMissions;
  InputUpdateExecutionMobileAction: InputUpdateExecutionMobileAction;
  InputUpdateScheduleDay: InputUpdateScheduleDay;
  InputUpdateScheduleEvery: InputUpdateScheduleEvery;
  Int: Scalars['Int']['output'];
  Json: Scalars['Json']['output'];
  LineGeometry: LineGeometry;
  MedifoxStatusAbsence: MedifoxStatusAbsence;
  Member: Member;
  MemberCreateInput: MemberCreateInput;
  MemberQualification: MemberQualification;
  MemberQualificationType: MemberQualificationType;
  MemberUpdateInput: MemberUpdateInput;
  MembersFilter: MembersFilter;
  Mission: Mission;
  MissionAnalytic: MissionAnalytic;
  MissionCreateBulkInput: MissionCreateBulkInput;
  MissionFilter: MissionFilter;
  MissionIdNoAction: MissionIdNoAction;
  MissionInput: MissionInput;
  MissionLowPerforming: MissionLowPerforming;
  MissionOptimize: MissionOptimize;
  MissionOptimizeResult: MissionOptimizeResult;
  MissionPublication: MissionPublication;
  MissionPublicationFilter: MissionPublicationFilter;
  MissionPublicationStatus: MissionPublicationStatus;
  MissionReturnBulkUpdate: MissionReturnBulkUpdate;
  MissionUpdateBulkInput: MissionUpdateBulkInput;
  MissionUpdateBulkInput1: MissionUpdateBulkInput1;
  MissionWithActionExecuted: MissionWithActionExecuted;
  MissionWithPauschale: MissionWithPauschale;
  MissionsByIdsFilter: MissionsByIdsFilter;
  MissionsWithPauschaleByIdsInput: MissionsWithPauschaleByIdsInput;
  MissionsWithPauschaleInput: MissionsWithPauschaleInput;
  MissonChange: MissonChange;
  MobileTask: MobileTask;
  MostRecentMissionPublicationFilter: MostRecentMissionPublicationFilter;
  MostRecentSyncInfo: MostRecentSyncInfo;
  Mutation: {};
  NewAssignedActionSchedule: NewAssignedActionSchedule;
  NewSchedule: NewSchedule;
  OperationalChanges: OperationalChanges;
  PActionWithQuantity: PActionWithQuantity;
  Patient: Patient;
  PatientAbsence: PatientAbsence;
  PatientActionDuration: PatientActionDuration;
  PatientCareInterval: PatientCareInterval;
  PatientCreateInput: PatientCreateInput;
  PatientDay: PatientDay;
  PatientDenyGender: PatientDenyGender;
  PatientInfoMessage: PatientInfoMessage;
  PatientInfoMessageInput: PatientInfoMessageInput;
  PatientInfoMessageResponse: PatientInfoMessageResponse;
  PatientInfoMessageResponseInput: PatientInfoMessageResponseInput;
  PatientInfoMessageUpdateInput: PatientInfoMessageUpdateInput;
  PatientNoAction: PatientNoAction;
  PatientPerformanceAnalyzis: PatientPerformanceAnalyzis;
  PatientScheduleException: PatientScheduleException;
  PatientTravelInput: PatientTravelInput;
  PatientTravelXXX: PatientTravelXxx;
  PatientUpdateInput: PatientUpdateInput;
  PatientUrl: PatientUrl;
  PatientsFilter: PatientsFilter;
  Point: Point;
  PointGeometry: PointGeometry;
  PublicationDayInput: PublicationDayInput;
  Query: {};
  ResultMissionUpdateMany: ResultMissionUpdateMany;
  Schedule: Schedule;
  ScheduleActivePatientInput: ScheduleActivePatientInput;
  ScheduleByDay: ScheduleByDay;
  ScheduleCreateBulkInput: ScheduleCreateBulkInput;
  ScheduleCreateInput: ScheduleCreateInput;
  ScheduleDay: ScheduleDay;
  ScheduleEvery: ScheduleEvery;
  ScheduleFilter: ScheduleFilter;
  ScheduleUpdateInput: ScheduleUpdateInput;
  Shift: Shift;
  SingleDistance: SingleDistance;
  StatisticData: StatisticData;
  StatisticInput: StatisticInput;
  String: Scalars['String']['output'];
  SupabaseMember: SupabaseMember;
  SyncMobile: SyncMobile;
  Tour: Tour;
  TourAutoroutingInput: TourAutoroutingInput;
  TourData: TourData;
  TourFilter: TourFilter;
  TourInfoMessage: TourInfoMessage;
  TourInfoMessageResponse: TourInfoMessageResponse;
  TourPause: TourPause;
  TourReviewStatus: TourReviewStatus;
  TourStatExecution: TourStatExecution;
  TourStatMission: TourStatMission;
  UpdatePatientAbsenceInput: UpdatePatientAbsenceInput;
  UpdatePatientScheduleExceptionInput: UpdatePatientScheduleExceptionInput;
  Weekday: Weekday;
  WeekdayCreateInput: WeekdayCreateInput;
  inputBulkOperationExecutionOfMission: InputBulkOperationExecutionOfMission;
  patientInfoState: PatientInfoState;
};

export type A_EinsatzResolvers<ContextType = any, ParentType extends ResolversParentTypes['A_Einsatz'] = ResolversParentTypes['A_Einsatz']> = {
  Bis?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Dauer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ENR?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  KDNR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Klasse?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Schicht?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  Schluessel_notwendig?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Von?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  WTNR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type A_SollLeistungResolvers<ContextType = any, ParentType extends ResolversParentTypes['A_SollLeistung'] = ResolversParentTypes['A_SollLeistung']> = {
  ENR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  LNR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SLNR?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Action'] = ResolversParentTypes['Action']> = {
  examinationRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  executable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leistungsart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungsinhalte?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungskomplex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobileDisplayGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredQualificationLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionCashValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionCashValue'] = ResolversParentTypes['ActionCashValue']> = {
  cashflowValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionTimeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionTime'] = ResolversParentTypes['ActionTime']> = {
  actions?: Resolver<Maybe<Array<ResolversTypes['Action']>>, ParentType, ContextType>;
  combinedLnrs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  time_sec?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionWithCashResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionWithCash'] = ResolversParentTypes['ActionWithCash']> = {
  actionLnrs?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  calculatedDuration_min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cashflowValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  complexedLnrs?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  valuableLnrs?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionWithQuantityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionWithQuantity'] = ResolversParentTypes['ActionWithQuantity']> = {
  action?: Resolver<ResolversTypes['Action'], ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  examinationRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  executable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastDateOfMission?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  leistungsart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungskomplex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActionWithQuantityScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionWithQuantitySchedule'] = ResolversParentTypes['ActionWithQuantitySchedule']> = {
  examinationRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  leistungsart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungsinhalte?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungskomplex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedActionMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignedActionMission'] = ResolversParentTypes['AssignedActionMission']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedActionMissionExecutedResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignedActionMissionExecuted'] = ResolversParentTypes['AssignedActionMissionExecuted']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignedActionScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignedActionSchedule'] = ResolversParentTypes['AssignedActionSchedule']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CashFlowMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CashFlowMission'] = ResolversParentTypes['CashFlowMission']> = {
  cashWithPauschale?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cashWithoutPauschale?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  durationDeviation_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  durationToBeWithPauschale_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  plannedCashPerMinuteWithPauschale?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  plannedPauschale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangeLogSyncMsqlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeLogSyncMSQL'] = ResolversParentTypes['ChangeLogSyncMSQL']> = {
  changeLogId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt_ISO?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoordinateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coordinate'] = ResolversParentTypes['Coordinate']> = {
  coordinate?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CriteriaPauschaleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CriteriaPauschale'] = ResolversParentTypes['CriteriaPauschale']> = {
  hasAtLeastOneGrundpflege?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasAtLeastOne_31_32_33?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSolitaer_3_to_33?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSolitaer_27_28_39_or_30?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  has_29_30?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFirstOfDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSecondOfDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CsvMission'] = ResolversParentTypes['CsvMission']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_5m?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelToNext_5m?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weekDayId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DayMobileResolvers<ContextType = any, ParentType extends ResolversParentTypes['DayMobile'] = ResolversParentTypes['DayMobile']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tasks?: Resolver<Array<ResolversTypes['MobileTask']>, ParentType, ContextType>;
  workEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DistrictResolvers<ContextType = any, ParentType extends ResolversParentTypes['District'] = ResolversParentTypes['District']> = {
  colorHex?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EffectiveActionScheduleDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['EffectiveActionScheduleDay'] = ResolversParentTypes['EffectiveActionScheduleDay']> = {
  actionLnrsCodedEffectiv?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionLnrsCodedSchedule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  effectiveActionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  exceptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasException?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeGroupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EffectiveScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['EffectiveSchedule'] = ResolversParentTypes['EffectiveSchedule']> = {
  allCodedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dayId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maybeCodedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedMaxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedMinStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  scheduleIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExcelMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExcelMission'] = ResolversParentTypes['ExcelMission']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dauer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dayId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  einsatzDatum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  einsatzUhrzeit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endZeitpunkt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  excelENR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mitarbeiter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mitarbeiterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mitarbeiterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rowIndex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startZeitpunkt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uniqueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExcelMissionPublicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExcelMissionPublication'] = ResolversParentTypes['ExcelMissionPublication']> = {
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExecutionMobileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExecutionMobile'] = ResolversParentTypes['ExecutionMobile']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  codedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endMinuteOfDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endTS_iso?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  infoMessage?: Resolver<Maybe<ResolversTypes['ExecutionMobileInfoMessage']>, ParentType, ContextType>;
  isInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceDisplay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startMinuteOfDay?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startTS_iso?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  waitTimeToOpen?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExecutionMobileActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExecutionMobileAction'] = ResolversParentTypes['ExecutionMobileAction']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  codedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  executionMobileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExecutionMobileInfoMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExecutionMobileInfoMessage'] = ResolversParentTypes['ExecutionMobileInfoMessage']> = {
  actionRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  executionMobileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExecutionMobileTourStatResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExecutionMobileTourStat'] = ResolversParentTypes['ExecutionMobileTourStat']> = {
  countFailedExecutions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countMissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countMissionsNoExecutions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countSuccessExecutions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ratioDurationPlan_Execution?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalDeviantionPlan_execution_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalExecutionDurationFailed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalExecutionDurationSuccess?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMissionDurationPlan?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTourDurationExecution_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTourDurationPlan_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FakturaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Faktura'] = ResolversParentTypes['Faktura']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  behandlungAdditionalActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  behandlungMaxActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endTS_iso?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  executedActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  generatedFrom?: Resolver<ResolversTypes['FakturaSource'], ParentType, ContextType>;
  grundActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pauschalenActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTS_iso?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FakturaStatus'], ParentType, ContextType>;
  timedActionCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weekday?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileInBucketResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileInBucket'] = ResolversParentTypes['FileInBucket']> = {
  buildIsoDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dir?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  etag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ext?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastModified?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  root?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FromExcelMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FromExcelMission'] = ResolversParentTypes['FromExcelMission']> = {
  actionLnrsCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gender'] = ResolversParentTypes['Gender']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  genderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoPatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoPatient'] = ResolversParentTypes['GeoPatient']> = {
  features?: Resolver<Maybe<ResolversTypes['GeoPatientsData']>, ParentType, ContextType>;
  geometry?: Resolver<Maybe<ResolversTypes['PointGeometry']>, ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['GeoPatientProperties'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoPatientFeatureCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoPatientFeatureCollection'] = ResolversParentTypes['GeoPatientFeatureCollection']> = {
  features?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeoPatient']>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoPatientPropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoPatientProperties'] = ResolversParentTypes['GeoPatientProperties']> = {
  colorHex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoPatientsDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoPatientsData'] = ResolversParentTypes['GeoPatientsData']> = {
  geometry?: Resolver<Maybe<ResolversTypes['Point']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTour'] = ResolversParentTypes['GeoTour']> = {
  geometry?: Resolver<Maybe<ResolversTypes['PointGeometry']>, ParentType, ContextType>;
  properties?: Resolver<ResolversTypes['GeoTourProperties'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourArcLayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourArcLayer'] = ResolversParentTypes['GeoTourArcLayer']> = {
  elements?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeoTourArcLayerElement']>>>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourArcLayerDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourArcLayerData'] = ResolversParentTypes['GeoTourArcLayerData']> = {
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeoTourArcLayerElement']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourArcLayerElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourArcLayerElement'] = ResolversParentTypes['GeoTourArcLayerElement']> = {
  duration_from?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['GeoTourArcLayerElementProp']>, ParentType, ContextType>;
  inbound?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outbound?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['GeoTourArcLayerElementProp']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourArcLayerElementPropResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourArcLayerElementProp'] = ResolversParentTypes['GeoTourArcLayerElementProp']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTS?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourFeatureCollectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourFeatureCollection'] = ResolversParentTypes['GeoTourFeatureCollection']> = {
  features?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeoTour']>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourLineStringResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourLineString'] = ResolversParentTypes['GeoTourLineString']> = {
  geometry?: Resolver<Maybe<ResolversTypes['LineGeometry']>, ParentType, ContextType>;
  properties?: Resolver<Maybe<ResolversTypes['GeoTourProperties']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTourPropertiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeoTourProperties'] = ResolversParentTypes['GeoTourProperties']> = {
  index?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Json'], any> {
  name: 'Json';
}

export type LineGeometryResolvers<ContextType = any, ParentType extends ResolversParentTypes['LineGeometry'] = ResolversParentTypes['LineGeometry']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<Array<Maybe<ResolversTypes['Float']>>>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MedifoxStatusAbsenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MedifoxStatusAbsence'] = ResolversParentTypes['MedifoxStatusAbsence']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAbsence?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInBetreuung?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['Member'] = ResolversParentTypes['Member']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasExamination?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasQualificationLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qualification?: Resolver<Maybe<ResolversTypes['MemberQualification']>, ParentType, ContextType>;
  qualifications?: Resolver<Array<ResolversTypes['MemberQualification']>, ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberQualificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MemberQualification'] = ResolversParentTypes['MemberQualification']> = {
  allowedAdditionalActions?: Resolver<Array<ResolversTypes['Action']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberQualificationType?: Resolver<Maybe<ResolversTypes['MemberQualificationType']>, ParentType, ContextType>;
  qualificationTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFromDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validToDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MemberQualificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MemberQualificationType'] = ResolversParentTypes['MemberQualificationType']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qualificationLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qualificationTypeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mission'] = ResolversParentTypes['Mission']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssignedActionMission']>>>, ParentType, ContextType>;
  cash?: Resolver<Maybe<ResolversTypes['ActionWithCash']>, ParentType, ContextType>;
  changes?: Resolver<Maybe<Array<ResolversTypes['OperationalChanges']>>, ParentType, ContextType>;
  codedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  connectedMission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  editAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endMinuteOfDay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTS?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  excelENR?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  executionMobiles?: Resolver<Array<ResolversTypes['ExecutionMobile']>, ParentType, ContextType>;
  hasAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasConflictAbsence?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasGrundPflege?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isChecked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxDuration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberHasQualificationLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberQuallificationCheck?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minReqQualification?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missionAnalytic?: Resolver<Maybe<ResolversTypes['MissionAnalytic']>, ParentType, ContextType>;
  missionPublicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startMinuteOfDay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startTS?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start_ISO?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionAnalyticResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionAnalytic'] = ResolversParentTypes['MissionAnalytic']> = {
  allActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allActions_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  behandlungAdditionalCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  behandlungAdditionalElements?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  behandlungAdditionalElements_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  behandlungMaxCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  behandlungMaxElements?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  behandlungMaxElements_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cashPerMinute?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  cashPerMinute_NOSAFE?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  complexedGrundElements?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  complexedGrundElements_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  durationCorrection_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  durationDeviation_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expectedDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expectedDurationCalc_NOSAFE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expectedDuration_NOSAFE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grundCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maxBehandlungsGruppe?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pauschaleHausCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pauschaleHausType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pauschaleSaveCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pauschaleSaveType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timedCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  timedElements?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timedElements_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCashValue_NOSAFE?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPauschaleCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPauschaleElements?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionIdNoActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionIdNoAction'] = ResolversParentTypes['MissionIdNoAction']> = {
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionLowPerformingResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionLowPerforming'] = ResolversParentTypes['MissionLowPerforming']> = {
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionOptimizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionOptimize'] = ResolversParentTypes['MissionOptimize']> = {
  calculatedDuration_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionPublicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plannedDuration_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionOptimizeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionOptimizeResult'] = ResolversParentTypes['MissionOptimizeResult']> = {
  calculatedTravelDistance_m?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  calculatedTravelTime_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deviationTravelTime_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['MissionOptimize']>, ParentType, ContextType>;
  fromPatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  plannedTravelTime_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['MissionOptimize']>, ParentType, ContextType>;
  toPatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  travelSpeed_kmh?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionPublicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionPublication'] = ResolversParentTypes['MissionPublication']> = {
  countExecutions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countMissionsTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countMissionsWithActions?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt_ISO?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  missions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType>;
  nextStatus_s?: Resolver<Array<ResolversTypes['MissionPublicationStatus']>, ParentType, ContextType>;
  releaseStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  releaseStatusName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionPublicationStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionPublicationStatus'] = ResolversParentTypes['MissionPublicationStatus']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  releaseStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  releaseStatusName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionReturnBulkUpdateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionReturnBulkUpdate'] = ResolversParentTypes['MissionReturnBulkUpdate']> = {
  endTS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  memberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionWithActionExecutedResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionWithActionExecuted'] = ResolversParentTypes['MissionWithActionExecuted']> = {
  actionsWithQuantityExecuted?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssignedActionMissionExecuted']>>>, ParentType, ContextType>;
  fromServer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissionWithPauschaleResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissionWithPauschale'] = ResolversParentTypes['MissionWithPauschale']> = {
  actionLnrs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  criteriaPauschale?: Resolver<Maybe<ResolversTypes['CriteriaPauschale']>, ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pauschale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reasonPauschale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valuePauschale?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissonChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MissonChange'] = ResolversParentTypes['MissonChange']> = {
  actionChanged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  added?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canceled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oldTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeChanged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MobileTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobileTask'] = ResolversParentTypes['MobileTask']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  actions_coded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MostRecentSyncInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['MostRecentSyncInfo'] = ResolversParentTypes['MostRecentSyncInfo']> = {
  einsatzFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leistungFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medifoxBetreuungszeitFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medifoxPatientFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  medifoxUnterbrechungszeitFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addMissionToDay?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationAddMissionToDayArgs, 'input'>>;
  addMissionToPublicationId?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationAddMissionToPublicationIdArgs, 'input'>>;
  closePatientInfoMessage?: Resolver<ResolversTypes['PatientInfoMessage'], ParentType, ContextType, RequireFields<MutationClosePatientInfoMessageArgs, 'id'>>;
  createActionTime?: Resolver<ResolversTypes['ActionTime'], ParentType, ContextType, RequireFields<MutationCreateActionTimeArgs, 'combinedLnrs' | 'time_sec'>>;
  createExecutionMobileAction?: Resolver<Maybe<ResolversTypes['ExecutionMobileAction']>, ParentType, ContextType, RequireFields<MutationCreateExecutionMobileActionArgs, 'data'>>;
  createExecutionMobileInfoMessage?: Resolver<Maybe<ResolversTypes['ExecutionMobileInfoMessage']>, ParentType, ContextType, RequireFields<MutationCreateExecutionMobileInfoMessageArgs, 'input'>>;
  createManyExcelMissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExcelMission']>>>, ParentType, ContextType, RequireFields<MutationCreateManyExcelMissionsArgs, 'fileChangeTime' | 'filePath' | 'input'>>;
  createManyExecutionOfMission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<MutationCreateManyExecutionOfMissionArgs, 'input'>>;
  createManyMissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<MutationCreateManyMissionsArgs, 'input'>>;
  createManySchedules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationCreateManySchedulesArgs, 'input'>>;
  createOneA_Einsatz?: Resolver<Maybe<ResolversTypes['A_Einsatz']>, ParentType, ContextType, RequireFields<MutationCreateOneA_EinsatzArgs, 'input'>>;
  createOneA_SollLeistung?: Resolver<Maybe<ResolversTypes['A_SollLeistung']>, ParentType, ContextType, RequireFields<MutationCreateOneA_SollLeistungArgs, 'input'>>;
  createOneAction?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType, RequireFields<MutationCreateOneActionArgs, 'data'>>;
  createOneChangeLog?: Resolver<Maybe<ResolversTypes['ChangeLogSyncMSQL']>, ParentType, ContextType, RequireFields<MutationCreateOneChangeLogArgs, 'input'>>;
  createOneMember?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType, RequireFields<MutationCreateOneMemberArgs, 'input'>>;
  createOnePatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType, RequireFields<MutationCreateOnePatientArgs, 'input'>>;
  createOneSchedule?: Resolver<ResolversTypes['Schedule'], ParentType, ContextType, RequireFields<MutationCreateOneScheduleArgs, 'input'>>;
  createOneWeekday?: Resolver<ResolversTypes['Weekday'], ParentType, ContextType, RequireFields<MutationCreateOneWeekdayArgs, 'data'>>;
  createOrUpdateExecutionMobile?: Resolver<ResolversTypes['ExecutionMobile'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateExecutionMobileArgs, 'data'>>;
  createOrUpdateTourReviewStatus?: Resolver<ResolversTypes['TourReviewStatus'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateTourReviewStatusArgs, 'day' | 'status' | 'tourId'>>;
  createPatientAbsence?: Resolver<ResolversTypes['PatientAbsence'], ParentType, ContextType, RequireFields<MutationCreatePatientAbsenceArgs, 'input'>>;
  createPatientActionDuration?: Resolver<ResolversTypes['PatientActionDuration'], ParentType, ContextType, RequireFields<MutationCreatePatientActionDurationArgs, 'input'>>;
  createPatientDenyGender?: Resolver<ResolversTypes['PatientDenyGender'], ParentType, ContextType, RequireFields<MutationCreatePatientDenyGenderArgs, 'actionLnr' | 'genderId' | 'patientId'>>;
  createPatientInfoMessage?: Resolver<ResolversTypes['PatientInfoMessage'], ParentType, ContextType, RequireFields<MutationCreatePatientInfoMessageArgs, 'input'>>;
  createPatientInfoMessageResponse?: Resolver<ResolversTypes['PatientInfoMessageResponse'], ParentType, ContextType, RequireFields<MutationCreatePatientInfoMessageResponseArgs, 'input'>>;
  createPatientScheduleException?: Resolver<ResolversTypes['PatientScheduleException'], ParentType, ContextType, RequireFields<MutationCreatePatientScheduleExceptionArgs, 'input'>>;
  createPatientUrl?: Resolver<ResolversTypes['PatientUrl'], ParentType, ContextType, RequireFields<MutationCreatePatientUrlArgs, 'patientId'>>;
  createScheduleDay?: Resolver<Maybe<ResolversTypes['ScheduleDay']>, ParentType, ContextType, RequireFields<MutationCreateScheduleDayArgs, 'input'>>;
  createScheduleDayFromLegacy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCreateScheduleDayFromLegacyArgs, 'patientId'>>;
  createScheduleEvery?: Resolver<ResolversTypes['ScheduleEvery'], ParentType, ContextType, RequireFields<MutationCreateScheduleEveryArgs, 'input'>>;
  createSupabaseMember?: Resolver<Maybe<ResolversTypes['SupabaseMember']>, ParentType, ContextType, RequireFields<MutationCreateSupabaseMemberArgs, 'email' | 'firstName' | 'lastName' | 'memberId'>>;
  createTourInfoMessage?: Resolver<ResolversTypes['TourInfoMessage'], ParentType, ContextType, RequireFields<MutationCreateTourInfoMessageArgs, 'day' | 'message' | 'tourId'>>;
  deleteActionTime?: Resolver<ResolversTypes['ActionTime'], ParentType, ContextType, RequireFields<MutationDeleteActionTimeArgs, 'id'>>;
  deleteAllSchedules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deleteExecutionMobile?: Resolver<ResolversTypes['ExecutionMobile'], ParentType, ContextType, RequireFields<MutationDeleteExecutionMobileArgs, 'id'>>;
  deleteManyExecutionOfMission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<MutationDeleteManyExecutionOfMissionArgs, 'input'>>;
  deleteMemberQualification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteMemberQualificationArgs, 'id'>>;
  deleteOneA_Einsatz?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteOneA_EinsatzArgs, 'input'>>;
  deleteOneA_SollLeistung?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteOneA_SollLeistungArgs, 'input'>>;
  deleteOneSchedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<MutationDeleteOneScheduleArgs, 'id'>>;
  deleteOneTourPause?: Resolver<ResolversTypes['TourPause'], ParentType, ContextType, RequireFields<MutationDeleteOneTourPauseArgs, 'id'>>;
  deletePatientAbsence?: Resolver<ResolversTypes['PatientAbsence'], ParentType, ContextType, RequireFields<MutationDeletePatientAbsenceArgs, 'id'>>;
  deletePatientActionDuration?: Resolver<ResolversTypes['PatientActionDuration'], ParentType, ContextType, RequireFields<MutationDeletePatientActionDurationArgs, 'id'>>;
  deletePatientScheduleException?: Resolver<ResolversTypes['PatientScheduleException'], ParentType, ContextType, RequireFields<MutationDeletePatientScheduleExceptionArgs, 'id'>>;
  deletePatientUrl?: Resolver<Maybe<ResolversTypes['PatientUrl']>, ParentType, ContextType, RequireFields<MutationDeletePatientUrlArgs, 'patientId'>>;
  deleteScheduleDay?: Resolver<Maybe<ResolversTypes['ScheduleDay']>, ParentType, ContextType, RequireFields<MutationDeleteScheduleDayArgs, 'id'>>;
  deleteScheduleEvery?: Resolver<Maybe<ResolversTypes['ScheduleEvery']>, ParentType, ContextType, RequireFields<MutationDeleteScheduleEveryArgs, 'id'>>;
  export2Access?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationExport2AccessArgs, 'endDay' | 'startDay'>>;
  generateActionTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  generateAssignTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  generateCashflowTable?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationGenerateCashflowTableArgs, 'missionPublicationId'>>;
  generateCashflowTablesByTimeRange?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationGenerateCashflowTablesByTimeRangeArgs, 'endDate' | 'startDate'>>;
  generateCashflowTablesByTimeRange_NEU?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationGenerateCashflowTablesByTimeRange_NeuArgs, 'endDate' | 'startDate'>>;
  inactivateMissionPublication?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<MutationInactivateMissionPublicationArgs, 'id'>>;
  migrateDates?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  missionSetAction?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationMissionSetActionArgs, 'actionIdsWQ' | 'id' | 'updatedAt'>>;
  missionSetIsActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationMissionSetIsActiveArgs, 'id' | 'isActive' | 'updatedAt'>>;
  moveFileToProcessed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationMoveFileToProcessedArgs, 'fullname'>>;
  patientSetDistrict?: Resolver<ResolversTypes['Patient'], ParentType, ContextType, RequireFields<MutationPatientSetDistrictArgs, 'districtId' | 'patientId'>>;
  purgeMissionPublication?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPurgeMissionPublicationArgs, 'missionPublicationId'>>;
  removeAllRetention?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  schedules2ExcelMission?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  setActivePatients?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationSetActivePatientsArgs, 'input'>>;
  setCheckStatusMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationSetCheckStatusMissionsArgs, 'checkStatus' | 'missionIds'>>;
  setMemberQualification?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType, RequireFields<MutationSetMemberQualificationArgs, 'memberId' | 'qualificationTypeId' | 'validFrom'>>;
  setMemberQualificationAllowedActions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetMemberQualificationAllowedActionsArgs, 'actionLnrs' | 'id'>>;
  setMemberQualificationValidToDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetMemberQualificationValidToDayArgs, 'id' | 'validToDay'>>;
  setMissionPublicationCancel?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<MutationSetMissionPublicationCancelArgs, 'missionPublicationId'>>;
  setMissionPublicationDraft?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<MutationSetMissionPublicationDraftArgs, 'missionPublicationId'>>;
  setMissionPublicationRework?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<MutationSetMissionPublicationReworkArgs, 'missionPublicationId'>>;
  setMissionPublicationStatus?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<MutationSetMissionPublicationStatusArgs, 'missionPublicationId' | 'status'>>;
  setMostRecentMissionPublicationDraft?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType>;
  setPatientDenyGenderActions?: Resolver<Array<ResolversTypes['PatientDenyGender']>, ParentType, ContextType, RequireFields<MutationSetPatientDenyGenderActionsArgs, 'actionLnrs' | 'genderId' | 'patientId'>>;
  setRetention?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  setValidationOneExecutionMobile?: Resolver<ResolversTypes['ExecutionMobile'], ParentType, ContextType, RequireFields<MutationSetValidationOneExecutionMobileArgs, 'id' | 'isInvalid'>>;
  smartUpdateCashFlowMisson?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  smartUpdateMissionAnalytic?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<MutationSmartUpdateMissionAnalyticArgs>>;
  splitMission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSplitMissionArgs, 'missionId' | 'updatedAt'>>;
  splitMissions?: Resolver<Maybe<Array<ResolversTypes['Mission']>>, ParentType, ContextType, RequireFields<MutationSplitMissionsArgs, 'input'>>;
  syncManyTourPauses?: Resolver<Maybe<ResolversTypes['TourPause']>, ParentType, ContextType, RequireFields<MutationSyncManyTourPausesArgs, 'input' | 'publicationDays' | 'tourIds'>>;
  updateAbsenceWithCsv?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updateActionTime?: Resolver<ResolversTypes['ActionTime'], ParentType, ContextType, RequireFields<MutationUpdateActionTimeArgs, 'combinedLnrs' | 'id' | 'time_sec'>>;
  updateExecutionMobileAction?: Resolver<Maybe<ResolversTypes['ExecutionMobileAction']>, ParentType, ContextType, RequireFields<MutationUpdateExecutionMobileActionArgs, 'data' | 'id'>>;
  updateManyMissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<MutationUpdateManyMissionsArgs, 'input'>>;
  updateManyMissionsEnhanced?: Resolver<Maybe<ResolversTypes['ResultMissionUpdateMany']>, ParentType, ContextType, RequireFields<MutationUpdateManyMissionsEnhancedArgs, 'input'>>;
  updateMedifoxPatientsWithCsv?: Resolver<ResolversTypes['String'], ParentType, ContextType, Partial<MutationUpdateMedifoxPatientsWithCsvArgs>>;
  updateMembersWithCsv?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateMissionSupabase?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationUpdateMissionSupabaseArgs, 'day' | 'tourIds'>>;
  updateOneA_Einsatz?: Resolver<Maybe<ResolversTypes['A_Einsatz']>, ParentType, ContextType, RequireFields<MutationUpdateOneA_EinsatzArgs, 'input'>>;
  updateOneA_SollLeistung?: Resolver<Maybe<ResolversTypes['A_SollLeistung']>, ParentType, ContextType, RequireFields<MutationUpdateOneA_SollLeistungArgs, 'input'>>;
  updateOneAction?: Resolver<ResolversTypes['Action'], ParentType, ContextType, RequireFields<MutationUpdateOneActionArgs, 'data'>>;
  updateOneMember?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType, RequireFields<MutationUpdateOneMemberArgs, 'input' | 'memberId'>>;
  updateOneMission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<MutationUpdateOneMissionArgs, 'id' | 'updatedAt'>>;
  updateOnePatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType, RequireFields<MutationUpdateOnePatientArgs, 'input' | 'patientId'>>;
  updateOneSchedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<MutationUpdateOneScheduleArgs, 'id' | 'input'>>;
  updatePatientAbsence?: Resolver<ResolversTypes['PatientAbsence'], ParentType, ContextType, RequireFields<MutationUpdatePatientAbsenceArgs, 'id' | 'input'>>;
  updatePatientActionDuration?: Resolver<ResolversTypes['PatientActionDuration'], ParentType, ContextType, RequireFields<MutationUpdatePatientActionDurationArgs, 'id' | 'input'>>;
  updatePatientGeo?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType, RequireFields<MutationUpdatePatientGeoArgs, 'geoString' | 'patientId'>>;
  updatePatientInfoMessage?: Resolver<ResolversTypes['PatientInfoMessage'], ParentType, ContextType, RequireFields<MutationUpdatePatientInfoMessageArgs, 'id' | 'input'>>;
  updatePatientScheduleException?: Resolver<ResolversTypes['PatientScheduleException'], ParentType, ContextType, RequireFields<MutationUpdatePatientScheduleExceptionArgs, 'id' | 'input'>>;
  updatePatientUrl?: Resolver<ResolversTypes['PatientUrl'], ParentType, ContextType, RequireFields<MutationUpdatePatientUrlArgs, 'patientId'>>;
  updatePatientsWithCsv?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateScheduleDay?: Resolver<Maybe<ResolversTypes['ScheduleDay']>, ParentType, ContextType, RequireFields<MutationUpdateScheduleDayArgs, 'id' | 'input'>>;
  updateScheduleEvery?: Resolver<ResolversTypes['ScheduleEvery'], ParentType, ContextType, RequireFields<MutationUpdateScheduleEveryArgs, 'id' | 'input'>>;
  uploadFile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  upsertBulkAssigendActionMissionExecuted?: Resolver<Maybe<Array<Maybe<ResolversTypes['AssignedActionMissionExecuted']>>>, ParentType, ContextType, RequireFields<MutationUpsertBulkAssigendActionMissionExecutedArgs, 'input'>>;
  upsertFaktura?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpsertFakturaArgs, 'day'>>;
  upsertFakturaRange?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpsertFakturaRangeArgs, 'maxDay' | 'minDay'>>;
  upsertOneTourPause?: Resolver<Maybe<ResolversTypes['TourPause']>, ParentType, ContextType, RequireFields<MutationUpsertOneTourPauseArgs, 'input'>>;
  upsertSupabaseExecutionMobile?: Resolver<Array<ResolversTypes['ExecutionMobile']>, ParentType, ContextType, RequireFields<MutationUpsertSupabaseExecutionMobileArgs, 'day'>>;
  writeAccessFiles?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type NewAssignedActionScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewAssignedActionSchedule'] = ResolversParentTypes['NewAssignedActionSchedule']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewSchedule'] = ResolversParentTypes['NewSchedule']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['NewAssignedActionSchedule']>>, ParentType, ContextType>;
  cash?: Resolver<Maybe<ResolversTypes['ActionWithCash']>, ParentType, ContextType>;
  days?: Resolver<Maybe<Array<ResolversTypes['Weekday']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plannedDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationalChangesResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationalChanges'] = ResolversParentTypes['OperationalChanges']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mutationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  newData?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  oldData?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  referenceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PActionWithQuantityResolvers<ContextType = any, ParentType extends ResolversParentTypes['PActionWithQuantity'] = ResolversParentTypes['PActionWithQuantity']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Patient'] = ResolversParentTypes['Patient']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorBg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorTxt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['District']>, ParentType, ContextType>;
  districtId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  genderDeny?: Resolver<Maybe<Array<ResolversTypes['PatientDenyGender']>>, ParentType, ContextType>;
  hasGenderDeny?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasOpenInfoMessage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSchedule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDa?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isInBetreuung?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isIncare?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kkId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kkName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kkType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lat_long?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  medifoxPatientInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientAbsenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientAbsence'] = ResolversParentTypes['PatientAbsence']> = {
  endDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientActionDurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientActionDuration'] = ResolversParentTypes['PatientActionDuration']> = {
  actionQuantityCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionsWithQuantity?: Resolver<Array<ResolversTypes['ActionWithQuantity']>, ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isEffective?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientCareIntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientCareInterval'] = ResolversParentTypes['PatientCareInterval']> = {
  endDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientDay'] = ResolversParentTypes['PatientDay']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromExcelMissions?: Resolver<Array<ResolversTypes['FromExcelMission']>, ParentType, ContextType>;
  isAbsent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  missionPublication?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schedules?: Resolver<Array<ResolversTypes['ScheduleByDay']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientDenyGenderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientDenyGender'] = ResolversParentTypes['PatientDenyGender']> = {
  action?: Resolver<Maybe<ResolversTypes['Action']>, ParentType, ContextType>;
  actionLnr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  genderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientInfoMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientInfoMessage'] = ResolversParentTypes['PatientInfoMessage']> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMatchingAbsence?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientInfoStatus?: Resolver<ResolversTypes['patientInfoState'], ParentType, ContextType>;
  responses?: Resolver<Array<ResolversTypes['PatientInfoMessageResponse']>, ParentType, ContextType>;
  startDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientInfoMessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientInfoMessageResponse'] = ResolversParentTypes['PatientInfoMessageResponse']> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientNoActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientNoAction'] = ResolversParentTypes['PatientNoAction']> = {
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientPerformanceAnalyzisResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientPerformanceAnalyzis'] = ResolversParentTypes['PatientPerformanceAnalyzis']> = {
  avgCashPerMinute?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  avgCashPerMinute_NOSAVE?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countAllMissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countNoAction?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countOverPerf?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countUnderPerf?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  noActionMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType>;
  overPerformanceMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sumCashValue_NOSAVE?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sumTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sumTotalCashValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCompensationOverPerf_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCompensationUnderPerf_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalOverPerf_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnderPerf_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  underPerformanceMissions?: Resolver<Array<ResolversTypes['Mission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientScheduleExceptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientScheduleException'] = ResolversParentTypes['PatientScheduleException']> = {
  allCodedActions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['Patient'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDay?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientTravelXxxResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientTravelXXX'] = ResolversParentTypes['PatientTravelXXX']> = {
  sourcePatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  sourcePatientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetPatient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  targetPatientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientUrl'] = ResolversParentTypes['PatientUrl']> = {
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  urlId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point']> = {
  coordinates?: Resolver<Array<Maybe<ResolversTypes['Float']>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointGeometryResolvers<ContextType = any, ParentType extends ResolversParentTypes['PointGeometry'] = ResolversParentTypes['PointGeometry']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  A_Einsaetze?: Resolver<Maybe<Array<Maybe<ResolversTypes['A_Einsatz']>>>, ParentType, ContextType>;
  A_SollLeistungen?: Resolver<Maybe<Array<Maybe<ResolversTypes['A_SollLeistung']>>>, ParentType, ContextType>;
  actionCashValue?: Resolver<Maybe<ResolversTypes['ActionCashValue']>, ParentType, ContextType, RequireFields<QueryActionCashValueArgs, 'lnr'>>;
  actionCashValues?: Resolver<Array<ResolversTypes['ActionCashValue']>, ParentType, ContextType>;
  actionTime?: Resolver<Maybe<ResolversTypes['ActionTime']>, ParentType, ContextType, RequireFields<QueryActionTimeArgs, 'id'>>;
  actionTimeDuration_min?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryActionTimeDuration_MinArgs, 'actionLnrs'>>;
  actionTimes?: Resolver<Array<ResolversTypes['ActionTime']>, ParentType, ContextType, RequireFields<QueryActionTimesArgs, 'actionLnrs'>>;
  actions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Action']>>>, ParentType, ContextType, Partial<QueryActionsArgs>>;
  activeMembers?: Resolver<Array<ResolversTypes['Member']>, ParentType, ContextType>;
  akLeistCsv?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allCurrentInBetreuungPatients?: Resolver<Array<ResolversTypes['Patient']>, ParentType, ContextType>;
  allCurrentPatientAbsences?: Resolver<Array<ResolversTypes['PatientAbsence']>, ParentType, ContextType>;
  allGenders?: Resolver<Array<ResolversTypes['Gender']>, ParentType, ContextType>;
  allMissionsByMemberPerRange?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<QueryAllMissionsByMemberPerRangeArgs, 'filter'>>;
  allPatientAbsences?: Resolver<Array<ResolversTypes['PatientAbsence']>, ParentType, ContextType>;
  allPatientScheduleExceptions?: Resolver<Array<ResolversTypes['PatientScheduleException']>, ParentType, ContextType>;
  cashActions?: Resolver<Maybe<ResolversTypes['ActionWithCash']>, ParentType, ContextType, RequireFields<QueryCashActionsArgs, 'input'>>;
  changeLogSyncMSQL?: Resolver<Maybe<ResolversTypes['ChangeLogSyncMSQL']>, ParentType, ContextType, RequireFields<QueryChangeLogSyncMsqlArgs, 'id'>>;
  changeLogSyncMSQLs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChangeLogSyncMSQL']>>>, ParentType, ContextType>;
  distinctMembersMobileMission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType>;
  districts?: Resolver<Array<ResolversTypes['District']>, ParentType, ContextType>;
  effectiveActionScheduleDay?: Resolver<Maybe<Array<ResolversTypes['EffectiveActionScheduleDay']>>, ParentType, ContextType, RequireFields<QueryEffectiveActionScheduleDayArgs, 'maxDay' | 'minDay' | 'patientId'>>;
  effectiveSchedules?: Resolver<Maybe<Array<ResolversTypes['EffectiveSchedule']>>, ParentType, ContextType, RequireFields<QueryEffectiveSchedulesArgs, 'input'>>;
  excelMission?: Resolver<Maybe<ResolversTypes['ExcelMission']>, ParentType, ContextType, RequireFields<QueryExcelMissionArgs, 'id'>>;
  excelMissionPublication?: Resolver<Maybe<ResolversTypes['ExcelMissionPublication']>, ParentType, ContextType, RequireFields<QueryExcelMissionPublicationArgs, 'id'>>;
  excelMissionPublications?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExcelMissionPublication']>>>, ParentType, ContextType>;
  executionMobile?: Resolver<ResolversTypes['ExecutionMobile'], ParentType, ContextType, RequireFields<QueryExecutionMobileArgs, 'id'>>;
  executionMobileAction?: Resolver<Maybe<ResolversTypes['ExecutionMobileAction']>, ParentType, ContextType, RequireFields<QueryExecutionMobileActionArgs, 'id'>>;
  executionMobileTourStat?: Resolver<Maybe<ResolversTypes['ExecutionMobileTourStat']>, ParentType, ContextType, RequireFields<QueryExecutionMobileTourStatArgs, 'day' | 'tourId'>>;
  executionMobiles?: Resolver<Array<ResolversTypes['ExecutionMobile']>, ParentType, ContextType, RequireFields<QueryExecutionMobilesArgs, 'day' | 'memberId'>>;
  fakturasByPatient?: Resolver<Array<ResolversTypes['Faktura']>, ParentType, ContextType, RequireFields<QueryFakturasByPatientArgs, 'maxDay' | 'minDay' | 'patientId'>>;
  geoPatients?: Resolver<Maybe<ResolversTypes['GeoPatientFeatureCollection']>, ParentType, ContextType, RequireFields<QueryGeoPatientsArgs, 'filter'>>;
  geoTourArcLayers?: Resolver<Maybe<Array<Maybe<ResolversTypes['GeoTourArcLayer']>>>, ParentType, ContextType, RequireFields<QueryGeoTourArcLayersArgs, 'day' | 'tourIds'>>;
  getMostRecentSyncFileNames?: Resolver<Maybe<ResolversTypes['MostRecentSyncInfo']>, ParentType, ContextType>;
  getTourenByFileName?: Resolver<Maybe<Array<ResolversTypes['PatientDay']>>, ParentType, ContextType, RequireFields<QueryGetTourenByFileNameArgs, 'fileName'>>;
  latestMissionWithAction?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<QueryLatestMissionWithActionArgs, 'patientId'>>;
  listBuckets?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  listFiles?: Resolver<Maybe<Array<ResolversTypes['FileInBucket']>>, ParentType, ContextType, RequireFields<QueryListFilesArgs, 'bucket'>>;
  medifoxStatusAbsences?: Resolver<Array<ResolversTypes['MedifoxStatusAbsence']>, ParentType, ContextType, RequireFields<QueryMedifoxStatusAbsencesArgs, 'maxDay' | 'minDay' | 'patientId'>>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType, RequireFields<QueryMemberArgs, 'memberId'>>;
  memberQualificationTypes?: Resolver<Array<ResolversTypes['MemberQualificationType']>, ParentType, ContextType>;
  memberQualifications?: Resolver<Array<ResolversTypes['MemberQualification']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType, Partial<QueryMembersArgs>>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType, RequireFields<QueryMissionArgs, 'id'>>;
  missionDistinctMemberIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryMissionDistinctMemberIdsArgs, 'filter'>>;
  missionDistinctMembers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Member']>>>, ParentType, ContextType, RequireFields<QueryMissionDistinctMembersArgs, 'filter'>>;
  missionPublication?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<QueryMissionPublicationArgs, 'id'>>;
  missionPublications?: Resolver<Maybe<Array<Maybe<ResolversTypes['MissionPublication']>>>, ParentType, ContextType, Partial<QueryMissionPublicationsArgs>>;
  missionWoActionsPatientIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryMissionWoActionsPatientIdsArgs, 'publicationId'>>;
  missions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<QueryMissionsArgs, 'filter'>>;
  missionsByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<QueryMissionsByIdsArgs, 'filter'>>;
  missionsTimeRange?: Resolver<Maybe<Array<ResolversTypes['Mission']>>, ParentType, ContextType, RequireFields<QueryMissionsTimeRangeArgs, 'filter'>>;
  missionsWithPauschale?: Resolver<Array<ResolversTypes['MissionWithPauschale']>, ParentType, ContextType, RequireFields<QueryMissionsWithPauschaleArgs, 'input'>>;
  missionsWithPauschaleByIds?: Resolver<Array<ResolversTypes['MissionWithPauschale']>, ParentType, ContextType, RequireFields<QueryMissionsWithPauschaleByIdsArgs, 'input'>>;
  mostRecentMissionPublications?: Resolver<Maybe<Array<Maybe<ResolversTypes['MissionPublication']>>>, ParentType, ContextType, RequireFields<QueryMostRecentMissionPublicationsArgs, 'filter'>>;
  nearByPatients?: Resolver<Array<ResolversTypes['Patient']>, ParentType, ContextType, RequireFields<QueryNearByPatientsArgs, 'minutes' | 'patientId'>>;
  newSchedules?: Resolver<Maybe<Array<Maybe<ResolversTypes['NewSchedule']>>>, ParentType, ContextType, RequireFields<QueryNewSchedulesArgs, 'filter'>>;
  opChange?: Resolver<Maybe<ResolversTypes['OperationalChanges']>, ParentType, ContextType, Partial<QueryOpChangeArgs>>;
  opChanges?: Resolver<Array<ResolversTypes['OperationalChanges']>, ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType, RequireFields<QueryPatientArgs, 'patientId'>>;
  patientAbsences?: Resolver<Array<ResolversTypes['PatientAbsence']>, ParentType, ContextType, RequireFields<QueryPatientAbsencesArgs, 'patientId'>>;
  patientActionDuration?: Resolver<Maybe<ResolversTypes['PatientActionDuration']>, ParentType, ContextType, RequireFields<QueryPatientActionDurationArgs, 'id'>>;
  patientActionDurations?: Resolver<Array<ResolversTypes['PatientActionDuration']>, ParentType, ContextType, Partial<QueryPatientActionDurationsArgs>>;
  patientCareIntervals?: Resolver<Array<ResolversTypes['PatientCareInterval']>, ParentType, ContextType, RequireFields<QueryPatientCareIntervalsArgs, 'patientId'>>;
  patientDenyGenders?: Resolver<Array<ResolversTypes['PatientDenyGender']>, ParentType, ContextType, RequireFields<QueryPatientDenyGendersArgs, 'patientId'>>;
  patientInfoMessages?: Resolver<Array<ResolversTypes['PatientInfoMessage']>, ParentType, ContextType, Partial<QueryPatientInfoMessagesArgs>>;
  patientScheduleExceptions?: Resolver<Array<ResolversTypes['PatientScheduleException']>, ParentType, ContextType, RequireFields<QueryPatientScheduleExceptionsArgs, 'patientId'>>;
  patientUrl?: Resolver<Maybe<ResolversTypes['PatientUrl']>, ParentType, ContextType, RequireFields<QueryPatientUrlArgs, 'patientId'>>;
  patients?: Resolver<Maybe<Array<Maybe<ResolversTypes['Patient']>>>, ParentType, ContextType, Partial<QueryPatientsArgs>>;
  patientsForReview?: Resolver<Maybe<Array<Maybe<ResolversTypes['Patient']>>>, ParentType, ContextType, RequireFields<QueryPatientsForReviewArgs, 'filter'>>;
  performanceIssues?: Resolver<Array<ResolversTypes['PatientPerformanceAnalyzis']>, ParentType, ContextType, RequireFields<QueryPerformanceIssuesArgs, 'maxDate' | 'minDate'>>;
  processEinsatzFile?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryProcessEinsatzFileArgs, 'einsatzFileName' | 'leistungFileName'>>;
  recentMissionPublication?: Resolver<Maybe<ResolversTypes['MissionPublication']>, ParentType, ContextType, RequireFields<QueryRecentMissionPublicationArgs, 'filter'>>;
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<QueryScheduleArgs, 'id'>>;
  scheduleDays?: Resolver<Array<ResolversTypes['ScheduleDay']>, ParentType, ContextType, Partial<QueryScheduleDaysArgs>>;
  scheduleEvery?: Resolver<Maybe<ResolversTypes['ScheduleEvery']>, ParentType, ContextType, RequireFields<QueryScheduleEveryArgs, 'id'>>;
  scheduleEverys?: Resolver<Maybe<Array<ResolversTypes['ScheduleEvery']>>, ParentType, ContextType, Partial<QueryScheduleEverysArgs>>;
  scheduleForDaysByPatient?: Resolver<Maybe<Array<ResolversTypes['ScheduleDay']>>, ParentType, ContextType, RequireFields<QueryScheduleForDaysByPatientArgs, 'maxDay' | 'minDay' | 'patientId'>>;
  schedules?: Resolver<Maybe<Array<Maybe<ResolversTypes['Schedule']>>>, ParentType, ContextType, RequireFields<QuerySchedulesArgs, 'filter'>>;
  singleDistance?: Resolver<Maybe<ResolversTypes['SingleDistance']>, ParentType, ContextType, RequireFields<QuerySingleDistanceArgs, 'fromPatientId' | 'toPatientId'>>;
  statistic?: Resolver<ResolversTypes['StatisticData'], ParentType, ContextType, RequireFields<QueryStatisticArgs, 'input'>>;
  supabaseMember?: Resolver<Maybe<ResolversTypes['SupabaseMember']>, ParentType, ContextType, RequireFields<QuerySupabaseMemberArgs, 'memberId'>>;
  supabaseMembers?: Resolver<Maybe<Array<ResolversTypes['SupabaseMember']>>, ParentType, ContextType>;
  syncMobile?: Resolver<ResolversTypes['SyncMobile'], ParentType, ContextType, Partial<QuerySyncMobileArgs>>;
  tour?: Resolver<Maybe<ResolversTypes['Tour']>, ParentType, ContextType, RequireFields<QueryTourArgs, 'filter'>>;
  tourAutorouting?: Resolver<Array<ResolversTypes['MissionOptimizeResult']>, ParentType, ContextType, RequireFields<QueryTourAutoroutingArgs, 'input'>>;
  tourData?: Resolver<Maybe<ResolversTypes['TourData']>, ParentType, ContextType, RequireFields<QueryTourDataArgs, 'day' | 'tourId'>>;
  tourDatas?: Resolver<Array<ResolversTypes['TourData']>, ParentType, ContextType, RequireFields<QueryTourDatasArgs, 'day'>>;
  tourIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryTourIdsArgs, 'filter'>>;
  tourInfoMessage?: Resolver<Maybe<ResolversTypes['TourInfoMessage']>, ParentType, ContextType, RequireFields<QueryTourInfoMessageArgs, 'day' | 'tourId'>>;
  tourInfoMessages?: Resolver<Array<ResolversTypes['TourInfoMessage']>, ParentType, ContextType, RequireFields<QueryTourInfoMessagesArgs, 'day' | 'tourId'>>;
  tourPause?: Resolver<Maybe<ResolversTypes['TourPause']>, ParentType, ContextType, RequireFields<QueryTourPauseArgs, 'id'>>;
  tourPauses?: Resolver<Array<ResolversTypes['TourPause']>, ParentType, ContextType, RequireFields<QueryTourPausesArgs, 'publicationDays' | 'tourIds'>>;
  tourReviewStatus?: Resolver<Maybe<ResolversTypes['TourReviewStatus']>, ParentType, ContextType, RequireFields<QueryTourReviewStatusArgs, 'day' | 'tourId'>>;
  tourStatExecutions?: Resolver<Array<ResolversTypes['TourStatExecution']>, ParentType, ContextType, RequireFields<QueryTourStatExecutionsArgs, 'maxDay' | 'minDay'>>;
  tourStatMissions?: Resolver<Array<ResolversTypes['TourStatMission']>, ParentType, ContextType, RequireFields<QueryTourStatMissionsArgs, 'maxDate' | 'minDate' | 'tourIds'>>;
  tours?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tour']>>>, ParentType, ContextType, RequireFields<QueryToursArgs, 'filter'>>;
  transferMissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Mission']>>>, ParentType, ContextType, RequireFields<QueryTransferMissionsArgs, 'filter'>>;
  travelPatients?: Resolver<Maybe<Array<Maybe<ResolversTypes['PatientTravelXXX']>>>, ParentType, ContextType, RequireFields<QueryTravelPatientsArgs, 'input'>>;
  weekdays?: Resolver<Maybe<Array<Maybe<ResolversTypes['Weekday']>>>, ParentType, ContextType>;
  whoami?: Resolver<ResolversTypes['Member'], ParentType, ContextType>;
};

export type ResultMissionUpdateManyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResultMissionUpdateMany'] = ResolversParentTypes['ResultMissionUpdateMany']> = {
  canBeWritten?: Resolver<Maybe<Array<Maybe<ResolversTypes['MissionReturnBulkUpdate']>>>, ParentType, ContextType>;
  dataWritten?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  notCanBeWritten?: Resolver<Maybe<Array<Maybe<ResolversTypes['MissionReturnBulkUpdate']>>>, ParentType, ContextType>;
  written?: Resolver<Maybe<Array<Maybe<ResolversTypes['MissionReturnBulkUpdate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['AssignedActionSchedule']>>, ParentType, ContextType>;
  days?: Resolver<Maybe<Array<ResolversTypes['Weekday']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plannedDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleByDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleByDay'] = ResolversParentTypes['ScheduleByDay']> = {
  allActions_c?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasMission?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxStartTimeExtended?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTimeExtended?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleDay'] = ResolversParentTypes['ScheduleDay']> = {
  acceptedMaxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acceptedMinStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  allCodedActions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allDays?: Resolver<Array<ResolversTypes['Weekday']>, ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  plannedDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedDays?: Resolver<Array<ResolversTypes['Weekday']>, ParentType, ContextType>;
  preferedMaxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedMinStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['Shift'], ParentType, ContextType>;
  shiftId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeGroup?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weekTurnus?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleEveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleEvery'] = ResolversParentTypes['ScheduleEvery']> = {
  acceptedMaxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acceptedMinStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionsWithQuantity?: Resolver<Maybe<Array<ResolversTypes['ActionWithQuantity']>>, ParentType, ContextType>;
  every?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  everyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  patient?: Resolver<Maybe<ResolversTypes['Patient']>, ParentType, ContextType>;
  patientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plannedDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedMaxStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preferedMinStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['Shift'], ParentType, ContextType>;
  shiftId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Shift'] = ResolversParentTypes['Shift']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shiftEndTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shiftStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleDistanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SingleDistance'] = ResolversParentTypes['SingleDistance']> = {
  distance_km?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  distance_m?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fromPatientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toPatientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  travelTime_min?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  travelTime_sec?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatisticDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticData'] = ResolversParentTypes['StatisticData']> = {
  countActionLnr?: Resolver<Array<ResolversTypes['PActionWithQuantity']>, ParentType, ContextType>;
  countMission?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countUniqueAction?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countUniqueMember?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countUniquePatient?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  missionLowPerforming?: Resolver<Maybe<Array<ResolversTypes['MissionLowPerforming']>>, ParentType, ContextType>;
  missionNoAction?: Resolver<Maybe<Array<ResolversTypes['MissionIdNoAction']>>, ParentType, ContextType>;
  patientNoAction?: Resolver<Maybe<Array<ResolversTypes['PatientNoAction']>>, ParentType, ContextType>;
  totalTimeAtPatient_hour?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SupabaseMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupabaseMember'] = ResolversParentTypes['SupabaseMember']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  member_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncMobileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncMobile'] = ResolversParentTypes['SyncMobile']> = {
  currentDay?: Resolver<ResolversTypes['DayMobile'], ParentType, ContextType>;
  member?: Resolver<ResolversTypes['Member'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tomorrow?: Resolver<ResolversTypes['DayMobile'], ParentType, ContextType>;
  yesterday?: Resolver<ResolversTypes['DayMobile'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tour'] = ResolversParentTypes['Tour']> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourData'] = ResolversParentTypes['TourData']> = {
  canSentToMobile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  latesUpdateSupabase?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxEndTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minStartTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionChanges?: Resolver<Maybe<Array<ResolversTypes['MissonChange']>>, ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourInfoMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourInfoMessage'] = ResolversParentTypes['TourInfoMessage']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  infoMessageResponse?: Resolver<Array<ResolversTypes['TourInfoMessageResponse']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourInfoMessageResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourInfoMessageResponse'] = ResolversParentTypes['TourInfoMessageResponse']> = {
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  infoMessage?: Resolver<ResolversTypes['TourInfoMessage'], ParentType, ContextType>;
  infoMessageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  responseType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourPauseResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourPause'] = ResolversParentTypes['TourPause']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endMinuteOfDay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTS?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  missionPublicationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startMinuteOfDay?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startTS?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourReviewStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourReviewStatus'] = ResolversParentTypes['TourReviewStatus']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourStatExecutionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourStatExecution'] = ResolversParentTypes['TourStatExecution']> = {
  countExecutions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countMissions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countOpenIssues?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dayOfWeek?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  diffWorkTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ratioCountExecuted?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ratioTimeExecuted?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sumWorkTimeExecuted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sumWorkTimePlanned?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourReviewStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TourStatMissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TourStatMission'] = ResolversParentTypes['TourStatMission']> = {
  actionExecutedCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionPLannedCoded?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  diffDuration_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  durationExecuted_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  durationPlanned_min?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasSuccessExecution?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['Member']>, ParentType, ContextType>;
  memberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mission?: Resolver<Maybe<ResolversTypes['Mission']>, ParentType, ContextType>;
  missionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tourReviewStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeekdayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Weekday'] = ResolversParentTypes['Weekday']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isoDayNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientInfoStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['patientInfoState'] = ResolversParentTypes['patientInfoState']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  A_Einsatz?: A_EinsatzResolvers<ContextType>;
  A_SollLeistung?: A_SollLeistungResolvers<ContextType>;
  Action?: ActionResolvers<ContextType>;
  ActionCashValue?: ActionCashValueResolvers<ContextType>;
  ActionTime?: ActionTimeResolvers<ContextType>;
  ActionWithCash?: ActionWithCashResolvers<ContextType>;
  ActionWithQuantity?: ActionWithQuantityResolvers<ContextType>;
  ActionWithQuantitySchedule?: ActionWithQuantityScheduleResolvers<ContextType>;
  AssignedActionMission?: AssignedActionMissionResolvers<ContextType>;
  AssignedActionMissionExecuted?: AssignedActionMissionExecutedResolvers<ContextType>;
  AssignedActionSchedule?: AssignedActionScheduleResolvers<ContextType>;
  CashFlowMission?: CashFlowMissionResolvers<ContextType>;
  ChangeLogSyncMSQL?: ChangeLogSyncMsqlResolvers<ContextType>;
  Coordinate?: CoordinateResolvers<ContextType>;
  CriteriaPauschale?: CriteriaPauschaleResolvers<ContextType>;
  CsvMission?: CsvMissionResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DayMobile?: DayMobileResolvers<ContextType>;
  District?: DistrictResolvers<ContextType>;
  EffectiveActionScheduleDay?: EffectiveActionScheduleDayResolvers<ContextType>;
  EffectiveSchedule?: EffectiveScheduleResolvers<ContextType>;
  ExcelMission?: ExcelMissionResolvers<ContextType>;
  ExcelMissionPublication?: ExcelMissionPublicationResolvers<ContextType>;
  ExecutionMobile?: ExecutionMobileResolvers<ContextType>;
  ExecutionMobileAction?: ExecutionMobileActionResolvers<ContextType>;
  ExecutionMobileInfoMessage?: ExecutionMobileInfoMessageResolvers<ContextType>;
  ExecutionMobileTourStat?: ExecutionMobileTourStatResolvers<ContextType>;
  Faktura?: FakturaResolvers<ContextType>;
  FileInBucket?: FileInBucketResolvers<ContextType>;
  FromExcelMission?: FromExcelMissionResolvers<ContextType>;
  Gender?: GenderResolvers<ContextType>;
  GeoPatient?: GeoPatientResolvers<ContextType>;
  GeoPatientFeatureCollection?: GeoPatientFeatureCollectionResolvers<ContextType>;
  GeoPatientProperties?: GeoPatientPropertiesResolvers<ContextType>;
  GeoPatientsData?: GeoPatientsDataResolvers<ContextType>;
  GeoTour?: GeoTourResolvers<ContextType>;
  GeoTourArcLayer?: GeoTourArcLayerResolvers<ContextType>;
  GeoTourArcLayerData?: GeoTourArcLayerDataResolvers<ContextType>;
  GeoTourArcLayerElement?: GeoTourArcLayerElementResolvers<ContextType>;
  GeoTourArcLayerElementProp?: GeoTourArcLayerElementPropResolvers<ContextType>;
  GeoTourFeatureCollection?: GeoTourFeatureCollectionResolvers<ContextType>;
  GeoTourLineString?: GeoTourLineStringResolvers<ContextType>;
  GeoTourProperties?: GeoTourPropertiesResolvers<ContextType>;
  Json?: GraphQLScalarType;
  LineGeometry?: LineGeometryResolvers<ContextType>;
  MedifoxStatusAbsence?: MedifoxStatusAbsenceResolvers<ContextType>;
  Member?: MemberResolvers<ContextType>;
  MemberQualification?: MemberQualificationResolvers<ContextType>;
  MemberQualificationType?: MemberQualificationTypeResolvers<ContextType>;
  Mission?: MissionResolvers<ContextType>;
  MissionAnalytic?: MissionAnalyticResolvers<ContextType>;
  MissionIdNoAction?: MissionIdNoActionResolvers<ContextType>;
  MissionLowPerforming?: MissionLowPerformingResolvers<ContextType>;
  MissionOptimize?: MissionOptimizeResolvers<ContextType>;
  MissionOptimizeResult?: MissionOptimizeResultResolvers<ContextType>;
  MissionPublication?: MissionPublicationResolvers<ContextType>;
  MissionPublicationStatus?: MissionPublicationStatusResolvers<ContextType>;
  MissionReturnBulkUpdate?: MissionReturnBulkUpdateResolvers<ContextType>;
  MissionWithActionExecuted?: MissionWithActionExecutedResolvers<ContextType>;
  MissionWithPauschale?: MissionWithPauschaleResolvers<ContextType>;
  MissonChange?: MissonChangeResolvers<ContextType>;
  MobileTask?: MobileTaskResolvers<ContextType>;
  MostRecentSyncInfo?: MostRecentSyncInfoResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NewAssignedActionSchedule?: NewAssignedActionScheduleResolvers<ContextType>;
  NewSchedule?: NewScheduleResolvers<ContextType>;
  OperationalChanges?: OperationalChangesResolvers<ContextType>;
  PActionWithQuantity?: PActionWithQuantityResolvers<ContextType>;
  Patient?: PatientResolvers<ContextType>;
  PatientAbsence?: PatientAbsenceResolvers<ContextType>;
  PatientActionDuration?: PatientActionDurationResolvers<ContextType>;
  PatientCareInterval?: PatientCareIntervalResolvers<ContextType>;
  PatientDay?: PatientDayResolvers<ContextType>;
  PatientDenyGender?: PatientDenyGenderResolvers<ContextType>;
  PatientInfoMessage?: PatientInfoMessageResolvers<ContextType>;
  PatientInfoMessageResponse?: PatientInfoMessageResponseResolvers<ContextType>;
  PatientNoAction?: PatientNoActionResolvers<ContextType>;
  PatientPerformanceAnalyzis?: PatientPerformanceAnalyzisResolvers<ContextType>;
  PatientScheduleException?: PatientScheduleExceptionResolvers<ContextType>;
  PatientTravelXXX?: PatientTravelXxxResolvers<ContextType>;
  PatientUrl?: PatientUrlResolvers<ContextType>;
  Point?: PointResolvers<ContextType>;
  PointGeometry?: PointGeometryResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ResultMissionUpdateMany?: ResultMissionUpdateManyResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  ScheduleByDay?: ScheduleByDayResolvers<ContextType>;
  ScheduleDay?: ScheduleDayResolvers<ContextType>;
  ScheduleEvery?: ScheduleEveryResolvers<ContextType>;
  Shift?: ShiftResolvers<ContextType>;
  SingleDistance?: SingleDistanceResolvers<ContextType>;
  StatisticData?: StatisticDataResolvers<ContextType>;
  SupabaseMember?: SupabaseMemberResolvers<ContextType>;
  SyncMobile?: SyncMobileResolvers<ContextType>;
  Tour?: TourResolvers<ContextType>;
  TourData?: TourDataResolvers<ContextType>;
  TourInfoMessage?: TourInfoMessageResolvers<ContextType>;
  TourInfoMessageResponse?: TourInfoMessageResponseResolvers<ContextType>;
  TourPause?: TourPauseResolvers<ContextType>;
  TourReviewStatus?: TourReviewStatusResolvers<ContextType>;
  TourStatExecution?: TourStatExecutionResolvers<ContextType>;
  TourStatMission?: TourStatMissionResolvers<ContextType>;
  Weekday?: WeekdayResolvers<ContextType>;
  patientInfoState?: PatientInfoStateResolvers<ContextType>;
};


export const Mission_TourManagerFragmentDoc = gql`
    fragment Mission_TourManager on Mission {
  id
  updatedAt
  memberId
  tourId
  isActive
  duration_min
  member {
    memberId
    shortName
    firstName
    lastName
    hasExamination
    hasQualificationLevel
  }
  cash {
    calculatedDuration_min
    cashflowValue
  }
  patientId
  patient {
    isPrivate
    lastName
    firstName
    colorBg
    colorTxt
    longitude
    latitude
  }
  minReqQualification
  memberHasQualificationLevel
  day
  time
  startTS
  endTS
}
    `;
export const Mission_TransferFragmentDoc = gql`
    fragment Mission_Transfer on Mission {
  id
  memberId
  patientId
  day
  time
  startTS
  endTS
  actionsWithQuantity {
    quantity
    actionLnr
  }
}
    `;
export const ActionFullFragmentDoc = gql`
    fragment actionFull on Action {
  lnr
  leistungsart
  examinationRequired
  leistungsinhalte
  leistungskomplex
  executable
  mobileDisplayGroup
}
    `;
export const ActionMinFragmentDoc = gql`
    fragment actionMin on Action {
  lnr
  leistungsart
  leistungskomplex
}
    `;
export const PatientMinFragmentDoc = gql`
    fragment patientMin on Patient {
  firstName
  lastName
}
    `;
export const Max_AssigendActionMissionExecutedFragmentDoc = gql`
    fragment max_assigendActionMissionExecuted on AssignedActionMissionExecuted {
  quantity
  actionLnr
  action {
    lnr
    leistungsart
    leistungskomplex
    examinationRequired
  }
}
    `;
export const Max_AssigendActionMissionFragmentDoc = gql`
    fragment max_assigendActionMission on AssignedActionMission {
  quantity
  actionLnr
  action {
    lnr
    leistungsart
    leistungskomplex
    examinationRequired
  }
}
    `;
export const AnalyzeMissionPatientFragmentDoc = gql`
    fragment analyzeMissionPatient on Patient {
  patientId
  firstName
  lastName
  kkType
}
    `;
export const DayInfoFragmentDoc = gql`
    fragment DayInfo on Weekday {
  id
  isoDayNumber
}
    `;
export const MissionDataFragmentDoc = gql`
    fragment MissionData on Mission {
  id
  updatedAt
  memberId
  start_ISO
  startTS
  endTS
  day
  isActive
  patientId
  patient {
    lastName
    firstName
  }
  actionsWithQuantity {
    quantity
    action {
      leistungskomplex
      leistungsart
      lnr
      leistungsinhalte
    }
  }
}
    `;
export const UpdateOneScheduleDocument = gql`
    mutation UpdateOneSchedule($id: String!, $input: ScheduleUpdateInput!) {
  updateOneSchedule(id: $id, input: $input) {
    id
    minStartTime
    maxStartTime
    days {
      id
      isoDayNumber
      name
    }
  }
}
    `;

export const UpdateOneScheduleComponent = (props: Omit<Urql.MutationProps<UpdateOneScheduleMutation, UpdateOneScheduleMutationVariables>, 'query'> & { variables?: UpdateOneScheduleMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateOneScheduleDocument} />
);


export function useUpdateOneScheduleMutation() {
  return Urql.useMutation<UpdateOneScheduleMutation, UpdateOneScheduleMutationVariables>(UpdateOneScheduleDocument);
};
export const SchedulesByPatientDocument = gql`
    query SchedulesByPatient($filter: ScheduleFilter!) {
  newSchedules(filter: $filter) {
    id
    patientId
    minStartTime
    maxStartTime
    plannedDuration
    days {
      ...DayInfo
    }
    plannedDuration
    actionsWithQuantity {
      quantity
      action {
        lnr
        leistungsart
        leistungskomplex
        leistungsinhalte
        examinationRequired
      }
    }
    cash {
      calculatedDuration_min
    }
  }
}
    ${DayInfoFragmentDoc}`;

export const SchedulesByPatientComponent = (props: Omit<Urql.QueryProps<SchedulesByPatientQuery, SchedulesByPatientQueryVariables>, 'query'> & { variables: SchedulesByPatientQueryVariables }) => (
  <Urql.Query {...props} query={SchedulesByPatientDocument} />
);


export function useSchedulesByPatientQuery(options: Omit<Urql.UseQueryArgs<SchedulesByPatientQueryVariables>, 'query'>) {
  return Urql.useQuery<SchedulesByPatientQuery, SchedulesByPatientQueryVariables>({ query: SchedulesByPatientDocument, ...options });
};
export const CreateEmptyScheduleDocument = gql`
    mutation CreateEmptySchedule($patientId: String!) {
  createOneSchedule(
    input: {patientId: $patientId, minStartTime: "00:00", maxStartTime: "00:00", plannedDuration: "00:00", dayIds: [], actionIds: []}
  ) {
    id
  }
}
    `;

export const CreateEmptyScheduleComponent = (props: Omit<Urql.MutationProps<CreateEmptyScheduleMutation, CreateEmptyScheduleMutationVariables>, 'query'> & { variables?: CreateEmptyScheduleMutationVariables }) => (
  <Urql.Mutation {...props} query={CreateEmptyScheduleDocument} />
);


export function useCreateEmptyScheduleMutation() {
  return Urql.useMutation<CreateEmptyScheduleMutation, CreateEmptyScheduleMutationVariables>(CreateEmptyScheduleDocument);
};
export const DeleteOneScheduleDocument = gql`
    mutation DeleteOneSchedule($id: String!) {
  deleteOneSchedule(id: $id) {
    id
  }
}
    `;

export const DeleteOneScheduleComponent = (props: Omit<Urql.MutationProps<DeleteOneScheduleMutation, DeleteOneScheduleMutationVariables>, 'query'> & { variables?: DeleteOneScheduleMutationVariables }) => (
  <Urql.Mutation {...props} query={DeleteOneScheduleDocument} />
);


export function useDeleteOneScheduleMutation() {
  return Urql.useMutation<DeleteOneScheduleMutation, DeleteOneScheduleMutationVariables>(DeleteOneScheduleDocument);
};
export const GetOnePatientDocument = gql`
    query GetOnePatient($id: String!) {
  patient(patientId: $id) {
    patientId
    lastName
    firstName
    zipCode
    city
    address
    isActive
    longitude
    latitude
    kkType
    districtId
    medifoxPatientInfo
  }
}
    `;

export const GetOnePatientComponent = (props: Omit<Urql.QueryProps<GetOnePatientQuery, GetOnePatientQueryVariables>, 'query'> & { variables: GetOnePatientQueryVariables }) => (
  <Urql.Query {...props} query={GetOnePatientDocument} />
);


export function useGetOnePatientQuery(options: Omit<Urql.UseQueryArgs<GetOnePatientQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOnePatientQuery, GetOnePatientQueryVariables>({ query: GetOnePatientDocument, ...options });
};
export const GetAllPatientsDocument = gql`
    query GetAllPatients($filter: PatientsFilter) {
  patients(filter: $filter) {
    patientId
    lastName
    firstName
    zipCode
    city
    address
  }
}
    `;

export const GetAllPatientsComponent = (props: Omit<Urql.QueryProps<GetAllPatientsQuery, GetAllPatientsQueryVariables>, 'query'> & { variables?: GetAllPatientsQueryVariables }) => (
  <Urql.Query {...props} query={GetAllPatientsDocument} />
);


export function useGetAllPatientsQuery(options?: Omit<Urql.UseQueryArgs<GetAllPatientsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllPatientsQuery, GetAllPatientsQueryVariables>({ query: GetAllPatientsDocument, ...options });
};
export const UpdatePatientGeoDataDocument = gql`
    mutation UpdatePatientGeoData($patientId: String!, $geoString: String!) {
  updatePatientGeo(patientId: $patientId, geoString: $geoString) {
    patientId
    lastName
    firstName
    zipCode
    city
    address
    isActive
    longitude
    latitude
  }
}
    `;

export const UpdatePatientGeoDataComponent = (props: Omit<Urql.MutationProps<UpdatePatientGeoDataMutation, UpdatePatientGeoDataMutationVariables>, 'query'> & { variables?: UpdatePatientGeoDataMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdatePatientGeoDataDocument} />
);


export function useUpdatePatientGeoDataMutation() {
  return Urql.useMutation<UpdatePatientGeoDataMutation, UpdatePatientGeoDataMutationVariables>(UpdatePatientGeoDataDocument);
};
export const ActionsDocument = gql`
    query Actions {
  actions(filter: {executable: true}) {
    leistungsart
    leistungskomplex
    lnr
  }
}
    `;

export const ActionsComponent = (props: Omit<Urql.QueryProps<ActionsQuery, ActionsQueryVariables>, 'query'> & { variables?: ActionsQueryVariables }) => (
  <Urql.Query {...props} query={ActionsDocument} />
);


export function useActionsQuery(options?: Omit<Urql.UseQueryArgs<ActionsQueryVariables>, 'query'>) {
  return Urql.useQuery<ActionsQuery, ActionsQueryVariables>({ query: ActionsDocument, ...options });
};
export const GetMissionsDayManagerDocument = gql`
    query GetMissionsDayManager($id: String, $date: String, $includeLate: Boolean, $includeEarly: Boolean, $isActive: Boolean, $updatedLater: String) {
  missionsTimeRange(
    filter: {publicationId: $id, maxDate: $date, minDate: $date, includeLate: $includeLate, includeEarly: $includeEarly, isActive: $isActive, updatedLater: $updatedLater}
  ) {
    updatedAt
    memberId
    isActive
    startTS
    endTS
    patientId
    id
    patient {
      lastName
      longitude
      latitude
      colorBg
      colorTxt
    }
    member {
      lastName
    }
    hasAction
  }
}
    `;

export const GetMissionsDayManagerComponent = (props: Omit<Urql.QueryProps<GetMissionsDayManagerQuery, GetMissionsDayManagerQueryVariables>, 'query'> & { variables?: GetMissionsDayManagerQueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsDayManagerDocument} />
);


export function useGetMissionsDayManagerQuery(options?: Omit<Urql.UseQueryArgs<GetMissionsDayManagerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsDayManagerQuery, GetMissionsDayManagerQueryVariables>({ query: GetMissionsDayManagerDocument, ...options });
};
export const UpdateOneMissionDayManagerDocument = gql`
    mutation UpdateOneMissionDayManager($id: String!, $startTS: String, $endTS: String, $updatedAt: String!, $isActive: Boolean) {
  updateOneMission(
    id: $id
    startTS: $startTS
    endTS: $endTS
    updatedAt: $updatedAt
    isActive: $isActive
  ) {
    id
    start_ISO
    isActive
    actionsWithQuantity {
      quantity
      actionLnr
    }
  }
}
    `;

export const UpdateOneMissionDayManagerComponent = (props: Omit<Urql.MutationProps<UpdateOneMissionDayManagerMutation, UpdateOneMissionDayManagerMutationVariables>, 'query'> & { variables?: UpdateOneMissionDayManagerMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateOneMissionDayManagerDocument} />
);


export function useUpdateOneMissionDayManagerMutation() {
  return Urql.useMutation<UpdateOneMissionDayManagerMutation, UpdateOneMissionDayManagerMutationVariables>(UpdateOneMissionDayManagerDocument);
};
export const UpdateManyMissionsDayManagerDocument = gql`
    mutation UpdateManyMissionsDayManager($input: [MissionUpdateBulkInput!]!) {
  updateManyMissions(input: $input) {
    id
    start_ISO
    updatedAt
    startTS
    endTS
  }
}
    `;

export const UpdateManyMissionsDayManagerComponent = (props: Omit<Urql.MutationProps<UpdateManyMissionsDayManagerMutation, UpdateManyMissionsDayManagerMutationVariables>, 'query'> & { variables?: UpdateManyMissionsDayManagerMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateManyMissionsDayManagerDocument} />
);


export function useUpdateManyMissionsDayManagerMutation() {
  return Urql.useMutation<UpdateManyMissionsDayManagerMutation, UpdateManyMissionsDayManagerMutationVariables>(UpdateManyMissionsDayManagerDocument);
};
export const UpdateManyMissionsTourManagerDocument = gql`
    mutation UpdateManyMissionsTourManager($input: [MissionUpdateBulkInput1!]!) {
  updateManyMissionsEnhanced(input: $input) {
    canBeWritten {
      id
    }
    notCanBeWritten {
      id
    }
    written {
      id
    }
    dataWritten
  }
}
    `;

export const UpdateManyMissionsTourManagerComponent = (props: Omit<Urql.MutationProps<UpdateManyMissionsTourManagerMutation, UpdateManyMissionsTourManagerMutationVariables>, 'query'> & { variables?: UpdateManyMissionsTourManagerMutationVariables }) => (
  <Urql.Mutation {...props} query={UpdateManyMissionsTourManagerDocument} />
);


export function useUpdateManyMissionsTourManagerMutation() {
  return Urql.useMutation<UpdateManyMissionsTourManagerMutation, UpdateManyMissionsTourManagerMutationVariables>(UpdateManyMissionsTourManagerDocument);
};
export const MissionDistinctMemberIdsDocument = gql`
    query MissionDistinctMemberIds($publicationId: String!, $includeLate: Boolean, $includeEarly: Boolean) {
  missionDistinctMemberIds(
    filter: {publicationId: $publicationId, includeLate: $includeLate, includeEarly: $includeEarly}
  )
}
    `;

export const MissionDistinctMemberIdsComponent = (props: Omit<Urql.QueryProps<MissionDistinctMemberIdsQuery, MissionDistinctMemberIdsQueryVariables>, 'query'> & { variables: MissionDistinctMemberIdsQueryVariables }) => (
  <Urql.Query {...props} query={MissionDistinctMemberIdsDocument} />
);


export function useMissionDistinctMemberIdsQuery(options: Omit<Urql.UseQueryArgs<MissionDistinctMemberIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<MissionDistinctMemberIdsQuery, MissionDistinctMemberIdsQueryVariables>({ query: MissionDistinctMemberIdsDocument, ...options });
};
export const MissionDistinctMembersDocument = gql`
    query MissionDistinctMembers($publicationId: String, $minDate: String, $maxDate: String, $includeLate: Boolean, $includeEarly: Boolean) {
  missionDistinctMembers(
    filter: {publicationId: $publicationId, minDate: $minDate, maxDate: $maxDate, includeEarly: $includeEarly, includeLate: $includeLate}
  ) {
    memberId
    lastName
  }
}
    `;

export const MissionDistinctMembersComponent = (props: Omit<Urql.QueryProps<MissionDistinctMembersQuery, MissionDistinctMembersQueryVariables>, 'query'> & { variables?: MissionDistinctMembersQueryVariables }) => (
  <Urql.Query {...props} query={MissionDistinctMembersDocument} />
);


export function useMissionDistinctMembersQuery(options?: Omit<Urql.UseQueryArgs<MissionDistinctMembersQueryVariables>, 'query'>) {
  return Urql.useQuery<MissionDistinctMembersQuery, MissionDistinctMembersQueryVariables>({ query: MissionDistinctMembersDocument, ...options });
};
export const SchedulesDocument = gql`
    query Schedules($filter: ScheduleFilter!) {
  newSchedules(filter: $filter) {
    id
    patientId
    minStartTime
    maxStartTime
    plannedDuration
    days {
      ...DayInfo
    }
    actionsWithQuantity {
      quantity
      actionLnr
      action {
        examinationRequired
        leistungsart
      }
    }
    cash {
      calculatedDuration_min
    }
  }
}
    ${DayInfoFragmentDoc}`;

export const SchedulesComponent = (props: Omit<Urql.QueryProps<SchedulesQuery, SchedulesQueryVariables>, 'query'> & { variables: SchedulesQueryVariables }) => (
  <Urql.Query {...props} query={SchedulesDocument} />
);


export function useSchedulesQuery(options: Omit<Urql.UseQueryArgs<SchedulesQueryVariables>, 'query'>) {
  return Urql.useQuery<SchedulesQuery, SchedulesQueryVariables>({ query: SchedulesDocument, ...options });
};
export const CreateManyMissionsDocument = gql`
    mutation CreateManyMissions($input: MissionCreateBulkInput!) {
  createManyMissions(input: $input) {
    id
  }
}
    `;

export const CreateManyMissionsComponent = (props: Omit<Urql.MutationProps<CreateManyMissionsMutation, CreateManyMissionsMutationVariables>, 'query'> & { variables?: CreateManyMissionsMutationVariables }) => (
  <Urql.Mutation {...props} query={CreateManyMissionsDocument} />
);


export function useCreateManyMissionsMutation() {
  return Urql.useMutation<CreateManyMissionsMutation, CreateManyMissionsMutationVariables>(CreateManyMissionsDocument);
};
export const GetMemberNameDocument = gql`
    query GetMemberName($memberId: String!) {
  member(memberId: $memberId) {
    lastName
    firstName
    shortName
  }
}
    `;

export const GetMemberNameComponent = (props: Omit<Urql.QueryProps<GetMemberNameQuery, GetMemberNameQueryVariables>, 'query'> & { variables: GetMemberNameQueryVariables }) => (
  <Urql.Query {...props} query={GetMemberNameDocument} />
);


export function useGetMemberNameQuery(options: Omit<Urql.UseQueryArgs<GetMemberNameQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMemberNameQuery, GetMemberNameQueryVariables>({ query: GetMemberNameDocument, ...options });
};
export const GetPatientsDocument = gql`
    query GetPatients($filter: PatientsFilter) {
  patients(filter: $filter) {
    patientId
    lastName
    firstName
    address
    city
    zipCode
    isActive
  }
}
    `;

export const GetPatientsComponent = (props: Omit<Urql.QueryProps<GetPatientsQuery, GetPatientsQueryVariables>, 'query'> & { variables?: GetPatientsQueryVariables }) => (
  <Urql.Query {...props} query={GetPatientsDocument} />
);


export function useGetPatientsQuery(options?: Omit<Urql.UseQueryArgs<GetPatientsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientsQuery, GetPatientsQueryVariables>({ query: GetPatientsDocument, ...options });
};
export const GetMembersDocument = gql`
    query GetMembers($filter: MembersFilter) {
  members(filter: $filter) {
    memberId
    shortName
    lastName
    firstName
    hasExamination
    hasQualificationLevel
  }
}
    `;

export const GetMembersComponent = (props: Omit<Urql.QueryProps<GetMembersQuery, GetMembersQueryVariables>, 'query'> & { variables?: GetMembersQueryVariables }) => (
  <Urql.Query {...props} query={GetMembersDocument} />
);


export function useGetMembersQuery(options?: Omit<Urql.UseQueryArgs<GetMembersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMembersQuery, GetMembersQueryVariables>({ query: GetMembersDocument, ...options });
};
export const CashActionsDocument = gql`
    query CashActions($input: ActionCashInput!) {
  cashActions(input: $input) {
    actionLnrs
    complexedLnrs
    cashflowValue
    calculatedDuration_min
  }
}
    `;

export const CashActionsComponent = (props: Omit<Urql.QueryProps<CashActionsQuery, CashActionsQueryVariables>, 'query'> & { variables: CashActionsQueryVariables }) => (
  <Urql.Query {...props} query={CashActionsDocument} />
);


export function useCashActionsQuery(options: Omit<Urql.UseQueryArgs<CashActionsQueryVariables>, 'query'>) {
  return Urql.useQuery<CashActionsQuery, CashActionsQueryVariables>({ query: CashActionsDocument, ...options });
};
export const GenerateCashFlowTableDocument = gql`
    mutation GenerateCashFlowTable($missionPublicationId: String!) {
  generateCashflowTable(missionPublicationId: $missionPublicationId)
}
    `;

export const GenerateCashFlowTableComponent = (props: Omit<Urql.MutationProps<GenerateCashFlowTableMutation, GenerateCashFlowTableMutationVariables>, 'query'> & { variables?: GenerateCashFlowTableMutationVariables }) => (
  <Urql.Mutation {...props} query={GenerateCashFlowTableDocument} />
);


export function useGenerateCashFlowTableMutation() {
  return Urql.useMutation<GenerateCashFlowTableMutation, GenerateCashFlowTableMutationVariables>(GenerateCashFlowTableDocument);
};
export const MissionWoActionPatientsIdsDocument = gql`
    query MissionWoActionPatientsIds($publicationId: String!) {
  missionWoActionsPatientIds(publicationId: $publicationId)
}
    `;

export const MissionWoActionPatientsIdsComponent = (props: Omit<Urql.QueryProps<MissionWoActionPatientsIdsQuery, MissionWoActionPatientsIdsQueryVariables>, 'query'> & { variables: MissionWoActionPatientsIdsQueryVariables }) => (
  <Urql.Query {...props} query={MissionWoActionPatientsIdsDocument} />
);


export function useMissionWoActionPatientsIdsQuery(options: Omit<Urql.UseQueryArgs<MissionWoActionPatientsIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<MissionWoActionPatientsIdsQuery, MissionWoActionPatientsIdsQueryVariables>({ query: MissionWoActionPatientsIdsDocument, ...options });
};
export const GetMissionsTourManager1Document = gql`
    query GetMissionsTourManager1($filter: GlobalTimeRangeFilter!) {
  missionsTimeRange(filter: $filter) {
    missionPublicationId
    ...Mission_TourManager
  }
}
    ${Mission_TourManagerFragmentDoc}`;

export const GetMissionsTourManager1Component = (props: Omit<Urql.QueryProps<GetMissionsTourManager1Query, GetMissionsTourManager1QueryVariables>, 'query'> & { variables: GetMissionsTourManager1QueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsTourManager1Document} />
);


export function useGetMissionsTourManager1Query(options: Omit<Urql.UseQueryArgs<GetMissionsTourManager1QueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsTourManager1Query, GetMissionsTourManager1QueryVariables>({ query: GetMissionsTourManager1Document, ...options });
};
export const GetMissionsByIdsTourManager1Document = gql`
    query GetMissionsByIdsTourManager1($filter: MissionsByIdsFilter!) {
  missionsByIds(filter: $filter) {
    missionPublicationId
    ...Mission_TourManager
  }
}
    ${Mission_TourManagerFragmentDoc}`;

export const GetMissionsByIdsTourManager1Component = (props: Omit<Urql.QueryProps<GetMissionsByIdsTourManager1Query, GetMissionsByIdsTourManager1QueryVariables>, 'query'> & { variables: GetMissionsByIdsTourManager1QueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsByIdsTourManager1Document} />
);


export function useGetMissionsByIdsTourManager1Query(options: Omit<Urql.UseQueryArgs<GetMissionsByIdsTourManager1QueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsByIdsTourManager1Query, GetMissionsByIdsTourManager1QueryVariables>({ query: GetMissionsByIdsTourManager1Document, ...options });
};
export const GetMissionsTourManagerDocument = gql`
    query GetMissionsTourManager($filter: AllMissionsByMemberPerWeekFilter!) {
  allMissionsByMemberPerRange(filter: $filter) {
    ...Mission_TourManager
  }
}
    ${Mission_TourManagerFragmentDoc}`;

export const GetMissionsTourManagerComponent = (props: Omit<Urql.QueryProps<GetMissionsTourManagerQuery, GetMissionsTourManagerQueryVariables>, 'query'> & { variables: GetMissionsTourManagerQueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsTourManagerDocument} />
);


export function useGetMissionsTourManagerQuery(options: Omit<Urql.UseQueryArgs<GetMissionsTourManagerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsTourManagerQuery, GetMissionsTourManagerQueryVariables>({ query: GetMissionsTourManagerDocument, ...options });
};
export const GetPatientsMapDocument = gql`
    query GetPatientsMap($filter: PatientsFilter) {
  patients(filter: $filter) {
    patientId
    lastName
    longitude
    latitude
  }
}
    `;

export const GetPatientsMapComponent = (props: Omit<Urql.QueryProps<GetPatientsMapQuery, GetPatientsMapQueryVariables>, 'query'> & { variables?: GetPatientsMapQueryVariables }) => (
  <Urql.Query {...props} query={GetPatientsMapDocument} />
);


export function useGetPatientsMapQuery(options?: Omit<Urql.UseQueryArgs<GetPatientsMapQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientsMapQuery, GetPatientsMapQueryVariables>({ query: GetPatientsMapDocument, ...options });
};
export const GetTravelDistancesDocument = gql`
    query GetTravelDistances($input: PatientTravelInput!) {
  travelPatients(input: $input) {
    sourcePatientId
    targetPatientId
    travelTime
    travelId
    sourcePatient {
      lastName
      latitude
      longitude
      address
      zipCode
      lat_long
    }
    targetPatient {
      lastName
      latitude
      longitude
      address
      zipCode
      lat_long
    }
  }
}
    `;

export const GetTravelDistancesComponent = (props: Omit<Urql.QueryProps<GetTravelDistancesQuery, GetTravelDistancesQueryVariables>, 'query'> & { variables: GetTravelDistancesQueryVariables }) => (
  <Urql.Query {...props} query={GetTravelDistancesDocument} />
);


export function useGetTravelDistancesQuery(options: Omit<Urql.UseQueryArgs<GetTravelDistancesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTravelDistancesQuery, GetTravelDistancesQueryVariables>({ query: GetTravelDistancesDocument, ...options });
};
export const GetMissionsTransferDocument = gql`
    query GetMissionsTransfer($filter: AllMissionsByMemberPerWeekFilter!) {
  allMissionsByMemberPerRange(filter: $filter) {
    ...Mission_Transfer
  }
}
    ${Mission_TransferFragmentDoc}`;

export const GetMissionsTransferComponent = (props: Omit<Urql.QueryProps<GetMissionsTransferQuery, GetMissionsTransferQueryVariables>, 'query'> & { variables: GetMissionsTransferQueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsTransferDocument} />
);


export function useGetMissionsTransferQuery(options: Omit<Urql.UseQueryArgs<GetMissionsTransferQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsTransferQuery, GetMissionsTransferQueryVariables>({ query: GetMissionsTransferDocument, ...options });
};
export const GetGeoPatientsDocument = gql`
    query GetGeoPatients($filter: GlobalTimeRangeFilter!) {
  geoPatients(filter: $filter) {
    type
    features {
      type
      geometry {
        type
        coordinates
      }
      properties {
        patientId
        lastName
        colorHex
      }
    }
  }
}
    `;

export const GetGeoPatientsComponent = (props: Omit<Urql.QueryProps<GetGeoPatientsQuery, GetGeoPatientsQueryVariables>, 'query'> & { variables: GetGeoPatientsQueryVariables }) => (
  <Urql.Query {...props} query={GetGeoPatientsDocument} />
);


export function useGetGeoPatientsQuery(options: Omit<Urql.UseQueryArgs<GetGeoPatientsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGeoPatientsQuery, GetGeoPatientsQueryVariables>({ query: GetGeoPatientsDocument, ...options });
};
export const GetArcLayerDocument = gql`
    query GetArcLayer($day: String!, $tourIds: [String!]!) {
  geoTourArcLayers(day: $day, tourIds: $tourIds) {
    memberId
    elements {
      inbound
      outbound
      memberId
      duration_from
      from {
        name
        coordinates
        startTime
        startTS
      }
      to {
        name
        coordinates
        startTime
      }
    }
  }
}
    `;

export const GetArcLayerComponent = (props: Omit<Urql.QueryProps<GetArcLayerQuery, GetArcLayerQueryVariables>, 'query'> & { variables: GetArcLayerQueryVariables }) => (
  <Urql.Query {...props} query={GetArcLayerDocument} />
);


export function useGetArcLayerQuery(options: Omit<Urql.UseQueryArgs<GetArcLayerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetArcLayerQuery, GetArcLayerQueryVariables>({ query: GetArcLayerDocument, ...options });
};
export const GetTourInfo1Document = gql`
    query GetTourInfo1($filter: TourFilter!) {
  tour(filter: $filter) {
    tourId
    date
    memberId
    member {
      lastName
      firstName
      shortName
      hasExamination
    }
  }
}
    `;

export const GetTourInfo1Component = (props: Omit<Urql.QueryProps<GetTourInfo1Query, GetTourInfo1QueryVariables>, 'query'> & { variables: GetTourInfo1QueryVariables }) => (
  <Urql.Query {...props} query={GetTourInfo1Document} />
);


export function useGetTourInfo1Query(options: Omit<Urql.UseQueryArgs<GetTourInfo1QueryVariables>, 'query'>) {
  return Urql.useQuery<GetTourInfo1Query, GetTourInfo1QueryVariables>({ query: GetTourInfo1Document, ...options });
};
export const GetPatientMissionListByIdsDocument = gql`
    query GetPatientMissionListByIds($filter: MissionsByIdsFilter!) {
  missionsByIds(filter: $filter) {
    patientId
    id
    startTS
    endTS
    day
    time
    duration_min
    minReqQualification
    tourId
    member {
      memberId
      shortName
      hasExamination
      hasQualificationLevel
    }
    patient {
      lastName
      firstName
      isPrivate
    }
    hasAction
    isActive
    updatedAt
    cash {
      calculatedDuration_min
    }
    actionsWithQuantity {
      quantity
      action {
        lnr
        leistungsart
        examinationRequired
        leistungsinhalte
        leistungskomplex
        executable
        mobileDisplayGroup
      }
    }
  }
}
    `;

export const GetPatientMissionListByIdsComponent = (props: Omit<Urql.QueryProps<GetPatientMissionListByIdsQuery, GetPatientMissionListByIdsQueryVariables>, 'query'> & { variables: GetPatientMissionListByIdsQueryVariables }) => (
  <Urql.Query {...props} query={GetPatientMissionListByIdsDocument} />
);


export function useGetPatientMissionListByIdsQuery(options: Omit<Urql.UseQueryArgs<GetPatientMissionListByIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientMissionListByIdsQuery, GetPatientMissionListByIdsQueryVariables>({ query: GetPatientMissionListByIdsDocument, ...options });
};
export const GetPatientMissionListDocument = gql`
    query GetPatientMissionList($filter: GlobalTimeRangeFilter!) {
  missionsTimeRange(filter: $filter) {
    patientId
    id
    startTS
    endTS
    day
    time
    duration_min
    tourId
    minReqQualification
    member {
      memberId
      shortName
      hasExamination
      hasQualificationLevel
    }
    patient {
      lastName
      firstName
      isPrivate
    }
    hasAction
    isActive
    updatedAt
    cash {
      calculatedDuration_min
    }
    actionsWithQuantity {
      quantity
      action {
        lnr
        leistungsart
        examinationRequired
        leistungsinhalte
        leistungskomplex
        executable
        mobileDisplayGroup
      }
    }
  }
}
    `;

export const GetPatientMissionListComponent = (props: Omit<Urql.QueryProps<GetPatientMissionListQuery, GetPatientMissionListQueryVariables>, 'query'> & { variables: GetPatientMissionListQueryVariables }) => (
  <Urql.Query {...props} query={GetPatientMissionListDocument} />
);


export function useGetPatientMissionListQuery(options: Omit<Urql.UseQueryArgs<GetPatientMissionListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientMissionListQuery, GetPatientMissionListQueryVariables>({ query: GetPatientMissionListDocument, ...options });
};
export const MissionSetIsActiveDocument = gql`
    mutation MissionSetIsActive($id: String!, $isActive: Boolean!, $updatedAt: String!) {
  missionSetIsActive(id: $id, isActive: $isActive, updatedAt: $updatedAt)
}
    `;

export const MissionSetIsActiveComponent = (props: Omit<Urql.MutationProps<MissionSetIsActiveMutation, MissionSetIsActiveMutationVariables>, 'query'> & { variables?: MissionSetIsActiveMutationVariables }) => (
  <Urql.Mutation {...props} query={MissionSetIsActiveDocument} />
);


export function useMissionSetIsActiveMutation() {
  return Urql.useMutation<MissionSetIsActiveMutation, MissionSetIsActiveMutationVariables>(MissionSetIsActiveDocument);
};
export const GetPatientIdsNoActionDocument = gql`
    query GetPatientIdsNoAction($filter: GlobalTimeRangeFilter!) {
  missionsTimeRange(filter: $filter) {
    patientId
    hasAction
    patient {
      patientId
      hasSchedule
    }
  }
}
    `;

export const GetPatientIdsNoActionComponent = (props: Omit<Urql.QueryProps<GetPatientIdsNoActionQuery, GetPatientIdsNoActionQueryVariables>, 'query'> & { variables: GetPatientIdsNoActionQueryVariables }) => (
  <Urql.Query {...props} query={GetPatientIdsNoActionDocument} />
);


export function useGetPatientIdsNoActionQuery(options: Omit<Urql.UseQueryArgs<GetPatientIdsNoActionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPatientIdsNoActionQuery, GetPatientIdsNoActionQueryVariables>({ query: GetPatientIdsNoActionDocument, ...options });
};
export const GetAnalyseMissionDocument = gql`
    query GetAnalyseMission($filter: GlobalTimeRangeFilter!) {
  missionsTimeRange(filter: $filter) {
    missionPublicationId
    updatedAt
    startTS
    endTS
    day
    duration_min
    memberId
    isActive
    isChecked
    editAllowed
    startTS
    endTS
    patientId
    tourId
    minReqQualification
    id
    patient {
      patientId
      firstName
      lastName
      kkType
      isPrivate
    }
    member {
      shortName
      lastName
      hasExamination
      hasQualificationLevel
    }
    actionsWithQuantity {
      ...max_assigendActionMission
    }
    hasAction
    missionAnalytic {
      totalCashValue
      totalCashValue_NOSAFE
      pauschaleHausType
      pauschaleSaveType
      duration_min
      expectedDuration
      expectedDuration_NOSAFE
      cashPerMinute
      cashPerMinute_NOSAFE
      durationDeviation_min
      totalPauschaleElements
      totalPauschaleCashValue
      timedElements
      complexedGrundElements
      behandlungMaxElements
      behandlungAdditionalElements
      allActions
      grundCashValue
      behandlungMaxCashValue
      timedCashValue
      behandlungAdditionalCashValue
      totalPauschaleElements
    }
  }
}
    ${Max_AssigendActionMissionFragmentDoc}`;

export const GetAnalyseMissionComponent = (props: Omit<Urql.QueryProps<GetAnalyseMissionQuery, GetAnalyseMissionQueryVariables>, 'query'> & { variables: GetAnalyseMissionQueryVariables }) => (
  <Urql.Query {...props} query={GetAnalyseMissionDocument} />
);


export function useGetAnalyseMissionQuery(options: Omit<Urql.UseQueryArgs<GetAnalyseMissionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAnalyseMissionQuery, GetAnalyseMissionQueryVariables>({ query: GetAnalyseMissionDocument, ...options });
};
export const GetActiveMembersDocument = gql`
    query GetActiveMembers($filter: GlobalTimeRangeFilter!) {
  missionDistinctMembers(filter: $filter) {
    memberId
    shortName
    firstName
    lastName
  }
}
    `;

export const GetActiveMembersComponent = (props: Omit<Urql.QueryProps<GetActiveMembersQuery, GetActiveMembersQueryVariables>, 'query'> & { variables: GetActiveMembersQueryVariables }) => (
  <Urql.Query {...props} query={GetActiveMembersDocument} />
);


export function useGetActiveMembersQuery(options: Omit<Urql.UseQueryArgs<GetActiveMembersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveMembersQuery, GetActiveMembersQueryVariables>({ query: GetActiveMembersDocument, ...options });
};
export const GetTourIdsDocument = gql`
    query GetTourIds($filter: GlobalTimeRangeFilter!) {
  tourIds(filter: $filter)
}
    `;

export const GetTourIdsComponent = (props: Omit<Urql.QueryProps<GetTourIdsQuery, GetTourIdsQueryVariables>, 'query'> & { variables: GetTourIdsQueryVariables }) => (
  <Urql.Query {...props} query={GetTourIdsDocument} />
);


export function useGetTourIdsQuery(options: Omit<Urql.UseQueryArgs<GetTourIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTourIdsQuery, GetTourIdsQueryVariables>({ query: GetTourIdsDocument, ...options });
};
export const AddMissionToPublicationIdDocument = gql`
    mutation AddMissionToPublicationId($input: AddMissionToPublicationInput!) {
  addMissionToPublicationId(input: $input) {
    id
  }
}
    `;

export const AddMissionToPublicationIdComponent = (props: Omit<Urql.MutationProps<AddMissionToPublicationIdMutation, AddMissionToPublicationIdMutationVariables>, 'query'> & { variables?: AddMissionToPublicationIdMutationVariables }) => (
  <Urql.Mutation {...props} query={AddMissionToPublicationIdDocument} />
);


export function useAddMissionToPublicationIdMutation() {
  return Urql.useMutation<AddMissionToPublicationIdMutation, AddMissionToPublicationIdMutationVariables>(AddMissionToPublicationIdDocument);
};
export const AddMissionToDayDocument = gql`
    mutation AddMissionToDay($input: AddMissionToDayInput!) {
  addMissionToDay(input: $input) {
    id
  }
}
    `;

export const AddMissionToDayComponent = (props: Omit<Urql.MutationProps<AddMissionToDayMutation, AddMissionToDayMutationVariables>, 'query'> & { variables?: AddMissionToDayMutationVariables }) => (
  <Urql.Mutation {...props} query={AddMissionToDayDocument} />
);


export function useAddMissionToDayMutation() {
  return Urql.useMutation<AddMissionToDayMutation, AddMissionToDayMutationVariables>(AddMissionToDayDocument);
};
export const MissionsWithPauschaleDocument = gql`
    query MissionsWithPauschale($day: String!, $patientId: String!) {
  missionsWithPauschale(input: {day: $day, patientId: $patientId}) {
    time
    actionLnrs
    pauschale
    reasonPauschale
    criteriaPauschale {
      hasAtLeastOneGrundpflege
      hasAtLeastOne_31_32_33
      hasSolitaer_3_to_33
      has_29_30
      hasSolitaer_27_28_39_or_30
    }
  }
}
    `;

export const MissionsWithPauschaleComponent = (props: Omit<Urql.QueryProps<MissionsWithPauschaleQuery, MissionsWithPauschaleQueryVariables>, 'query'> & { variables: MissionsWithPauschaleQueryVariables }) => (
  <Urql.Query {...props} query={MissionsWithPauschaleDocument} />
);


export function useMissionsWithPauschaleQuery(options: Omit<Urql.UseQueryArgs<MissionsWithPauschaleQueryVariables>, 'query'>) {
  return Urql.useQuery<MissionsWithPauschaleQuery, MissionsWithPauschaleQueryVariables>({ query: MissionsWithPauschaleDocument, ...options });
};
export const GetMissionsByIdsDocument = gql`
    query GetMissionsByIds($filter: MissionsByIdsFilter!) {
  missionsByIds(filter: $filter) {
    missionPublicationId
    updatedAt
    startTS
    endTS
    duration_min
    memberId
    isActive
    isChecked
    editAllowed
    startTS
    endTS
    patientId
    id
    tourId
    minReqQualification
    patient {
      patientId
      firstName
      lastName
      kkType
      isPrivate
    }
    member {
      shortName
      lastName
      hasExamination
      hasQualificationLevel
    }
    actionsWithQuantity {
      ...max_assigendActionMission
    }
    hasAction
    missionAnalytic {
      totalCashValue
      totalCashValue_NOSAFE
      pauschaleHausType
      pauschaleSaveType
      duration_min
      expectedDuration
      expectedDuration_NOSAFE
      cashPerMinute
      cashPerMinute_NOSAFE
      durationDeviation_min
      totalPauschaleElements
      totalPauschaleCashValue
      timedElements
      complexedGrundElements
      behandlungMaxElements
      behandlungAdditionalElements
      allActions
      grundCashValue
      behandlungMaxCashValue
      timedCashValue
      behandlungAdditionalCashValue
      totalPauschaleElements
    }
  }
}
    ${Max_AssigendActionMissionFragmentDoc}`;

export const GetMissionsByIdsComponent = (props: Omit<Urql.QueryProps<GetMissionsByIdsQuery, GetMissionsByIdsQueryVariables>, 'query'> & { variables: GetMissionsByIdsQueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsByIdsDocument} />
);


export function useGetMissionsByIdsQuery(options: Omit<Urql.UseQueryArgs<GetMissionsByIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsByIdsQuery, GetMissionsByIdsQueryVariables>({ query: GetMissionsByIdsDocument, ...options });
};
export const SplitMissionsDocument = gql`
    mutation SplitMissions($input: InputSplitMissions!) {
  splitMissions(input: $input) {
    id
    tourId
  }
}
    `;

export const SplitMissionsComponent = (props: Omit<Urql.MutationProps<SplitMissionsMutation, SplitMissionsMutationVariables>, 'query'> & { variables?: SplitMissionsMutationVariables }) => (
  <Urql.Mutation {...props} query={SplitMissionsDocument} />
);


export function useSplitMissionsMutation() {
  return Urql.useMutation<SplitMissionsMutation, SplitMissionsMutationVariables>(SplitMissionsDocument);
};
export const GetOneMissionDocument = gql`
    query GetOneMission($id: String!) {
  mission(id: $id) {
    id
    patientId
    memberId
    startTS
    endTS
    day
    isActive
    isChecked
    updatedAt
    actionsWithQuantity {
      quantity
      actionLnr
      action {
        leistungsart
        leistungskomplex
        leistungsinhalte
        examinationRequired
      }
    }
  }
}
    `;

export const GetOneMissionComponent = (props: Omit<Urql.QueryProps<GetOneMissionQuery, GetOneMissionQueryVariables>, 'query'> & { variables: GetOneMissionQueryVariables }) => (
  <Urql.Query {...props} query={GetOneMissionDocument} />
);


export function useGetOneMissionQuery(options: Omit<Urql.UseQueryArgs<GetOneMissionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOneMissionQuery, GetOneMissionQueryVariables>({ query: GetOneMissionDocument, ...options });
};
export const MissionPublicationEditDocument = gql`
    query MissionPublicationEdit($id: String!) {
  missionPublication(id: $id) {
    id
    day
  }
}
    `;

export const MissionPublicationEditComponent = (props: Omit<Urql.QueryProps<MissionPublicationEditQuery, MissionPublicationEditQueryVariables>, 'query'> & { variables: MissionPublicationEditQueryVariables }) => (
  <Urql.Query {...props} query={MissionPublicationEditDocument} />
);


export function useMissionPublicationEditQuery(options: Omit<Urql.UseQueryArgs<MissionPublicationEditQueryVariables>, 'query'>) {
  return Urql.useQuery<MissionPublicationEditQuery, MissionPublicationEditQueryVariables>({ query: MissionPublicationEditDocument, ...options });
};
export const GetMissionsTimeRangeDocument = gql`
    query GetMissionsTimeRange($filter: GlobalTimeRangeFilter!) {
  missionsTimeRange(filter: $filter) {
    updatedAt
    startTS
    endTS
    duration_min
    memberId
    isActive
    startTS
    endTS
    patientId
    id
    patient {
      lastName
      firstName
      longitude
      latitude
      colorBg
      colorTxt
    }
    member {
      lastName
    }
    hasAction
  }
}
    `;

export const GetMissionsTimeRangeComponent = (props: Omit<Urql.QueryProps<GetMissionsTimeRangeQuery, GetMissionsTimeRangeQueryVariables>, 'query'> & { variables: GetMissionsTimeRangeQueryVariables }) => (
  <Urql.Query {...props} query={GetMissionsTimeRangeDocument} />
);


export function useGetMissionsTimeRangeQuery(options: Omit<Urql.UseQueryArgs<GetMissionsTimeRangeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissionsTimeRangeQuery, GetMissionsTimeRangeQueryVariables>({ query: GetMissionsTimeRangeDocument, ...options });
};
export const MissionSetActionDocument = gql`
    mutation MissionSetAction($id: String!, $actionIdsWQ: [ActionIdWithQuantity!]!, $updatedAt: String!) {
  missionSetAction(id: $id, actionIdsWQ: $actionIdsWQ, updatedAt: $updatedAt) {
    id
    actionsWithQuantity {
      quantity
      actionLnr
    }
  }
}
    `;

export const MissionSetActionComponent = (props: Omit<Urql.MutationProps<MissionSetActionMutation, MissionSetActionMutationVariables>, 'query'> & { variables?: MissionSetActionMutationVariables }) => (
  <Urql.Mutation {...props} query={MissionSetActionDocument} />
);


export function useMissionSetActionMutation() {
  return Urql.useMutation<MissionSetActionMutation, MissionSetActionMutationVariables>(MissionSetActionDocument);
};