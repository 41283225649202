import React, { useEffect } from "react";
import LoginButton from "./Auth0/LoginButton";
import inMemoryJwt from "./Auth0/inMemoryJwt";

import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { myClientId } from "../App";

type Props = {};

export const ServerMessage = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <ServerMessage1 />;
};

const ServerMessage1 = (props: Props) => {
  const [infoText, setInfoText] = React.useState("");
  useEffect(() => {
    // const _socket = io(process.env.REACT_APP_SOCKET_ENDPOINT || "", {
    //   auth: {
    //     token: inMemoryJwt.getToken(),
    //   },
    // });
    // //console.log("socket", _socket);
    // _socket.on("connect", () => {
    //   //   toast.success("Connected to Server" + _socket.id);
    //   _socket.on("disconnect", () => {
    //     //   toast.error("Disconnected from Server");
    //   });
    // });
    // _socket.emit("join-room", myClientId);
    // console.log("join-room", myClientId);
    // _socket.on("info-from-server", (args) => {
    //   console.log("hurga " + myClientId, args);
    //   //      setInfoText(args);
    // });
    // _socket.onAny((event, args) => {
    //   setInfoText(args);
    //   //console.log(event, args);
    // });
  }, []);

  return (
    <div className="flex flex-row gap-2">
      <div>{myClientId}</div>
    </div>
  );
};

export default ServerMessage;
