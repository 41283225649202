import React from "react";

import { useTourBuilder } from "./useTourBuilder";

type Props = {};

const TimeRangeSelect = (props: Props) => {
  const { setSelectedTimeRanges, allTimeRanges, selectedTimeRanges } =
    useTourBuilder();

  return (
    <div className="flex flex-row">
      {allTimeRanges.map((timeRange) => {
        return (
          <div className="m-2">
            <div>{timeRange.displayName}</div>
            <input
              type="checkbox"
              checked={selectedTimeRanges.includes(timeRange)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedTimeRanges([...selectedTimeRanges, timeRange]);
                } else {
                  setSelectedTimeRanges(
                    selectedTimeRanges.filter(
                      (selectedTimeRange) => selectedTimeRange !== timeRange
                    )
                  );
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TimeRangeSelect;
