import classNames from "classnames";
import React, { ReactElement } from "react";
import { ActionWithQuantity } from "../../generated/graphql";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import { LocalActionWithQuantity } from "../../type-definitions";
import DisplayCashflow from "../Cashflow/DisplayCashflow";

interface Props {
  action: {
    quantity: number;
    action:
      | {
          leistungsart: string;
          examinationRequired: boolean;
        }
      | undefined
      | null;
  };
}

export default function DisplayAction(props: Props): ReactElement {
  const toggleActionClipboardContent = useActionClipboard(
    (state) => state.toggleActionClipboardContent
  );

  const allActions = useActionClipboard((state) => state.allActions);

  // console.log("DisplayAction", allActions);
  const aq = props.action;

  let reqExaminationLevel = "x";

  if (allActions.length !== 0) {
    const _action = allActions.find(
      (a) => a?.leistungsart === aq?.action?.leistungsart
    );
    if (_action) {
      //   console.log("DisplayAction", _action);
      reqExaminationLevel = _action.requiredQualificationLevel;
    }
  }

  // console.log("DisplayAction", aq, reqExaminationLevel);

  return (
    <div
      className={classNames(
        "p-1",

        {
          "bg-blue-100 border border-blue-300 m-1":
            !aq?.action?.examinationRequired,
        },
        {
          "bg-yellow-100 border border-yellow-200 m-1":
            aq?.action?.examinationRequired,
        },
        {
          "bg-yellow-300 border-yellow-300 m-1": reqExaminationLevel >= "3",
        },
        {
          "bg-gray-300 border-gray-300 m-1": reqExaminationLevel >= "x",
        }
      )}
      onClick={() => {
        toggleActionClipboardContent(aq);
        console.log("ActionClicked", aq);
      }}
    >
      {aq?.action?.leistungsart}

      {aq.quantity === 1 ? "" : <span>({aq.quantity})</span>}
    </div>
  );
}

export function DisplayActionNeu(props: Props): ReactElement {
  const toggleActionClipboardContent = useActionClipboard(
    (state) => state.toggleActionClipboardContent
  );
  const aq = props.action;

  return (
    <div
      className={classNames(
        "p-1",
        {
          "bg-blue-100 border border-blue-300 m-1":
            !aq?.action?.examinationRequired,
        },
        {
          "bg-yellow-100 border border-yellow-200 m-1":
            aq?.action?.examinationRequired,
        }
      )}
      onClick={() => {
        toggleActionClipboardContent(aq);
        console.log("ActionClicked", aq);
      }}
    >
      {aq?.action?.leistungsart}
      {aq.quantity === 1 ? "" : <span>({aq.quantity})</span>}
    </div>
  );
}
