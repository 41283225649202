import dayjs from "dayjs";
import produce from "immer";
import _ from "lodash";
import create from "zustand";

import { graphql, DocumentType } from "../../gql";
import { client } from "../../urqlClient";

import {
  PatientMissionListQuery,
  PatientMissionListQueryVariables,
  PatientMissionListByIdsQuery,
  PatientMissionListByIdsQueryVariables,
  PatientsNoGeoDataQuery,
  PatientsNoGeoDataQueryVariables,
} from "../../gql/graphql";
import { ArrElement } from "../../helpers/typeHelpers";

const PatientsNoGeoData = graphql(/* GraphQL */ `
  query PatientsNoGeoData($filter: PatientsFilter) {
    patients(filter: $filter) {
      patientId
      lastName
      firstName
      zipCode
      city
      address
      isActive
    }
  }
`);

type LocalPatientsNoGeoData = ArrElement<PatientsNoGeoDataQuery["patients"]>;

export type LocalActionWithQuantity_PatientMissionList = ArrElement<
  PatientMissionListQuery["missionsTimeRange"]
>["actionsWithQuantity"];

const PatientMissionList = graphql(/* GraphQL */ `
  query PatientMissionList($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      patientId
      id
      startTS
      endTS
      day
      time
      duration_min
      editAllowed
      tourId
      minReqQualification
      member {
        memberId
        shortName
        hasExamination
        hasQualificationLevel
      }
      patient {
        lastName
        firstName
        isPrivate
      }
      hasAction
      isActive
      #hasMobileExecution
      updatedAt
      missionAnalytic {
        totalCashValue
        totalCashValue_NOSAFE
        pauschaleHausType
        pauschaleSaveType
        duration_min
        expectedDuration
        expectedDuration_NOSAFE
        cashPerMinute
        cashPerMinute_NOSAFE
        durationDeviation_min
        totalPauschaleElements
        totalPauschaleCashValue
        timedElements
        complexedGrundElements
        behandlungMaxElements
        behandlungAdditionalElements
        allActions
        grundCashValue
        behandlungMaxCashValue
        timedCashValue
        behandlungAdditionalCashValue
        totalPauschaleElements
        durationCorrection_min
      }
      actionsWithQuantity {
        quantity
        actionLnr
        action {
          lnr
          leistungsart
          examinationRequired
          leistungsinhalte
          leistungskomplex
          executable
          mobileDisplayGroup
        }
      }
    }
  }
`);

const PatientMissionListByIds = graphql(/* GraphQL */ `
  query PatientMissionListByIds($filter: MissionsByIdsFilter!) {
    missionsByIds(filter: $filter) {
      patientId
      id
      startTS
      endTS
      day
      time
      duration_min
      minReqQualification
      editAllowed
      tourId
      member {
        memberId
        shortName
        hasExamination
        hasQualificationLevel
      }
      patient {
        lastName
        firstName
        isPrivate
      }
      hasAction
      isActive
      #hasMobileExecution
      updatedAt
      missionAnalytic {
        totalCashValue
        totalCashValue_NOSAFE
        pauschaleHausType
        pauschaleSaveType
        duration_min
        expectedDuration
        expectedDuration_NOSAFE
        cashPerMinute
        cashPerMinute_NOSAFE
        durationDeviation_min
        totalPauschaleElements
        totalPauschaleCashValue
        timedElements
        complexedGrundElements
        behandlungMaxElements
        behandlungAdditionalElements
        allActions
        grundCashValue
        behandlungMaxCashValue
        timedCashValue
        behandlungAdditionalCashValue
        totalPauschaleElements
        durationCorrection_min
      }

      actionsWithQuantity {
        quantity
        actionLnr
        action {
          lnr
          leistungsart
          examinationRequired
          leistungsinhalte
          leistungskomplex
          executable
          mobileDisplayGroup
        }
      }
    }
  }
`);

export type LocalPatientMission = ArrElement<
  PatientMissionListQuery["missionsTimeRange"]
>;

type IPatientMissionList = {
  filteredMissions: LocalPatientMission[];
  missionIds: string[];
  clearAll: () => void;
  loadMissions: (patientId: string, maxtDate: string, minDate: string) => void;
  updateMissions: () => void;
  updateMission: (missionId: string) => void;
  patientIds: string[];
  maxDate: string;
  minDate: string;
  isLoading: boolean;
  patientsNoGeoData: LocalPatientsNoGeoData[];
  loadPatientsNoGeoData: () => void;
};

export const usePatientMissionList = create<IPatientMissionList>(
  (set, get) => ({
    filteredMissions: [],
    patientsNoGeoData: [],
    isLoading: false,
    patientIds: [],
    maxDate: "",
    minDate: "",
    missionIds: [],
    loadMissions: async (
      patientId: string,
      minDate: string,
      maxDate: string
    ) => {
      set({ isLoading: true });
      const patientIds = [] as string[];
      patientIds.push(patientId);
      set({ patientIds, minDate, maxDate });
      const missionsTimeRange = await client
        .query<PatientMissionListQuery, PatientMissionListQueryVariables>(
          PatientMissionList,
          {
            filter: {
              patientIds: patientIds,
              minDate: minDate,
              maxDate: maxDate,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          set({
            filteredMissions:
              _.orderBy(
                result?.data?.missionsTimeRange,
                ["day", "time"],
                ["desc", "asc"]
              ) || [],
          });
        });
      set({ isLoading: false });
    },
    updateMissions: async () => {
      console.log("updateMissions");
      set({ isLoading: true });
      const missionsTimeRange = await client
        .query<PatientMissionListQuery, PatientMissionListQueryVariables>(
          PatientMissionList,
          {
            filter: {
              patientIds: get().patientIds,
              minDate: get().minDate,
              maxDate: get().maxDate,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          set({
            filteredMissions:
              _.orderBy(
                result?.data?.missionsTimeRange,
                ["day", "time"],
                ["desc", "asc"]
              ) || [],
          });
        });
      set({ isLoading: false });
    },
    updateMission: async (missionId: string) => {
      set({ isLoading: true });
      const missionsIds = [] as string[];
      missionsIds.push(missionId);

      console.log(missionsIds);

      //lastUpdatedAt: (dayjs().unix() * 1000).toString(),

      const mission = await client
        .query<
          PatientMissionListByIdsQuery,
          PatientMissionListByIdsQueryVariables
        >(
          PatientMissionListByIds,
          {
            filter: {
              missionIds: missionsIds,
              updatedLater: (
                dayjs().subtract(100, "minute").unix() * 1000
              ).toString(),
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          console.log(result);
          if (
            result?.data?.missionsByIds !== undefined &&
            result?.data?.missionsByIds !== null
          ) {
            const _newMission = result?.data?.missionsByIds[0];
            console.log("newMission", _newMission);
            if (_newMission !== undefined) {
              const _newMissions = produce(get().filteredMissions, (draft) => {
                const index = draft.findIndex(
                  (mission) => mission.id === missionId
                );
                if (index !== -1 && _newMission !== undefined) {
                  draft[index] = _newMission!;
                }
              });

              set({ filteredMissions: _newMissions });
            }
          }
        });
      set({ isLoading: false });
    },
    clearAll: () => {
      set({
        filteredMissions: [],
        patientIds: [],
        maxDate: "",
        minDate: "",
        missionIds: [],
      });
    },
    loadPatientsNoGeoData: async () => {
      const patientsNoGeoData = await client
        .query<PatientsNoGeoDataQuery, PatientsNoGeoDataQueryVariables>(
          PatientsNoGeoData,
          {
            filter: {
              hasGeo: false,
              daysOfLastMission: 30,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          set({
            patientsNoGeoData: result?.data?.patients || [],
          });
        });
    },
  })
);
