import React, { useEffect, useState } from "react";
import WeekdaySelector, { SelectedIsoDay } from "./WeekdaySelector";

import { usePlanSchedule } from "./usePlanSchedule";
import PlanScheduleRow from "./PlanScheduleDayRow";
import TimeSelector from "./TimeSelectorPlanSchedule";
import { Button } from "../../UiComponents/Button";
import ActionClipboard from "../../ActionClipboard/ActionClipboard";
import PatientSearchbox from "../../PatientSearchbox/PatientSearchbox";
import { DisplayPatientInfo } from "../../Patient/DisplayPatientInfo";
import { DisplayFullPatientInfo } from "../../Patient/DisplayFullPatientInfo";
import PlanScheduleEveryRow from "./PlanScheduleEveryRow";
import inMemoryJwt from "../../Auth0/inMemoryJwt";
import LoginButton from "../../Auth0/LoginButton";

const isoDaysReference: SelectedIsoDay[] = [
  {
    id: "6",
    isSelected: false,
    shortName: "Sa",
    isoDayNumber: 6,
  },
  {
    id: "7",
    isSelected: false,
    shortName: "So",
    isoDayNumber: 7,
  },
  {
    id: "1",
    isSelected: false,
    shortName: "Mo",
    isoDayNumber: 1,
  },
  {
    id: "2",
    isSelected: false,
    shortName: "Di",
    isoDayNumber: 2,
  },
  {
    id: "3",
    isSelected: false,
    shortName: "Mi",
    isoDayNumber: 3,
  },
  {
    id: "4",
    isSelected: false,
    shortName: "Do",
    isoDayNumber: 4,
  },
  {
    id: "5",
    isSelected: false,
    shortName: "Fr",
    isoDayNumber: 5,
  },
];
type Props = {};

export const PlanSchedule = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return <PlanSchedule1 />;
};

const PlanSchedule1 = (props: Props) => {
  const {
    scheduleDays,
    scheduleEverys,
    loadEverySchedules,
    loadDaySchedules,
    createScheduleDay,
    createScheduleEvery,
    setPatientId,
    patientId,
    timeGroups,
  } = usePlanSchedule();

  const [selectedIsoDays, setSelectedIsoDays] = useState<SelectedIsoDay[]>([
    ...isoDaysReference,
  ]);

  useEffect(() => {
    loadDaySchedules();
    loadEverySchedules();
  }, []);

  return (
    <div>
      <div className="flex flex-row ">
        <div className="w-96">
          <DisplayFullPatientInfo patientId={patientId} />
        </div>

        <div>
          <PatientSearchbox
            onSelect={(patientId) => {
              setPatientId(patientId);
            }}
          />
        </div>
        <div>
          <Button
            onClick={() => {
              loadDaySchedules();
              loadEverySchedules();
            }}
          >
            Update
          </Button>
        </div>
      </div>

      <div className="bg-white w-96">
        <ActionClipboard />
      </div>
      <div className="min-w-[1000px]  w-6/12">
        <div className="p-2 text-center text-xl font-semibold bg-gray-50">
          Wöchentliche Planung
        </div>
        <div className="flex flex-col gap-2">
          {timeGroups.map((timeGroup) => {
            const _filteredSchedules = scheduleDays.filter((schedule) => {
              return schedule.timeGroup === timeGroup;
            });
            return (
              <div className="">
                {_filteredSchedules.map((schedule) => {
                  return (
                    <div key={schedule.id}>
                      <PlanScheduleRow schedule={schedule} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div>
          <Button
            onClick={() => {
              createScheduleDay({
                acceptedMaxStartTime: "12:00",
                acceptedMinStartTime: "08:00",
                preferedMaxStartTime: "12:00",
                preferedMinStartTime: "08:00",
                patientId: patientId,
                count: 1,
                shiftId: "f",
                weekTurnus: 1,
                allCodedActions: "",
                allDayIds: [],
                preferedDayIds: [],
              });
            }}
          >
            Neu Wöchentlich
          </Button>
        </div>
        <div className="p-2 text-center text-xl font-semibold bg-gray-50">
          Tag über Tag Planung
        </div>
        <div className="flex flex-col gap-2">
          {scheduleEverys.map((schedule) => {
            return (
              <div key={schedule.id}>
                <PlanScheduleEveryRow schedule={schedule} />
              </div>
            );
          })}
        </div>
        <Button
          onClick={() => {
            createScheduleEvery({
              acceptedMaxStartTime: "12:00",
              acceptedMinStartTime: "08:00",
              preferedMaxStartTime: "12:00",
              preferedMinStartTime: "08:00",
              patientId: patientId,
              shiftId: "f",
              allCodedActions: "",
              everyType: "day",
              every: 2,
            });
          }}
        >
          Neu
        </Button>
        <div className="p-2 text-center text-xl font-semibold bg-gray-50">
          Tagesübersicht
        </div>
        <Button>Neu</Button>
      </div>
    </div>
  );
};

export default PlanSchedule;
