import React, { useEffect, useRef } from "react";
import { Map, MapRef, Marker } from "react-map-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { BiAddToQueue } from "react-icons/bi";

type Props = {
  longitude: number | undefined | null;
  latitude: number | undefined | null;
};

const PatientMap = (props: Props) => {
  const { longitude, latitude } = props;

  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapRef.current === null) return;

    const map = new maplibregl.Map({
      container: "myMap",
      //style: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
      //   style:
      //   style:        "https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_col.json",
      style: "/style_08_58_48.json",
      //style: "/style_09_28_45.json",

      center: [longitude || 6.93191365074411, latitude || 51.40530535770421],
      zoom: 10,
      attributionControl: false,
    });

    const marker = new maplibregl.Marker();

    marker
      .setLngLat([longitude || 6.93191365074411, latitude || 51.40530535770421])
      .addTo(map);
  }, [longitude, latitude]);

  return (
    <div className="w-96 h-48" id="myMap" ref={mapRef}>
      {/* <Map
        ref={mapRef}
        initialViewState={{
          latitude: 50.771584814384916,
          longitude: 6.082608616157349,
          zoom: 10,
        }}
        mapLib={maplibregl}
        style={{ width: 200, height: 200 }}
        //mapStyle={"/style_08_58_48.json"}
        // mapStyle="https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json"
        //mapStyle="https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json"
        mapStyle={
          "https://sgx.geodatenzentrum.de/gdz_basemapde_vektor/styles/bm_web_col.json"
        }
      >
        <Marker
          key="sdfsfsdfsfsfsf"
          longitude={6.082608616157349}
          latitude={50.771584814384916}
        >
          <BiAddToQueue className="text-green-500 h-4 w-4" />
        </Marker>
      </Map> */}
    </div>
  );
};

export default PatientMap;
