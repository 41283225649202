import React, { useCallback, useEffect } from "react";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { useReviewExecutionMobile } from "./useReviewExecutionMobile";
import dayjs from "dayjs";
import { Dialog, DialogTrigger } from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { DialogContent } from "../UiComponents/Dialog";
import {
  ChevronDown,
  ChevronRightIcon,
  CircleHelp,
  Edit2Icon,
  Frown,
  GroupIcon,
  Hand,
  SquareCheckBig,
  TriangleAlert,
  UngroupIcon,
} from "lucide-react";
import { House } from "lucide-react";

import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";

import _, { set } from "lodash";

import { cn } from "../../helpers/utils";
import { Link } from "react-router-dom";

import {
  LocalTourStatExecutions_ReviewExecutionMobile,
  useReviewExecutionMobile1,
} from "./useReviewExecutionMobile1";

import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  GroupingState,
  useReactTable,
} from "@tanstack/react-table";
import { info } from "console";
import { DisplayMemberSmall } from "../Member/DisplayMemberSmall";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import WeekSelect from "../DateRangeSelector/WeekSelectStartSa";

const DisplayTimeDiffence = (props: {
  durationPlanned: number;
  durationExecuted: number;
}) => {
  const { durationPlanned, durationExecuted } = props;

  console.log(durationPlanned, durationExecuted);

  if (durationExecuted === 0)
    return (
      <div className="p-1 bg-yellow-400">
        <TriangleAlert className=" text-black " size={17} />
      </div>
    );

  if (durationPlanned === 0) {
    return (
      <div className="p-1 bg-red-400">
        <TriangleAlert className=" text-black " size={17} />
      </div>
    );
  }

  if (
    (durationExecuted / durationPlanned > 1.1 &&
      durationExecuted - durationPlanned < 3) ||
    (durationExecuted / durationPlanned < 0.7 &&
      durationExecuted - durationPlanned < -2)
  ) {
    return (
      <div className="p-1 bg-red-500 text-white">
        <div>{durationExecuted - durationPlanned}</div>
      </div>
    );
  }

  return (
    <div className="p-1">
      <div>{durationExecuted - durationPlanned}</div>
    </div>
  );
};

type Props = {};

export const ReviewExecutionMobile = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return (
    <>
      {/* <AllReviewExecutionMobile /> */}
      <ReviewExecutionMobile1 />
    </>
  );
};

export const resultToIcon = (result: string) => {
  switch (result) {
    case "success":
      return (
        <SquareCheckBig className="bg-gray-100 text-green-500" size={17} />
      );
    case "no-open":
      return <House size={17} className="bg-gray-100 text-red-500" />;
    case "no-allow":
      return <Hand size={17} className="bg-gray-100 text-red-500" />;
    case "not-possible":
      return <Frown size={17} className="bg-gray-100 text-red-500" />;
    default:
      return <CircleHelp size={17} className="bg-gray-100 text-red-500" />;
  }
};

// const ReviewExecutionMobile1 = (props: Props) => {
//   const {
//     loadData,
//     distinctDays,
//     missionsMap,
//     setSelectedMission,
//     selectedMemberId,
//     selectedTourId,
//     startDay,
//     endDay,
//     analyzeTour,
//     loadTours,
//     loadAllMissionsWithComments,
//     missionActionCompareResultsMap,
//     actionsMap,
//   } = useReviewExecutionMobile();

//   const [openEditExecutionMobile, setOpenEditExecutionMobile] =
//     React.useState(false);

//   const _deferredLoadData = useCallback(_.debounce(loadData, 700), []);

//   const handleTabVisible = () => {
//     console.log("Tab is visible");
//     // Your custom function logic here
//     toast.success("Wilkommen zurück!", {
//       position: "top-center",
//     });
//     _deferredLoadData();
//   };

//   //sePageVisibility(handleTabVisible);

//   return (
//     <div className="bg-white">

//       <div className="flex flex-row gap-2 sticky top-0 bg-white p-2">
//         <Button
//           onClick={() => {
//             loadData();
//           }}
//         >
//           Neu Laden
//         </Button>
//         <Dialog>
//           <DialogTrigger>
//             <Button>All With Comments</Button>
//           </DialogTrigger>
//           <DialogContent className="w-[80%] h-[80%] overflow-y-hidden">
//             <AllMissionsWithComments />
//           </DialogContent>
//         </Dialog>
//         <Dialog>
//           <DialogTrigger>
//             <Button>Tour auswählen</Button>
//           </DialogTrigger>
//           <DialogContent className="w-[80%] h-[80%] overflow-y-scroll">
//             <SelectWeekTourId />
//           </DialogContent>
//         </Dialog>
//         <div className="font-semibold">
//           {dayjs(startDay).format("ddd, DD.MMM")} -{" "}
//           {dayjs(endDay).format("ddd, DD.MMM")}
//         </div>
//         <div className="font-semibold">{selectedTourId}</div>
//       </div>
//       <div className="flex flex-row gap-2">
//         {distinctDays.map((day) => {
//           const _missions = missionsMap?.get(day);

//           const _distinctMembers = _.chain(_missions)
//             .uniqBy("memberId")
//             .value();

//           if (_distinctMembers.length !== 1)
//             return <div>Nicht eindeutige Mitarbeier</div>;

//           const _distinctMember = _distinctMembers[0].member;

//           return (
//             <div className="flex flex-col gap-2 w-64 bg-gray-100 p-2" key={day}>
//               <div className="text-white bg-gray-400 p-1 flex flex-row justify-between sticky top-10">
//                 <div className="flex flex-col">
//                   <div className="flex flex-row gap-2">
//                     <div>{dayjs(day).format("ddd, DD.MMMM")}</div>
//                     <div>{_distinctMember?.shortName}</div>

//                     <Link
//                       target="_blank"
//                       className="cursor-pointer"
//                       to={`/reviewonedayexecution?tourId=${selectedTourId}&day=${day}`}
//                     >
//                       <Button className="text-xs">E</Button>
//                     </Link>
//                   </div>
//                   <div>
//                     <ExecutionTourStat day={day} tourId={selectedTourId} />
//                   </div>
//                 </div>
//               </div>

//               {missionsMap?.get(day)?.map((mission) => {
//                 const hasMobileExecWithComment = mission.executionMobiles.some(
//                   (executionMobile) => executionMobile.remark !== ""
//                 );

//                 const _missionActionCompareResult =
//                   missionActionCompareResultsMap?.get(mission.id);

//                 const successExecutions = mission.executionMobiles.filter(
//                   (executionMobile) => executionMobile.result === "success"
//                 );

//                 const sucessDuration = successExecutions.reduce(
//                   (acc, executionMobile) => acc + executionMobile.duration_min,
//                   0
//                 );

//                 return (
//                   <div className="flex flex-col" key={mission.id}>
//                     <div className="flex fle-row justify-between items-center">
//                       <div className=" flex flex-row gap-2 items-center">
//                         <div
//                           className={cn("font-semibold", {
//                             "bg-red-300": !mission.isActive,
//                           })}
//                         >
//                           {mission.patient?.shortName}
//                         </div>
//                         <DisplayPatientIdWithLink
//                           className="text-xs"
//                           patientId={mission.patientId}
//                         />
//                       </div>
//                       {/* <div className="flex flex-row gap-1 justify-between items-center p-1"></div> */}
//                       <div className="flex flex-row justify-end items-center">
//                         <div>
//                           <Popover>
//                             <PopoverTrigger>
//                               <Button variant={"neutral-ghost"}>
//                                 <InfoIcon className="text-blue-500" size={15} />
//                               </Button>
//                             </PopoverTrigger>
//                             <PopoverContent className="text-xs">
//                               <DisplayMissionAnalytic missionId={mission.id} />
//                             </PopoverContent>
//                           </Popover>
//                         </div>

//                         <div>
//                           {hasMobileExecWithComment && (
//                             <div>
//                               <MessageSquare size={15} />
//                             </div>
//                           )}
//                         </div>
//                         <Button
//                           variant={"neutral-ghost"}
//                           onClick={() => {
//                             setSelectedMission(mission);
//                             setOpenEditExecutionMobile(true);
//                           }}
//                         >
//                           <Edit2Icon size={15} />
//                         </Button>
//                       </div>
//                     </div>
//                     <div className="flex flex-row gap-2 p-1">
//                       <div className="flex flex-col gap-2 w-1/2 bg-white">
//                         <div className="flex flex-row justify-between items-center">
//                           <div className="text-xs">
//                             {mission.time} ({mission.duration_min})
//                           </div>

//                           <div>
//                             <DisplayTimeDiffence
//                               durationExecuted={sucessDuration}
//                               durationPlanned={mission.duration_min}
//                             />
//                           </div>
//                         </div>
//                         {/* <div className="flex  flex-row gap-1">
//                           {mission.actionsWithQuantity?.map((awq) => {
//                             return <div>{awq?.actionLnr}</div>;
//                           })}
//                         </div> */}
//                       </div>

//                       <div className="flex flex-col gap-2 bg-gray-100 w-1/2">
//                         {mission.executionMobiles.map((executionMobile) => {
//                           if (executionMobile.isInvalid) return null;
//                           return (
//                             <div
//                               key={executionMobile.id}
//                               className="flex flex-col"
//                             >
//                               <div className="w-full flex flex-row justify-between bg-white p-1 gap-1 items-center">
//                                 <div>
//                                   {resultToIcon(executionMobile.result)}
//                                 </div>
//                                 <div>
//                                   {dayjs(executionMobile.startTS_iso).format(
//                                     "HH:mm"
//                                   )}
//                                 </div>
//                                 <div>{executionMobile.duration_min}</div>
//                               </div>
//                               {/* <div>{executionMobile.codedActions}</div> */}
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </div>
//                     {_missionActionCompareResult && (
//                       <div className="bg-white p-1 flex flex-row gap-2">
//                         {_missionActionCompareResult.identical.map((result) => {
//                           if (!result) return null;
//                           if (!result.lnr) return null;
//                           const action = actionsMap?.get(result.lnr.toString());
//                           return (
//                             <div className="bg-green-100 p-1 text-xs">
//                               {action?.action?.leistungskomplex}
//                             </div>
//                           );
//                         })}
//                         {_missionActionCompareResult.onlyMission.map(
//                           (result) => {
//                             if (!result) return null;
//                             if (!result.lnr) return null;
//                             const action = actionsMap?.get(
//                               result.lnr.toString()
//                             );

//                             return (
//                               <div className="bg-red-100 p-1 text-xs">
//                                 {action?.action?.leistungskomplex}
//                               </div>
//                             );
//                           }
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 );
//               })}
//             </div>
//           );
//         })}
//       </div>
//       <Dialog
//         open={openEditExecutionMobile}
//         onOpenChange={setOpenEditExecutionMobile}
//       >
//         <DialogContent className="w-[60%] h-[80%]">
//           <EditExecutionMobile />
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// };

const ReviewExecutionMobile1 = (props: Props) => {
  const {
    loadData,
    setStartEndDay,
    startDay,
    endDay,
    tourStatExecutions,
    distinctDays,
    distinctTourIds,
    syncSupabase,
  } = useReviewExecutionMobile1();

  const [grouping, setGrouping] = React.useState<GroupingState>(["tourId"]);

  useEffect(() => {
    setStartEndDay({
      startDay: dayjs(getStartDateOfWeek(dayjs().toISOString())).format(
        "YYYY-MM-DD"
      ),
      endDay: dayjs(getEndDateOfWeek(dayjs().toISOString())).format(
        "YYYY-MM-DD"
      ),
    });

    syncSupabaseAllDays();

    loadData();
  }, []);

  const syncSupabaseAllDays = () => {
    distinctDays.forEach((day) => {
      syncSupabase(day);
    });
  };

  const columns = React.useMemo<
    ColumnDef<LocalTourStatExecutions_ReviewExecutionMobile>[]
  >(
    () => [
      {
        header: "Tour",
        accessorKey: "tourId",
        cell: (info) => info.getValue(),
      },
      {
        header: "Mitarbeiter",
        accessorKey: "member.shortName",
        aggregationFn: "unique",

        cell: ({ row }) => {
          const _member = row.original.member;
          return (
            <div>
              <DisplayMemberShortName {..._member} />
            </div>
          );
        },
        aggregatedCell: (info) => {
          const _subrows = info.row.subRows;

          const values = _.uniqBy(_subrows, "original.memberId");

          return (
            <div className="flex flex-row gap-1 flex-wrap">
              {values.map((value) => {
                const _member = value.original.member;
                return (
                  <div className="text-xs flex flex-row flex-wrap">
                    <DisplayMemberShortName {..._member} />
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        header: "Tag",
        accessorKey: "dayOfWeek",
        cell: (info) => {
          const _day = info.getValue() as string;
          const _date = dayjs(info.row.original.day).format("ddd DD MMM");
          const _countOpenIssues = info.row.original.countOpenIssues;
          const isWeekday = dayjs(info.row.original.day).day() < 6;

          return (
            <div
              className={cn("p-1", {
                "bg-blue-50": isWeekday,
                "bg-yellow-50": !isWeekday,
              })}
            >
              <div className="flex flex-row gap-2 items-center">
                {_date}
                {_countOpenIssues > 0 && (
                  <div className="text-xs text-red-500">
                    ({_countOpenIssues})
                  </div>
                )}
              </div>
            </div>
          );
        },
        aggregatedCell: (info) => {
          //const values = info.cell.getValue() as string[];

          const _row = info.row;
          const values = _row.getLeafRows();

          //console.log("In aggregatedCell", _subrows);

          return (
            <div className="flex flex-row gap-1">
              {values.map((value) => {
                // const isWeekday = dayjs(info.row.original.day).day() < 6;
                // console.log("In day", info.row.original.day);
                const _day = value.original.day;

                const isWeekday =
                  dayjs(_day).day() < 6 && dayjs(_day).day() > 0;
                const tourReviewStatus = value.original.tourReviewStatus;

                return (
                  <div
                    className={cn("text-xs p-1", {
                      "bg-blue-50 border-b-4 border-blue-50 border-t-4 ":
                        isWeekday,
                      "bg-yellow-50 border-b-4 border-yellow-50 border-t-4":
                        !isWeekday,
                      "border-b-4 border-b-yellow-400":
                        tourReviewStatus === "touched",
                      "border-b-4 border-b-green-400":
                        tourReviewStatus === "reviewed",
                      "border-t-4 border-t-red-400":
                        value.original.countOpenIssues > 0,
                    })}
                  >
                    {value.original.dayOfWeek}
                  </div>
                );
              })}
            </div>
          );
        },

        aggregationFn: "unique",
      },
      {
        accessorKey: "ratioCountExecuted",
        header: "Anzahl",
        cell: (info) => info.getValue(),

        aggregatedCell: ({ getValue }) => {
          return <div>{Math.round(getValue<number>() * 100) / 100}%</div>;
        },

        aggregationFn: "mean",

        enableGrouping: false,
      },

      // {
      //   header: () => (
      //     <div className="text-xs">
      //       <div>Anz</div>
      //       <div>Soll</div>
      //     </div>
      //   ),
      //   accessorKey: "countMissions",
      //   cell: (info) => info.getValue(),
      //   enableGrouping: false,
      // },
      // {
      //   header: () => (
      //     <div className="text-xs">
      //       <div>Anz</div>
      //       <div>Erl</div>
      //     </div>
      //   ),
      //   accessorKey: "countExecutions",
      //   enableGrouping: false,

      //   cell: ({ row }) => {
      //     const _countExecutions = row.original.countExecutions;
      //     const _countMissions = row.original.countMissions;

      //     const diff = _countMissions - _countExecutions;

      //     return (
      //       <div
      //         className={cn("text-center", {
      //           "bg-red-100": diff > 0,
      //           "bg-green-100": diff === 0,
      //         })}
      //       >
      //         {_countExecutions}
      //       </div>
      //     );
      //   },
      // },
      {
        accessorKey: "ratioTimeExecuted",
        header: "Zeit",
        enableGrouping: false,
        cell: (info) => info.getValue(),
        aggregatedCell: ({ getValue }) => {
          return <div>{Math.round(getValue<number>() * 100) / 100}%</div>;
        },
        aggregationFn: "mean",
      },

      {
        header: "Aktion",
        enableGrouping: false,
        cell: ({ row }) => {
          const _tourId = row.original.tourId;
          const _day = row.original.day;
          return (
            <Link
              target="_blank"
              to={`/reviewonedayexecution?tourId=${_tourId}&day=${_day}`}
              className="text-xs cursor-pointer"
            >
              <Button variant={"neutral-ghost"}>
                <Edit2Icon size={18} />
              </Button>
            </Link>
          );
        },
      },
    ],
    [distinctDays]
  );

  const table = useReactTable({
    columns: columns,
    data: tourStatExecutions,
    state: {
      grouping,
    },
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    renderFallbackValue: () => {
      return <div>Huhu</div>;
    },
    aggregationFns: {
      myAggFn: (columnId, leafRows, childRows) => {
        return "Huhu";
      },
    },
  });

  return (
    <div className="bg-white flex flex-col gap-4">
      <div className="flex flex-row bg-grey-100 gap-2 p-2">
        <Button
          onClick={() => {
            syncSupabaseAllDays();
            loadData();
          }}
        >
          Laden
        </Button>
        <WeekSelect
          currentWeek={{
            startDay: startDay,
            endDay: endDay,
          }}
          setWeek={setStartEndDay}
          updateData={loadData}
        />

        <Button>Command</Button>
        <Button>Command</Button>
        <Button>Command</Button>
        <Button>Command</Button>
      </div>
      <div>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div className="flex flex-row gap-2 items-center justify-center">
                          {header.column.getCanGroup() ? (
                            // If the header can be grouped, let's add a toggle
                            <button
                              {...{
                                onClick:
                                  header.column.getToggleGroupingHandler(),
                                style: {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {header.column.getIsGrouped() ? (
                                <div className="flex flex-row gap-2 items-center">
                                  <UngroupIcon size={18} />
                                </div>
                              ) : (
                                <GroupIcon size={18} />
                              )}
                            </button>
                          ) : null}{" "}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="border-2 border-gray-200">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={cn("p-1 text-center", {
                          // "bg-gray-200": cell.getIsGrouped(),
                          // "bg-yellow-100": cell.getIsAggregated(),
                        })}
                        {...{
                          key: cell.id,
                        }}
                      >
                        {cell.getIsGrouped() ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <button
                              className="flex flex-row"
                              {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                  cursor: row.getCanExpand()
                                    ? "pointer"
                                    : "normal",
                                },
                              }}
                            >
                              {row.getIsExpanded() ? (
                                <ChevronDown />
                              ) : (
                                <ChevronRightIcon />
                              )}{" "}
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}{" "}
                              ({row.subRows.length})
                            </button>
                          </>
                        ) : cell.getIsAggregated() ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          flexRender(
                            cell.column.columnDef.aggregatedCell ??
                              cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewExecutionMobile;
