import React from "react";

import { useGetOnePatientQuery } from "../../generated/graphql";

interface Props {
  patientId: string;
}

export const DisplayFullPatientInfo = (props: Props) => {
  const [result, reececuteQuery] = useGetOnePatientQuery({
    variables: { id: props.patientId },
  });

  const { data, error, fetching } = result;
  if (fetching) return <div>loading</div>;
  if (error)
    return (
      <div>
        E: {props.patientId} {JSON.stringify(error, null, 2)}
      </div>
    );

  return (
    <>
      <div className="text-gray-600 my-2 bg-white">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row">
            <div className="flex text-xl mr-2">{data?.patient?.lastName}, </div>
            <div className="flex text-xl ">{data?.patient?.firstName}</div>
          </div>
          <div className="flex">
            <div className="flex text-sm font-light ">
              ({data?.patient?.patientId})
            </div>
          </div>
        </div>

        <span className="title text-xs text-gray-500 mt-1">
          {data?.patient?.address},{" "}
        </span>
        <span className="title text-xs text-gray-500 mt-1">
          {data?.patient?.zipCode} {data?.patient?.city}
        </span>
        <div>
          {data?.patient?.isActive === false ? (
            <div className="text-red-600 font-bold">Inaktiv !!!!</div>
          ) : null}
        </div>
        <div>{data?.patient?.kkType}</div>
      </div>
    </>
  );
};
