import { create } from "zustand";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import dayjs from "dayjs";
import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import {
  GetPatientIssuesQuery,
  GetPatientIssuesQueryVariables,
  MissionsTimeRange_PatientIssuesQuery,
  MissionsTimeRange_PatientIssuesQueryVariables,
} from "../../gql/graphql";
import { client } from "../../urqlClient";
import _ from "lodash";
import { toast } from "react-toastify";

const SingleDistance_PatientIssues = graphql(/* GraphQL */ `
  query SingleDistance_PatientIssues(
    $fromPatientId: String!
    $toPatientId: String!
  ) {
    singleDistance(fromPatientId: $fromPatientId, toPatientId: $toPatientId) {
      distance_km
      travelTime_min
    }
  }
`);

const MissionsTimeRange_PatientIssues = graphql(/* GraphQL */ `
  query MissionsTimeRange_PatientIssues($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      time
      day
      duration_min
      patientId
      tourId
      patient {
        lastName
        firstName
      }
    }
  }
`);

const GetPatientIssues = graphql(/* GraphQL */ `
  query GetPatientIssues($minDate: String!, $maxDate: String!) {
    performanceIssues(minDate: $minDate, maxDate: $maxDate) {
      patientId
      countUnderPerf
      countOverPerf
      countAllMissions
      totalOverPerf_min
      totalUnderPerf_min
      totalCompensationUnderPerf_min
      totalCompensationOverPerf_min
      sumTotalCashValue
      sumCashValue_NOSAVE
      avgCashPerMinute
      avgCashPerMinute_NOSAVE
      sumTime

      patient {
        lastName
        firstName
      }
      # overPerformanceMissions {
      #   id
      #   time
      #   day
      #   duration_min
      #   missionAnalytic {
      #     cashPerMinute
      #     durationCorrection_min
      #     expectedDurationCalc_NOSAFE
      #   }
      # }
      # underPerformanceMissions {
      #   id
      #   time
      #   day
      #   duration_min
      #   missionAnalytic {
      #     cashPerMinute
      #     durationCorrection_min
      #     expectedDurationCalc_NOSAFE
      #   }
      # }
      # noActionMissions {
      #   id
      #   time
      #   day
      # }
    }
  }
`);

export type LocalPatientsIssue = ArrElement<
  GetPatientIssuesQuery["performanceIssues"]
>;

export type LocalMission_PatientIssue = ArrElement<
  MissionsTimeRange_PatientIssuesQuery["missionsTimeRange"]
>;

type AnalyzeResult = {
  day: string;
  tourId: string;
  currentPatientId: string;
  curentPatientName: string;
  bevorPatientId: string;
  beforePatientName: string;
  afterPatientName: string;
  afterPatientId: string;
  travelTimeToPatient_min: number;
  travelTimeFromPatient_min: number;
  distanceToPatient_km: number;
  distanceFromPatient_km: number;
};

type PatientIssueState = {
  minDate: string;
  maxDate: string;
  allMissions: LocalMission_PatientIssue[];
  analyzeResults: AnalyzeResult[];
  patientIssues: LocalPatientsIssue[];
  loadPatientIssues: (minDate: string, maxDate: string) => void;
  isLoading: boolean;
  analyzeTravelTime: (patientId: string) => void;
};

export const usePatientIssue = create<PatientIssueState>((set, get) => ({
  minDate: dayjs(getStartDateOfWeek()).format("YYYY-MM-DD"),
  maxDate: dayjs(getEndDateOfWeek()).format("YYYY-MM-DD"),
  patientIssues: [],
  allMissions: [],
  isLoading: false,
  analyzeResults: [],
  loadPatientIssues: async (minDate, maxDate) => {
    set({ isLoading: true });

    client
      .query<
        MissionsTimeRange_PatientIssuesQuery,
        MissionsTimeRange_PatientIssuesQueryVariables
      >(
        MissionsTimeRange_PatientIssues,
        {
          filter: {
            minDate,
            maxDate,
          },
        },
        { requestPolicy: "network-only" }
      )
      .toPromise()
      .then((result) => {
        set({ allMissions: result?.data?.missionsTimeRange || [] });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        set({ isLoading: false });
      });

    client
      .query<GetPatientIssuesQuery, GetPatientIssuesQueryVariables>(
        GetPatientIssues,
        {
          minDate,
          maxDate,
        },
        { requestPolicy: "network-only" }
      )
      .toPromise()
      .then((result) => {
        set({
          isLoading: false,
          patientIssues: _.orderBy(
            result?.data?.performanceIssues,
            "patient.lastName",
            "asc"
          ),
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        set({ isLoading: false });
      });
  },
  analyzeTravelTime: async (patientId) => {
    // get all missions for this patient
    const patientMissions = get().allMissions.filter(
      (mission) => mission.patientId === patientId
    );

    set({ analyzeResults: [] });

    const _analyzeResults = get().analyzeResults;

    for (let m of patientMissions) {
      const _completeTour = _.chain(get().allMissions)
        .filter((mission) => {
          return mission.day === m.day && mission.tourId === m.tourId;
        })
        .orderBy("time", "asc")
        .value();

      // find mission before and after the mision of the patient

      const index = _.findIndex(
        _completeTour,
        (mission) => mission.id === m.id
      );
      if (index === -1) return;
      if (index > 1 && index < _completeTour.length - 1) {
        const _beforeMission = _completeTour[index - 1];
        const _afterMission = _completeTour[index + 1];

        const bevoreCurrent = await client
          .query(SingleDistance_PatientIssues, {
            fromPatientId: _beforeMission.patientId,
            toPatientId: m.patientId,
          })
          .toPromise();

        const afterCurrent = await client
          .query(SingleDistance_PatientIssues, {
            fromPatientId: m.patientId,
            toPatientId: _afterMission.patientId,
          })
          .toPromise();

        _analyzeResults.push({
          day: m.day,
          tourId: m.tourId || "",
          currentPatientId: m.patientId,
          curentPatientName: m.patient?.lastName || "",
          beforePatientName: _beforeMission.patient?.lastName || "",
          afterPatientName: _afterMission.patient?.lastName || "",
          bevorPatientId: _beforeMission.patientId,
          afterPatientId: _afterMission.patientId,
          travelTimeToPatient_min: _.round(
            afterCurrent.data?.singleDistance?.travelTime_min || 0,
            2
          ),
          travelTimeFromPatient_min: _.round(
            bevoreCurrent.data?.singleDistance?.travelTime_min || 0,
            2
          ),
          distanceToPatient_km: _.round(
            afterCurrent.data?.singleDistance?.distance_km || 0,
            2
          ),
          distanceFromPatient_km: _.round(
            bevoreCurrent.data?.singleDistance?.distance_km || 0,
            2
          ),
        });
      }

      // update mission with travel time correction
    }

    console.log(_analyzeResults);
    set({ analyzeResults: _.orderBy(_analyzeResults, "day", "asc") });
  },
}));
