import { create } from "zustand";
import { graphql } from "../../gql";
import { ArrElement } from "@/src/helpers/typeHelpers";
import {
  TourDatas_TourPublishQuery,
  TourDatas_TourPublishQueryVariables,
} from "@/src/gql/graphql";
import { client } from "../../urqlClient";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const TourDatas_TourPublish = graphql(/* GraphQL */ `
  query TourDatas_TourPublish($day: String!) {
    tourDatas(day: $day) {
      tourId
      day
      latesUpdateSupabase
      shift
      tourName
      maxEndTime
      minStartTime
      canSentToMobile
      member {
        memberId
        shortName
        hasExamination
        hasQualificationLevel
      }
      missionChanges {
        mission {
          patient {
            lastName
            firstName
          }
          time
          codedActions
        }
        missionId
        added
        canceled
        timeChanged
        oldTime
        newTime
      }
    }
  }
`);

const UpdateMissionSupabase_TourPublish = graphql(/* GraphQL */ `
  mutation UpdateMissionSupabase_TourPublish(
    $day: String!
    $tourIds: [String!]!
  ) {
    updateMissionSupabase(day: $day, tourIds: $tourIds)
  }
`);

export type LocalTourDatas_TourPublish = ArrElement<
  TourDatas_TourPublishQuery["tourDatas"]
>;

type TourPublishState = {
  selectedDay: string;
  isLoading: boolean;
  setSelectedDay: (day: string) => void;
  nextDay: () => void;
  prevDay: () => void;
  loadData: () => void;
  selectedTourId: string;
  tourDatas: LocalTourDatas_TourPublish[];
  updateMissionSupabase: (tourIds: string[]) => void;
  setSelectedTourId: (tourId: string) => void;
};

export type LocalChangedMission_TourPublish = ArrElement<
  LocalTourDatas_TourPublish["missionChanges"]
>;

export const useTourPublish = create<TourPublishState>((set, get) => ({
  selectedDay: "",
  tourDatas: [],
  selectedTourId: "",
  isLoading: false,
  setSelectedTourId: (tourId) => {
    set({
      selectedTourId: tourId,
    });
  },
  setSelectedDay: (day) => {
    set({
      selectedDay: day,
    });
    get().loadData();
  },
  loadData: async () => {
    if (get().selectedDay === "") return;

    set({
      tourDatas: [],
      isLoading: true,
    });

    client
      .query<TourDatas_TourPublishQuery, TourDatas_TourPublishQueryVariables>(
        TourDatas_TourPublish,
        {
          day: get().selectedDay,
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((result) => {
        console.log(result);
        set({
          tourDatas: result?.data?.tourDatas || [],
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error loading data");
        set({
          isLoading: false,
        });
      });
  },
  updateMissionSupabase: async (tourIds) => {
    if (get().selectedDay === "") return;

    client
      .mutation(UpdateMissionSupabase_TourPublish, {
        day: get().selectedDay,
        tourIds,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        get().loadData();
      });
  },
  nextDay: () => {
    set({
      selectedDay: dayjs(get().selectedDay).add(1, "day").format("YYYY-MM-DD"),
    });
    get().loadData();
  },
  prevDay: () => {
    set({
      selectedDay: dayjs(get().selectedDay)
        .subtract(1, "day")
        .format("YYYY-MM-DD"),
    });
    get().loadData();
  },
}));
