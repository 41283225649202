import React, { useEffect, useState } from "react";

import { ScheduleCompact } from "../../type-definitions";

import {
  NewSchedule,
  useSchedulesByPatientQuery,
} from "../../generated/graphql";
import { groupSchedules } from "./groupSchedules";

import {
  DayArrayTemplate,
  daysToArray,
  DayWithActionListSelected,
} from "./daysToArray";
import DisplayAction from "../Action/DisplayAction";
import DisplayCashflow from "../Cashflow/DisplayCashflow";

import dayjs from "dayjs";
import { useMission } from "../../services/Mission/useMission";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import { Button } from "../UiComponents/Button";
import { getMostRecentSaturday } from "../../helpers/datetime";
import { useManageSchedules } from "../ManageSchedules/useManageSchedules";
import DisplayActionCompact from "../Action/DisplayActionCompact";
import {
  CopyIcon,
  CrossCircledIcon,
  PlusCircledIcon,
  UpdateIcon,
} from "@radix-ui/react-icons";
import { TbEdit, TbEditCircle } from "react-icons/tb";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";

type Props = {
  patientId: string;
};

const templateDays = [
  {
    displayName: "Sa",
    id: "6",
  },
  {
    displayName: "So",
    id: "7",
  },
  {
    displayName: "Mo",
    id: "1",
  },
  {
    displayName: "Di",
    id: "2",
  },
  {
    displayName: "Mi",
    id: "3",
  },
  {
    displayName: "Do",
    id: "4",
  },
  {
    displayName: "Fr",
    id: "5",
  },
] as DayArrayTemplate[];

const DisplayScheduleByDay_old = (props: Props) => {
  const [result, reececuteQuery] = useSchedulesByPatientQuery({
    //pause: true,
    requestPolicy: "network-only",
    variables: { filter: { patientId: props.patientId } },
  });

  const setLastUpdated = useMission((state) => state.setLastUpdated);
  const {} = useManageSchedules();
  const [weekStartDay, setWeekStartDay] = useState<string>(
    getMostRecentSaturday()
  );

  const setActionClipboardContent = useActionClipboard(
    (state) => state.setActionClipboardContent
  );

  const [checkedArray, setCheckedArray] = useState<any[]>([]);
  const [missions, setMissions] = useState<any[]>([]);

  const { data, fetching, error } = result;

  const [compact, setCompact] = useState<ScheduleCompact[]>([]);

  const { hasMission, newMissions, toogleMission, createMissions } =
    useManageSchedules();

  useEffect(() => {
    console.log("useEffect Data", data);
    if (!data) return;
    if (data.newSchedules !== undefined && data.newSchedules !== null) {
      console.log("useEffect Data", data);
      const _schedules = data.newSchedules as NewSchedule[];

      const newSchedules = groupSchedules(_schedules);

      setCompact(newSchedules);
      setCheckedArray(
        new Array(newSchedules.length).fill(new Array(7).fill(false))
      );
    }
  }, [data]);

  // const getScheduleInfo = (
  //   compactElement: ScheduleCompact,
  //   day: DayWithActionListSelected
  // ) => {
  //   let obj = {} as any;

  //   console.log("getScheduleInfo", compactElement, day);

  //   // 6->0, 7->1, 1->2, 2->3, 3->4, 4->5, 5->6
  //   let dayIndex = Number(day.id) - 6;
  //   if (dayIndex < 0) {
  //     dayIndex = 7 + dayIndex;
  //   }

  //   const dayDate = dayjs(weekStartDay)
  //     .add(dayIndex, "day")
  //     .format("YYYY-MM-DD");

  //   const start = dayjs(
  //     dayDate + " " + compactElement.minStartTime,
  //     "YYYY-MM-DD HH:mm"
  //   );

  //   const actionIdsWithQuantity = day.actions.map((a) => {
  //     return { lnr: a.action?.lnr, quantity: a.quantity };
  //   });

  //   const _duration = day.plannedDuration_min;

  //   obj = {
  //     actionIdsWithQuantity: actionIdsWithQuantity,
  //     duration: _duration,
  //     patientId: props.patientId,
  //     startTS: start.toDate(),
  //     endTS: start.add(_duration, "minute").toDate(),
  //   };

  //   return obj;
  // };

  if (checkedArray === undefined || checkedArray.length === 0) {
    return <div>No Schedules</div>;
  }
  if (fetching) return <div>loading</div>;
  if (error) return <div>Errot {JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      <div className="flex flex-row items-center justify-center:">
        <Button
          onClick={() => {
            setWeekStartDay(
              dayjs(weekStartDay).subtract(7, "days").format("YYYY-MM-DD")
            );
          }}
        >
          -
        </Button>
        <div className="mx-2">{weekStartDay}</div>
        <Button
          onClick={() => {
            setWeekStartDay(
              dayjs(weekStartDay).add(7, "days").format("YYYY-MM-DD")
            );
          }}
        >
          +
        </Button>

        {/* <div className="mx-2">
          <Button
            variant="primary-outline"
            onClick={() => {
              createMissions();
            }}
          >
            Einsäte erzeugen {newMissions.length}
          </Button>
        </div> */}
      </div>
      <div className="grid grid-cols-8 gap-2">
        <div className="bg-white"> Zeit</div>
        {templateDays.map((day, index) => {
          return (
            <div className="flex flex-row justify-between bg-white">
              <div className="bg-white">{day.displayName}</div>
              <div className="text-primary">
                {dayjs(weekStartDay)
                  .startOf("day")
                  .add(index, "days")
                  .format("DD.MM")}
              </div>
              <Button variant={"danger-ghost"}>
                <CrossCircledIcon />
              </Button>
            </div>
          );
        })}

        {compact.map((c, rowIndex) => {
          return (
            <>
              <div className=" bg-white">
                {c.minStartTime} bis {c.maxStartTime}
              </div>
              {daysToArray(c.daysWithQuantityList, templateDays).map(
                (day, colIndex) => {
                  return (
                    <>
                      <div className="bg-white flex flex-col justify-end">
                        {day.isSelected === true
                          ? day.actions.map((action) => {
                              return (
                                <div className="text-xs px-2 py-1">
                                  <DisplayActionCompact
                                    action={
                                      action as {
                                        quantity: number;
                                        action: {
                                          leistungsart: string;
                                          examinationRequired: boolean;
                                        };
                                      }
                                    }
                                  />
                                </div>
                              );
                            })
                          : ""}
                        <div>
                          {day.isSelected === true ? (
                            <div className="flex flex-row text-xs text-gray-400 justify-between">
                              <Button
                                variant={"danger-ghost"}
                                onClick={() => {
                                  console.log(c, day, rowIndex, colIndex);
                                }}
                              >
                                <CrossCircledIcon className="h-4 w-4" />
                              </Button>
                              <Button variant={"neutral-ghost"}>
                                <HiOutlineWrenchScrewdriver className="w-4 h-4 text-gray-600" />
                              </Button>
                              {/* <DisplayCashflow
                                actionWqLnrs={day.actions.map((aq: any) => {
                                  return {
                                    actionLnr: aq.action.lnr,
                                    quantity: aq.quantity,
                                  };
                                })} 
                              />*/}

                              <Button
                                onClick={() => {
                                  setActionClipboardContent(day.actions);
                                }}
                                variant="neutral-ghost"
                              >
                                <CopyIcon />
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button variant={"success-ghost"}>
                                <PlusCircledIcon className="h-4 w-4" />
                              </Button>
                            </div>
                          )}
                        </div>
                        {/* {day.isSelected===true?<div className="text-xs text-gray-500">EUR / Min  Dauer: 12m</div>:""} */}
                      </div>
                    </>
                  );
                }
              )}
            </>
          );
        })}
      </div>
      <div>
        {missions.map((mission) => {
          return <div>{JSON.stringify(mission)}</div>;
        })}
      </div>
    </>
  );
};

export default DisplayScheduleByDay_old;
