import React, { useEffect } from "react";

import { usePatientManageDistrict } from "./usePatientManageDistrict";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../UiComponents/Dialog";
type Props = {
  patientId: string;
};

const PatientManageDistrict = (props: Props) => {
  const { patientId } = props;
  const { allDistricts, patient, loadData, setPatientDistrict } =
    usePatientManageDistrict();

  useEffect(() => {
    loadData(patientId);
  }, [patientId]);

  return (
    <>
      <Dialog>
        <DialogTrigger>
          <div
            className="border-2 border-gray-300 p-2 cursor-pointer"
            style={{
              color: patient?.district?.colorHex,
            }}
          >
            <div> {patient?.district?.description || "Fehlt"}</div>
          </div>
        </DialogTrigger>
        <DialogContent className="w-96">
          <div>
            <div>Klicken zum Auswählen</div>
            <div className="flex flex-row gap-2 flex-wrap">
              {allDistricts.map((district) => {
                return (
                  <DialogClose>
                    <div
                      onClick={() => {
                        setPatientDistrict(district.id);
                      }}
                      key={district.id}
                      className="border-2 border-gray-300 p-2 cursor-pointer"
                      style={{
                        color: district.colorHex,
                      }}
                    >
                      {district.description || "Fehlt"}
                    </div>
                  </DialogClose>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PatientManageDistrict;
