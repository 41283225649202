import React from "react";

import { useTourInfoMessage } from "./useTourInfoMessage";
import { Textarea } from "../UiComponents/Textarea";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";

type Props = {
  day: string;
  tourId: string;
  setOpen: (isOpen: boolean) => void;
};

const TourInfoMessage = (props: Props) => {
  const { day, tourId } = props;
  const { latestMessage, loadData, createTourInfoMessage } =
    useTourInfoMessage();
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    loadData(day, tourId);
    console.log("loadData", day, tourId, latestMessage);
  }, [day, tourId]);

  React.useEffect(() => {
    console.log("latestMessage", latestMessage);
    if (latestMessage) {
      setMessage(latestMessage.message);
    } else {
      setMessage("");
    }
  }, [latestMessage]);

  return (
    <div className="flex flex-col w-full gap-2">
      <div>
        TourInfoMessage {dayjs(props.day).format("ddd DD. MMM")} {props.tourId}{" "}
        {latestMessage?.version}
      </div>
      <div>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          spellCheck={true}
          rows={5}
        />
      </div>
      <div className="flex flex-row gap-2 w-32">
        <Button
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Abbrechen
        </Button>
        <Button
          variant={"primary"}
          onClick={() => {
            createTourInfoMessage(message);
            props.setOpen(false);
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
};

export default TourInfoMessage;
