import { create } from "zustand";

export type User = {
  id: string;
  name: string;
  email: string;
  roles: string[];
};

type UserInfoState = {
  user: User | null;
  setUser: (user: User) => void;
  roles: string[];
  hasRole: (role: string) => boolean;
};

export const useUserInfo = create<UserInfoState>((set, get) => ({
  user: null,
  setUser: (user) => {
    set({ user, roles: user.roles });
  },
  roles: [],
  hasRole: (role) => get().roles.includes(role),
}));
