import React, { useEffect, useRef, useState } from "react";
import Map, { Layer, Marker, Source, MapRef } from "react-map-gl";

import DeckGL from "@deck.gl/react/typed";
import {
  ArcLayer,
  TextLayer,
  ColumnLayer,
  IconLayer,
} from "@deck.gl/layers/typed";
//import { TripsLayer } from "deck.gl";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import {
  useGetGeoPatientsQuery,
  useGetArcLayerQuery,
} from "../../generated/graphql";
import { FeatureCollection, Feature } from "geojson";
//import { Popover } from "../UiComponents/Popover";
import SelectDayTour from "./SelectDayTour";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import PatientMissionList from "../PatientMissionList/PatientMissionList";
import DisplayPatientsMissionDay from "./DisplayPatientsMissionDay";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { Button } from "../UiComponents/Button";
import {
  PopoverRadix,
  PopoverRadixContent,
  PopoverRadixTrigger,
} from "../UiComponents/PopoverRadix";

const colors = require("tailwindcss/colors");

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

const tourColors = [
  hexToRgb(colors.blue[500]),
  hexToRgb(colors.yellow[500]),
  hexToRgb(colors.green[500]),
  hexToRgb(colors.red[500]),
  hexToRgb(colors.sky[500]),
  hexToRgb(colors.teal[500]),
];

const textColors = [
  hexToRgb(colors.white),
  hexToRgb(colors.black),
  hexToRgb(colors.black),
  hexToRgb(colors.white),
  hexToRgb(colors.black),
  hexToRgb(colors.white),
];

type Props = {};

const initialViewState = {
  longitude: 6.93191365074411,
  latitude: 51.40530535770421,
  zoom: 14,
  pitch: 0,
  bearing: 0,
};

// Call the getIso function
// You will remove this later - it's just here so you can see the console.log results in this step
//getIso();

type LngLat = {
  lng: number;
  lat: number;
};

export interface SelectedTour {
  tourIds: string[];
  day: string;
}

export const DistrictsMap = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return <DistrictsMap1 />;
};

const DistrictsMap1 = (props: Props) => {
  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState(initialViewState);
  const [allPatients, setAllPatients] = useState<any>();
  const [tours, setTours] = useState<any[]>([]);
  const [bounderies, setBounderies] = useState<FeatureCollection>();
  const [isHovering, setIsHovering] = useState(false);
  const [timeInterval, setTimeInterval] = useState(90);
  const [timeRange, setTimeRange] = useState<{
    startTime: string;
    endTime: string;
  }>({ startTime: "06:00", endTime: "23:59" });

  const [tripData, setTripData] = useState<any>();

  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);

  const [selectedTour, setSelectedTour] = useState<SelectedTour>({
    day: dayjs().format("YYYY-MM-DD").toString(),
    tourIds: [],
  });

  const [layers, setLayers] = useState<any[]>([]);

  const [currentPosition, setCurrentPosition] = useState<LngLat>({
    lat: 51.40530535770421,
    lng: 6.93191365074411,
  });

  const [tourResult] = useGetArcLayerQuery({
    variables: {
      day: selectedTour.day,
      tourIds: selectedTour.tourIds,
    },
  });
  const {
    fetching: tourFetching,
    data: tourData,
    error: tourError,
  } = tourResult;

  const [allPatintsResult] = useGetGeoPatientsQuery({
    variables: {
      filter: { minDate: selectedTour.day, maxDate: selectedTour.day },
    },
  });
  const {
    fetching: allPatientsFetching,
    data: allPatientsData,
    error: allPatientsError,
  } = allPatintsResult;

  useEffect(() => {
    if (!tourData) return;
    if (!tourData.geoTourArcLayers) return;

    console.log("Touren da");

    console.log(tourData.geoTourArcLayers);

    let _tours = [] as any[];

    tourData.geoTourArcLayers.map((tour) => {
      const filteredElements = _.filter(
        tour?.elements,
        (o) =>
          o?.from?.startTime! >= timeRange.startTime &&
          o?.from?.startTime! <= timeRange.endTime
      );

      console.log("Filtered Elements", filteredElements);
      const _tour = { ...tour, elements: filteredElements };

      _tours.push(_tour);
    });

    console.log(_tours);
    setTours(_tours);
  }, [tourData, timeRange]);

  useEffect(() => {
    if (!allPatientsData) return;
    console.log("Patienten da");
    console.log(allPatientsData.geoPatients);
    const _allPatients = allPatientsData.geoPatients;
    setAllPatients(_allPatients);
  }, [allPatientsData]);

  const layerStylePatients = {
    id: "point1",
    type: "circle",
    paint: {
      "circle-radius": 5,
      //"circle-color": "#007cbf",

      "circle-color": ["get", "colorHex"],
      // "circle-color": "black",
      "circle-opacity": 0.5,
    },
  } as any;

  const layerLablePatients = {
    id: "point",
    type: "symbol",
    minzoom: 13, // Set zoom level to whatever suits your needs
    // onClickMap: (event: any) => {
    //   console.log(event);
    // },
    // onHover: (info: any, event: any) => {
    //   setIsHovering(info.picked || false);
    //   console.log("Patient Hovered", info, event);
    // },
    layout: {
      "text-size": 10,
      "text-field": ["get", "lastName"],
      "text-variable-anchor": ["top", "bottom", "left", "right"],
      "text-radial-offset": 0.5,
      "text-justify": "auto",
    },
  } as any;

  const layerStyleBounderies = {
    id: "bounderies",
    type: "fill",
    paint: {
      "fill-color": "#5a3fc0",
      "fill-opacity": 0.1,
    },
  } as any;

  // const layerStyleTour = {
  //   id: "route",
  //   type: "line",
  //   paint: {
  //     "line-color": "red",
  //     "line-width": 1,
  //   },
  // } as any;

  const onClickMap = (e: any) => {
    console.log(e);

    const features = mapRef.current?.queryRenderedFeatures([e.x, e.y], {
      layers: ["point", "point1"],
    });

    console.log(features);

    if (features) {
      if (features.length === 0) {
        setCurrentPosition({ lat: e.coordinate[1], lng: e.coordinate[0] });
      } else {
        setSelectedPatientIds([features[0].properties!.patientId || ""]);
      }
    }
  };

  const onHoverMap = (e: any) => {
    const res = mapRef.current?.queryRenderedFeatures([e.x, e.y], {
      layers: ["point", "point1"],
    });

    if (res?.length !== 0) {
      //console.log("Hovered", res);
      setIsHovering(true);
    } else {
      setIsHovering(false);
    }
  };

  const urlBase = "https://api.mapbox.com/isochrone/v1/mapbox/";
  const profile = "driving"; // Set the default routing profile
  // Create a function that sets up the Isochrone API query then makes an fetch call
  async function getIso(minutes: number) {
    const query = await fetch(
      `${urlBase}${profile}/${currentPosition?.lng},${currentPosition?.lat}?contours_minutes=${minutes}&polygons=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
      { method: "GET" }
    );
    const data = await query.json();
    console.log(data);
    setBounderies(data as any);
  }

  useEffect(() => {
    const _layers = [] as any[];

    function randomInteger(max: any) {
      return Math.floor(Math.random() * (max + 1));
    }

    tours.map((tour, index) => {
      // let r = 234;
      // let g = 179;
      // let b = 8;

      const { r, g, b } = tourColors[index];
      const { r: tr, g: tg, b: tb } = textColors[index];

      const sortedTour = _.orderBy(tour.elements, ["from.startTime"], ["asc"]);
      console.log(sortedTour);

      const pointsWithIndex = [] as {
        index: number;
        coordinates: any;
        startTime: string;
      }[];
      let iindex = 1;
      for (let e of sortedTour) {
        pointsWithIndex.push({
          coordinates: e.from.coordinates,
          startTime: e.from.startTime,
          index: iindex,
        });
        iindex++;
      }

      const indexLayer = new TextLayer({
        id: "index-layer" + tour.memberId,
        data: pointsWithIndex,
        pickable: false,
        getPosition: (d: any) => d.coordinates,
        getText: (d: any) => {
          // return d.index.toString();
          return d.startTime;
        },
        billboard: true,
        getSize: 12,
        getAngle: 45,
        background: false,
        getBackgroundColor: (d: any) => [r, g, b],
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        getColor: (d: any) => [r, g, b],
        getPixelOffset: [30, 0],
      });

      const iconLayer = new IconLayer({
        id: "icon-layer" + tour.memberId,
        data: pointsWithIndex,
        pickable: true,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas:
          "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
        iconMapping: {
          marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
        },
        getIcon: (d: any) => "marker",

        sizeScale: 15,
        getPosition: (d: any) => d.coordinates,
        getSize: (d: any) => 5,
        getColor: (d: any) => [Math.sqrt(d.exits), 140, 0],
      });

      const arcLayer = new ArcLayer({
        id: "arc-layer" + tour.memberId,
        data: tour.elements,
        pickable: true,
        getWidth: 2,
        // onHover: (info, event) => {
        //   setIsHovering(info.picked || false);
        //   console.log("ArcLayer Hovered", info, event);
        // },
        getSourcePosition: (d: any) => d.from.coordinates,
        getTargetPosition: (d: any) => d.to.coordinates,
        getSourceColor: (d: any) => [r, g, b],
        getTargetColor: (d: any) => [r, g, b],
        getCursor: () => "crosshair",
      });

      // const tripLayer = new TripsLayer({
      //   id: "trip-layer" + tour.memberId,
      //   data: tripData,
      //   getPath: (d) => d.waypoints.map((p: any) => p.coordinates),
      //   // deduct start timestamp from each data point to avoid overflow
      //   getTimestamps: (d) =>
      //     d.waypoints.map((p: any) => p.timestamp - dayjs().unix()),
      //   getColor: [253, 128, 93],
      //   opacity: 0.8,
      //   widthMinPixels: 5,
      //   trailLength: 200,
      //   currentTime: 0,
      // });

      const textLayer = new TextLayer({
        id: "text-layer" + tour.memberId,
        data: tour.elements,
        pickable: true,
        getPosition: (d: any) => d.from.coordinates,
        // getText: (d: any) => {

        //   return "xxxx";
        // },
        getSize: 12,
        getAngle: 0,
        getTextAnchor: "middle",
        getAlignmentBaseline: "center",
        getColor: [r, g, b],
        getPixelOffset: [30, 0],
      });

      const columnLayer = new ColumnLayer({
        id: "column-layer" + tour.menberId,
        data: tour.elements,
        diskResolution: 12,
        radius: 5,
        extruded: true,
        pickable: true,
        elevationScale: 5,
        getPosition: (d: any) => d.from.coordinates,
        getFillColor: [r, g, b],
        getLineColor: [r, g, b],
        getElevation: (d: any) => d.duration_from,
      });
      _layers.push(arcLayer);

      _layers.push(indexLayer);
      //_layers.push(iconLayer);

      //_layers.push(textLayer);
      _layers.push(columnLayer);
    });
    setLayers(_layers);
  }, [tours]);

  // if (tourFetching)
  //   return <div className="relative top-4 m-12 rounded-xl">Loading Tours</div>;
  if (tourError) return <div>Error {JSON.stringify(tourError, null, 2)}</div>;
  // if (allPatientsFetching) return <div>Loading Patients</div>;
  if (allPatientsError)
    return <div>Error {JSON.stringify(allPatientsError, null, 2)}</div>;

  return (
    <>
      <Helmet>
        <title>Tour Karte {selectedTour.day}</title>
      </Helmet>
      <div className="h-full relative">
        <div className="bg-gray-100 text-white px-6 py-2 z-10 absolute -top-8 -right-8  m-12 rounded-xl flex flex-row bg-opacity-90">
          <div className="w-80 h-auto max-h-32 overflow-y-scroll">
            <DisplayPatientsMissionDay
              patientIds={selectedPatientIds}
              day={selectedTour.day}
            />
          </div>
        </div>
        <div className="bg-gray-100 text-black px-6 py-2 z-50 absolute top-4 m-12 rounded-xl flex flex-row bg-opacity-90">
          <div className="!z-50">
            <PopoverRadix>
              <PopoverRadixTrigger>
                <Button variant={"primary"}>Ändern</Button>
              </PopoverRadixTrigger>
              <PopoverRadixContent>
                <>
                  <SelectDayTour
                    selectedTour={selectedTour}
                    setSelectedTour={setSelectedTour}
                  />
                </>
              </PopoverRadixContent>
            </PopoverRadix>

            {/* <Popover
              render={({ close, labelId, descriptionId }) => (
                <>
                  <SelectDayTour
                    selectedTour={selectedTour}
                    setSelectedTour={setSelectedTour}
                  />
                </>
              )}
            ></Popover> */}
          </div>
          <div className="mx-2 text-black">{selectedTour.day}</div>
          <Button
            // className="btn btn-scondary btn-sm"
            onClick={() => {
              getIso(5);
            }}
          >
            5
          </Button>
          <Button
            className="btn btn-scondary btn-sm mx-1"
            onClick={() => {
              getIso(10);
            }}
          >
            10
          </Button>
          <Button
            className="btn btn-scondary btn-sm mx-1"
            onClick={() => {
              getIso(15);
            }}
          >
            15
          </Button>
          <Button
            className="btn btn-scondary btn-sm"
            onClick={() => {
              setBounderies(undefined);
            }}
          >
            Cl
          </Button>
          <Button
            className="btn btn-accent btn-sm"
            onClick={() => {
              const newStart = dayjs(timeRange.startTime, "HH:mm")
                .subtract(30, "minute")
                .format("HH:mm");
              const newEnd = dayjs(timeRange.endTime, "HH:mm")
                .subtract(30, "minute")
                .format("HH:mm");

              console.log("newStart", newStart, "newEnd", newEnd);

              setTimeRange({
                startTime: newStart,
                endTime: newEnd,
              });
            }}
          >
            Minus
          </Button>
          <Button
            className="btn btn-accent btn-sm"
            onClick={() => {
              const newStart = dayjs(timeRange.startTime, "HH:mm")
                .add(30, "minute")
                .format("HH:mm");
              const newEnd = dayjs(timeRange.endTime, "HH:mm")
                .add(30, "minute")
                .format("HH:mm");

              console.log("newStart", newStart, "newEnd", newEnd);

              setTimeRange({
                startTime: newStart,
                endTime: newEnd,
              });
            }}
          >
            Plus
          </Button>
          <div className="text-black">
            {timeRange.startTime} {timeRange.endTime}
          </div>
          <Button
            className="btn btn-accent btn-sm"
            onClick={() => {
              setTimeInterval((prev) => prev + 30);

              const newEnd = dayjs(timeRange.endTime, "HH:mm")
                .subtract(timeInterval, "minute")
                .format("HH:mm");

              setTimeRange({
                startTime: timeRange.startTime,
                endTime: newEnd,
              });
            }}
          >
            Intervall Minus
          </Button>
          <Button
            className="btn btn-accent btn-sm"
            onClick={() => {
              setTimeInterval((prev) => prev - 30);

              const newEnd = dayjs(timeRange.endTime, "HH:mm")
                .add(timeInterval, "minute")
                .format("HH:mm");

              setTimeRange({
                startTime: timeRange.startTime,
                endTime: newEnd,
              });
            }}
          >
            Interval Plus
          </Button>
        </div>
        <div onContextMenu={(evt) => evt.preventDefault()}>
          <DeckGL
            layers={layers}
            initialViewState={initialViewState}
            controller={true}
            onClick={onClickMap}
            onHover={onHoverMap}
            // getCursor={({ isDragging, isHovering }) =>
            //   isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
            // }
            getCursor={() => {
              return isHovering ? "crosshair" : "grab";
            }}
            // getTooltip={({ object }: { object: any }) =>
            //   object && `${object.from.name} to ${object.to.name}`
            // }
          >
            <Map
              //   {...viewState}
              onMove={(evt: any) => setViewState(evt.viewState)}
              style={{ width: "100%", height: "100%", cursor: "crosshair" }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              //mapStyle="mapbox://styles/capsicum42/cl0zfhxa3007914lp31nbqtug"
              //mapStyle="mapbox://styles/capsicum42/cl0zhzy92003a15p6p99ax98s"
              //    mapStyle="mapbox://styles/capsicum42/cl0zf82sh000q14pbgvd1s9av"

              //mapStyle="mapbox://styles/capsicum42/cl0zfhxa3007914lp31nbqtug"
              mapStyle="mapbox://styles/capsicum42/cl7xahs94001314o6ztr12osy"
              ref={mapRef}

              //onClick={onClickMap}
            >
              <Source id="patients" type="geojson" data={allPatients}>
                <Layer {...layerStylePatients}></Layer>
                <Layer {...layerLablePatients}></Layer>
              </Source>

              {/* <Source type="geojson" data={allPatients}>
              <Layer {... layerStyleTour} ></Layer>
          </Source> */}
              {bounderies && (
                <Source id="range" type="geojson" data={bounderies}>
                  <Layer {...layerStyleBounderies}></Layer>
                </Source>
              )}

              <Marker
                longitude={currentPosition?.lng}
                latitude={currentPosition?.lat}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </Marker>
            </Map>
          </DeckGL>
        </div>
      </div>
    </>
  );
};

export default DistrictsMap;
