import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { ItemTypes } from "./ItemTypes";
import { Local } from "d3";
import { LocalMission_TourBuilder } from "./useTourBuilder";
import classNames from "classnames";

export interface CardProps {
  id: string;
  tourId: string;
  time: string;
  index: number;
  travelTime: number;
  mission: LocalMission_TourBuilder;
  className?: string;
  moveCard: (
    dragIndex: number,
    hoverIndex: number,
    itemId: string,
    hoverTourId: string
  ) => void;
}

const style = {
  border: "1px dashed gray",
  padding: "0.0rem 0rem",
  marginBottom: ".1rem",
  backgroundColor: "white",
  cursor: "move",
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card: FC<CardProps> = ({
  id,
  time,
  travelTime,
  mission,
  tourId,
  index,
  moveCard,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    drop(item, monitor) {
      // console.log("im drop card", item, tourId, monitor);
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      //console.log("im Hover card", item, monitor);

      const dragIndex = item.index;
      const hoverIndex = index;
      //console.log("im Hover card", item, monitor);

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, item.id, tourId);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      //console.log("im Hover card", item, tourId, dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //console.log("im card", ref);
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <div className={classNames(className, "text-xs")}>
        <div>
          {mission.patient?.lastName}({mission.time})
        </div>
        <div>
          {mission.patient?.firstName} {time}D:
          {mission.duration_min}
          T:{travelTime}
        </div>
      </div>
    </div>
  );
};
