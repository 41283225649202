import React, { useCallback } from "react";
import { ActionExecution, useReviewOneDay } from "./useReviewOneDay";
import { cn } from "../../../helpers/utils";
import {
  LocalActionWithQuantity_ReviewExecutionMobile,
  LocalExecutionMobile_ReviewExecutionMobile,
  LocalMission_ReviewExecutionMobile,
} from "../useReviewExecutionMobile";
import { Action } from "xstate";
import { Button } from "../../UiComponents/Button";
import produce from "immer";
import { Input } from "../../UiComponents/Input";
import { InputCreateExecutionMobile } from "../../../gql/graphql";
import { actions2Coded } from "../../../helpers/actionLnrCoded";
import _ from "lodash";

type Props = {
  execution: LocalExecutionMobile_ReviewExecutionMobile;
  mission: LocalMission_ReviewExecutionMobile;
  remark: string | undefined | null;
};

const EditManualMobileExecution = (props: Props) => {
  const { execution, mission } = props;
  const { missonExecutionResultMap, createOrUpdateExecutionMobile } =
    useReviewOneDay();

  const [actions, setActions] = React.useState<ActionExecution[]>([]);
  const [remark, setRemark] = React.useState<string | undefined | null>(
    props.remark
  );

  React.useEffect(() => {
    const _missionActionExecutions = missonExecutionResultMap?.get(
      execution.id
    );
    if (_missionActionExecutions) {
      setActions(_missionActionExecutions);
    }
  }, [missonExecutionResultMap]);

  const debouncedUpdateExecution = useCallback(
    _.debounce((newActions: ActionExecution[], remark?: string) => {
      updateExecution(newActions, remark);
    }, 1000),
    [] // Empty dependency array to ensure it's only created once
  );

  const updateExecution = (_newActions: ActionExecution[], remark?: string) => {
    const _executedActions = _newActions.filter(
      (ae) => ae.executionStatus === "executed"
    );
    const _input: InputCreateExecutionMobile = {
      id: execution.id,
      startTS: execution.startTS_iso,
      endTS: execution.endTS_iso,
      memberId: mission.memberId,
      patientId: mission.patientId,
      referenceId: mission.id,
      referenceType: "mission",
      remark: remark || "",
      result: execution.result,

      codedActions:
        actions2Coded(
          _executedActions.map((a) => {
            return {
              quantity: a.action?.quantity || 0,
              lnr: a.action?.actionLnr || "0",
            };
          })
        ) || "",
    };

    console.log("createOrUpdateExecutionMobile", _input);

    createOrUpdateExecutionMobile(_input);
  };

  const handleActionChange = (action: ActionExecution) => {
    const _newActions = produce(actions, (draft) => {
      const index = draft.findIndex((ae) => ae.actionLnr === action.actionLnr);
      if (index !== -1) {
        draft[index].executionStatus =
          draft[index].executionStatus === "executed"
            ? "notExecuted"
            : "executed";
      }
    });
    setActions(_newActions);
    debouncedUpdateExecution(_newActions);
  };

  return (
    <div className="flex flex-row w-full gap-2">
      {actions && (
        <div className="bg-white p-1 flex flex-row gap-2 text-xs">
          {actions.map((ae) => {
            return (
              <div
                key={ae.actionLnr}
                className={cn("flex flex-row gap-2 items-center")}
              >
                <Button
                  variant={"neutral-ghost"}
                  className={cn("p-1", {
                    "bg-red-100": ae.executionStatus === "notExecuted",
                    "bg-green-100": ae.executionStatus === "executed",
                  })}
                  onClick={() => {
                    handleActionChange(ae);
                  }}
                >
                  {ae.action?.action?.leistungskomplex}
                </Button>
              </div>
            );
          })}
        </div>
      )}
      <input
        className="w-full border-2 border-gray-300 p-1"
        type="text"
        placeholder="Bemerkung"
        value={remark || ""}
        onChange={(e) => {
          setRemark(e.target.value);
          debouncedUpdateExecution(actions, e.target.value);
        }}
      />
    </div>
  );
};

export default EditManualMobileExecution;
