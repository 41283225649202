import React from "react";

import { useGlobalService } from "./useGlobalService";
import { Button } from "../UiComponents/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import { MyDayPicker } from "../UiComponents/MyDayPicker";

import dayjs from "dayjs";
import MissionPublicationStatus from "../MissionPublicationStatus/MissionPublicationStatus";

type Props = {};

const GlobalWeekSelect = (props: Props) => {
  const {
    currentMinDay,
    currentMaxDay,
    prevWeek,
    nextWeek,
    setCurrentDateInterval,
    currentMaxDate,
    currentMinDate,
  } = useGlobalService();

  return (
    <div className="flex flex-row justify-center items-center bg-gray-50 m-1 p-1">
      <Button
        className="bg-white m-1"
        variant={"neutral-ghost"}
        onClick={prevWeek}
      >
        -
      </Button>
      <Popover>
        <PopoverTrigger>
          <span className="text-primary bg-white m-1 p-1">
            {currentMinDay} - {currentMaxDay}
          </span>
        </PopoverTrigger>
        <PopoverContent>
          <MyDayPicker
            weekStartsOn={6}
            mode="single"
            className="rounded-md border"
            selected={currentMinDate}
            onDayClick={(day) => {
              if (dayjs(day).day() === 6) {
                setCurrentDateInterval({
                  min: dayjs(day).toDate(),
                  max: dayjs(day).add(6, "days").toDate(),
                });
              } else {
                setCurrentDateInterval({
                  min: dayjs(day).day(6).subtract(7, "days").toDate(),
                  max: dayjs(day)
                    .day(6)
                    .subtract(7, "days")
                    .add(6, "days")
                    .toDate(),
                });
              }
            }}
          />
          <div className="flex flex-row justify-center items-center m-1 p-1">
            <Button
              onClick={() => {
                if (dayjs().day() === 6) {
                  setCurrentDateInterval({
                    min: dayjs().toDate(),
                    max: dayjs().add(6, "days").toDate(),
                  });
                } else {
                  setCurrentDateInterval({
                    min: dayjs().day(6).subtract(7, "days").toDate(),
                    max: dayjs()
                      .day(6)
                      .subtract(7, "days")
                      .add(6, "days")
                      .toDate(),
                  });
                }
              }}
            >
              akuelle Woche
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      <Button
        className="bg-white m-1"
        variant={"neutral-ghost"}
        onClick={nextWeek}
      >
        +
      </Button>
    </div>
  );
};

export default GlobalWeekSelect;
