import React, { useEffect } from "react";

import {
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "../UiComponents/Command";
import {
  LocalPatientSearchBox,
  usePatientSearchbox,
} from "./usePatientSearchbox";
import { Button } from "../UiComponents/Button";
import classNames from "classnames";
import {
  CheckBadgeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { useHotkeys } from "react-hotkeys-hook";
import { Command } from "lucide-react";

type Props = {
  onSelect: (patientId: string) => void;
  setPatient?: (patient: LocalPatientSearchBox) => void;
};

const PatientSearchbox = (props: Props) => {
  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState(false);
  const { loadData, patients } = usePatientSearchbox();
  const [selectedPatient, setSelectedPatient] =
    React.useState<LocalPatientSearchBox | null>(null);

  useHotkeys("ctrl+k", () => {
    setOpen(true);
  });

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Button
        onClick={() => {
          setOpen((open) => !open);
        }}
      >
        <div className="flex flex-row items-center gap-2">
          Patient{" "}
          <div className="flex flex-row items-center text-xs">
            <Command className="h-3 w-3" />K
          </div>
        </div>
      </Button>
      <CommandDialog
        className="w-96"
        open={open}
        onOpenChange={setOpen}
        filter={(value, search) => {
          //const _patient = JSON.parse(value);
          if (value.includes(search)) return 1;
          return 0;
        }}
      >
        <div className="w-96">
          <CommandInput placeholder="zum suchen tipppen" />
          <CommandList className="w-96">
            <CommandEmpty>No results found.</CommandEmpty>
            {patients.map((patient) => (
              <CommandItem
                key={patient?.patientId}
                value={JSON.stringify(patient) || ""}
                onSelect={() => {
                  console.log("onSelect", patient);
                  setSelectedPatient(patient);
                  props.onSelect(patient?.patientId || "");
                  if (props.setPatient) props.setPatient(patient);
                  setOpen(false);
                }}
              >
                <div className="flex flex-col">
                  <div
                    className={classNames({
                      "text-red-500": !patient?.isActive,
                    })}
                  >
                    <div className="flex flex-row gap-1">
                      {patient?.isDa === true && patient.isActive === false ? (
                        <div>
                          <QuestionMarkCircleIcon />
                        </div>
                      ) : null}
                      <div>
                        {patient?.lastName}, {patient?.firstName}{" "}
                      </div>
                      <div>{patient?.patientId}</div>
                    </div>
                  </div>
                  <div className="text-xs text-gray-500">
                    {patient?.address}, {patient?.zipCode} {patient?.city}{" "}
                  </div>
                </div>
              </CommandItem>
            ))}
          </CommandList>
        </div>
      </CommandDialog>
    </div>
  );
};

export default PatientSearchbox;
