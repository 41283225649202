import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { useActionClipboard } from "./useActionClipboard";
import DisplayAction from "../Action/DisplayAction";
import MyModal from "../UiComponents/MyModal";
import { SelectActionsDialog } from "../SelectActions/SelectActionsDialog";
import EditActionClipboard from "./EditActionClipboard";
import { Dialog, DialogContent } from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { BiPlusCircle, BiTrash } from "react-icons/bi";

type XXXProps = {
  targetRef: MutableRefObject<null>;
  isVisible: boolean;
};

const ActionClipboard = () => {
  const actionsClipboardContent = useActionClipboard(
    (state) => state.actionsClipboardContent
  );
  const clearActionClipboardContent = useActionClipboard(
    (state) => state.clearActionClipboardContent
  );

  const loadAllActions = useActionClipboard((state) => state.loadAllActions);

  // const allActions = useActionClipboard((state) => state.allActions);

  useEffect(() => {
    loadAllActions();
  }, []);

  const setActionClipboardContent = useActionClipboard(
    (state) => state.setActionClipboardContent
  );
  const [addActionOpen, setAddActionOpen] = useState(false);

  return (
    <div className="border-2 border-gray-200 flex">
      <Dialog open={addActionOpen} onOpenChange={setAddActionOpen}>
        <DialogContent
          title="Leistungen"
          className=" flex flex-col w-[80%] h-[80%]"
        >
          <EditActionClipboard />
        </DialogContent>
      </Dialog>
      <div className="flex flex-wrap text-xs">
        {actionsClipboardContent.map((action) => {
          return (
            <>
              <div className="flex flex-row justify-center items-baseline text-xs mx-2">
                <div className="relative inline-flex items-center text-xs ">
                  <DisplayAction key={action.action.lnr} action={action} />
                  <span className="absolute inline-flex items-center justify-center w-6 h-6 text-xs  -top-1 -right-3">
                    {action.action.leistungskomplex}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>

      <div className="btn-group">
        <Button
          variant="neutral-ghost"
          onClick={() => {
            clearActionClipboardContent();
          }}
        >
          <BiTrash className="h-5 w-5" />
        </Button>
        <Button
          variant={"neutral-ghost"}
          onClick={() => {
            setAddActionOpen(true);
          }}
        >
          <BiPlusCircle className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default ActionClipboard;
