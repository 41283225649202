import React, { useEffect } from "react";
import { client } from "../../../urqlClient";
import { graphql } from "../../../gql";
import { Button } from "../../UiComponents/Button";

const TourReviewStatusQuery = graphql(/* GraphQL */ `
  query TourReviewStatusQuery($tourId: String!, $day: String!) {
    tourReviewStatus(tourId: $tourId, day: $day) {
      day
      tourId
      status
    }
  }
`);

const TourReviewStatusCreateOrUpdateMutation = graphql(/* GraphQL */ `
  mutation TourReviewStatusCreateOrUpdateMutation(
    $tourId: String!
    $day: String!
    $status: String!
  ) {
    createOrUpdateTourReviewStatus(
      tourId: $tourId
      day: $day
      status: $status
    ) {
      day
      tourId
      status
    }
  }
`);

type Props = {
  tourId: string;
  day: string;
};

const TourReviewStatus = (props: Props) => {
  const { tourId, day } = props;

  const [status, setStatus] = React.useState<string | null>("open");

  useEffect(() => {
    console.log("TourReviewStatus useEffect");
    client
      .query(
        TourReviewStatusQuery,
        { tourId, day },
        { requestPolicy: "network-only" }
      )
      .toPromise()
      .then((result) => {
        if (result?.data?.tourReviewStatus) {
          setStatus(result.data.tourReviewStatus.status);
        } else {
          setStatus("open");
        }
      });
  }, [tourId, day]);

  const updateStatus = (status: string) => {
    client
      .mutation(TourReviewStatusCreateOrUpdateMutation, { tourId, day, status })
      .toPromise()
      .then((result) => {
        if (result?.data?.createOrUpdateTourReviewStatus) {
          setStatus(result.data.createOrUpdateTourReviewStatus.status);
        }
      });
  };

  return (
    <div>
      <Button
        onClick={() => {
          if (status === "open") {
            updateStatus("reviewed");
          }
          if (status === "reviewed") {
            updateStatus("touched");
          }
          if (status === "touched") {
            updateStatus("reviewed");
          }
        }}
      >
        {status}
      </Button>
    </div>
  );
};

export default TourReviewStatus;
