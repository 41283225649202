import { create } from "zustand";
import { produce } from "immer";
import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import {
  MissionsTourBuilderQuery,
  SingleDistanceTourEditQuery,
} from "../../gql/graphql";
import { client } from "../../urqlClient";
import _ from "lodash";
import { to } from "@react-spring/web";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  ArcLayer,
  TextLayer,
  ColumnLayer,
  IconLayer,
} from "@deck.gl/layers/typed";
import { OperationResult } from "urql";
import { TimeRange } from "../MapPatientAnalyzis/useMapPatientAnalyzis";
import { hexToRgb, rgbToHex } from "../../helpers/colors";

import distance from "@turf/distance";
import { point } from "@turf/helpers";

const colors = require("tailwindcss/colors");

dayjs.extend(duration);
dayjs.extend(customParseFormat);

const myColorPalette = [
  hexToRgb(colors.teal[500]),
  hexToRgb(colors.sky[500]),
  hexToRgb(colors.blue[500]),
  hexToRgb(colors.green[500]),
  hexToRgb(colors.red[500]),
  hexToRgb(colors.yellow[500]),
];

const timeRanges: TimeRange[] = [
  { displayName: "06", start: "06:00", end: "06:59" },
  { displayName: "07", start: "07:00", end: "07:59" },
  { displayName: "08", start: "08:00", end: "08:59" },
  { displayName: "09", start: "09:00", end: "09:59" },
  { displayName: "10", start: "10:00", end: "10:59" },
  { displayName: "11", start: "11:00", end: "11:59" },
  { displayName: "12", start: "12:00", end: "12:59" },
  { displayName: "13", start: "13:00", end: "13:59" },
  { displayName: "14", start: "14:00", end: "14:59" },
  { displayName: "15", start: "15:00", end: "15:59" },
  { displayName: "16", start: "16:00", end: "16:59" },
  { displayName: "17", start: "17:00", end: "17:59" },
  { displayName: "18", start: "18:00", end: "18:59" },
  { displayName: "19", start: "19:00", end: "19:59" },
  { displayName: "20", start: "20:00", end: "20:59" },
  { displayName: "21", start: "21:00", end: "21:59" },
  { displayName: "22", start: "22:00", end: "22:59" },
  { displayName: "23", start: "23:00", end: "23:59" },
];

const SingleDistanceTourEdit = graphql(/* GraphQL */ `
  query SingleDistanceTourEdit($fromPatientId: String!, $toPatientId: String!) {
    singleDistance(fromPatientId: $fromPatientId, toPatientId: $toPatientId) {
      fromPatientId
      toPatientId
      travelTime_min
      distance_km
    }
  }
`);

const MissionsTourBuilder = graphql(/* GraphQL */ `
  query MissionsTourBuilder($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      tourId
      memberId
      patientId
      patient {
        lastName
        firstName
        latitude
        longitude
      }
      time
      startTS
      endTS
      duration_min
      day
    }
  }
`);

export type LocalMission_TourBuilder = ArrElement<
  MissionsTourBuilderQuery["missionsTimeRange"]
>;

export type LocalEditMission_TourBuilder = {
  newStartTime: string;
  newTourId: string;
  travelTimeToNext_min: number;
  dbMission: LocalMission_TourBuilder;
};

export type LocalTour_TourBuilder = {
  tourId: string;
  tourStartTime: string;
  day: string;
  missions: LocalEditMission_TourBuilder[];
  filteredMissions: LocalEditMission_TourBuilder[];
  color: [number, number, number];
  bgColor: string;
  geoPatientCollection: GeoJSON.FeatureCollection<GeoJSON.Point> | {} | null;
  archLayer: ArcLayer | {} | null | undefined;
};

type ArcLayerElement = {
  // inbound: string;
  // outbound: string;
  isSelected: boolean;
  from: {
    name: string;
    coordinates: [number, number];
  };
  to: {
    name: string;
    coordinates: [number, number];
  };
};

type GenerateArchlayerInput = {
  tourId: string;
  //missions: LocalEditMission_TourBuilder[];
  filteredMissions: LocalEditMission_TourBuilder[];
  color: [number, number, number];
};

type TourBuilderState = {
  selectedDay: string;
  colorPool: number[][];
  setSelectedDay: (day: string) => void;
  archLayers: ArcLayer[];
  geoPatients: GeoJSON.FeatureCollection<GeoJSON.Point> | undefined;
  tours: LocalTour_TourBuilder[];
  addTour: (tourId: string) => void;
  adjustTourTimes: (tourId: string) => void;
  adjustAllTourTimes: () => void;
  setTours: (tours: LocalTour_TourBuilder[]) => void;
  generateArcLayer: (input: GenerateArchlayerInput) => ArcLayer | undefined;
  generateGeoPatients: () => void;
  selectedTimeRanges: TimeRange[];
  setSelectedTimeRanges: (timeRanges: TimeRange[]) => void;
  allTimeRanges: TimeRange[];
  allMissionsOfDay: LocalMission_TourBuilder[];
  loadAllMissionsOfDay: () => void;
  distinctTourIds: string[];
  removeTour: (tourId: string) => void;
  updateAllTours: () => void;
  getMissionById: (missionId: string) => LocalMission_TourBuilder | undefined;
  getAllMissionsWithinRadius: ({
    missionId,
    radius_km,
    time,
  }: {
    missionId: string;
    radius_km: number;
    time: string;
  }) => LocalMission_TourBuilder[];
};

export const useTourBuilder = create<TourBuilderState>((set, get) => ({
  selectedDay: "2023-06-19",
  colorPool: myColorPalette,

  tours: [],
  archLayers: [],
  geoPatients: undefined,
  selectedTimeRanges: [],
  allTimeRanges: timeRanges,
  distinctTourIds: [],
  allMissionsOfDay: [],
  setSelectedDay: (day: string) => {
    set({
      selectedDay: day,
      allMissionsOfDay: [],
      tours: [],
      archLayers: [],
      geoPatients: undefined,
      colorPool: [...myColorPalette],
    });
  },

  adjustTourTimes: async (tourId: string) => {
    //Iterate over all missions and adjust start and end time
    const _tour = _.find(get().tours, (o) => o.tourId === tourId);
    if (_tour !== undefined) {
      const missions = _tour.missions;

      const _missions: LocalEditMission_TourBuilder[] = [];

      const _travelRets = [] as Promise<
        OperationResult<SingleDistanceTourEditQuery>
      >[];

      // Get traveldistance and time for entire tour
      for (let i = 0; i < missions.length - 1; i++) {
        const _from = missions[i].dbMission.patientId;
        const _to = missions[i + 1].dbMission.patientId;

        if (_to !== undefined) {
          const ret = client
            .query(SingleDistanceTourEdit, {
              fromPatientId: _from,
              toPatientId: _to,
            })
            .toPromise();

          _travelRets.push(ret); //Push all promises to array to reolve them later
        }
      }

      let _travelDistances = (await Promise.all(_travelRets)).map((res) => {
        const _travelDistance = res?.data?.singleDistance?.distance_km;
        const _travelTime = res?.data?.singleDistance?.travelTime_min;
        const _obj = {
          from_to:
            res?.data?.singleDistance?.fromPatientId +
            "_" +
            res?.data?.singleDistance?.toPatientId,
          travelDistance: _travelDistance || 0,
          travelTime: _travelTime || 0,
        };
        return _obj;
      });

      let _startTime = _tour.tourStartTime;
      let _prevStartTime = _tour.tourStartTime;

      for (let i = 0; i < missions.length; i++) {
        const mission = missions[i];
        const _prevMission = missions[i - 1];
        const _from = missions[i];
        const _to = missions[i + 1];

        //console.log("mission", _from, _to);

        let _travelTimeToNext;

        if (_to !== undefined) {
          //console.log(_travelDistances);
          _travelTimeToNext = _.find(
            _travelDistances,
            (o) =>
              o.from_to ===
              _from.dbMission.patientId + "_" + _to.dbMission.patientId
          );
        }

        if (_prevMission === undefined) {
          _startTime = _startTime;
          _prevStartTime = _prevStartTime;

          console.log("first mission", _startTime);
        } else {
          const _travelDuration = dayjs.duration({
            minutes: _prevMission.travelTimeToNext_min,
          });
          const _workDuration = dayjs.duration({
            minutes: _prevMission.dbMission.duration_min,
          });

          // console.log("prevStartTime", _prevStartTime);

          // console.log("dayjs", dayjs(_prevStartTime, "HH:mm").format("HH:mm"));

          _startTime = dayjs(_prevStartTime, "HH:mm")
            .add(_travelDuration)
            .add(_workDuration)
            .format("HH:mm");

          _prevStartTime = _startTime;

          // console.log(
          //   _startTime,
          //   _prevStartTime,
          //   _travelDuration.asMinutes(),
          //   _workDuration.asMinutes()
          // );
        }
        //current start Time

        const _obj = {
          newStartTime: _startTime,
          newTourId: tourId,
          dbMission: mission.dbMission,
          travelTimeToNext_min: Math.round(_travelTimeToNext?.travelTime || 0),
        };
        //console.log("_obj", _obj);
        _missions.push(_obj);
      }

      const _newTours = get().tours.map((tour) => {
        if (tour.tourId === tourId) {
          return {
            ...tour,
            missions: _missions,
          };
        } else {
          return tour;
        }
      });

      set({ tours: _newTours });

      //console.log("_missions", _missions);
    }
  },

  removeTour: (tourId: string) => {
    const _newTours = get().tours.filter((tour) => tour.tourId !== tourId);

    const _tour = _.find(get().tours, (o) => o.tourId === tourId);

    if (_tour !== undefined) {
      const _tempColorPool = [...get().colorPool];

      _tempColorPool.push(_tour.color);

      set({ colorPool: _tempColorPool });

      console.log("colorPool", get().colorPool);
    }

    set({ tours: _newTours });
  },

  addTour: async (tourId: string) => {
    const ret = client
      .query(MissionsTourBuilder, {
        filter: {
          tourIds: [tourId],
          minDate: get().selectedDay,
          maxDate: get().selectedDay,
        },
      })
      .toPromise()
      .then(async (res) => {
        const missions =
          _.orderBy(res?.data?.missionsTimeRange, ["startTS", "asc"]) || [];
        // create new tour

        const _missions: LocalEditMission_TourBuilder[] = [];

        const _travelRets = [] as Promise<
          OperationResult<SingleDistanceTourEditQuery>
        >[];

        // Get traveldistance and time for entire tour
        for (let i = 0; i < missions.length - 1; i++) {
          const _from = missions[i].patientId;
          const _to = missions[i + 1].patientId;

          if (_to !== undefined) {
            const ret = client
              .query(SingleDistanceTourEdit, {
                fromPatientId: _from,
                toPatientId: _to,
              })
              .toPromise();

            _travelRets.push(ret);
          }
        }

        // const _travelDistances = [] as {
        //   from_to: string;
        //   travelDistance: number;
        //   travelTime: number;
        // }[];

        const _tourStartTime = dayjs(
          Number(_.minBy(missions, "startTS")?.startTS)
        ).format("HH:mm");

        let _travelDistances = (await Promise.all(_travelRets)).map((res) => {
          const _travelDistance = res?.data?.singleDistance?.distance_km;
          const _travelTime = res?.data?.singleDistance?.travelTime_min;
          const _obj = {
            from_to:
              res?.data?.singleDistance?.fromPatientId +
              "_" +
              res?.data?.singleDistance?.toPatientId,
            travelDistance: _travelDistance || 0,
            travelTime: _travelTime || 0,
          };
          return _obj;
        });

        //console.log("createdArr", _travelDistances);

        //console.log("Jetzt starten die missions");

        for (let i = 0; i < missions.length; i++) {
          const mission = missions[i];
          const _from = missions[i];
          const _to = missions[i + 1];

          //console.log("mission", _from, _to);

          let _travelTimeToNext;

          if (_to !== undefined) {
            //console.log(_travelDistances);
            _travelTimeToNext = _.find(
              _travelDistances,
              (o) => o.from_to === _from.patientId + "_" + _to.patientId
            );
          }

          const _obj = {
            newStartTime: dayjs(Number(mission.startTS)).format("HH:mm"),
            newTourId: tourId,
            dbMission: mission,
            travelTimeToNext_min: Math.round(
              _travelTimeToNext?.travelTime || 0
            ),
          };
          //console.log("_obj", _obj);
          _missions.push(_obj);
        }

        // choose color from array, depending on number of tours

        const _numberOfTours = get().tours.length;

        const _tempColorPool = get().colorPool;

        console.log("_tempColorPool", _tempColorPool);

        const _color = _tempColorPool.pop() as [number, number, number];
        console.log("_colorPool", get().colorPool, _color);

        set({ colorPool: [..._tempColorPool] });

        //myColorPalette.pop() as [number, number, number];

        const _hexColor = rgbToHex(_color[0], _color[1], _color[2]);
        const _bgColor = `${_hexColor}`;

        const _newTours = produce(get().tours, (draft) => {
          const newTour: LocalTour_TourBuilder = {
            tourStartTime: _tourStartTime,
            tourId: tourId,
            day: get().selectedDay,
            missions: _missions,
            filteredMissions: _missions,
            geoPatientCollection: null,
            archLayer: get().generateArcLayer({
              filteredMissions: _missions,
              tourId: tourId,
              color: _color,
            }),
            color: _color,
            bgColor: _bgColor,
          };

          draft.push(newTour);
        });
        get().setTours(_newTours);
        console.log("tours", get().tours);
      });
  },

  updateAllTours: () => {
    // now the old way

    const _tours = get().tours;

    const _newTours: LocalTour_TourBuilder[] = [];

    _tours.forEach((tour) => {
      const _archLayers = get().generateArcLayer({
        filteredMissions: tour.missions,
        tourId: tour.tourId,
        color: tour.color,
      });

      _newTours.push({
        ...tour,

        archLayer: _archLayers,
      });
    });

    set({ tours: _newTours });
  },

  setTours: (tours: LocalTour_TourBuilder[]) => {
    set({ tours: tours });
    get().generateGeoPatients();

    // Apply filter
    // create archLayer

    const _archLayers: ArcLayer[] = [];

    tours.forEach((tour) => {
      const arcLayer = get().generateArcLayer({
        color: tour.color,
        filteredMissions: tour.filteredMissions,
        tourId: tour.tourId,
      });
      if (arcLayer) {
        _archLayers.push(arcLayer);
      }

      get().adjustTourTimes(tour.tourId);
    });

    set({ archLayers: _archLayers });

    // console.log("archLayers", get().archLayers);
  },

  generateArcLayer: (input: GenerateArchlayerInput) => {
    const arcLayerElements: ArcLayerElement[] = [];

    const _tour = get().tours.find((tour) => tour.tourId === input.tourId);

    const _filteredMissions = _tour?.filteredMissions;

    //console.log("input", input);

    for (let i = 0; i < input.filteredMissions.length - 1; i++) {
      const from = input.filteredMissions[i].dbMission.patient;
      const to = input.filteredMissions[i + 1].dbMission.patient;

      // const fromMission = tour.filteredMissions[i].dbMission;
      // const toMission = tour.filteredMissions[i + 1].dbMission;

      if (!from || !to) continue;
      if (!from.latitude || !from.longitude) continue;
      if (!to.latitude || !to.longitude) continue;

      const _isSelected = _filteredMissions?.includes(
        input.filteredMissions[i]
      );

      //console.log("_isSelected", _isSelected);

      arcLayerElements.push({
        // outbound: fromMission.patient?.lastName || "",
        // inbound: toMission.patient?.lastName || "",
        isSelected: _isSelected || false,
        from: {
          name: `${from?.firstName} ${from?.lastName}`,
          coordinates: [from?.longitude, from?.latitude],
        },
        to: {
          name: `${to?.firstName} ${to?.lastName}`,
          coordinates: [to?.longitude, to?.latitude],
        },
      });
    }

    console.log("arcLayerElements", arcLayerElements);

    const arcLayer = new ArcLayer({
      id: input.tourId,
      data: arcLayerElements,
      getSourcePosition: (d: ArcLayerElement) => d.from.coordinates,
      getTargetPosition: (d: ArcLayerElement) => d.to.coordinates,
      getSourceColor: (d) => {
        if (d.isSelected) {
          return [...input.color, 255];
        } else {
          return [...input.color, 50];
        }
      },

      getTargetColor: (d) => {
        if (d.isSelected) {
          return [...input.color, 255];
        } else {
          return [...input.color, 50];
        }
      },

      getWidth: 2,
    });

    //console.log("arcLayer", arcLayerElements);
    return arcLayer;
  },
  generateGeoPatients: () => {
    const geoPatients: GeoJSON.FeatureCollection<GeoJSON.Point> = {
      type: "FeatureCollection",
      features: [],
    };

    get().tours.forEach((tour) => {
      tour.missions.forEach((mission) => {
        const patient = mission.dbMission.patient;
        if (!patient) return;
        if (!patient.latitude || !patient.longitude) return;

        const feature: GeoJSON.Feature<GeoJSON.Point> = {
          type: "Feature",
          properties: {
            name: `${patient?.lastName}`,
            patientId: mission.dbMission.patientId,
            missionId: mission.dbMission.id,
            color: tour.bgColor,
            time: mission.newStartTime,
            info: patient.lastName + " " + mission.newStartTime,
          },
          geometry: {
            type: "Point",
            coordinates: [patient?.longitude, patient?.latitude],
          },
        };

        geoPatients.features.push(feature);
      });
    });

    set({ geoPatients: geoPatients });
  },
  adjustAllTourTimes: () => {
    get().tours.forEach((tour) => {
      get().adjustTourTimes(tour.tourId);
    });
  },
  setSelectedTimeRanges: (timeRanges) => {
    const _tours = get().tours;
    const _selectedTimeRanges = timeRanges;

    // const _newTours = produce(_tours, (draft) => {
    //   draft.forEach((tour) => {
    //     const _filteredMissions = tour.missions.filter((mission) => {
    //       const _time = mission.newStartTime;

    //       const _timeRange = _selectedTimeRanges.find((timeRange) => {
    //         return _time >= timeRange.start && _time <= timeRange.end;
    //       });

    //       return _timeRange !== undefined;
    //     });
    //     tour.filteredMissions = _filteredMissions;
    //     tour.archLayer = get().generateArcLayer({
    //       tourId: tour.tourId,
    //       filteredMissions: _filteredMissions,
    //       color: tour.color,
    //     });
    //   });
    //   return draft;
    // });

    const __newTours = produce(_tours, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        const tour = draft[i];
        const _filteredMissions = tour.missions.filter((mission) => {
          const _time = mission.newStartTime;

          const _timeRange = _selectedTimeRanges.find((timeRange) => {
            return _time >= timeRange.start && _time <= timeRange.end;
          });

          return _timeRange !== undefined;
        });
        tour.filteredMissions = _filteredMissions;
        //  tour.archLayer = {};
        //   tour.archLayer = {
        //     ...get().generateArcLayer({
        //       tourId: tour.tourId,
        //       filteredMissions: _filteredMissions,
        //       color: tour.color,
        //     }),
        //   };
      }

      return draft;
    });

    console.log("_newTours", __newTours);

    set({ selectedTimeRanges: timeRanges });

    // _tours.forEach((tour) => {
    //   get().generateArcLayer(tour);
    //   get().adjustTourTimes(tour.tourId);
    // });

    get().setTours(__newTours);
    get().updateAllTours();
  },
  loadAllMissionsOfDay: () => {
    const ret = client
      .query(MissionsTourBuilder, {
        filter: {
          minDate: get().selectedDay,
          maxDate: get().selectedDay,
        },
      })
      .toPromise()
      .then((res) => {
        const _missions = res.data?.missionsTimeRange;
        console.log("_missions", _missions);
        set({ allMissionsOfDay: _missions || [] });

        if (!_missions) return;

        const _distinctToursIds = _.uniqBy(_missions, "tourId").map(
          (mission) => {
            return mission.tourId;
          }
        );

        const __distinctTourIds: string[] = [];
        // remove all elements where tourId is null
        for (let d of _distinctToursIds) {
          if (!d) {
          } else {
            __distinctTourIds.push(d);
          }
        }
        set({ distinctTourIds: __distinctTourIds });
      });
  },
  getMissionById: (missionId: string) => {
    const _mission = get().allMissionsOfDay.find((mission) => {
      return mission.id === missionId;
    });

    return _mission;
  },
  getAllMissionsWithinRadius: ({
    missionId,
    radius_km,
    time,
  }: {
    missionId: string;
    radius_km: number;
    time: string;
  }) => {
    const _mission = get().getMissionById(missionId);

    if (!_mission) return [];
    if (!_mission.patient) return [];
    if (!_mission.patient.latitude) return [];
    if (!_mission.patient.longitude) return [];

    const _allMissionsOfDay = get().allMissionsOfDay;

    const _minTime = dayjs(time, "HH:mm").subtract(2, "hour").format("HH:mm");
    const _maxTime = dayjs(time, "HH:mm").add(2, "hour").format("HH:mm");

    console.log("_minTime", _minTime);
    console.log("_maxTime", _maxTime);

    const centerPoint = point([
      _mission?.patient?.longitude,
      _mission?.patient?.latitude,
    ]);

    const _missionsWithinRadius = _allMissionsOfDay.filter((mission) => {
      if (!mission.patient) return false;
      if (!mission.patient.latitude) return false;
      if (!mission.patient.longitude) return false;

      const _distance = distance(
        centerPoint,
        point([mission.patient.longitude, mission.patient.latitude]),
        { units: "kilometers" }
      );

      return (
        _distance <= radius_km &&
        mission.time >= _minTime &&
        mission.time <= _maxTime
      );
    });

    return _missionsWithinRadius;
  },
}));
