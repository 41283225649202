import React from "react";
import { DialogClose } from "../../UiComponents/Dialog";
import { Button } from "../../UiComponents/Button";
import { Textarea } from "../../UiComponents/Textarea";
import { useReviewOneDay } from "./useReviewOneDay";
import { Switch } from "@radix-ui/react-switch";
import { Check } from "lucide-react";
import { Checkbox } from "../../UiComponents/Checkbox";

type Props = {
  message: string;
  actionReuired: boolean;
  executionId: string;
  executionMobileId?: string;
  onClose: () => void;
};

const EditExecutionMobileInfoMessage = (props: Props) => {
  const [text, setText] = React.useState<string>(props.message);
  const [actionRequired, setActionRequired] = React.useState<boolean>(
    props.actionReuired
  );
  const { createExecutionMobileInfoMessage } = useReviewOneDay();

  return (
    <div className="flex flex-row gap-4 overflow-y-auto">
      <div className="bg-gray-50 p-2 overflow-y-scroll w-1/2">
        <div className="flex flex-row justify-between">
          <div>Info Message</div>
        </div>
        <div className="flex flex-col">
          <Checkbox
            checked={actionRequired}
            onCheckedChange={() => setActionRequired(!actionRequired)}
          />
          <Textarea
            className="w-full border-2 border-gray-300 p-1"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <Button
          onClick={() => {
            createExecutionMobileInfoMessage({
              message: text,
              executionMobileId: props.executionMobileId || "",
              actionRequired: actionRequired,
            });

            props.onClose();
          }}
        >
          Okay
        </Button>
      </div>
      <div className="bg-green-50 p-2 w-1/2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Et aliquid
        saepe ad molestiae. In omnis animi illum saepe doloremque! Atque magnam,
        praesentium numquam velit reprehenderit fugit voluptatem. Autem,
        excepturi ipsum!
      </div>
    </div>
  );
};

export default EditExecutionMobileInfoMessage;
