import { graphql } from "../../gql";
import {
  EffectiveActionScheduleDay_DisplayScheduleByDayQuery,
  MedifoxStatusAbsence_DisplayScheduleByDayQuery,
  PatientAbsences_DisplayScheduleByDayQuery,
} from "@/src/gql/graphql";

import { ArrElement } from "@/src/helpers/typeHelpers";
import { client } from "../../urqlClient";
import { create } from "zustand";
import dayjs from "dayjs";
import _ from "lodash";
import { type } from "os";
import toast from "react-hot-toast";

const MedifoxStatusAbsence_DisplayScheduleByDay = graphql(/* GraphQL */ `
  query MedifoxStatusAbsence_DisplayScheduleByDay(
    $patientId: String!
    $minDay: String!
    $maxDay: String!
  ) {
    medifoxStatusAbsences(
      patientId: $patientId
      minDay: $minDay
      maxDay: $maxDay
    ) {
      isAbsence
      isInBetreuung
      day
      patientId
    }
  }
`);

const PatientAbsences_DisplayScheduleByDay = graphql(/* GraphQL */ `
  query PatientAbsences_DisplayScheduleByDay(
    $patientId: String!
    $minDay: String!
    $maxDay: String!
  ) {
    patientAbsences(patientId: $patientId, minDay: $minDay, maxDay: $maxDay) {
      id
      patientId
      startDay
      endDay
      patient {
        lastName
        firstName
      }
    }
  }
`);

const EffectiveActionScheduleDay_DisplayScheduleByDay = graphql(/* GraphQL */ `
  query EffectiveActionScheduleDay_DisplayScheduleByDay(
    $minDay: String!
    $maxDay: String!
    $patientId: String!
  ) {
    effectiveActionScheduleDay(
      minDay: $minDay
      maxDay: $maxDay
      patientId: $patientId
    ) {
      day
      timeGroupId
      minStartTime
      maxStartTime
      actionLnrsCodedEffectiv
      actionLnrsCodedSchedule
      exceptionId
      hasException
      effectiveActionsWithQuantity {
        quantity
        action {
          lnr
          leistungsart
          leistungskomplex
          leistungsinhalte
          examinationRequired
        }
      }
    }
  }
`);

const CreatePatientScheduleException_DisplayScheduleByDay =
  graphql(/* GraphQL */ `
    mutation CreatePatientScheduleException_DisplayScheduleByDay(
      $input: CreatePatientScheduleExceptionInput!
    ) {
      createPatientScheduleException(input: $input) {
        id
      }
    }
  `);

const UpdatePatientScheduleException_DisplayScheduleByDay =
  graphql(/* GraphQL */ `
    mutation UpdatePatientScheduleException_DisplayScheduleByDay(
      $id: String!
      $input: UpdatePatientScheduleExceptionInput!
    ) {
      updatePatientScheduleException(id: $id, input: $input) {
        id
      }
    }
  `);

const DeletePatientScheduleException_DisplayScheduleByDay =
  graphql(/* GraphQL */ `
    mutation DeletePatientScheduleException_DisplayScheduleByDAy($id: String!) {
      deletePatientScheduleException(id: $id) {
        id
      }
    }
  `);

type LocalEffectiveActionScheduleDay = ArrElement<
  EffectiveActionScheduleDay_DisplayScheduleByDayQuery["effectiveActionScheduleDay"]
>;

type LocalPatientAbsence_DisplayScheduleByDay = ArrElement<
  PatientAbsences_DisplayScheduleByDayQuery["patientAbsences"]
>;

type LocalMedifoxStatusAbsence_DisplayScheduleByDay = ArrElement<
  MedifoxStatusAbsence_DisplayScheduleByDayQuery["medifoxStatusAbsences"]
>;

type DisplayScheduleByDayState = {
  patientId: string;
  patientAbsences: LocalPatientAbsence_DisplayScheduleByDay[];
  patientMedifoxStatusAbsences: LocalMedifoxStatusAbsence_DisplayScheduleByDay[];
  setDayRange: ({ minDay, maxDay }: { minDay: string; maxDay: string }) => void;
  minDay: string;
  maxDay: string;
  dayArray: string[];
  setPatientId: (patientId: string) => void;
  effectiveActionScheduleDays: LocalEffectiveActionScheduleDay[];
  loadData: () => void;
  distinctTimeGroups: string[];
  createPatientScheduleException: (input: {
    patientId: string;
    startTime: string;
    endTime: string;
    startDay: string;
    endDay: string;
    allCodedActions: string;
    type: string;
  }) => void;
  updatePatientScheduleException: (
    id: string,
    input: {
      allCodedActions: string;
      type: string;
    }
  ) => void;
  deletePatientScheduleException: (id: string) => void;
};

export const useDisplayScheduleByDay = create<DisplayScheduleByDayState>(
  (set, get) => ({
    patientId: "",
    patientMedifoxStatusAbsences: [],
    patientAbsences: [],
    minDay: "",
    maxDay: "",
    distinctTimeGroups: [],
    dayArray: [],
    setPatientId: (patientId: string) => set({ patientId }),
    effectiveActionScheduleDays: [],
    loadData: async () => {
      //set({ minDay: "2023-10-14", maxDay: "2023-10-20" });

      const _dayArray = [];

      const _diffDays = dayjs(get().maxDay).diff(get().minDay, "day");

      for (let i = 0; i <= _diffDays; i++) {
        const dayString = dayjs(get().minDay)
          .add(i, "day")
          .format("YYYY-MM-DD");
        _dayArray.push(dayString);
      }

      set({ dayArray: _dayArray });

      console.log(get().minDay);
      console.log(get().maxDay);
      console.log(get().patientId);

      client
        .query(MedifoxStatusAbsence_DisplayScheduleByDay, {
          patientId: get().patientId,
          minDay: get().minDay,
          maxDay: get().maxDay,
        })
        .toPromise()
        .then((result) => {
          console.log(result);
          set({
            patientMedifoxStatusAbsences:
              result?.data?.medifoxStatusAbsences || [],
          });
        });

      client
        .query(
          PatientAbsences_DisplayScheduleByDay,
          {
            patientId: get().patientId,
            minDay: get().minDay,
            maxDay: get().maxDay,
          },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then((result) => {
          console.log(result);
          set({
            patientAbsences: result?.data?.patientAbsences || [],
          });
        });

      client
        .query(
          EffectiveActionScheduleDay_DisplayScheduleByDay,
          {
            patientId: get().patientId,
            minDay: get().minDay,
            maxDay: get().maxDay,
          },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then((result) => {
          const _data = result?.data?.effectiveActionScheduleDay || [];
          console.log(_data);
          const _distinctTimeGroups = _.chain(_data)
            .orderBy("timeGroupId")
            .uniqBy("timeGroupId")
            .map((o) => o.timeGroupId)
            .value();

          set({
            distinctTimeGroups: _distinctTimeGroups,
            effectiveActionScheduleDays:
              result?.data?.effectiveActionScheduleDay || [],
          });
        });
    },
    createPatientScheduleException: async (input) => {
      client
        .mutation(CreatePatientScheduleException_DisplayScheduleByDay, {
          input,
        })
        .toPromise()
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            toast.error("Nicht möglich");
            return;
          }
          get().loadData();
        });
    },
    setDayRange: ({ minDay, maxDay }) => {
      set({ minDay, maxDay });
      get().loadData();
    },
    deletePatientScheduleException: async (id: string) => {
      client
        .mutation(DeletePatientScheduleException_DisplayScheduleByDay, { id })
        .toPromise()
        .then((result) => {
          get().loadData();
        });
    },
    updatePatientScheduleException: async (id, input) => {
      client
        .mutation(UpdatePatientScheduleException_DisplayScheduleByDay, {
          id,
          input,
        })
        .toPromise()
        .then((result) => {
          get().loadData();
        });
    },
  })
);
