import { create } from "zustand";

import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";

import {
  TourOptimizeQuery,
  TourOptimizeQueryVariables,
} from "../../gql/graphql";
import { client } from "../../urqlClient";
import _ from "lodash";
import produce from "immer";

const TourOptimize = graphql(/* GraphQL */ `
  query TourOptimize($input: TourAutoroutingInput!) {
    tourAutorouting(input: $input) {
      from {
        missionId
        type
        patientId
        plannedDuration_sec
        calculatedDuration_sec
        mission {
          actionsWithQuantity {
            actionLnr
            quantity
            action {
              leistungsart
              lnr
              leistungskomplex
              examinationRequired
            }
          }
          patient {
            patientId
            lastName
            firstName
            address
            city
          }
          missionAnalytic {
            totalCashValue
            totalCashValue_NOSAFE
            pauschaleHausType
            pauschaleSaveType
            duration_min
            expectedDuration
            expectedDuration_NOSAFE
            cashPerMinute
            cashPerMinute_NOSAFE
            durationDeviation_min
            durationCorrection_min
            expectedDurationCalc_NOSAFE
          }
        }
      }
      to {
        missionId
        patientId
      }
      toPatient {
        patientId
        lastName
        address
        city
      }
      plannedTravelTime_sec
      calculatedTravelTime_sec
      travelSpeed_kmh
      calculatedTravelDistance_m
      deviationTravelTime_sec
    }
  }
`);

type TourOptimizeAggregation = {
  totalTravelTime_min: number;
  totalTravelDistance_km: number;
  totalTravelTimeDeviation_min: number;
  totalWorkTimeCalculated_min: number;
  totalWorkTimePlanned_min: number;
};

export type LocalTourOptimize = ArrElement<
  TourOptimizeQuery["tourAutorouting"]
>;

type TourAggregation = {
  tourId: string;
  day: string;
  aggregation: TourOptimizeAggregation;
};

interface TourOptimizeState {
  tourData: LocalTourOptimize[];
  loadData: (input: { tourId: string; day: string }) => void;
  aggregation: TourOptimizeAggregation;
  tourId: string;
  day: string;
  aggregationCache: TourAggregation[];
}

export const useTourOptimize = create<TourOptimizeState>((set, get) => ({
  tourData: [],
  tourId: "",
  day: "",
  aggregationCache: [],
  aggregation: {
    totalTravelTime_min: 0,
    totalTravelDistance_km: 0,
    totalTravelTimeDeviation_min: 0,
    totalWorkTimeCalculated_min: 0,
    totalWorkTimePlanned_min: 0,
  },
  loadData: async (input) => {
    set({
      tourData: [],
    });

    client
      .query<TourOptimizeQuery, TourOptimizeQueryVariables>(TourOptimize, {
        input: {
          tourId: input.tourId,
          day: input.day,
        },
      })
      .toPromise()
      .then((result) => {
        // console.log("TourOptimize", result);
        // console.log("TourOptimize", result?.data?.tourAutorouting);

        const _totalTravelTime_sec = result?.data?.tourAutorouting?.reduce(
          (acc, cur) => acc + cur.calculatedTravelTime_sec,
          0
        );

        const _totalTravelDistance_m = result?.data?.tourAutorouting?.reduce(
          (acc, cur) => acc + cur.calculatedTravelDistance_m,
          0
        );

        const _totalWorkTimePlanned_sec = result?.data?.tourAutorouting?.reduce(
          (acc, cur) => acc + (cur.from?.plannedDuration_sec || 0),
          0
        );

        const _totalTravelTimeDeviation_sec =
          result?.data?.tourAutorouting?.reduce(
            (acc, cur) => acc + (cur.deviationTravelTime_sec || 0),
            0
          );
        const _totalWorkTimeCalculated_sec =
          result?.data?.tourAutorouting?.reduce(
            (acc, cur) => acc + (cur.from?.calculatedDuration_sec || 0),
            0
          );

        const _pauses = _.filter(result?.data?.tourAutorouting, (item) => {
          return item.from?.type === "pause";
        });

        const _totalPauseTime_sec = _pauses?.reduce(
          (acc, cur) => acc + (cur.from?.plannedDuration_sec || 0),
          0
        );

        // console.log("_totalPauseTime_sec", _totalPauseTime_sec);
        // console.log(
        //   "_totalWorkTimeCalculated_sec",
        //   _totalWorkTimeCalculated_sec
        // );
        // console.log("_totalWorkTimePlanned_sec", _totalWorkTimePlanned_sec);
        // console.log(
        //   "_totalTravelTimeDeviation_sec",
        //   _totalTravelTimeDeviation_sec
        // );
        // console.log("_totalTravelDistance_m", _totalTravelDistance_m);
        // console.log(result?.data?.tourAutorouting);

        const _aggregation = {
          totalTravelTime_min: Math.round((_totalTravelTime_sec || 0) / 60),
          totalTravelDistance_km: Math.round(
            (_totalTravelDistance_m || 0) / 1000
          ),
          totalWorkTimeCalculated_min: Math.round(
            ((_totalWorkTimeCalculated_sec || 0) - _totalPauseTime_sec) / 60
          ),
          totalWorkTimePlanned_min: Math.round(
            ((_totalWorkTimePlanned_sec || 0) - _totalPauseTime_sec) / 60
          ),
          totalTravelTimeDeviation_min:
            (_totalTravelTimeDeviation_sec || 0) / 60,
        };

        const _newAggegationCache = produce(get().aggregationCache, (draft) => {
          const _index = _.findIndex(draft, (item) => {
            return item.tourId === input.tourId && item.day === input.day;
          });

          if (_index === -1) {
            draft.push({
              tourId: input.tourId,
              day: input.day,
              aggregation: _aggregation,
            });
          } else {
            draft[_index] = {
              tourId: input.tourId,
              day: input.day,
              aggregation: _aggregation,
            };
          }
        });

        set({ aggregationCache: _newAggegationCache });

        set({
          tourData: result?.data?.tourAutorouting || [],
          day: input.day,
          tourId: input.tourId,
          aggregation: _aggregation,
        });
      });
  },
}));
