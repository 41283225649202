import { createClient, Provider, fetchExchange, cacheExchange } from "urql";
//import { authExchange } from '@urql/exchange-auth';
import { devtoolsExchange } from "@urql/devtools";
import inMemoryJWT from "./Components/Auth0/inMemoryJwt";
import { myClientId } from "./App";

export const client = createClient({
  url: process.env.REACT_APP_SERVER_ENDPOINT || "http://localhost:4000/graphql",
  fetchOptions: () => {
    const token = inMemoryJWT.getToken();
    const clientId = inMemoryJWT.getClientId();
    const userId = inMemoryJWT.getUserId();
    //console.log("FetchOptions", clientId);
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        clientid: myClientId,
        userId: userId,
      },
    };
  },
  exchanges: [devtoolsExchange, cacheExchange, fetchExchange],
});
