import { graphql } from "../../../src/gql";
import {
  Missions_ReviewExecutionMobileQuery,
  Tours_ReviewExecutionMobileQuery,
} from "../../../src/gql/graphql";

import { ArrElement } from "@/src/helpers/typeHelpers";
import { create } from "zustand";
import { client } from "../../../src/urqlClient";
import _ from "lodash";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { createId } from "@paralleldrive/cuid2";

export type Tuple = { quantity: number; lnr: number };

export type MissionActionCompareResult = {
  missionId: string;
  identical: Tuple[];
  onlyMission: Tuple[];
  onlyExecution: Tuple[];
};

// Function to parse the string into an array of tuples
export function parseString(str: string): Tuple[] {
  return str.split("_").map((item) => {
    const [quantity, lnr] = item.split("x").map(Number);
    return { quantity, lnr };
  });
}

// Function to compare two arrays of tuples
export function compareTuples(str1: string, str2: string) {
  const tuples1 = parseString(str1);
  const tuples2 = parseString(str2);

  const map1 = new Map<number, number>();
  const map2 = new Map<number, number>();

  // Populate map1 with tuples from str1
  for (const tuple of tuples1) {
    map1.set(tuple.lnr, (map1.get(tuple.lnr) || 0) + tuple.quantity);
  }

  // Populate map2 with tuples from str2
  for (const tuple of tuples2) {
    map2.set(tuple.lnr, (map2.get(tuple.lnr) || 0) + tuple.quantity);
  }

  const identicalTuples: Tuple[] = [];
  const uniqueToStr1: Tuple[] = [];
  const uniqueToStr2: Tuple[] = [];

  // Find identical tuples and unique tuples in str1
  for (const [lnr, quantity] of map1) {
    if (map2.has(lnr)) {
      const quantity2 = map2.get(lnr)!;
      identicalTuples.push({ quantity: Math.min(quantity, quantity2), lnr });
      if (quantity > quantity2) {
        uniqueToStr1.push({ quantity: quantity - quantity2, lnr });
      } else if (quantity < quantity2) {
        uniqueToStr2.push({ quantity: quantity2 - quantity, lnr });
      }
      map2.delete(lnr);
    } else {
      uniqueToStr1.push({ quantity, lnr });
    }
  }

  // Find unique tuples in str2
  for (const [lnr, quantity] of map2) {
    uniqueToStr2.push({ quantity, lnr });
  }

  return { identicalTuples, uniqueToStr1, uniqueToStr2 };
}

export const UpsertSupabaseME_ReviewExecutionMobile = graphql(/* GraphQL */ `
  mutation UpsertSupabaseExecutionMobile($day: String!) {
    upsertSupabaseExecutionMobile(day: $day) {
      id
    }
  }
`);

export const Tours_ReviewExecutionMobile = graphql(/* GraphQL */ `
  query Tours_ReviewExecutionMobile($filter: FilterTours!) {
    tours(filter: $filter) {
      tourId
      date
      minStart
      maxStart
      memberId
      member {
        memberId
        shortName
        lastName
        firstName
      }
    }
  }
`);

type Tour_ReviewExecutionMobile = ArrElement<
  Tours_ReviewExecutionMobileQuery["tours"]
>;

type Member_ReviewExecutionMobile = NonNullable<
  NonNullable<Tour_ReviewExecutionMobile>["member"]
>;

export const SetValidationOneExecutionMobile_ReviewExecutionMobile = graphql(
  /* GraphQL */ `
    mutation SetValidationOneExecutionMobile_ReviewExecutionMobile(
      $id: String!
      $isInvalid: Boolean!
    ) {
      setValidationOneExecutionMobile(id: $id, isInvalid: $isInvalid) {
        id
        isInvalid
      }
    }
  `
);

export const CreateOrUpdateExecutionMobile_ReviewExecutionMobile = graphql(
  /* GraphQL */ `
    mutation CreateOrUpdateExecutionMobile_ReviewExecutionMobile(
      $data: InputCreateExecutionMobile!
    ) {
      createOrUpdateExecutionMobile(data: $data) {
        id
        startTS_iso
        endTS_iso
        result
        duration_min
        codedActions
        remark
      }
    }
  `
);

export const DeleteExecutionMobile_ReviewExecutionMobile = graphql(
  /* GraphQL */ `
    mutation DeleteExecetionMobile_ReviewExecutionMobile($id: String!) {
      deleteExecutionMobile(id: $id) {
        id
      }
    }
  `
);

export const Missions_ReviewExecutionMobile = graphql(/* GraphQL */ `
  query Missions_ReviewExecutionMobile($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      missionPublicationId
      isActive
      updatedAt
      patientId
      duration_min
      startMinuteOfDay
      endMinuteOfDay
      startTS
      endTS
      time
      day
      tourId
      memberId
      memberHasQualificationLevel
      minReqQualification
      editAllowed

      actionsWithQuantity {
        quantity
        actionLnr
        action {
          examinationRequired
          leistungsart
          lnr
          leistungskomplex
        }
      }

      member {
        memberId
        lastName
        firstName
        shortName
        hasQualificationLevel
        hasExamination
      }
      patient {
        lastName
        firstName
        isPrivate
        shortName
        latitude
        longitude
      }
      executionMobiles {
        memberId
        referenceId
        patientId
        isInvalid
        source
        sourceDisplay
        id
        startTS_iso
        endTS_iso
        result
        duration_min
        codedActions
        remark
        waitTimeToOpen

        infoMessage {
          message
          actionRequired
        }
        member {
          memberId
          lastName
          firstName
          shortName
          hasQualificationLevel
        }
        actionsWithQuantity {
          quantity
          actionLnr
          action {
            examinationRequired
            leistungsart
            lnr
            leistungskomplex
          }
        }
      }
    }
  }
`);

export type LocalMission_ReviewExecutionMobile = ArrElement<
  Missions_ReviewExecutionMobileQuery["missionsTimeRange"]
>;

export type LocalExecutionMobile_ReviewExecutionMobile = ArrElement<
  LocalMission_ReviewExecutionMobile["executionMobiles"]
>;

export type LocalActionWithQuantity_ReviewExecutionMobile = ArrElement<
  LocalMission_ReviewExecutionMobile["actionsWithQuantity"]
>;

type TourStats_ReviewExecutionMobile = {
  tourId: string;
  day: string;
  uniqueId: string;
  countMissions: number;
  totalTimePatient: number;
  totalTimeTravel: number;
  noMobileExecutionMissions: LocalMission_ReviewExecutionMobile[];
  withCommentOnMobileExecutionMissions: LocalMission_ReviewExecutionMobile[];
};

interface ReviewExecutionMobileState {
  startDay: string;
  endDay: string;
  missionActionCompareResultsMap:
    | Map<string, MissionActionCompareResult>
    | undefined;
  setStartEndDayTourId: ({
    startDay,
    endDay,
    tourId,
  }: {
    startDay: string;
    endDay: string;
    tourId: string;
  }) => void;
  selectedMemberId: string;
  actionsMap:
    | Map<string, LocalActionWithQuantity_ReviewExecutionMobile>
    | undefined;
  selectedTourId: string;
  toursMap: Map<string, Tour_ReviewExecutionMobile[]> | undefined;

  tourMemberMap: Map<string, Member_ReviewExecutionMobile[]> | undefined;
  allTours: Tour_ReviewExecutionMobile[];
  allMissions: LocalMission_ReviewExecutionMobile[];
  distinctMembers: Member_ReviewExecutionMobile[];
  distinctDays: string[];
  missionsMap: Map<string, LocalMission_ReviewExecutionMobile[]> | undefined;
  selectedMission: LocalMission_ReviewExecutionMobile | undefined;
  preMissionSelected: LocalMission_ReviewExecutionMobile | undefined;
  setSelectedMission: (mission: LocalMission_ReviewExecutionMobile) => void;
  setNextSelectedMission: () => void;
  setPrevSelectedMission: () => void;
  writeData: ({
    originalMobileExecutions,
    updatedMobileExecutions,
  }: {
    originalMobileExecutions: LocalExecutionMobile_ReviewExecutionMobile[];
    updatedMobileExecutions: LocalExecutionMobile_ReviewExecutionMobile[];
  }) => void;
  loadData: () => void;
  createExecutionMobile: ({
    patientId,
    startTS,
    endTS,
    memmberId,
    referenceId,
    referenceType,
    result,
    codedActions,
    remark,
  }: {
    patientId: string;
    startTS: string;
    endTS: string;
    memmberId: string;
    referenceId: string;
    referenceType: string;
    result: string;
    codedActions: string;
    remark: string;
  }) => void;
  loadAllMissionsWithComments: () => void;
  analyzeTour: () => void;
  loadTours: ({
    startDay,
    endDay,
  }: {
    startDay: string;
    endDay: string;
  }) => void;
}

export const useReviewExecutionMobile = create<ReviewExecutionMobileState>(
  (set, get) => ({
    startDay: "",
    endDay: "",
    missionActionCompareResultsMap: undefined,
    selectedMemberId: "",
    selectedTourId: "F_B_Saarn_Speldorf_Holthausen",
    distinctDays: [],
    distinctMembers: [],
    actionsMap: undefined,
    tourMemberMap: undefined,
    tourStatMissionsMap: undefined,
    allTours: [],
    toursMap: undefined,
    allMissions: [],
    missionsMap: undefined,
    selectedMission: undefined,
    preMissionSelected: undefined,
    loadData: async () => {
      if (get().selectedTourId === "") return;

      const days = [];
      let currentDay = get().startDay;
      while (
        currentDay <= get().endDay &&
        currentDay <= dayjs().format("YYYY-MM-DD")
      ) {
        days.push(currentDay);
        currentDay = dayjs(currentDay).add(1, "day").format("YYYY-MM-DD");
      }

      for (let day of days) {
        if (dayjs().diff(day, "day") > 10) continue;

        const ret = await client
          .mutation(UpsertSupabaseME_ReviewExecutionMobile, {
            day: day,
          })
          .toPromise();

        toast.success("Mobile Daten aktualisiert " + day);

        console.log(ret);
      }

      console.log("SupabaseME upserted");

      client
        .query(
          Missions_ReviewExecutionMobile,
          {
            filter: {
              minDate: get().startDay,
              maxDate: get().endDay,
              tourIds: [get().selectedTourId],
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise()
        .then((result) => {
          const missions = result?.data?.missionsTimeRange;

          if (!missions) return;

          const _actionsMap = new Map<
            string,
            LocalActionWithQuantity_ReviewExecutionMobile
          >();

          const _districtActions = _.chain(missions)
            .map((m) => m.actionsWithQuantity)
            .flatten()
            .uniqBy("actionLnr")
            .value();

          for (let action of _districtActions) {
            if (!action) continue;
            _actionsMap.set(action.actionLnr, action);
          }

          set({ actionsMap: _actionsMap });

          const _missionCompareResultsMap = new Map<
            string,
            MissionActionCompareResult
          >();

          for (let mission of missions) {
            if (!mission.executionMobiles) continue;
            if (!mission.actionsWithQuantity) continue;

            for (let execution of mission.executionMobiles) {
              if (!execution.actionsWithQuantity) continue;
              if (!mission.actionsWithQuantity) continue;
              if (execution.result !== "success") continue;

              const result = compareTuples(
                mission.actionsWithQuantity
                  .map((awq) => `${awq?.quantity}x${awq?.actionLnr}`)
                  .join("_"),
                execution.actionsWithQuantity
                  .map((awq) => `${awq.quantity}x${awq.actionLnr}`)
                  .join("_")
              );

              _missionCompareResultsMap.set(mission.id, {
                missionId: mission.id,
                identical: result.identicalTuples,
                onlyMission: result.uniqueToStr1,
                onlyExecution: result.uniqueToStr2,
              });
            }
          }

          console.log(_missionCompareResultsMap);
          set({ missionActionCompareResultsMap: _missionCompareResultsMap });

          const _distinctDays = _.chain(missions)
            .uniqBy("day")
            .map((m) => m.day)
            .orderBy()
            .value();

          console.log(_distinctDays);
          set({ distinctDays: _distinctDays });

          const _missionsMap = new Map<
            string,
            LocalMission_ReviewExecutionMobile[]
          >();

          _distinctDays.forEach((day) => {
            const _missions = _.orderBy(
              missions?.filter((m) => m.day === day),
              ["startMinuteOfDay"],
              ["asc"]
            );
            _missionsMap.set(day, _missions || []);
          });

          set({ missionsMap: _missionsMap });
        });
    },
    setSelectedMission: (mission) => {
      // find missions before and after

      if (!mission) return;

      const _missionsMap = get().missionsMap;

      if (!_missionsMap) return;

      const currentTourMissions = _missionsMap.get(mission.day);

      if (!currentTourMissions) return;

      const currentIndex = currentTourMissions.findIndex(
        (m) => m.id === mission.id
      );

      if (currentIndex === -1) return;

      const prevMission = currentTourMissions[currentIndex - 1];

      set({
        selectedMission: mission,
        preMissionSelected: prevMission,
      });
    },
    writeData: async ({
      originalMobileExecutions,
      updatedMobileExecutions,
    }) => {
      console.log(
        "writeData",
        originalMobileExecutions,
        updatedMobileExecutions
      );

      const _selectedMission = get().selectedMission;

      if (!_selectedMission) return;

      const deletedManualExecutions = [];
      const changedMobileExecutions = [];
      const newManualExecutions = [];

      for (let e of updatedMobileExecutions) {
        // find in original

        const original = originalMobileExecutions.find((o) => o.id === e.id);
        if (!original) {
          newManualExecutions.push(e);
          continue;
        }
        if (e.isInvalid !== original.isInvalid) {
          changedMobileExecutions.push(e);
          continue;
        }
      }

      for (let e of originalMobileExecutions) {
        const updated = updatedMobileExecutions.find((o) => o.id === e.id);
        if (!updated) {
          deletedManualExecutions.push(e);
        }
      }

      console.log("deletedManualExecutions", deletedManualExecutions);
      console.log("changedMobileExecutions", changedMobileExecutions);
      console.log("newManualExecutions", newManualExecutions);

      const ret1 = await Promise.all(
        deletedManualExecutions.map((execution) =>
          client
            .mutation(DeleteExecutionMobile_ReviewExecutionMobile, {
              id: execution.id,
            })
            .toPromise()
        )
      );

      const ret2 = await Promise.all(
        changedMobileExecutions.map((execution) =>
          client
            .mutation(SetValidationOneExecutionMobile_ReviewExecutionMobile, {
              id: execution.id,
              isInvalid: execution.isInvalid,
            })
            .toPromise()
        )
      );

      const ret3 = await Promise.all(
        newManualExecutions.map((execution) =>
          client
            .mutation(CreateOrUpdateExecutionMobile_ReviewExecutionMobile, {
              data: {
                startTS: execution.startTS_iso,
                endTS: execution.endTS_iso,
                result: execution.result,
                codedActions: execution.codedActions,
                id: execution.id,
                memberId: _selectedMission.memberId,
                referenceId: _selectedMission.id,
                patientId: _selectedMission.patientId,
                referenceType: "mission",
                remark: execution.remark,
              },
            })
            .toPromise()
        )
      );

      console.log("ret1", ret1);
      console.log("ret2", ret2);
      console.log("ret3", ret3);

      get().loadData();
    },
    setNextSelectedMission: () => {
      const _selectedMission = get().selectedMission;
      if (!_selectedMission) return;

      const _missionsMap = get().missionsMap;

      if (!_missionsMap) return;

      const currentTourMissions = _missionsMap.get(_selectedMission.day);

      if (!currentTourMissions) return;

      const currentIndex = currentTourMissions.findIndex(
        (m) => m.id === _selectedMission.id
      );

      if (currentIndex === -1) return;

      const nextMission = currentTourMissions[currentIndex + 1];
      if (!nextMission) return;

      get().setSelectedMission(nextMission);
    },
    setPrevSelectedMission: () => {
      const _selectedMission = get().selectedMission;
      if (!_selectedMission) return;
      const _missionsMap = get().missionsMap;

      if (!_missionsMap) return;

      const currentTourMissions = _missionsMap.get(_selectedMission.day);

      if (!currentTourMissions) return;

      const currentIndex = currentTourMissions.findIndex(
        (m) => m.id === _selectedMission.id
      );

      if (currentIndex === -1) return;

      const prevMission = currentTourMissions[currentIndex - 1];
      if (!prevMission) return;

      get().setSelectedMission(prevMission);
    },
    analyzeTour: () => {
      const _missionsMap = get().missionsMap;

      if (!_missionsMap) return;

      const _selectedTourId = get().selectedTourId;

      const _distinctDays = get().distinctDays;

      const _tourStats: TourStats_ReviewExecutionMobile[] = [];

      _distinctDays.forEach((day) => {
        const currentTourMissions = _missionsMap.get(day);

        if (!currentTourMissions) return;

        const _noMobileExecutionMissions = currentTourMissions.filter(
          (m) => m.executionMobiles.length === 0
        );

        const _withCommentMobileExecutions = currentTourMissions.filter(
          (m) =>
            m.executionMobiles.length > 0 &&
            m.executionMobiles.some((e) => e.remark !== "")
        );

        const _totalTimePatient = currentTourMissions.reduce(
          (acc, m) =>
            acc +
            m.executionMobiles.reduce((acc2, e) => acc2 + e.duration_min, 0),
          0
        );

        const _totalTimeTravel = currentTourMissions.reduce(
          (acc, m) =>
            acc +
            m.executionMobiles.reduce((acc2, e) => acc2 + e.duration_min, 0),
          0
        );

        _tourStats.push({
          tourId: _selectedTourId,
          day: day,
          uniqueId: `${_selectedTourId}_${day}`,
          countMissions: currentTourMissions.length,
          totalTimePatient: _totalTimePatient,
          totalTimeTravel: _totalTimeTravel,
          noMobileExecutionMissions: _noMobileExecutionMissions,
          withCommentOnMobileExecutionMissions: _withCommentMobileExecutions,
        });
      });

      console.log("TourStats", _tourStats);
    },
    setStartEndDayTourId: ({ startDay, endDay, tourId }) => {
      set({ startDay, endDay });
      set({ selectedTourId: tourId });
      get().loadData();
    },
    loadTours: async ({ startDay, endDay }) => {
      console.log("loadTours", startDay, endDay);

      const allDays = [];
      let currentDay = startDay;
      while (currentDay <= endDay) {
        allDays.push(currentDay);
        currentDay = dayjs(currentDay).add(1, "day").format("YYYY-MM-DD");
      }

      const _allTours = [];

      for (let day of allDays) {
        const result = await client
          .query(Tours_ReviewExecutionMobile, {
            filter: {
              day: day,
            },
          })
          .toPromise();

        const tours = result?.data?.tours || [];
        _allTours.push(...tours);

        //console.log("day set", day, tours);
      }
      const _distinctMembers =
        _.chain(_allTours)
          .orderBy(["member.lastName"], ["asc"])
          .uniqBy("memberId")
          .map((t) => {
            return {
              shortName: t?.member?.shortName || "NichtDa",
              lastName: t?.member?.lastName || "NichtDa",
              firstName: t?.member?.firstName || "NichtDa",
              memberId: t?.memberId || "NichtDa",
            };
          })
          .value() || [];

      console.log(_distinctMembers);
      console.log(_allTours);

      set({ distinctMembers: _distinctMembers || [], allTours: _allTours });
    },
    loadAllMissionsWithComments: async () => {
      const result = await client
        .query(
          Missions_ReviewExecutionMobile,
          {
            filter: {
              minDate: get().startDay,
              maxDate: get().endDay,
            },
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();

      const missions = result?.data?.missionsTimeRange || [];

      // const _missionsWithComments = missions.filter((m) =>
      //   m.executionMobiles.some((e) => e.remark !== "" && e.remark !== null)
      // );

      console.log(
        "loadAllMissionsWithComments",
        _.orderBy(missions, ["startTS"], ["asc"])
      );

      set({
        allMissions: _.orderBy(missions, ["startTS"], ["asc"]),
      });
    },
    createExecutionMobile({
      patientId,
      startTS,
      endTS,
      memmberId,
      referenceId,
      referenceType,
      result,
      codedActions,
      remark,
    }) {
      client
        .mutation(CreateOrUpdateExecutionMobile_ReviewExecutionMobile, {
          data: {
            startTS: startTS,
            endTS: endTS,
            result: result,
            codedActions: codedActions,
            id: createId(),
            memberId: memmberId,
            referenceId: referenceId,
            patientId: patientId,
            referenceType: referenceType,
            remark: remark,
          },
        })
        .toPromise()
        .then((result) => {
          console.log("createExecutionMobile", result);
          toast.success("Erfolgreich erstellt");
        });
    },
  })
);
