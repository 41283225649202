import React, { useEffect } from "react";

import { usePatientAbsence } from "./usePatientAbsence";
import { Calendar } from "../../UiComponents/Calendar";
import dayjs from "dayjs";
import { Button } from "../../UiComponents/Button";
import { BiEdit, BiTrash } from "react-icons/bi";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "../../UiComponents/DateRangePicker";
import { set } from "lodash";
import { cn } from "../../../helpers/utils";
import { Textarea } from "../../UiComponents/Textarea";
import DisplayPatient from "../DisplayPatient";
import { DisplayPatientDynamik } from "../DisplayPatientDynamic";
import { DisplayPatientInfo } from "../DisplayPatientInfo";

const hasOverlayingDateRange = (dateRange: DateRange | undefined) => {
  if (dateRange === undefined) {
    return false;
  }
  const { patientAbsences } = usePatientAbsence.getState();

  const dateRanges = patientAbsences.map((absence) => {
    return {
      from: dayjs(absence.startDay).toDate(),
      to: dayjs(absence.endDay).toDate(),
    };
  });

  if (dateRange.from === undefined || dateRange.to === undefined) {
    return false;
  }

  dateRanges.push({
    from: dateRange.from,
    to: dateRange.to,
  });

  if (dateRanges.length < 2) {
    return false;
  }

  for (let i = 0; i < dateRanges.length; i++) {
    for (let j = 0; j < dateRanges.length; j++) {
      if (i === j) {
        continue;
      }

      if (
        dayjs(dateRanges[i].from).isBefore(dateRanges[j].to) &&
        dayjs(dateRanges[i].from).isAfter(dateRanges[j].from)
      ) {
        return true;
      }

      if (
        dayjs(dateRanges[i].to).isBefore(dateRanges[j].to) &&
        dayjs(dateRanges[i].to).isAfter(dateRanges[j].from)
      ) {
        return true;
      }

      if (
        dayjs(dateRanges[i].from).isSame(dateRanges[j].from) ||
        dayjs(dateRanges[i].to).isSame(dateRanges[j].to)
      ) {
        return true;
      }
    }
  }

  return false;
};

type Props = {
  patientId: string;
  updateData?: () => void;
};

const ManageOnePatientAbsence = (props: Props) => {
  const { patientId } = props;
  const { setPatientId, patientAbsences, patientCareIntervals } =
    usePatientAbsence();
  const [selectedAbssenceIndex, setSelectedAbsenceIndex] = React.useState(-1);
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>({
    from: dayjs().toDate(),
    to: dayjs().add(7, "day").toDate(),
  });

  const [localRemark, setLocalRemark] = React.useState("");
  const [allDateRanges, setAllDateRanges] = React.useState<DateRange[]>([]);

  useEffect(() => {
    setPatientId(patientId);
    return () => {
      setPatientId("");
    };
  }, [patientId]);

  return (
    <div className="h-96 overflow-y-scroll">
      <div>
        <DisplayPatientInfo patientId={patientId} />
      </div>
      <div className="flex flex-col gap-2 h-full ">
        <div
          className=""
          onClick={() => {
            setSelectedAbsenceIndex(-1);
            setDateRange(undefined);
            setLocalRemark("");
          }}
        >
          {patientAbsences.map((absence, index) => {
            return (
              <div
                className={cn(
                  "flex flex-row gap-4",
                  index === selectedAbssenceIndex && "bg-gray-200"
                )}
              >
                <div className="font-semibold">
                  {dayjs(absence.startDay).format("YYYY, ddd DD. MMM")}{" "}
                  <span className="text-xs">bis</span>{" "}
                </div>
                <div className="font-semibold">
                  {dayjs(absence.endDay).format("YYYY, ddd DD. MMM")}
                </div>
                <div>{absence.remark}</div>
              </div>
            );
          })}
        </div>
        <div className="text-xl">Betreuungszeiträume</div>
        <div>
          {patientCareIntervals?.map((interval) => {
            return (
              <div className="flex flex-row gap-2 items-center">
                <div>
                  {dayjs(interval.startDay).format("YYYY, ddd DD. MMM")}
                </div>
                <span className="text-xs">bis</span>
                <div>{dayjs(interval.endDay).format("YYYY, ddd DD. MMM")}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManageOnePatientAbsence;
