import React, { useState } from "react";
import { Input } from "../UiComponents/Input";
import { Label } from "../UiComponents/Label";
import { DialogClose } from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { useMissionEasyPlan } from "./useMissionEasyPlan";

type Props = {};

const CreateNewTour = (props: Props) => {
  const [tourId, setTourId] = useState<string>("");

  const { createNewTour } = useMissionEasyPlan();

  return (
    <div>
      <div>Neue Tour</div>
      <Label htmlFor="tourid">Tour ID</Label>
      <Input
        id="tourid"
        value={tourId}
        onChange={(e) => setTourId(e.target.value)}
      />
      <DialogClose>
        <Button>Abbrechen</Button>
      </DialogClose>
      <Button
        onClick={() => {
          createNewTour({ tourId });
        }}
      >
        Anlegen
      </Button>
    </div>
  );
};

export default CreateNewTour;
