import React, { useState } from "react";
import {
  useGetOnePatientQuery,
  useUpdatePatientGeoDataMutation,
} from "../../generated/graphql";
import { Button } from "../UiComponents/Button";

import { RiMapPin2Line } from "react-icons/ri";
import classNames from "classnames";
import {
  PopoverRadix,
  PopoverRadixContent,
  PopoverRadixTrigger,
} from "../UiComponents/PopoverRadix";

type Props = { patientId: string };

const ManageGeoData = (props: Props) => {
  const [result, reececuteQuery] = useGetOnePatientQuery({
    variables: { id: props.patientId },
  });

  const [upadatePatientGeoDataResult, updatePatientGeoData] =
    useUpdatePatientGeoDataMutation();

  const [coordinateInput, setCoordinateInput] = useState("");

  const { data, error, fetching } = result;
  if (fetching) return <div>loading</div>;
  if (error)
    return (
      <div>
        E: {props.patientId} {JSON.stringify(error, null, 2)}
      </div>
    );

  return (
    <>
      <div className="flex m-2 bg-white w-fit">
        <div className="flex flex-row items-center">
          <div>
            <PopoverRadix>
              <PopoverRadixTrigger>
                <RiMapPin2Line
                  className={classNames("h-7 w-7", {
                    "text-success": data?.patient?.latitude,
                    "text-red-500": !data?.patient?.latitude,
                  })}
                />
              </PopoverRadixTrigger>
              <PopoverRadixContent>
                <div className="m-2 p-2">
                  <div>
                    {data?.patient?.latitude} {data?.patient?.longitude}
                  </div>
                  <input
                    className="py-2 px-3 w-full border-2 border-primaryLighter mt-1 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    onChange={(event) => {
                      setCoordinateInput(event.target.value);
                    }}
                  />
                  <Button
                    className="btn btn-accent btn-sm m-2 p-2 btn-outline"
                    onClick={() => {
                      updatePatientGeoData({
                        patientId: props.patientId,
                        geoString: coordinateInput,
                      }).then((result) => {
                        console.log(result);
                      });
                    }}
                  >
                    Speichern
                  </Button>
                  <div>
                    <div>{data?.patient?.districtId}</div>
                  </div>
                </div>
              </PopoverRadixContent>
            </PopoverRadix>
          </div>
        </div>

        <Button
          variant={"neutral-outline"}
          onClick={() => {
            const addr =
              data?.patient?.address +
              " " +
              data?.patient?.zipCode +
              " " +
              data?.patient?.city;
            window.open(`https://maps.google.com/maps/place/${addr}`);
          }}
        >
          Maps
        </Button>
      </div>
    </>
  );
};

export default ManageGeoData;
