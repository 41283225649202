import { create } from "zustand";

import { graphql } from "../../gql";
import { client } from "../../urqlClient";
import { ArrElement } from "../../helpers/typeHelpers";
import { PatientActionDurationsQuery } from "../../gql/graphql";

const DeletePatientActionDuration = graphql(/* GraphQL */ `
  mutation DeletePatientActionDuration($id: String!) {
    deletePatientActionDuration(id: $id) {
      id
    }
  }
`);

const PatientActionDurations = graphql(/* GraphQL */ `
  query PatientActionDurations($patientId: String) {
    patientActionDurations(patientId: $patientId) {
      id
      patientId
      actionQuantityCoded
      startDay
      endDay
      duration_min
      actionsWithQuantity {
        quantity
        actionLnr
        action {
          leistungsart
          lnr
          leistungskomplex
          examinationRequired
        }
      }
      isEffective
      patient {
        patientId
        lastName
        firstName
      }
    }
  }
`);

export type LocalPatientActionDuration = ArrElement<
  PatientActionDurationsQuery["patientActionDurations"]
>;

type PatientActionDurationState = {
  patientId: string;
  setPatientId: (patientId: string) => void;
  actionDurations: LocalPatientActionDuration[];
  loadData: () => void;
  loadAllData: () => void;
  deletePatientActionDuration: (id: string) => void;
};

export const usePatientActionDuration = create<PatientActionDurationState>(
  (set, get) => ({
    patientId: "",
    actionDurations: [],
    setPatientId: (patientId) => {
      set({ patientId });
      get().loadData();
    },
    loadData: async () => {
      const { patientId } = get();
      if (patientId) {
        const ret = await client
          .query(
            PatientActionDurations,
            { patientId },
            { requestPolicy: "network-only" }
          )
          .toPromise();
        console.log(ret.data);
        set({ actionDurations: ret.data?.patientActionDurations || [] });
      }
    },
    deletePatientActionDuration: async (id) => {
      await client.mutation(DeletePatientActionDuration, { id }).toPromise();
      get().loadData();
    },
    loadAllData: async () => {
      console.log("Load all Data");
      const ret = await client
        .query(
          PatientActionDurations,
          {},

          { requestPolicy: "network-only" }
        )
        .toPromise();

      console.log(ret);
      set({ actionDurations: ret.data?.patientActionDurations || [] });
    },
  })
);
