import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../UiComponents/Button";
import PatientSearchbox from "../PatientSearchbox/PatientSearchbox";

import MemberSearchbox from "../MemberSearchbox/MemberSearchbox";
import { MyDayPicker } from "../UiComponents/MyDayPicker";

import { useMissionCreate } from "./useMissionCreate";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import { InfoCircledIcon } from "@radix-ui/react-icons";

import { BiAbacus, BiAddToQueue, BiAlarm } from "react-icons/bi";
import { Popover } from "@radix-ui/react-popover";
import { PopoverContent, PopoverTrigger } from "../UiComponents/Popover";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";
import { useMemberSearchbox } from "../MemberSearchbox/useMemberSearchbox";
import { Input } from "../UiComponents/Input";
import { Label } from "../UiComponents/Label";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import dayjs from "dayjs";
import { set } from "lodash";
import toast from "react-hot-toast";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";

const selectableMinutes = ["05", "10", "15", "20", "25", "30"];

const timeAdjustMinutes = ["-15", "-10", "-5", "+5", "+10", "+15"];

const formSchema = z.object({
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
});

const DisplayMissionMember = (props: {
  id: string;
  day: string;
  patient?:
    | {
        patientId: string;
        firstName?: string | undefined | null;
        lastName?: string | undefined | null;
      }
    | null
    | undefined;
  memberId: string;
  time: string;
}) => {
  const { id, patient, time } = props;
  return (
    <div className="">
      <div className="grid grid-cols-3 ">
        <div>
          <Popover>
            <PopoverTrigger>
              <Button variant={"neutral-ghost"} size={"compact-sm"}>
                <InfoCircledIcon className="w-4 h-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <DisplayMissionAnalytic missionId={id} />
            </PopoverContent>
          </Popover>

          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAlarm className="w-4 h-4" />
          </Button>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAddToQueue className="w-4 h-4" />
          </Button>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAbacus className="w-4 h-4" />
          </Button>
        </div>
        <div>{time}</div>
        <div>
          {patient?.lastName}, {patient?.firstName}
        </div>
      </div>
    </div>
  );
};

const DisplayMission = (props: {
  id: string;
  day: string;
  member?: {
    shortName: string | "";
    hasExamination: boolean;
    hasQualificationLevel: string;
  } | null;
  memberId: string;
  time: string;
  setMemberId?: (memberId: string) => void;
}) => {
  const { id, day, memberId, time, member } = props;

  const { setSelectedMemberId } = useMemberSearchbox();
  return (
    <div>
      <div className="grid grid-cols-3 ">
        <div>
          <Popover>
            <PopoverTrigger>
              <Button variant={"neutral-ghost"} size={"compact-sm"}>
                <InfoCircledIcon className="w-4 h-4" />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <DisplayMissionAnalytic missionId={id} />
            </PopoverContent>
          </Popover>

          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAlarm className="w-4 h-4" />
          </Button>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAddToQueue className="w-4 h-4" />
          </Button>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <BiAbacus className="w-4 h-4" />
          </Button>
        </div>
        <div>{time}</div>
        <div>
          <Button
            variant={"neutral-ghost"}
            size={"compact-sm"}
            onClick={() => {
              props.setMemberId?.(memberId);
              setSelectedMemberId(memberId);
            }}
          >
            <DisplayMemberShortName {...member} />
          </Button>
        </div>
      </div>
    </div>
  );
};

type Props = {
  memberId?: string;
  patientId?: string;
};

export const MissionCreate = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <MissionCreate1 {...props} />;
};

const MissionCreate1 = (props: Props) => {
  const {
    selectedPatient,
    misionPublicationStatus,
    isOperating,
    setSelectedPatientId,
    selectedDate,
    selectedDay,
    setSelectedDate,
    patientMissions,
    memberMissions,
    setSelectedMemberId,
    createMission,
  } = useMissionCreate();

  const { actionsClipboardContent } = useActionClipboard();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
    },
  });

  const [patientId, setPatientId] = React.useState<string>("");
  const [memberId, setMemberId] = React.useState<string>("");
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  const initialDays: Date[] = [];
  const [days, setDays] = React.useState<Date[] | undefined>(initialDays);

  const [startTS, setStartTS] = React.useState<string>("");
  const [endTS, setEndTS] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<string>("08:00");
  const [duration, setDuration] = React.useState<string>("05");

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
  };

  const memberChange = (memberId: string) => {
    setMemberId(memberId);
    setSelectedMemberId(memberId);
  };

  useEffect(() => {
    const _day = dayjs(selectedDate).format("YYYY-MM-DD");
    // console.log(_day);
    // console.log(startTime);
    // console.log(duration);

    // console.log(_day + "T" + startTime);

    setStartTS(dayjs(_day + "T" + startTime, "YYYY-MM-DDTHH:mm").toISOString());
  }, [startTime, duration, selectedDate]);

  return (
    <>
      <div className="flex justify-center items-center p-2 h-full flex-col overflow-y-hidden">
        <div className=" bg-white p-2 h-full flex flex-row  justify-center items-start gap-2 overflow-y-hidden">
          <div className="w-[400px] bg-white p-2 flex flex-col gap-2 h-full overflow-y-hidden">
            <div className="sticky h-12 p-2 bg-gray-100 flex flex-row justify-between">
              <MemberSearchbox setMemberId={memberChange} />
              <Button disabled={true}>{"<------"}</Button>
            </div>
            <div className="overflow-y-scroll">
              {memberMissions.map((mission) => {
                return <DisplayMissionMember {...mission} />;
              })}
            </div>
          </div>

          <div className="w-[400px] bg-white p-2 flex flex-col gap-2 overflow-y-scroll">
            {misionPublicationStatus !== "rework" && (
              <div className="bg-red-400 text-white p-2">
                Tag kann nicht bearbeitet werden. Status beachten
              </div>
            )}
            <ActionClipboard />

            <div>
              <MyDayPicker
                mode="single"
                selected={selectedDate}
                onSelect={setSelectedDate}
                className="rounded-md border"
              />
            </div>
            <div>
              <Label htmlFor="time">
                Zeit(
                {timeAdjustMinutes.map((m) => {
                  return (
                    <span>
                      <Button
                        onClick={() => {
                          setStartTime(
                            dayjs(startTime, "HH:mm")
                              .add(Number(m), "minute")
                              .format("HH:mm")
                          );
                        }}
                        size={"compact-sm"}
                        variant={"primary-ghost"}
                      >
                        {m}
                      </Button>
                    </span>
                  );
                })}
                )
              </Label>
              <Input
                type="time"
                id="time"
                placeholder="zeit"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                step="300"
                min="06:00"
                max="23:55"
              />
              <Label htmlFor="duration">
                Dauer (
                {selectableMinutes.map((m) => {
                  return (
                    <span>
                      <Button
                        onClick={() => {
                          setDuration(m);
                        }}
                        size={"compact-sm"}
                        variant={"primary-ghost"}
                      >
                        {m}
                      </Button>
                    </span>
                  );
                })}
                )
              </Label>

              <Input
                type="number"
                id="duration"
                placeholder="Dauer"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                step="5"
                min="05"
                max="120"
              />
            </div>
            <div>
              <div className="text-center font-semibold">
                {dayjs(startTS).format("YYYY-MM-DD HH:mm")}{" "}
                <span className="font-light">für</span> {duration}{" "}
                <span className="font-light">Minuten</span>
              </div>
              <Button
                disabled={
                  isOperating ||
                  !selectedPatient ||
                  !memberId ||
                  !startTS ||
                  misionPublicationStatus !== "rework"
                }
                onClick={() => {
                  if (!selectedPatient || !memberId || !startTS) {
                    toast.error(
                      "Bitte Patient, Mitarbeiter und Datum auswählen"
                    );
                    return;
                  }

                  createMission({
                    patientId: selectedPatient?.patientId,
                    memberId: memberId,
                    startTS: startTS,
                    endTS: dayjs(startTS)
                      .add(Number(duration), "minute")
                      .subtract(1, "second")
                      .toISOString(),
                    actions: actionsClipboardContent.map((a) => {
                      return {
                        quantity: a.quantity,
                        lnr: a.action.lnr,
                      };
                    }),

                    tourId: "NoTourId",
                  });
                }}
              >
                Einsatz anlegen
              </Button>
            </div>
          </div>

          <div className="w-[400px] bg-white p-2 flex flex-col overflow-y-hidden h-full">
            <div className="font-bold p-2 flex flex-row  justify-between  items-center h-12 bg-gray-100 ">
              {selectedPatient?.lastName} {selectedPatient?.firstName}
              <PatientSearchbox onSelect={setSelectedPatientId} />
            </div>
            <div className="overflow-y-scroll">
              {patientMissions.map((mission) => {
                return (
                  <DisplayMission setMemberId={memberChange} {...mission} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionCreate;
