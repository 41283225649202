import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  useSchedulesByPatientQuery,
  useGetOnePatientQuery,
  useCreateEmptyScheduleMutation,
} from "../../generated/graphql";

import _ from "lodash";

import { useParams } from "react-router";

import { DisplayFullPatientInfo } from "../Patient/DisplayFullPatientInfo";
import MyModal from "../UiComponents/MyModal";
import { SelectPatient } from "../Patient/SelectPatient";
import LoginButton from "../Auth0/LoginButton";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import ManageGeoData from "../Patient/ManageGeoData";
import DisplayScheduleByDay from "../Schedule/DisplayScheduleByDay";
import dayjs from "dayjs";
import PatientMissionList from "../PatientMissionList/PatientMissionList";
import DialogCreateMission from "./DialogCreateMission";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import PatientNoActionList from "../PatientMissionList/PatientNoActionList";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import { PatientSearchBox } from "./PatientSearchBox";
import { Button } from "../UiComponents/Button";
import { useManageSchedules } from "./useManageSchedules";
import PatientGenderDeny from "../PatientDenyGender/PatientDenyGender";
import PatientIssue from "../PatientIssue/PatientIssue";
import { Dialog, DialogContent } from "../UiComponents/Dialog";
import PatientSearchbox from "../PatientSearchbox/PatientSearchbox";
import GlobalWeekSelect from "../GlobalService/GlobalWeekSelect";
import PatientActionDurationManage from "../PatientActionDuration/PatientActionDurationManage";
import { usePatientMissionList } from "../PatientMissionList/usePatientMissionList";
import MissionPublicationStatus from "../MissionPublicationStatus/MissionPublicationStatus";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";

import { io, Socket } from "socket.io-client";
import { toast } from "react-toastify";
import { Sheet, SheetContent } from "../UiComponents/Sheet";
import PatientMap from "../Patient/PatientMap/PatientMap";
import { useAuth0 } from "@auth0/auth0-react";
import PatientInfoMessage from "../Patient/PatientInfoMessage/PatientInfoMessage";
import DisplayActivePatientAbsence from "../Patient/PatientAbsence/DisplayActivePatientAbsence";
import PatientManageDistrict from "../Patient/PatientManageDistrict/PatientManageDistrict";
import AllPatientInfoMessage from "../Patient/PatientInfoMessage/AllPatientInfoMessage";
import PatientInfoMessageDisplayAll from "../Patient/PatientInfoMessage/PatientInfoMessageDisplayAll";

interface ParamTypes {
  patientId: string;
}

interface Props {}

export const ManageSchedules = (props: Props) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    <LoginButton />;
  }

  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <ManageSchedules1 />;
};

export const ManageSchedules1 = (props: Props) => {
  const { patientId } = useParams<keyof ParamTypes>() as ParamTypes;

  const [socket, setSocket] = useState<Socket | undefined>();

  const navigate = useNavigate();

  const [currentPatientId, setCurrentPatientId] = useState(patientId || "2710");
  const clearActionClipboardContent = useActionClipboard(
    (state) => state.clearActionClipboardContent
  );

  const clearMissions = useManageSchedules((state) => state.clearMissions);

  const [result, reececuteQuery] = useSchedulesByPatientQuery({
    //pause: true,
    requestPolicy: "network-only",
    variables: { filter: { patientId: patientId } },
  });

  const { updateMissions, loadMissions } = usePatientMissionList();

  const [isOpenPatientSelect, setIsOpenPatientSelect] = useState(false);
  const [isOpenCreateMission, setIsOpenCreateMission] = useState(false);

  const [issueListOpen, setIssueListOpen] = useState(false);

  const [isOpenPatientActionDuration, setIsOpenPatientActionDuration] =
    useState(false);

  const [isOpenPatientInfoMessage, setIsOpenPatientInfoMessage] =
    useState(false);

  const [resultPatient, reececuteQueryPatient] = useGetOnePatientQuery({
    variables: { id: patientId },
  });

  const { data: patientData } = resultPatient;

  const [createEmptyScheduleResult, createEmptySchedule] =
    useCreateEmptyScheduleMutation();

  const { fetching: createEmptyScheduleFetching } = createEmptyScheduleResult;

  const { data, fetching, error } = result;
  const [values, setValues] = useState([10]);

  const handleSelectPatient = () => {
    navigate(`/manageschedules/${currentPatientId}`, {
      replace: true,
    });
  };

  useEffect(() => {
    console.log("Use effect socket", socket);
    if (!socket) {
      const _socket = io(process.env.REACT_APP_SOCKET_ENDPOINT || "", {
        auth: {
          token: inMemoryJwt.getToken(),
        },
      });
      console.log("socket", _socket);
      _socket.on("connect", () => {
        toast.success("Connected to Server" + _socket.id);

        _socket.on("disconnect", () => {
          toast.error("Disconnected from Server");
        });
      });

      _socket.on("mission-update", (args) => {
        console.log("hurga", args);
        // toast(`${args}`, {
        //   autoClose: 800,
        // });
        updateMissions();
      });

      setSocket(_socket);
    }
  }, []);

  useEffect(() => {
    navigate(`/manageschedules/${currentPatientId}`);
  }, [currentPatientId, navigate]);

  useEffect(() => {
    console.log(patientId);
    clearActionClipboardContent();
    clearMissions();
    reececuteQuery();
  }, [patientId, reececuteQuery]);

  if (fetching) {
    return <div>Loading</div>;
  }
  if (!data) return <div>No Data</div>;
  if (error) return <div>Error {JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      <Helmet>
        <title>
          {patientData?.patient?.lastName || ""},{" "}
          {patientData?.patient?.firstName || ""}
        </title>
      </Helmet>

      <div className="bg-white mt-2 mb-2 w-11/12 mx-auto flex flex-col items-center overflow-y-hidden">
        <div className="flex flex-row items-center w-full justify-around ">
          <div>
            <Button
              onClick={() => {
                setIssueListOpen(!issueListOpen);
              }}
            >
              Keine Leistungen
            </Button>
          </div>

          <DisplayFullPatientInfo patientId={patientId} />
          <PatientGenderDeny patientId={patientId} />
          <ManageGeoData patientId={patientId} />
          <PatientManageDistrict patientId={patientId} />
          <div>
            <Button
              onClick={() => {
                setIsOpenPatientInfoMessage(true);
              }}
            >
              Info
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                setIsOpenPatientActionDuration(true);
              }}
            >
              Zeitvorgaben
            </Button>
          </div>

          <PatientSearchbox
            //handleSave={handleSelectPatient}
            onSelect={setCurrentPatientId}
          />
          <div>
            <Popover>
              <PopoverTrigger>
                <Button>Freigabe</Button>
              </PopoverTrigger>
              <PopoverContent>
                <MissionPublicationStatus
                  updateParentFunction={() => {
                    updateMissions();
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        {/* End of Header */}

        <div className="flex flex-row-reverse h-full w-full ">
          <div className="flex flex-col h-full w-2/6">
            <div className="">
              <ActionClipboard />
            </div>

            <div className="overflow-y-scroll h-[32rem] w-full">
              <PatientMissionList patientId={patientId} />
              {/* <TableTest /> */}
            </div>
          </div>
          <div className="w-4/6">
            <div className="w-12/12 m-2 p-2 bg-slate-100">
              <DisplayScheduleByDay patientId={patientId} />
            </div>
            <div className="flex flex-row gap-2">
              <div>
                <PatientMap
                  latitude={patientData?.patient?.latitude}
                  longitude={patientData?.patient?.longitude}
                />
                <DisplayActivePatientAbsence patientId={patientId} />
              </div>

              <div className="text-xs whitespace-pre-wrap">
                {patientData?.patient?.medifoxPatientInfo}
              </div>
            </div>
            <div>
              {/* <div>
                <PatientNoActionList
                  handleSave={handleSelectPatient}
                  setCurrentPatientId={setCurrentPatientId}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Sheet modal={false} open={issueListOpen} onOpenChange={setIssueListOpen}>
        <SheetContent side={"bottom"} title={"Keine Leistungen"} className="">
          <div>
            <PatientNoActionList
              handleSave={handleSelectPatient}
              setCurrentPatientId={setCurrentPatientId}
            />
          </div>
        </SheetContent>
      </Sheet>
      <Dialog
        open={isOpenPatientActionDuration}
        onOpenChange={setIsOpenPatientActionDuration}
      >
        <DialogContent
          title={"Zeitvorgaben^"}
          className=" flex flex-col w-[80%] h-[80%]"
        >
          <PatientActionDurationManage
            patientId={currentPatientId}
            updateParentFunction={() => {
              updateMissions();
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isOpenPatientInfoMessage}
        onOpenChange={setIsOpenPatientInfoMessage}
      >
        <DialogContent
          title={"Patienten Informationen"}
          className=" flex flex-col w-[80%] h-[80%]"
        >
          <PatientInfoMessageDisplayAll patientId={currentPatientId} />
        </DialogContent>
      </Dialog>

      {/* <DialogCreateMission
        handleSave={() => {}}
        open={isOpenCreateMission}
        setOpen={setIsOpenCreateMission}
      /> */}
    </>
  );
};
