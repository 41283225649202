import { create } from "zustand";
import { LocalPatientSearchBox } from "../PatientSearchbox/usePatientSearchbox";
import { graphql } from "../../gql";
import { client } from "../../urqlClient";

import {
  MissionsMissionCreateQuery,
  PatientMissionCreateQuery,
} from "../../gql/graphql";
import dayjs from "dayjs";
import { ArrElement } from "../../helpers/typeHelpers";
import _ from "lodash";
import toast from "react-hot-toast";
import { error } from "console";
import { to } from "@react-spring/web";

const PatientMissionCreate = graphql(/* GraphQL */ `
  query PatientMissionCreate($patientId: String!) {
    patient(patientId: $patientId) {
      patientId
      lastName
      firstName
      zipCode
      city
      address
      isActive
    }
  }
`);

export type LocalPatientMissionCreate = PatientMissionCreateQuery["patient"];

const MissionsMissionCreate = graphql(/* GraphQL */ `
  query MissionsMissionCreate($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      memberId
      patient {
        lastName
        firstName
        patientId
      }
      member {
        lastName
        firstName
        shortName
        hasExamination
        hasQualificationLevel
      }
      time
      startTS
      endTS
      duration_min
      day
    }
  }
`);

export const MissionPublications_MissionCreate = graphql(`
  query MissionPublications_MissionCreate(
    $filter: MostRecentMissionPublicationFilter!
  ) {
    mostRecentMissionPublications(filter: $filter) {
      id
      releaseStatus
      releaseStatusName
      day
      createdAt
    }
  }
`);

export type LocalMissionsMissionCreate = ArrElement<
  MissionsMissionCreateQuery["missionsTimeRange"]
>;

const AddMissionToDay_MissionCreate = graphql(/* GraphQL */ `
  mutation addMissionToDay_missionCreate($input: AddMissionToDayInput!) {
    addMissionToDay(input: $input) {
      id
    }
  }
`);

/**
startTS: String!
endTS: String!
tourId: String!
patientId: String!
memberId: String!
actionsWithQantity: [ActionIdWithQuantity!]!
 */

/**
quantity: Int!
lnr: String!
 */

interface MissionCreateState {
  selectedPatientId: string;
  selectedMemberId: string;
  isOperating: boolean;
  misionPublicationStatus: string;
  selectedPatient: LocalPatientMissionCreate | undefined;
  setSelectedPatientId: (patientId: string) => void;
  setSelectedMemberId: (memberId: string) => void;
  selectedDate: Date | undefined;
  selectedDay: string;
  setSelectedDate: (date: Date | undefined) => void;
  patientMissions: LocalMissionsMissionCreate[];
  memberMissions: LocalMissionsMissionCreate[];
  loadPatientMissions: () => void;
  loadMemberMissions: () => void;
  loadMissionPublicationStatus: () => void;
  createMission: ({
    startTS,
    endTS,
    memberId,
    patientId,
    actions,
    tourId,
  }: {
    startTS: string;
    endTS: string;
    memberId: string;
    patientId: string;
    tourId: string;
    actions: { quantity: number; lnr: string }[];
  }) => void;

  // selectedMemeberId: string;
  // setSelectedMemberId: (memberId: string) => void;
  // missions: any[];
}

export const useMissionCreate = create<MissionCreateState>((set, get) => ({
  selectedPatientId: "",
  selectedMemberId: "",
  selectedPatient: undefined,
  selectedDate: undefined,
  selectedDay: "",
  isOperating: false,
  misionPublicationStatus: "",
  patientMissions: [],
  memberMissions: [],

  setSelectedDate: (date: Date | undefined) => {
    console.log("setSelectedDate", date);

    set({
      selectedDate: date,
      selectedDay: dayjs(date).format("YYYY-MM-DD") || "",
    });

    set({ misionPublicationStatus: "" });

    get().loadPatientMissions();
    get().loadMemberMissions();
    get().loadMissionPublicationStatus();
  },
  setSelectedPatientId: async (patientId: string) => {
    const ret = await client
      .query(
        PatientMissionCreate,
        { patientId },
        { requestPolicy: "network-only" }
      )
      .toPromise();

    set({ selectedPatient: ret.data?.patient });
    set({ selectedPatientId: patientId });

    get().loadPatientMissions();
  },
  setSelectedMemberId: (memberId: string) => {
    set({ selectedMemberId: memberId });

    get().loadMemberMissions();
  },
  loadPatientMissions: async () => {
    const { selectedDay, selectedPatientId } = get();
    if (selectedDay === "" || selectedPatientId === "") return;

    const ret = await client
      .query(
        MissionsMissionCreate,
        {
          filter: {
            maxDate: selectedDay,
            minDate: selectedDay,
            patientIds: [selectedPatientId],
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    set({
      patientMissions:
        _.orderBy(ret.data?.missionsTimeRange, ["time", "asc"]) || [],
    });

    console.log("loadMissions", ret.data?.missionsTimeRange);
  },
  loadMemberMissions: async () => {
    const { selectedDay, selectedMemberId } = get();
    if (selectedDay === "" || selectedMemberId === "") return;

    console.log("loadMemberMissions", selectedDay, selectedMemberId);

    const ret = await client
      .query(
        MissionsMissionCreate,
        {
          filter: {
            maxDate: selectedDay,
            minDate: selectedDay,
            memberIds: [selectedMemberId],
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    console.log("loadMemberMissions", ret.data?.missionsTimeRange);

    set({
      memberMissions:
        _.orderBy(ret.data?.missionsTimeRange, ["time", "asc"]) || [],
    });
  },
  createMission: async ({
    startTS,
    endTS,
    memberId,
    patientId,
    actions,
    tourId,
  }) => {
    const loadingToast = toast.loading("Einsatz erstellen", {});
    set({ isOperating: true });

    client
      .mutation(AddMissionToDay_MissionCreate, {
        input: {
          day: dayjs(startTS).format("YYYY-MM-DD"),
          mission: {
            startTS,
            endTS,
            memberId,
            patientId,
            tourId,
            actionsWithQantity: actions,
          },
        },
      })
      .toPromise()
      .then((result) => {
        if (!result.error) {
          get().loadMemberMissions();
          get().loadPatientMissions();
          toast.dismiss(loadingToast);
          toast.success("Einsatz erstellt");
          set({ isOperating: false });
        } else {
          get().loadMemberMissions();
          get().loadPatientMissions();
          toast.dismiss(loadingToast);
          toast.error(result.error.message);
          set({ isOperating: false });
        }
      })
      .catch((err) => {
        get().loadMemberMissions();
        get().loadPatientMissions();
        toast.dismiss(loadingToast);
        toast.error(err.message);
        set({ isOperating: false });
      });
  },
  loadMissionPublicationStatus: async () => {
    const { selectedDay } = get();
    if (selectedDay === "") return;

    const ret = await client
      .query(MissionPublications_MissionCreate, {
        filter: {
          maxDate: selectedDay,
          minDate: selectedDay,
        },
      })
      .toPromise();

    if (!ret.data || !ret.data?.mostRecentMissionPublications) {
      toast.error("Noch keine Einsätze für diesen Tag vorhanden");
      return;
    }

    const _missionPublication = ret.data?.mostRecentMissionPublications[0];
    if (!_missionPublication) {
      toast.error("Noch keine Einsätze für diesen Tag vorhanden");
      return;
    }

    set({
      misionPublicationStatus: _missionPublication.releaseStatus,
    });
  },
}));
