import React from "react";
import { Link } from "react-router-dom";

import { useGetOnePatientQuery } from "../../generated/graphql";

interface Props {
  patientId: string;
}

export const DisplayPatientInfo = (props: Props) => {
  const [result, reececuteQuery] = useGetOnePatientQuery({
    variables: { id: props.patientId },
  });

  const { data, error, fetching } = result;

  if (fetching) return <div>loading</div>;
  if (error) return <div>error</div>;

  return (
    <div>
      <div className="flex  text-gray-600 mb-2 bg-white ">
        <div className="sec self-center p-2 w-full">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row w-full">
              <div className="flex text-xl mr-2">
                {data?.patient?.lastName},{" "}
              </div>
              <div className="flex text-xl ">{data?.patient?.firstName}</div>
            </div>
            <div className="flex">
              <div className="flex text-sm font-light ">
                <Link
                  target="_blank"
                  className="btn btn-primary btn-outline"
                  to={`/manageschedules/${data?.patient?.patientId}`}
                >
                  {data?.patient?.patientId}
                </Link>
              </div>
            </div>
          </div>

          <div className="title text-sm text-gray-500 mt-1">
            {data?.patient?.address}, {data?.patient?.zipCode}{" "}
            {data?.patient?.city}
          </div>
        </div>
      </div>
    </div>
  );
};
