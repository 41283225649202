import React, { useEffect, useMemo } from "react";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";

import {
  LocalPatientActionDuration,
  usePatientActionDuration,
} from "./usePatientActionDuration";

import {
  createColumnHelper,
  GroupingState,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  ColumnDef,
} from "@tanstack/react-table";
import { Button } from "../UiComponents/Button";
import { Checkbox } from "../UiComponents/Checkbox";
import classNames from "classnames";
import dayjs from "dayjs";
import DisplayAction from "../Action/DisplayAction";
import { HiOutlineArrowUpOnSquareStack } from "react-icons/hi2";
import { TbTrash } from "react-icons/tb";
import { Link } from "react-router-dom";
import DisplayActionCompact from "../Action/DisplayActionCompact";
import { cn } from "../../helpers/utils";

type Props = {};

export const PatientActionDurationOverview = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <PatientActionDurationOverview1 {...props} />;
};

const PatientActionDurationOverview1 = (props: Props) => {
  const { loadAllData, actionDurations } = usePatientActionDuration();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [grouping, setGrouping] = React.useState<GroupingState>([]);

  const columns = useMemo<ColumnDef<LocalPatientActionDuration>[]>(
    () => [
      {
        accessorKey: "buttons",
        header: "",
        enableGrouping: false,
        cell: (info) => {
          const _isEffective = info.row.original.isEffective;
          return (
            <div
              className={classNames({
                "text-gray-300": _isEffective,
              })}
            >
              <Button
                variant={"danger-ghost"}
                size={"compact-sm"}
                onClick={() => {
                  console.log("click");
                }}
              >
                <TbTrash className="h-5 w-5" />
              </Button>
            </div>
          );
        },
      },
      {
        accessorKey: "patientId",

        cell: (info) => {
          const _value = info.row.original.patientId;
          const _isEffective = info.row.original.isEffective;
          return (
            <div
              className={cn({
                "opacity-50 font-normal": _isEffective,
              })}
            >
              {_value}
            </div>
          );
        },
        canGroup: true,
      },
      {
        accessorKey: "duration_min",
        cell: (info) => {
          const _isEffective = info.row.original.isEffective;
          const _value = info.row.original.duration_min;

          return (
            <div
              className={cn({
                "opacity-50 font-normal": _isEffective,
              })}
            >
              {_value}
            </div>
          );
        },
      },
      {
        accessorKey: "patient.lastName",
        header: "Patient",
        cell: (info) => {
          const _patient = info.row.original.patient;
          const _isEffective = info.row.original.isEffective;
          return (
            <div
              className={cn({
                "opacity-50 font-normal": _isEffective,
              })}
            >
              {_patient.lastName}, {_patient.firstName}
            </div>
          );
        },

        canGroup: true,
      },
      {
        accessorKey: "startDay",
        cell: (info) => {
          const _isEffective = info.row.original.isEffective;
          const _value = info.row.original.startDay;

          return (
            <div
              className={cn("", {
                "opacity-50 font-normal": _isEffective,
              })}
            >
              {_value}
            </div>
          );
        },
        canGroup: false,
      },
      {
        accessorKey: "endDay",
        cell: (info) => {
          const _isEffective = info.row.original.isEffective;
          const _value = info.row.original.endDay;

          return (
            <div
              className={cn("font-semibold ", {
                "opacity-50 font-normal": _isEffective,
              })}
            >
              {_value}
            </div>
          );
        },

        canGroup: false,
      },
      {
        accessorKey: "actions",
        header: "Leistungen",
        canGroup: false,
        cell: (info) => {
          const _isEffective = info.row.original.isEffective;
          const _actions = info.row.original.actionsWithQuantity;
          return (
            <div
              className={classNames(
                "flex flex-row gap-1 flex-wrap text-xs w-96",
                {
                  "opacity-50": _isEffective,
                }
              )}
            >
              {_actions.map((action) => {
                return <DisplayActionCompact action={action} />;
              })}
            </div>
          );
        },
      },
    ],
    [actionDurations]
  );

  // const columns = useMemo(() => {
  //   const columns = [
  //     columnHelper.display({
  //       id: "buttons",
  //       header: "",
  //       cell: (info) => {
  //         const _isEffective = info.row.original.isEffective;
  //         return (
  //           <div
  //             className={classNames({
  //               "text-gray-300": _isEffective,
  //             })}
  //           >
  //             <Button
  //               variant={"danger-ghost"}
  //               size={"compact-sm"}
  //               onClick={() => {
  //                 console.log("click");
  //               }}
  //             >
  //               <TbTrash className="h-5 w-5" />
  //             </Button>
  //           </div>
  //         );
  //       },
  //     }),

  //     columnHelper.accessor("patientId", {
  //       cell: (info) => {
  //         return (
  //           <Link
  //             target="_blank"
  //             className="cursor-pointer"
  //             to={`/manageschedules/${info.getValue()}`}
  //           >
  //             <Button onClick={() => {}}>{info.getValue()}</Button>
  //           </Link>
  //         );
  //       },
  //     }),
  //     columnHelper.accessor("patient", {
  //       cell: (info) => {
  //         const _patient = info.getValue();

  //         const _isEffective = info.row.original.isEffective;

  //         return (
  //           <div
  //             className={classNames("flex flex-row gap-1", {
  //               "text-gray-400": _isEffective,
  //             })}
  //           >
  //             <div>{_patient?.lastName},</div>
  //             <div>{_patient?.firstName}</div>
  //           </div>
  //         );
  //       },
  //     }),
  //     columnHelper.accessor("startDay", {
  //       cell: (info) => {
  //         const _value = info.getValue();
  //         const _isEffective = info.row.original.isEffective;
  //         return (
  //           <div
  //             className={classNames({
  //               "text-gray-300": _isEffective,
  //             })}
  //           >
  //             {_value}
  //           </div>
  //         );
  //       },
  //     }),
  //     columnHelper.accessor("endDay", {
  //       cell: (info) => {
  //         const _value = info.getValue();
  //         const _isEffective = info.row.original.isEffective;
  //         return (
  //           <div
  //             className={classNames({
  //               "text-gray-300": _isEffective,
  //             })}
  //           >
  //             {_value}
  //           </div>
  //         );
  //       },
  //     }),
  //     columnHelper.accessor("duration_min", {
  //       cell: (info) => {
  //         const _isEffective = info.row.original.isEffective;
  //         return (
  //           <div
  //             className={classNames({
  //               "text-gray-300": _isEffective,
  //             })}
  //           >
  //             {info.getValue()}
  //           </div>
  //         );
  //       },
  //     }),
  //     columnHelper.display({
  //       id: "actionswithquntity",
  //       header: "Leistungen",
  //       cell: (info) => {
  //         const _isEffective = info.row.original.isEffective;
  //         const _actions = info.row.original.actionsWithQuantity;
  //         return (
  //           <div
  //             className={classNames("flex flex-row gap-1 flex-wrap text-xs", {
  //               "text-gray-400": _isEffective,
  //             })}
  //           >
  //             {_actions.map((action) => {
  //               return <DisplayAction action={action} />;
  //             })}
  //           </div>
  //         );
  //       },
  //     }),
  //   ];
  //   return columns;
  // }, [actionDurations]);

  const table = useReactTable({
    data: actionDurations,
    columns,
    state: {
      sorting,
      grouping,
    },
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  return (
    <div className="gap-2 m-2 flex flex-row">
      <div className="bg-white w-48 p-2">
        <div className="text-center font-semibold">Filter</div>
        <div className="items-top flex space-x-2">
          <Checkbox id="onlyactive" />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="onlyactive"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Nur Wirksam
            </label>
          </div>
        </div>
      </div>
      <div>
        <table className="bg-white">
          <thead className="gap-2">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div className="flex flex-row items-center">
                          <div className="">
                            {header.column.getCanGroup() ? (
                              <button
                                className="p-2"
                                {...{
                                  onClick:
                                    header.column.getToggleGroupingHandler(),
                                  style: {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {header.column.getIsGrouped() ? `🛑` : `👊 `}
                              </button>
                            ) : null}
                          </div>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="">
            {table.getRowModel().rows.map((row) => (
              <tr className="p-1 m-1" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className="p-1" key={cell.id}>
                    <div className="">
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <div className="">
                          <div className=" flex flex-row">
                            <button
                              className="flex flex-row"
                              {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                  cursor: row.getCanExpand()
                                    ? "pointer"
                                    : "normal",
                                },
                              }}
                            >
                              {row.getIsExpanded() ? "👇" : "👉"}{" "}
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}{" "}
                              ({row.subRows.length})
                            </button>
                          </div>
                        </div>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(
                          cell.column.columnDef.aggregatedCell ??
                            cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientActionDurationOverview;
