import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

export const PopoverRadix = PopoverPrimitive.Root;
export const PopoverRadixTrigger = PopoverPrimitive.Trigger;

export const PopoverRadixContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentProps<typeof PopoverPrimitive.Content>
>(({ children, ...props }, forwardedRef) => {
  return (
    <PopoverPrimitive.Content
      asChild
      sideOffset={12}
      className="bg-gray-50/90 backdrop-blur-sm rounded-lg shadow-lg"
      {...props}
      ref={forwardedRef}
    >
      <div className="z-[9999]">
        {children}
        <PopoverPrimitive.Arrow />
      </div>
    </PopoverPrimitive.Content>
  );
});
