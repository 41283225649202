import _ from "lodash";
import { AssignedActionSchedule } from "../../generated/graphql";
import { DayWithActionList } from "../../type-definitions";

export interface DayWithActionListSelected {
  displayName: string;
  id: string;
  isSelected: boolean;
  actions: AssignedActionSchedule[];
  plannedDuration_min: number;
}

export interface DayArrayTemplate {
  displayName: string;
  id: string;
}

export const daysToArray = (
  daysFromShedule: DayWithActionList[],
  templateDays: DayArrayTemplate[]
): DayWithActionListSelected[] => {
  const dayArraySchedule = [] as DayWithActionListSelected[];

  templateDays.forEach((day) => {
    dayArraySchedule.push({
      displayName: day.displayName,
      id: day.id,
      isSelected: false,
      actions: [] as AssignedActionSchedule[],
      plannedDuration_min: 0,
    });
  });

  for (let day of dayArraySchedule) {
    const foundDay = _.find(daysFromShedule, (d) => {
      return d.day.id === day.id;
    });

    if (foundDay) {
      day.isSelected = true;
      day.actions = _.orderBy(foundDay.actionsWithQuantity, "actionLnr");
      day.plannedDuration_min = foundDay.plannedDuration_min;
    }
  }

  return dayArraySchedule;
};
