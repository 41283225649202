import { graphql } from "../../../gql";
import { client } from "../../../urqlClient";
import React, { useEffect, useRef, useState } from "react";
import { useReviewOneDay } from "./useReviewOneDay";
import { ArrElement } from "@/src/helpers/typeHelpers";
import { TourData } from "@/src/generated/graphql";
import { TourData_ReviewExecutionMobileQuery } from "@/src/gql/graphql";
import dayjs from "dayjs";
import { Input } from "../../UiComponents/Input";
import { set } from "lodash";
import { Button } from "../../UiComponents/Button";
import { DialogClose } from "../../UiComponents/Dialog";
import DisplayMemberShortName from "../../Member/DisplayMemberShortName";
// import "dayjs/locale/de"; // Import German locale

// // Set the global locale to German
// dayjs.locale("de");

const TourData_ReviewExecutionMobile = graphql(/* GraphQL */ `
  query TourData_ReviewExecutionMobile($day: String!) {
    tourDatas(day: $day) {
      day
      tourId
      memberId
      member {
        shortName
        firstName
        lastName
        hasQualificationLevel
        hasExamination
      }
      maxEndTime
      minStartTime
    }
  }
`);

type LocalTourData_ReviewExecutionMobile = ArrElement<
  TourData_ReviewExecutionMobileQuery["tourDatas"]
>;

type Props = {};

const TourSelectReviewOneDayExecution = (props: Props) => {
  const {
    selectedDay,
    setSelectedTourId,
    setSelectedDay,
    setSelectedMemberId,
  } = useReviewOneDay();
  const [filterText, setFilterText] = React.useState("");
  const [filteredTourDatas, setFilteredTourDatas] = React.useState<
    LocalTourData_ReviewExecutionMobile[]
  >([]);
  const [tourDatas, setTourDatas] = React.useState<
    LocalTourData_ReviewExecutionMobile[]
  >([]);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1); // Tracks the currently focused button

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  useEffect(() => {
    client
      .query(TourData_ReviewExecutionMobile, { day: selectedDay })
      .toPromise()
      .then((result) => {
        if (result.data?.tourDatas) {
          setTourDatas(result?.data?.tourDatas);
          setFilteredTourDatas(result?.data?.tourDatas);
        }
      });
  }, [selectedDay]);

  useEffect(() => {
    // filter all firstmane
    setFocusedIndex(-1);
    if (filterText === "") {
      setFilteredTourDatas(tourDatas);
    } else {
      const filteredFirstNames = tourDatas.filter((tourData) =>
        tourData.member?.firstName
          ?.toLowerCase()
          .includes(filterText.toLowerCase())
      );

      const filteredLastNames = tourDatas.filter((tourData) =>
        tourData.member?.lastName
          ?.toLowerCase()
          .includes(filterText.toLowerCase())
      );

      const filteredTourIds = tourDatas.filter((tourData) =>
        tourData.tourId?.toLowerCase().includes(filterText.toLowerCase())
      );

      // merge all filtered arrays with unique values
      const merged = Array.from(
        new Set([
          ...filteredFirstNames,
          ...filteredLastNames,
          ...filteredTourIds,
        ])
      );

      setFilteredTourDatas(merged);
    }
  }, [filterText]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log("handleKeyDown", e);
    if (e.key === "Escape") {
      e.stopPropagation(); // Prevent the ESC key from propagating to the dialog and closing it
      setFilterText(""); // Clear the input field
    }
  };

  return (
    <div className="flex flex-col h-full overflow-y-scroll">
      <div className=" sticky top-0 p-2 bg-white flex flex-row items-center">
        <div className="px-2">
          <Input
            value={filterText}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setFilterText(e.target.value);
              setFilteredTourDatas(
                tourDatas.filter((tourData) =>
                  tourData.tourId.includes(filterText)
                )
              );
            }}
            placeholder="Filter"
          />
        </div>
        <div className="flex flex-row items-center">
          <Button
            onClick={() => {
              setSelectedDay(
                dayjs(selectedDay).subtract(1, "day").format("YYYY-MM-DD")
              );
            }}
          >
            -
          </Button>

          <div> {dayjs(selectedDay).format("dddd, DD.MMM")}</div>
          <Button
            onClick={() => {
              setSelectedDay(
                dayjs(selectedDay).add(1, "day").format("YYYY-MM-DD")
              );
            }}
          >
            +
          </Button>
        </div>
      </div>
      {filteredTourDatas.map((tourData, index) => {
        return (
          <div key={tourData.tourId} className="flex flex-row gap-2 p-2">
            <div>
              <DisplayMemberShortName
                shortName={tourData.member?.shortName}
                hasExamination={tourData.member?.hasExamination}
                hasQualificationLevel={tourData.member?.hasQualificationLevel}
              />
            </div>
            <DialogClose asChild>
              <Button
                ref={(el) => (buttonRefs.current[index] = el)} // Save button refs for focus
                onClick={() => {
                  setSelectedTourId(tourData.tourId);
                  setSelectedMemberId(tourData.memberId);
                }}
              >
                {tourData.tourId}
              </Button>
            </DialogClose>
            <div>
              {tourData.member?.lastName}, {tourData.member?.firstName}
            </div>

            <div>{tourData.minStartTime}</div>
            <div>{tourData.maxEndTime}</div>
          </div>
        );
      })}
    </div>
  );
};

export default TourSelectReviewOneDayExecution;
