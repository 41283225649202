import React, { useEffect } from "react";
import { useDisplayScheduleByDay } from "./useDisplayScheduleByDay";
import { set, split, update } from "lodash";
import DisplayAction from "../Action/DisplayAction";
import DisplayActionCompact from "../Action/DisplayActionCompact";
import {
  CrossCircledIcon,
  CopyIcon,
  ArrowBottomLeftIcon,
  PlusCircledIcon,
  QuestionMarkIcon,
} from "@radix-ui/react-icons";
import { Button } from "../UiComponents/Button";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";

import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import dayjs from "dayjs";
import { FcStumbleupon } from "react-icons/fc";
import { GiBrassEye } from "react-icons/gi";
import { useGlobalService } from "../GlobalService/useGlobalService";
import { TbTrashFilled } from "react-icons/tb";
import isBetween from "dayjs/plugin/isBetween";
import { cn } from "../../helpers/utils";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import ManageOnePatientAbsence from "../Patient/PatientAbsence/ManageOnePatientAbsence";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  MinusCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../UiComponents/Tooltip";
import { BsQuestionCircle } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
dayjs.extend(isBetween);

const DisplayPatientsBetreuungsStatus = (props: {
  isInBetreuung: boolean | undefined;
}) => {
  if (props.isInBetreuung === undefined) {
    return (
      <div>
        <QuestionMarkIcon className="h-5 w-5 text-blue-500" />
      </div>
    );
  }

  return (
    <div>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="h-5 w-5 text-gray-500">
            {props.isInBetreuung === true ? null : (
              <MinusCircleIcon className="h-5 w-5 text-red-500" />
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <div>
            {props.isInBetreuung === true ? (
              <div>in Betreuung</div>
            ) : (
              <div>nicht in Betreuung</div>
            )}
          </div>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

type Props = {
  patientId: string;
  minDay?: string;
  maxDay?: string;
};

const DisplayScheduleByDay = (props: Props) => {
  const {
    loadData,
    setPatientId,
    setDayRange,
    patientAbsences,
    patientMedifoxStatusAbsences,
    effectiveActionScheduleDays,
    dayArray,
    distinctTimeGroups,
    createPatientScheduleException,
    deletePatientScheduleException,
    updatePatientScheduleException,
  } = useDisplayScheduleByDay();

  const {
    currentMinDay,
    currentMaxDay,
    prevWeek,
    nextWeek,
    setCurrentDateInterval,
  } = useGlobalService();

  const { setActionClipboardContent, getActionClipbaordContentAsCoded } =
    useActionClipboard();

  // if (props.maxDay !== undefined && props.minDay !== undefined) {
  //   setCurrentDateInterval({
  //     min: new Date(props.minDay),
  //     max: new Date(props.maxDay),
  //   });
  // }

  useEffect(() => {
    if (props.maxDay !== undefined && props.minDay !== undefined) {
      setCurrentDateInterval({
        min: new Date(props.minDay),
        max: new Date(props.maxDay),
      });
    }
  }, []);

  useEffect(() => {
    setPatientId(props.patientId);
    loadData();
  }, [props.patientId]);

  useEffect(() => {
    console.log("currentMinDay", currentMinDay);
    setDayRange({ minDay: currentMinDay, maxDay: currentMaxDay });
    loadData();
  }, [currentMinDay, currentMaxDay]);

  //console.log("dayArray", dayArray);

  return (
    <div className="bg-white">
      <div className=" bg-white grid grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr,1fr] items-center text-center  border-2 border-gray-200 ">
        <div>
          Zeit
          <Dialog>
            <DialogContent className="w-[60%]" title="Abwesenheit verwalten">
              <ManageOnePatientAbsence
                patientId={props.patientId}
                updateData={loadData}
              />
            </DialogContent>

            <DialogTrigger>
              <Button>Nicht Da</Button>
            </DialogTrigger>
          </Dialog>
        </div>
        {dayArray.map((day) => {
          //console.log("patientAbsences", patientAbsences);
          const absence = patientAbsences.find((obj) => {
            return obj.startDay <= day && obj.endDay >= day;
          });

          const _patientMedifoxStatusAbsences =
            patientMedifoxStatusAbsences.find((obj) => {
              return obj.day === day;
            });

          //console.log("isAbsent", absence);

          return (
            <div
              key={day}
              className="flex flex-col justify-center items-center"
            >
              <div>{day}</div>
              <div className="flex flex-row justify-between items-center">
                <div
                  className={cn("w-6 h-6 rounded-full", {
                    "bg-red-500 text-white": absence,
                  })}
                >
                  {dayjs(day).format("dd")}
                </div>
                <div>
                  {_patientMedifoxStatusAbsences?.isAbsence === true ? (
                    <div className="h-5 w-5 text-red-500">A </div>
                  ) : null}
                </div>
                <div>
                  <DisplayPatientsBetreuungsStatus
                    isInBetreuung={_patientMedifoxStatusAbsences?.isInBetreuung}
                  />
                  {/* <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="h-5 w-5 text-gray-500">


                        {_patientMedifoxStatusAbsences?.isInBetreuung ===
                        true ? null : ( // <CheckCircleIcon className="h-5 w-5 text-green-500" />
                          <MinusCircleIcon className="h-5 w-5 text-red-500" />
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div>
                        {_patientMedifoxStatusAbsences?.isInBetreuung ===
                        true ? (
                          <div>in Betreuung</div>
                        ) : (
                          <div>nicht in Betreuung</div>
                        )}
                      </div>
                    </TooltipContent>
                  </Tooltip> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="">
        {distinctTimeGroups.map((timeGroup) => {
          return (
            <>
              <div className="grid grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr,1fr,1fr]  bg-gray-100 p-2">
                <div className="bg-white ">{timeGroup}</div>
                {dayArray.map((day) => {
                  const effectiveActionScheduleDay =
                    effectiveActionScheduleDays.find(
                      (effectiveActionScheduleDay) => {
                        return (
                          effectiveActionScheduleDay.day === day &&
                          effectiveActionScheduleDay.timeGroupId === timeGroup
                        );
                      }
                    );
                  return (
                    <div className=" flex flex-col bg-white justify-end">
                      <div className="p-1">
                        {effectiveActionScheduleDay?.effectiveActionsWithQuantity?.map(
                          (awq) => {
                            return (
                              <div className="text-xs">
                                <DisplayActionCompact
                                  action={
                                    awq as {
                                      quantity: number;
                                      action: {
                                        leistungsart: string;
                                        examinationRequired: boolean;
                                      };
                                    }
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                      </div>

                      <div className="flex flex-row text-xs text-gray-400 justify-between bg-gray-50 items-center">
                        {effectiveActionScheduleDay !== undefined ? (
                          <div className="w-full">
                            <Button
                              variant={"danger-ghost"}
                              size={"compact-sm"}
                              onClick={() => {
                                //if there is e regular schedule delete ist, if not create an exception
                                console.log(
                                  "redcross action",
                                  effectiveActionScheduleDay
                                );
                                if (
                                  effectiveActionScheduleDay?.hasException ===
                                  true
                                ) {
                                  deletePatientScheduleException(
                                    effectiveActionScheduleDay?.exceptionId ||
                                      ""
                                  );
                                } else {
                                  createPatientScheduleException({
                                    patientId: props.patientId,
                                    startDay: day,
                                    endDay: day,
                                    startTime:
                                      effectiveActionScheduleDay?.minStartTime ||
                                      "",
                                    endTime:
                                      effectiveActionScheduleDay?.maxStartTime ||
                                      "",
                                    allCodedActions: "",
                                    type: "absentSchedule",
                                  });
                                }
                              }}
                            >
                              <CrossCircledIcon className="h-4 w-4" />
                            </Button>
                            <Button
                              variant={"neutral-ghost"}
                              size={"compact-sm"}
                              onClick={() => {
                                console.log(
                                  "redcross action",
                                  effectiveActionScheduleDay?.hasException
                                );
                                if (
                                  effectiveActionScheduleDay?.hasException ===
                                  false
                                ) {
                                  createPatientScheduleException({
                                    patientId: props.patientId,
                                    startDay: day,
                                    endDay: day,
                                    startTime:
                                      effectiveActionScheduleDay?.minStartTime ||
                                      "",
                                    endTime:
                                      effectiveActionScheduleDay?.maxStartTime ||
                                      "",
                                    allCodedActions:
                                      getActionClipbaordContentAsCoded(),
                                    type: "newActions",
                                  });
                                } else {
                                  updatePatientScheduleException(
                                    effectiveActionScheduleDay?.exceptionId ||
                                      "",
                                    {
                                      allCodedActions:
                                        getActionClipbaordContentAsCoded(),
                                      type: "newActions",
                                    }
                                  );
                                }
                              }}
                            >
                              <HiOutlineWrenchScrewdriver className="w-4 h-4 text-gray-600" />
                            </Button>
                            {effectiveActionScheduleDay?.hasException ===
                            true ? (
                              <Button
                                variant={"primary-ghost"}
                                size={"compact-sm"}
                                onClick={() => {
                                  deletePatientScheduleException(
                                    effectiveActionScheduleDay?.exceptionId ||
                                      ""
                                  );
                                }}
                              >
                                <GiBrassEye className="w-4 h-4" />
                              </Button>
                            ) : (
                              " "
                            )}

                            <Button
                              onClick={() => {
                                setActionClipboardContent(
                                  effectiveActionScheduleDay?.effectiveActionsWithQuantity ||
                                    []
                                );
                              }}
                              variant="neutral-ghost"
                            >
                              <CopyIcon />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              variant={"success-ghost"}
                              onClick={() => {
                                console.log(day, timeGroup);
                                console.log(split(timeGroup, "_")[0]);
                                console.log(split(timeGroup, "_")[1]);
                                createPatientScheduleException({
                                  patientId: props.patientId,
                                  startDay: day,
                                  endDay: day,
                                  startTime: split(timeGroup, "_")[0],

                                  endTime: split(timeGroup, "_")[1],

                                  allCodedActions:
                                    getActionClipbaordContentAsCoded(),
                                  type: "addSchedule",
                                });
                              }}
                            >
                              <PlusCircledIcon className="h-4 w-4" />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>

      <div>test</div>
    </div>
  );
};

export default DisplayScheduleByDay;
