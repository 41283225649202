import { graphql } from "../../../gql";
import {
  AllDistrictsQuery,
  Patient_ManageDistrictQuery,
} from "../../../gql/graphql";
import { ArrElement } from "@/src/helpers/typeHelpers";
import { create } from "zustand";
import { client } from "../../../urqlClient";

const AllDictricts = graphql(/* GraphQL */ `
  query AllDistricts {
    districts {
      id
      colorHex
      description
    }
  }
`);

const ChangePatientDistrict = graphql(/* GraphQL */ `
  mutation ChangePatientDistrict($patientId: String!, $districtId: String!) {
    patientSetDistrict(patientId: $patientId, districtId: $districtId) {
      patientId
    }
  }
`);

const Patient_ManageDistrict = graphql(/* GraphQL */ `
  query Patient_ManageDistrict($patientId: String!) {
    patient(patientId: $patientId) {
      patientId
      firstName
      lastName
      district {
        id
        colorHex
        description
      }
    }
  }
`);

type LocalPatient_ManageDistrict = Patient_ManageDistrictQuery["patient"];

export type Local_District = ArrElement<AllDistrictsQuery["districts"]>;

interface PatientManageDistrictState {
  allDistricts: Local_District[];
  patientId: string;
  currentDistrictId: string;
  loadData: (patientId: string) => void;
  patient: LocalPatient_ManageDistrict | undefined;
  setPatientDistrict: (districtId: string) => void;
}

export const usePatientManageDistrict = create<PatientManageDistrictState>(
  (set, get) => ({
    allDistricts: [],
    patient: undefined,
    patientId: "",
    currentDistrictId: "",
    loadData: async (patientId: string) => {
      const { data } = await client.query(AllDictricts, {}).toPromise();
      set({ allDistricts: data?.districts, patientId: patientId });

      const { data: patientData } = await client
        .query(Patient_ManageDistrict, { patientId })
        .toPromise();
      set({ patient: patientData?.patient });
    },
    setPatientDistrict: async (districtId: string) => {
      const { patientId } = get();
      await client
        .mutation(ChangePatientDistrict, { patientId, districtId })
        .toPromise();
      get().loadData(patientId);
    },
  })
);
