import classNames from "classnames";
import React from "react";
import {
  Action,
  Max_AssigendActionMissionExecutedFragment,
  Max_AssigendActionMissionFragment,
} from "../../generated/graphql";
import { LocalGetAnalyseMission } from "./useAnalyzeMission";
import _ from "lodash";

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type LocalActionWithQuantity = ArrElement<
  LocalGetAnalyseMission["actionsWithQuantity"]
>;

// export type LocalExecutedActionWithQuantity = ArrElement<
//   LocalGetAnalyseMission["aggMobileExecutions"]
// >;

type Props = {
  plannedActions: Max_AssigendActionMissionFragment[] | undefined | null;
  executedActions:
    | Max_AssigendActionMissionExecutedFragment[]
    | undefined
    | null;
  mission: LocalGetAnalyseMission;
};

const DisplayMarkedActions = (props: Props) => {
  const { plannedActions, executedActions } = props;
  //   console.log(props);

  const actionsWithInfo = [] as {
    status: "planned" | "executed" | "cancled" | "new";
    action: any;
  }[];

  const clonedExecutedActions =
    executedActions?.map((ea) => {
      return ea;
    }) || [];

  /**
   * Alle geplanten Aktionen werden iteriert und es wird geprüft, ob es eine Rückgemeldete Aktion gibt.
   *
   * Aber nur dann, wenn es auch einen rückgemeldeten Einsatz gibt
   */

  plannedActions?.forEach((plannedAction) => {
    const index = clonedExecutedActions?.findIndex((ea) => {
      return ea.actionLnr === plannedAction.actionLnr;
    });

    //Action wurde in den Ausgeführten gefunden
    if (index !== undefined && index !== -1) {
      actionsWithInfo.push({
        status: "executed",
        action: plannedAction,
      });
      clonedExecutedActions?.splice(index, 1); //rausnehmen, damit es nicht nochmal gefunden wird
    } else {
      //console.log("Executed Actions", executedActions);
      if (executedActions?.length !== 0 && executedActions !== undefined) {
        actionsWithInfo.push({
          status: "cancled",
          action: plannedAction,
        });
      } else {
        actionsWithInfo.push({
          status: "planned",
          action: plannedAction,
        });
      }
    }
  });
  clonedExecutedActions?.forEach((ea) => {
    actionsWithInfo.push({ status: "new", action: ea });
  });

  //console.log(actionsWithInfo);

  const _ordredActions = _.orderBy(
    actionsWithInfo,
    ["action.action.examinationRequired", "action.action.leistungskomplex"],
    ["asc", "asc"]
  );

  //setSelectedActions(executedActions||[])

  return (
    <div className="flex flex-row flex-wrap">
      {_ordredActions.map((s) => {
        const q = s.action.quantity;

        return (
          <>
            <span
              key={s.action.actionLnr}
              className={classNames(
                "text-xs m-1 p-1 rounded-md",
                {
                  "bg-red-200": s.status === "cancled",
                  "bg-teal-200": s.status === "new",
                  "bg-green-100": s.status === "executed",
                  "bg-gray-200": s.status === "planned",
                },
                {
                  " border-b-4 border-yellow-300":
                    s.action.action.examinationRequired,
                  "border-b-4 border-blue-300":
                    !s.action.action.examinationRequired,
                }
              )}
            >
              {s.action?.action?.leistungskomplex}
              {s.action?.quantity > 1 ? <>({s.action?.quantity})</> : null}
            </span>
            {s.action?.action?.leistungskomplex === "31" ? (
              <span className="text-xs m-1 p-1 rounded-md text-red-500">
                {q * 5} MIN
              </span>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default DisplayMarkedActions;
