import React, { useEffect, useRef, useState } from "react";

import Map, {
  Layer,
  LayerProps,
  Marker,
  Source,
  ScaleControl,
  MapRef,
} from "react-map-gl";

import StaticMap from "react-map-gl";

import DeckGL from "@deck.gl/react/typed";
import {
  ArcLayer,
  TextLayer,
  ColumnLayer,
  IconLayer,
} from "@deck.gl/layers/typed";
import SelectTour from "../SelectTour/SelectTour";
import { Button } from "../UiComponents/Button";
import {
  LocalEditMission_TourBuilder,
  LocalMission_TourBuilder,
  useTourBuilder,
} from "./useTourBuilder";
import TourBuilderEditor from "./TourBuilderEditor";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { add, set } from "lodash";
import TimeRangeSelect from "./TimeRangeSelect";
import classNames from "classnames";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import { DisplayMissionDetail } from "../TourManager/DisplayMissionDetail";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";
import { a } from "@react-spring/web";
import GlobalDaySelect from "../GlobalService/GlobalDaySelect";
import { useGlobalService } from "../GlobalService/useGlobalService";
import { PopoverClose } from "@radix-ui/react-popover";
import TourOptimizeData from "../TourOptimize/TourOptimizeData";

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 4,
    "circle-color": ["get", "color"],
  },
} as LayerProps;

const layerLablePatients = {
  id: "Point",
  type: "symbol",
  minzoom: 13, // Set zoom level to whatever suits your needs
  // onClickMap: (event: any) => {
  //   console.log(event);
  // },
  // onHover: (info: any, event: any) => {
  //   //  setIsHovering(info.picked || false);
  //   console.log("Patient Hovered", info, event);
  // },
  layout: {
    "text-size": 10,
    "text-field": ["get", "info"],
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-radial-offset": 0.5,
    "text-justify": "auto",
  },
} as LayerProps;

type Props = {};

const initialViewState = {
  longitude: 6.93191365074411,
  latitude: 51.40530535770421,
  zoom: 14,
};

const TourBuilder = (props: Props) => {
  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState({
    initialViewState,
  });

  const [missionId, setMissionId] = useState<string>("");
  const [openPopover, setOpenPopover] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const [archLayers, setArchLayers] = useState<ArcLayer[]>([]);

  const [nearByMissions, setNearByMissions] = useState<
    LocalMission_TourBuilder[]
  >([]);

  const { currentSelectedDay } = useGlobalService();

  const {
    addTour,
    //archLayers,
    geoPatients,
    setSelectedDay,
    selectedDay,
    adjustAllTourTimes,
    loadAllMissionsOfDay,
    getMissionById,
    distinctTourIds,
    tours,
    getAllMissionsWithinRadius,
  } = useTourBuilder();

  const onClickMap = (event: any) => {
    const toast = mapRef.current?.queryRenderedFeatures([event.x, event.y], {
      layers: ["Point"],
    });
    if (toast && toast?.length > 0) {
      //console.log("Patient Hovered", toast);
      setMissionId(toast[0].properties?.missionId);
      const _nearByMissions = getAllMissionsWithinRadius({
        missionId: toast[0].properties?.missionId,
        radius_km: 1,
        time: toast[0].properties?.time,
      });

      setNearByMissions(_nearByMissions);

      setOpenPopover(true);
    } else {
      setOpenPopover(false);
    }

    console.log("Map clicked", event, toast);
  };

  const onHoverMap = (event: any) => {
    const toast = mapRef.current?.queryRenderedFeatures([event.x, event.y], {
      layers: ["Point"],
    });
    if (toast && toast?.length > 0) {
      setIsHovering(true);
      //console.log("Patient Hovered", toast);
      //  setMissionId(toast[0].properties?.missionId);
    } else {
      setIsHovering(false);
    }
  };

  useEffect(() => {
    setSelectedDay(currentSelectedDay);
    loadAllMissionsOfDay();
  }, [currentSelectedDay]);

  useEffect(() => {
    const _archLayers = tours
      .map((tour) => {
        return tour.archLayer;
      })
      .filter((layer) => layer !== undefined) as ArcLayer[];

    console.log("archLayers", _archLayers);
    setArchLayers(_archLayers);
  }, [tours]);

  //console.log("geoPatients", geoPatients);

  return (
    <div className="h-full relative overflow-y-hidden">
      <div className="absolute z-50 top-2 right-2 flex flex-row">
        <GlobalDaySelect />
        <TimeRangeSelect />
      </div>
      <div className="absolute z-50 bottom-2 right-4 ">
        <Popover
          open={openPopover}
          onOpenChange={() => {
            setOpenPopover(false);
          }}
        >
          <PopoverTrigger></PopoverTrigger>

          <PopoverContent
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
          >
            <PopoverClose>X</PopoverClose>
            <div className="h-96 overflow-y-scroll bg-gray-50 m-1 p-1">
              {nearByMissions.map((mission) => {
                return (
                  <div className="bg-white" key={mission.id}>
                    {mission.patient?.lastName}, {mission.patient?.firstName}{" "}
                    <Button
                      variant={"neutral-ghost"}
                      onClick={() => {
                        addTour(mission.tourId || "");
                      }}
                    >
                      {mission.tourId}
                    </Button>{" "}
                    {mission.time}
                  </div>
                );
              })}
            </div>
            {(() => {
              const _mission = getMissionById(missionId);

              return (
                <div>
                  {_mission?.patient?.lastName}, {_mission?.patient?.firstName}
                </div>
              );
            })()}
            <div className="bg-gray-50 m-1 p-1">
              <DisplayMissionAnalytic missionId={missionId}>
                {missionId}
              </DisplayMissionAnalytic>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="px-2 py-2 z-10 absolute m-2 rounded-xl flex flex-row bg-opacity-90 overflow-y-none">
        <div className="bg-gray-50 p-2 border-1 border-gray-100 rounded-xl bg-opacity-90 z-0 flex flex-col h-[700px] overflow-y-auto">
          <Button
            variant={"primary-outline"}
            onClick={() => {
              loadAllMissionsOfDay();
            }}
          >
            Laden
          </Button>
          <Button
            onClick={() => {
              adjustAllTourTimes();
            }}
          >
            Alle Rechner
          </Button>
          <div className="flex flex-col">
            {distinctTourIds.map((tourId) => {
              return (
                <div>
                  <Button
                    key={tourId}
                    onClick={() => {
                      addTour(tourId);
                    }}
                  >
                    {tourId}
                  </Button>
                  {/* <div className="text-sm">
                    <TourOptimizeData tourId={tourId} day={selectedDay} />
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
        <div className="overflow-y-hidden">
          <DndProvider backend={HTML5Backend}>
            <TourBuilderEditor />
          </DndProvider>
        </div>
      </div>
      <div
        onContextMenu={(evt) => {
          evt.preventDefault();
        }}
      >
        <DeckGL
          layers={archLayers}
          initialViewState={initialViewState}
          controller={true}
          onClick={onClickMap}
          onHover={onHoverMap}
          getCursor={() => {
            return isHovering ? "crosshair" : "grab";
          }}
          // getTooltip={(info) =>
          //   isHovering
          //     ? {
          //         html: JSON.stringify(info.object, null, 2),
          //       }
          //     : null
          // }
        >
          <StaticMap
            {...viewState}
            onMove={(evt: any) => setViewState(evt.viewState)}
            style={{ width: "100%", height: "100%" }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            //mapStyle="mapbox://styles/capsicum42/cl0zfhxa3007914lp31nbqtug"
            mapStyle="mapbox://styles/capsicum42/cl7xahs94001314o6ztr12osy"
            // interactiveLayerIds={[clusterLayer.id]}
            ref={mapRef}
          >
            <Source id="my-data" type="geojson" data={geoPatients}>
              <Layer {...layerStyle} />
              <Layer {...layerLablePatients}></Layer>
            </Source>
          </StaticMap>
        </DeckGL>
      </div>
    </div>
  );
};

export default TourBuilder;
