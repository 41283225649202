import React from "react";
import MyDatePicker from "./MyDatePicker";
import { useAnalyzeMission } from "./useAnalyzeMission";
import { useAnalyzeMissionStatus } from "../AnalyzeMissionStatus/useAnalyzeMissionStatus";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerWithRange } from "../UiComponents/DateRangePicker";
import { DateRange } from "react-day-picker";
import dayjs from "dayjs";
import { on } from "events";

type Props = {};

const OptionsInput = (props: Props) => {
  const { dateRange, setDateRange } = useAnalyzeMission();
  const { setDateRange: setDateRangeStatus } = useAnalyzeMissionStatus();

  const [dateRangeInput, setDateRangeInput] = React.useState<
    DateRange | undefined
  >({
    from: dayjs().toDate(),
    to: dayjs().add(7, "day").toDate(),
  });
  const onChange = (dateRange: DateRange | undefined) => {
    console.log(dateRange);
    const [start, end] = dateRange
      ? [dateRange.from, dateRange.to]
      : [undefined, undefined];

    setDateRangeInput(dateRange);

    if (start === undefined || end === undefined) {
      return;
    }

    setDateRange({ startDate: start, endDate: end });
    setDateRangeStatus({ startDate: start, endDate: end });
  };

  return (
    <>
      <div className="w-80">
        {/* <MyDatePicker /> */}
        <DatePickerWithRange
          className="w-full"
          dateRange={dateRangeInput}
          setDateRange={onChange}
          weekStartsOn={1}
        />
      </div>
    </>
  );
};

export default OptionsInput;
