import { create } from "zustand";
import produce from "immer";

import { client } from "../../urqlClient";

import {
  ActionIdWithQuantity,
  Exact,
  GetMissionsTimeRangeDocument,
  GetMissionsTimeRangeQuery,
  GetMissionsTimeRangeQueryVariables,
} from "../../generated/graphql";

import {
  MissionSetActionDocument,
  MissionSetActionMutation,
  MissionSetActionMutationVariables,
} from "../../generated/graphql";

//import {
//   GetPatientIdsNoActionDocument,
//   GetPatientIdsNoActionQuery,
//   GetPatientIdsNoActionQueryVariables,
// } from "../../generated/graphql";
import { LocalActionWithQuantity } from "../../type-definitions";
import { OperationResult, gql } from "urql";
import { graphql } from "../../gql";
import { SplitMissionMutation } from "@/src/gql/graphql";

const SplitMission = graphql(/* GraphQL */ `
  mutation SplitMission($missionId: String!, $updatedAt: String!) {
    splitMission(missionId: $missionId, updatedAt: $updatedAt)
  }
`);

interface IGetMissions {
  loading: boolean;
  getMissions: (
    filter: GetMissionsTimeRangeQueryVariables
  ) => Promise<GetMissionsTimeRangeQuery | undefined>;
  lastUpdated: Date;
  setLastUpdated: () => void;
  splitMission: ({
    missionId,
    updatedAt,
  }: {
    missionId: string;
    updatedAt: string;
  }) => Promise<OperationResult<SplitMissionMutation>>;
  missionSetAction: (
    misionId: string,
    actions: LocalActionWithQuantity[],
    updatetAt: string
  ) => Promise<
    OperationResult<
      MissionSetActionMutation,
      Exact<{
        id: string;
        actionIdsWQ: ActionIdWithQuantity | ActionIdWithQuantity[];
        updatedAt: string;
      }>
    >
  >;
  // getPatientIdsWoAction: (
  //   minDate: string,
  //   maxDate: string
  // ) => Promise<string[]>;
}

export const useMission = create<IGetMissions>((set, get) => ({
  loading: false,
  lastUpdated: new Date(),
  setLastUpdated: () => set(() => ({ lastUpdated: new Date() })),
  getMissions: async (filter: GetMissionsTimeRangeQueryVariables) => {
    set(() => ({ loading: true }));
    const ret = await client
      .query<GetMissionsTimeRangeQuery, GetMissionsTimeRangeQueryVariables>(
        GetMissionsTimeRangeDocument,
        filter,
        { requestPolicy: "network-only" }
      )
      .toPromise();
    console.log("Missions", ret);
    set(() => ({ loading: false }));
    return ret.data;
  },
  missionSetAction: (
    missionId: string,
    actions: LocalActionWithQuantity[],
    updatetAt: string
  ) => {
    const actionIdsWQ = actions.map((a) => ({
      lnr: a.action.lnr,
      quantity: a.quantity,
    }));

    console.log("bookMissionAction", actionIdsWQ);

    const ret = client.mutation<
      MissionSetActionMutation,
      MissionSetActionMutationVariables
    >(MissionSetActionDocument, {
      id: missionId,
      actionIdsWQ: actionIdsWQ,
      updatedAt: updatetAt,
    });
    return ret.toPromise();
  },

  splitMission: async ({ missionId, updatedAt }) => {
    const ret = await client
      .mutation<
        SplitMissionMutation,
        Exact<{
          missionId: string;
          updatedAt: string;
        }>
      >(SplitMission, {
        missionId: missionId,
        updatedAt: updatedAt,
      })
      .toPromise();
    return ret;
  },

  // getPatientIdsWoAction: async (minDate: string, maxDate: string) => {
  //   const ret = await client
  //     .query<GetPatientIdsNoActionQuery, GetMissionsTimeRangeQueryVariables>(
  //       GetPatientIdsNoActionDocument,
  //       {
  //         filter: {
  //           minDate: minDate,
  //           maxDate: maxDate,
  //           hasAction: false,
  //         },
  //       },
  //       { requestPolicy: "network-only" }
  //     )
  //     .toPromise();
  //   console.log("Missions", ret.data);

  //   const distinctPatientIds = ret.data?.missionsTimeRange?.map(
  //     (m) => m?.patientId
  //   );

  //   return distinctPatientIds;
  // },
}));
