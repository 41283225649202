import dayjs from "dayjs";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useGetTourIdsQuery } from "../../generated/graphql";
import { SelectedTour } from "./DistrictsMap";
import classNames from "classnames";

import produce from "immer";
import { Button } from "../UiComponents/Button";
import { InfoIcon } from "lucide-react";
import { Dialog, DialogContent } from "../UiComponents/Dialog";
import TourOptimize from "../TourOptimize/TourOptimize";

type Props = {
  selectedTour: SelectedTour;
  setSelectedTour: (selectedTour: SelectedTour) => void;
};

const SelectDayTour = (props: Props) => {
  const { setSelectedTour, selectedTour } = props;
  const [startDate, setStartDate] = useState<Date | null>(
    dayjs(selectedTour.day).toDate()
  );

  const [tourOptimizeOpen, setTourOptimizeOpen] = useState(false);

  const [timeRange, setTimeRange] = useState<{
    startTime: Date;
    endTime: Date;
  }>();

  const [selectedTourIds, setSelectedTourIds] = useState(selectedTour.tourIds);
  const [result] = useGetTourIdsQuery({
    variables: {
      filter: {
        minDate: dayjs(startDate).format("YYYY-MM-DD").toString(),
        maxDate: dayjs(startDate).format("YYYY-MM-DD").toString(),
      },
    },
  });

  const { data, fetching, error } = result;

  // if (fetching) {
  //   return <div>Loading...</div>;
  // }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <>
      <div className="text-black w-auto bg-gray-50 shadow-lg rounded-lg">
        <div className="items-center text-center text-black">
          <div className="flex flex-row">
            <div className="text-black">
              <DatePicker
                selected={startDate}
                todayButton={"Heute"}
                calendarStartDay={6}
                onChange={(date: Date) => {
                  setStartDate(date);
                  setSelectedTour({
                    day: dayjs(date).format("YYYY-MM-DD"),
                    tourIds: selectedTour.tourIds,
                  });
                }}
                inline
              />

              <div className="flex flex-wrap w-80">
                {selectedTourIds.map((t) => {
                  return (
                    <div className="flex flex-row items-center">
                      <Button
                        onClick={() => {
                          const _newTourIds = produce(
                            selectedTourIds,
                            (draft) => {
                              draft.splice(draft.indexOf(t), 1);
                              return draft;
                            }
                          );
                          setSelectedTourIds(_newTourIds);
                          setSelectedTour({
                            day: dayjs(startDate)
                              .format("YYYY-MM-DD")
                              .toString(),
                            tourIds: _newTourIds,
                          });
                        }}
                      >
                        {t}
                      </Button>
                      <Button
                        onClick={() => {
                          setTourOptimizeOpen(true);
                        }}
                      >
                        <InfoIcon className="w-5 h-5 text-blue-500" />
                      </Button>
                      <Dialog
                        open={tourOptimizeOpen}
                        onOpenChange={setTourOptimizeOpen}
                      >
                        <DialogContent
                          title={
                            "Touroptimieren " +
                            t +
                            " " +
                            dayjs(startDate).format("DD. MMMM")
                          }
                          className="flex flex-col w-[80%] h-[80%] bg-white"
                        >
                          <TourOptimize
                            day={dayjs(startDate).format("YYYY-MM-DD")}
                            tourId={t}
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="h-96 w-56 overflow-y-auto btn-group-vertical">
              {data?.tourIds?.map((tour) => {
                return (
                  <div className="">
                    <Button
                      variant={
                        classNames({
                          "neutal-ghost": !selectedTourIds.includes(tour!),
                          "accent-ghost": selectedTourIds.includes(tour!),
                        }) as any
                      }
                      onClick={() => {
                        const _newTourIds = produce(
                          selectedTourIds,
                          (draft) => {
                            const index = draft.indexOf(tour!);

                            if (index === -1) {
                              if (draft.length < 5) {
                                draft.push(tour!);
                              }
                            } else draft.splice(index, 1);
                            return draft;
                          }
                        );
                        console.log(_newTourIds);
                        setSelectedTourIds(_newTourIds);
                        setSelectedTour({
                          day: dayjs(startDate).format("YYYY-MM-DD").toString(),
                          tourIds: _newTourIds,
                        });
                      }}
                    >
                      {tour}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="card-actions justify-end"></div>
          <div className="card-actions justify-end">
            <Button className="btn btn-ghost btn-sm">Zurück</Button>
            <Button>Okay</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectDayTour;
