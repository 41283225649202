import React from "react";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import classNames from "classnames";
import { title } from "process";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button } from "./Button";

import { cn } from "../../helpers/utils";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({
  //className,
  children,
  ...props
}: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal
    // className={cn(className)}
    {...props}
  >
    <div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-gray-500/50 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fixed z-50 grid w-full gap-4 rounded-b-lg border bg-background p-6 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0",
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <Cross2Icon className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};

export const DialogClose = DialogPrimitive.Close;

// type DialogProps = {
//   title: string;
// };

// export const Dialog = React.forwardRef<
//   React.ElementRef<typeof DialogPrimitive.Root>,
//   React.ComponentProps<typeof DialogPrimitive.Root>
// >(({ children, ...props }, forwardRef) => {
//   return (
//     <DialogPrimitive.Root {...props}>
//       <DialogPrimitive.Portal>
//         <DialogPrimitive.Overlay className="flex fixed z-[999] inset-0 bg-black/50 h-full w-full">
//           {children}
//         </DialogPrimitive.Overlay>
//       </DialogPrimitive.Portal>
//     </DialogPrimitive.Root>
//   );
// });

// export const DialogContent = React.forwardRef<
//   React.ElementRef<typeof DialogPrimitive.Content>,
//   React.ComponentProps<typeof DialogPrimitive.Content> & DialogProps
// >(({ title, children, ...props }, forwardedRef) => (
//   <DialogPrimitive.Content
//     {...props}
//     ref={forwardedRef}
//     className={classNames(
//       props.className,
//       "fixed ",
//       "rounded-lg p-4",
//       "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
//       "bg-white dark:bg-gray-800"
//       // "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
//     )}
//   >
//     <DialogPrimitive.Title className="flex flex-row justify-between mb-4 items-center">
//       <div className="text-xl font-semibold">{title}</div>
//       <div>
//         <DialogPrimitive.Close asChild>
//           <Button variant="neutral-outline" aria-label="Close">
//             <Cross2Icon />
//           </Button>
//         </DialogPrimitive.Close>
//       </div>
//     </DialogPrimitive.Title>
//     {children}
//   </DialogPrimitive.Content>
// ));
// export const DialogTrigger = DialogPrimitive.Trigger;
// export const DialogClose = DialogPrimitive.Close;

// export const DialogTitle = React.forwardRef<
//   React.ElementRef<typeof DialogPrimitive.Title>,
//   React.ComponentProps<typeof DialogPrimitive.Title>
// >(({ children, ...props }, forwardRef) => (
//   <DialogPrimitive.Title
//     className="text-xl mb-4 text-center"
//     {...props}
//     ref={forwardRef}
//     // className={classNames(
//     //   "fixed z-50",
//     //   "w-[95vw] max-w-md rounded-lg p-4 md:w-full",
//     //   "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
//     //   "bg-white dark:bg-gray-800",
//     //   "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
//     // )}
//   >
//     {children}
//   </DialogPrimitive.Title>
// ));
