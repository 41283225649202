import dayjs from "dayjs";

export const ts2hhmmss = (ts: string) => {
  return dayjs(Number(ts)).format("HH:mm:ss").toString();
};

/**
 * Calculates most recent Saturday from current day
 * @returns {string} "YYYY-MM-DD"
 */

export const getMostRecentSaturday = () => {
  {
    const today = dayjs();
    const day = today.day();
    if (day === 6) {
      return today.format("YYYY-MM-DD");
    } else {
      return dayjs().day(6).subtract(7, "days").format("YYYY-MM-DD");
    }
  }
};

export const getStartDateOfWeek = (ankerDay?: string) => {
  const today = dayjs(ankerDay);
  //console.log(today.format("YYYY-MM-DD"));
  const day = today.day();
  if (day === 6) {
    // console.log(today.format("YYYY-MM-DD"));
    return today.toDate();
  } else {
    //console.log(dayjs(ankerDay).day(6).subtract(7, "days").toDate());
    return dayjs(ankerDay).day(6).subtract(7, "days").toDate();
  }
};

export const getEndDateOfWeek = (ankerDay?: string) => {
  // const today = dayjs();
  // const day = today.day();
  // if (day === 6) {
  //   return today.add(6, "days").toDate();
  // } else {
  //   return dayjs().day(6).toDate();
  // }
  return dayjs(getStartDateOfWeek(ankerDay)).add(6, "days").toDate();
};

export const isWeekend = (date: Date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};
