import { create } from "zustand";

import { graphql } from "../../gql";
import { client } from "../../urqlClient";

import {
  CreatePatientActionDurationMutation,
  CreatePatientActionDurationMutationVariables,
  TourListMissionsQuery,
  TourListMissionsQueryVariables,
} from "../../gql/graphql";
import { ArrElement } from "../../helpers/typeHelpers";
import _ from "lodash";
import dayjs from "dayjs";

const CreatePatientActionDuration = graphql(/* GraphQL */ `
  mutation CreatePatientActionDuration(
    $input: InputCreatePatientActionDuration!
  ) {
    createPatientActionDuration(input: $input) {
      id
    }
  }
`);

const TourListMissions = graphql(/* GraphQL */ `
  query TourListMissions($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      missionPublicationId

      missionAnalytic {
        totalCashValue
        totalCashValue_NOSAFE
        pauschaleHausType
        pauschaleSaveType
        duration_min
        expectedDuration
        expectedDuration_NOSAFE
        cashPerMinute
        cashPerMinute_NOSAFE
        durationDeviation_min
        durationCorrection_min
        expectedDurationCalc_NOSAFE
      }

      memberId
      member {
        memberId
        lastName
        firstName
        shortName
        hasExamination
        hasQualificationLevel
      }
      patient {
        patientId
        lastName
        firstName
      }
      time
      startTS
      endTS
      duration_min
      day
      tourId

      actionsWithQuantity {
        actionLnr
        quantity
        action {
          leistungsart
          lnr
          leistungskomplex
          examinationRequired
        }
      }
    }
  }
`);

type TravelData = {
  asIsTravelTime?: number;
  calculatedTravelTime?: number;
};

export type LocalTourListMission = ArrElement<
  TourListMissionsQuery["missionsTimeRange"]
> &
  TravelData;

export type LocalTourListMissionAction = ArrElement<
  LocalTourListMission["actionsWithQuantity"]
>;

interface ITourList {
  missions: LocalTourListMission[];
  loadMissionList: (memberId: string, day: string, tourId: string) => void;
  calculateTravelData: () => void;
  setPatientActionDuration: ({
    patientId,
    actions,
    duration_min,
    startDay,
    endDay,
  }: {
    patientId: string | null | undefined;
    actions: { actionLnr: string; quantity: number }[] | null | undefined;
    duration_min: number | null | undefined;
    startDay: string;
    endDay: string;
  }) => void;
}

export const useTourList = create<ITourList>((set, get) => ({
  missions: [],
  loadMissionList(memberId, day, tourId) {
    const memberIds = [];
    memberIds.push(memberId);

    const tourIds = [];
    tourIds.push(tourId);

    console.log("loadMissionList", memberIds, tourIds, day);

    client
      .query<TourListMissionsQuery, TourListMissionsQueryVariables>(
        TourListMissions,
        {
          filter: {
            memberIds: memberIds,
            minDate: day,
            maxDate: day,
            tourIds: tourIds,
            isActive: true,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then((result) => {
        console.log(result);
        set({
          missions:
            _.orderBy(result?.data?.missionsTimeRange, ["time"], ["asc"]) || [],
        });
        get().calculateTravelData();
      });
  },
  setPatientActionDuration({
    patientId,
    actions,
    duration_min,
    startDay,
    endDay,
  }) {
    if (!patientId || !actions || !duration_min) return;
    if (actions.length === 0) {
      console.log("Keine Leistungen");
      return;
    }

    const _sortdedActions = _.orderBy(actions, ["actionLnr"], ["asc"]);

    const _actionQuantityCoded = _sortdedActions.map((action, index) => {
      if (index == 0) return action?.quantity + "x" + action?.actionLnr;
      else return "_" + action?.quantity + "x" + action?.actionLnr;
    });

    console.log(
      "setPatientActionDuration",
      patientId,
      actions,
      duration_min,
      _actionQuantityCoded.join("")
    );

    client
      .mutation<
        CreatePatientActionDurationMutation,
        CreatePatientActionDurationMutationVariables
      >(CreatePatientActionDuration, {
        input: {
          patientId: patientId,
          actionQuantityCoded: _actionQuantityCoded.join(""),
          duration_min: duration_min,
          startDay: startDay,
          endDay: endDay,
        },
      })
      .toPromise()
      .then((result) => {
        console.log(result);
      });
  },
  calculateTravelData: () => {
    const _missions = _.orderBy(get().missions, ["startTS", "asc"]);

    const _newMissions = [];

    if (_missions.length < 2) return;

    _newMissions.push(_missions[0]);

    for (let i = 1; i < _missions.length; i++) {
      const _newMission = { ..._missions[i] };

      const time = dayjs(Number(_missions[i].startTS)).diff(
        dayjs(Number(_missions[i - 1].endTS)),
        "minute"
      );

      _newMission.asIsTravelTime = time;

      _newMissions.push(_newMission);
    }

    set({ missions: _newMissions });
  },
}));
