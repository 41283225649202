import React, { useEffect } from "react";

import { usePatientActionDuration } from "./usePatientActionDuration";
import _ from "lodash";
import classNames from "classnames";
import dayjs from "dayjs";
import { DisplayFullPatientInfo } from "../Patient/DisplayFullPatientInfo";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { FcRight } from "react-icons/fc";
import DisplayAction from "../Action/DisplayAction";
import { BiTrash } from "react-icons/bi";
import { Button } from "../UiComponents/Button";

type Props = {
  patientId: string;
  updateParentFunction?: () => void;
};

const PatientActionDurationManage = (props: Props) => {
  const {
    setPatientId,
    patientId,
    actionDurations,
    deletePatientActionDuration,
  } = usePatientActionDuration();

  useEffect(() => {
    console.log("PatientActionDurationManage", props.patientId);
    setPatientId(props.patientId);
  }, [props.patientId]);

  return (
    <div>
      <div className="w-96">
        <DisplayFullPatientInfo patientId={patientId} />
      </div>
      {actionDurations.map((actionDuration) => {
        const _orderedActions = _.orderBy(
          actionDuration?.actionsWithQuantity,
          ["action.examinationRequired", "action.leistungskomplex"],
          ["asc", "asc"]
        );

        return (
          <div className="flex flex-row gap-2 items-center">
            <Button
              onClick={() => {
                deletePatientActionDuration(actionDuration.id);
                if (props.updateParentFunction) props.updateParentFunction();
              }}
              variant={"danger-ghost"}
            >
              <BiTrash className="h-5 w-5" />
            </Button>
            {actionDuration.startDay}
            <FcRight className="w-5 h-5" />
            {dayjs(actionDuration.endDay).diff(
              dayjs(actionDuration.startDay),
              "day"
            )}
            {" Tage "}
            <FcRight className="w-5 h-5" />
            {actionDuration.endDay}
            <FcRight className="w-5 h-5" />
            {actionDuration.duration_min} Minuten
            <div className=" flex flex-row gap-1 flex-wrap items-center text-xs ">
              <FcRight className="w-5 h-5" />
              {_orderedActions?.map((action) => {
                return <DisplayAction action={action} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PatientActionDurationManage;
